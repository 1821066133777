import React from "react";
import { useSelector } from "react-redux";
import CustomTable from "./CustomTable";

const ProductDetailsTable = () => {
  const { product } = useSelector((state: any) => state);

  const columns = [
    {
      label: "Channel",
      key: "channel",
    },

    {
      label: "Specifications",
      key: "specification",
    },

    {
      label: "Prices (BWP)",
      key: "price",
    },
    {
      label: "Extended Warranty",
      key: "extendedWarranty",
      render: (text: any, _record: any) => <p>{`${text}%`}</p>,
    },
  ];

  const formData = product?.selectedProduct?.data?.map((el: any) => {
    return {
      channel: el?.channel?.name,
      price: el?.prices
        ?.filter((d: any) => d.isActive === true)
        ?.map((d: any) => d.value)
        .toLocaleString(),
      extendedWarranty: el?.extendedWarranty,
      specification: `${el?.specification
        ?.map((d: any) => d[1] && `${d[0]}: ${d[1]}`)
        ?.join(", ")}`,
      data: el,
    };
  });

  return (
    <CustomTable
      columns={columns}
      data={formData}
      loading={product?.isFetchingPrices}
    />
  );
};

export default ProductDetailsTable;
