import React, { useState } from "react";
import { DateRangePicker } from "react-date-range";
import { DefaultStaticRanges } from "./defaultRanges";
import { enUS } from "date-fns/locale";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import "./styles.css";
// import { getShopsStatAction } from "../../../store/shop/actions";
import { formatDateToYYYYMMDD } from "../../../utils/converter";

interface DateRange {
  startDate: Date;
  endDate: Date;
}

interface DateRangeSelectorProps {
  ranges?: any[];
  onSubmit?: (selectedRange: DateRange) => void;
}

const DateRangeSelector: React.FC<DateRangeSelectorProps> = ({
  ranges = DefaultStaticRanges,
  onSubmit,
}) => {
  const formattedDate = new Date().toISOString().slice(0, 10);
  const [startDate, setStartDate] = useState(formattedDate);
  const [endDate, setEndDate] = useState(formattedDate);

  const [selectedDateRange, setSelectedDateRange] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: "selection",
  });

  const handleSelect = (ranges: any) => {
    const { selection } = ranges;
    setSelectedDateRange(selection);

    setStartDate(formatDateToYYYYMMDD(new Date(selection.startDate)));
    setEndDate(formatDateToYYYYMMDD(new Date(selection.endDate)));
  };

  return (
    <>
      <div className="shadow overflow-x-auto">
        <DateRangePicker
          onChange={handleSelect}
          showPreview
          moveRangeOnFirstSelection={false}
          months={1}
          ranges={[selectedDateRange]}
          direction="vertical"
          locale={enUS}
        />
      </div>
    </>
  );
};
export default DateRangeSelector;
