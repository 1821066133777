import React from "react";
import { Transfer } from "antd";
import "../../../../styles/antdTransferCustomStyle.css";

interface DataItem {
  key: string;
  title: string;
}

const Widget: React.FC<{
  allAccessKeys: any;
  setTargetKeys: any;
  targetKeys: any;
}> = ({ allAccessKeys, setTargetKeys, targetKeys }) => {
  const handleChange = (
    nextTargetKeys: any,
    direction: string,
    moveKeys: string[]
  ) => {
    setTargetKeys(nextTargetKeys);
  };

  const renderItem = (item: any) => item.title;

  const data = allAccessKeys?.map((d: any) => {
   
    return {
      title: d?.key,
      key: d?.key,
    };
  });

  return (
    <Transfer<DataItem>
      showSearch
      listStyle={{
        width: 500,
        height: 300,
      }}
      dataSource={data}
      targetKeys={targetKeys}
      // onChange={handleChange}
      render={renderItem}
    />
  );
};

export default Widget;
