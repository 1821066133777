/* eslint-disable @typescript-eslint/no-unused-vars */
import { Skeleton } from "@mui/material";
import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import AddItem from "../channels/priceList/AddItems";
import { useDispatch, useSelector } from "react-redux";
import ProductGrid from "../../../components/grids/priceList/ProductGrid";
import {
  getAllAttributesAction,
  getAllProductsAction,
} from "../../../store/product/actions";
import {
  getPricelistItemAction,
  getPricelistAction,
  getPricelistSearchAction,
} from "../../../store/channel/actions";
import { getActivePrice } from "../../../utils/converter";
import { PriceListHeaders } from "../../../components/csvHeaders/Headers";
import CSVExportButton from "../../../components/cards/NoSale/CSVExportButton";
import { getAllPriceList_CSVAction } from "../../../store/csvDownload/actions";
import SubLayout from "../../../components/layout/subLayout/SubLayout";
import PrimaryButton from "../../../components/buttons/PrimaryButton";
import GeneralModal from "../../../components/Modals/GeneralModal";
import InputSearch from "../../../components/inputs/InputSearch";
import { myProductActions } from "../../../store/product";

const PageView = () => {
  const { product, auth, quotation, channel, layout, CSV } = useSelector(
    (state: any) => state
  );
  const dispatch = useDispatch();
  const { mascomChannelId } = useParams();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [searchKey, setSearchKey] = React.useState(" ");
  const channelID = mascomChannelId || channel?.selected?._id;

  const handleCancel = () => {
    setIsModalOpen(false);
    getPricelistItemAction(
      auth?.token,
      `?page=${page - 1}&limit=${limit}&type=pos${
        channelID ? `&channel=${channelID}` : ""
      }`
    )(dispatch);
  };
  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const [selectedModelId, setSelectedModelId] = useState("");

  const [page, setPage] = React.useState(1);
  const [limit, setLimit] = React.useState(15);

  useEffect(() => {
    auth?.token &&
      getPricelistAction(
        auth?.token,
        `?${channelID ? `channel=${channelID}` : ""}`
      )(dispatch);
  }, [auth?.token, channelID, dispatch]);

  React.useEffect(() => {
    auth?.token &&
      channelID &&
      getPricelistItemAction(
        auth?.token,
        `?page=${page - 1}&limit=${limit}&type=pos${
          channelID ? `&channel=${channelID}` : ""
        }`
      )(dispatch);
  }, [auth?.token, channelID, dispatch, page, limit]);

  useEffect(() => {
    auth?.token &&
      getAllProductsAction(
        auth?.token,
        `?${
          layout?.selectedFilters?.type
            ? `type=${layout?.selectedFilters?.type}`
            : ""
        }
      ${
        layout?.selectedFilters?.brand
          ? `&brand=${layout?.selectedFilters?.brands}`
          : ""
      }` || "?limit=15"
      )(dispatch);
  }, [
    auth?.token,
    layout?.selectedFilters?.brand,
    layout?.selectedFilters?.type,
    quotation?.allRfqSupplier?.data,
  ]);

  dispatch(
    myProductActions.setQuery(
      `?${
        layout?.selectedFilters?.type
          ? `type=${layout?.selectedFilters?.type}`
          : ""
      }
      ${
        layout?.selectedFilters?.brand
          ? `&brand=${layout?.selectedFilters?.brands}`
          : ""
      }`
    )
  );

  useEffect(() => {
    const fetchingData = async () => {
      auth?.token &&
        getAllProductsAction(
          auth?.token,
          `?${
            layout?.selectedFilters?.type
              ? `type=${layout?.selectedFilters?.type}`
              : ""
          }
      ${
        layout?.selectedFilters?.brand
          ? `&brand=${layout?.selectedFilters?.brands}`
          : ""
      }&limit=${product?.all?.total}&page=${page - 1}` || "?"
        )(dispatch);
      auth?.token &&
        (await getAllAttributesAction(
          auth?.token,
          `?${
            layout?.selectedFilters?.type
              ? `type=${layout?.selectedFilters?.type}`
              : ""
          }
      ${
        layout?.selectedFilters?.brand
          ? `&brand=${layout?.selectedFilters?.brands}`
          : ""
      }` || "?"
        )(dispatch));
    };
    fetchingData();
  }, [
    auth,
    layout?.selectedFilters?.brand,
    layout?.selectedFilters?.type,
    dispatch,
    limit,
    page,
  ]);

  const handleSubmit = () => {
    auth?.token &&
      getPricelistItemAction(
        auth?.token,
        `?${
          layout?.selectedFilters?.product
            ? `product=${layout?.selectedFilters?.product}`
            : ""
        }${channel?.selected?._id ? `&channel=${channel?.selected?._id}` : ""}`
      )(dispatch);
  };

  useEffect(() => {
    auth?.token &&
      getPricelistItemAction(
        auth?.token,
        `?channel=${channel?.selected?._id}&limit=15&type=pos`
      )(dispatch);
  }, []);

  useEffect(() => {
    if (layout?.searchText === "") {
      auth?.token &&
        getPricelistItemAction(
          auth?.token,
          `?page=${page - 1}&limit=${limit}&type=pos${
            channelID ? `&channel=${channelID}` : ""
          }`
        )(dispatch);
    } else {
      auth?.token &&
        getPricelistSearchAction(
          auth?.token,
          `?sk=${layout?.searchText}&type=pos${
            channelID ? `&channel=${channelID}` : ""
          }`
        )(dispatch);
    }
  }, [layout?.searchText, page, limit, auth?.token, channelID, dispatch]);

  const planData = [
    {
      title: "type",
      subItems: product?.attributes?.types?.map((el: any) => {
        return {
          label: el?.value,
          value: el?.value,
        };
      }),
    },
    layout?.selectedFilters?.type && {
      title: "brands",
      subItems: product?.attributes?.brands?.map((el: any) => {
        return {
          label: el?.label,
          value: el?.value,
        };
      }),
    },
    layout?.selectedFilters?.brands && {
      title: "product",
      subItems: product?.all?.data?.map((el: any) => {
        return {
          label: el?.model,
          value: el?._id,
        };
      }),
    },
  ];

  const csvData = CSV?.csvDownloadedData?.data?.map((d: any) => ({
    amount: getActivePrice(d?.prices)?.toLocaleString(),
    model: d?.product?.model,
    type: d?.product?.type,
    brand: d?.product?.brand,
    storage: d?.specification
      ?.filter((item: any) =>
        ["capacity (rom)", "capacity(rom)", "rom", "capacity"]?.includes(
          item[0]?.toLowerCase()
        )
      )
      ?.map((item: any) => item[1]),
    memory: d?.specification
      ?.filter((item: any) =>
        ["memory (ram)", "memory (ram)", "ram", "memory"]?.includes(
          item[0]?.toLowerCase()
        )
      )
      ?.map((item: any) => item[1]),
  }));

  useEffect(() => {
    if (layout?.openCSVButton)
      auth?.token &&
        getAllPriceList_CSVAction(
          auth?.token,
          `?hasSubs=false&page=0&limit=10000}`
        )(dispatch);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [layout?.openCSVButton]);

  return (
    <>
      <SubLayout
        currentModule={channel?.selected?.name}
        subModule={"Plans"}
        setPage={setPage}
        setLimit={setLimit}
        limit={limit}
        total={channel?.priceListItems?.total}
        count={Math.ceil(channel?.priceListItems?.total / limit)}
        buttons={[
          auth?.user?.tag !== "mascom" &&
            ["admin", "finance-manager", "dev"]?.includes(
              auth?.user?.role?.toLowerCase()
            ) && (
              <PrimaryButton
                btnName="New Modal Device"
                onClick={handleOpenModal}
              />
            ),
          [
            "admin",
            "finance-manager",
            "sales-supervisor",
            "dev",
            "inventory",
            "b2b-relation",
            "sales-manager",
          ].includes(auth?.user?.role?.toLowerCase()) && (
            <>
              <CSVExportButton
                csvHeaders={PriceListHeaders}
                csvData={csvData}
                filename={`Plans.csv`}
              />
            </>
          ),
        ]}
        search={
          <div className="w-full xl:w-[25rem]">
            <InputSearch placeHolder="Search Plan" />
          </div>
        }
        filterData={planData}
        handleSubmit={handleSubmit}
        loading={channel.isFetching}
      >
        {channel?.isFetching && (
          <div
            className={`w-full grid md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-4 text-[#030229]`}
          >
            {Array.from({ length: 15 }).map((d: any, index: number) => (
              <div key={index}>
                <Skeleton animation="wave" />
                <Skeleton variant="rectangular" height={150} />
              </div>
            ))}
          </div>
        )}
        {!channel.isFetching && (
          <div
            className={`w-full grid sm:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-4 text-[#030229]`}
          >
            <ProductGrid data={channel?.priceListItems?.data} />
          </div>
        )}
        <GeneralModal
          onOpen={isModalOpen}
          onClose={handleCancel}
          width={700}
          title={"Modal Device"}
          component={
            <AddItem
              setSelectedModelId={setSelectedModelId}
              onCancel={handleCancel}
            />
          }
        />
      </SubLayout>
    </>
  );
};

export default PageView;
