import React from "react";
import { Link } from "react-router-dom";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import CustomTable from "../../reUsableTable/CustomTable";
import { Select } from "antd";
import CustomizedPieChart from "../../../pages/dashboard/subscription/overview/CustomizedPieChart";
import { COLORS } from "../../../assets/data/data";
import { useSelector } from "react-redux";
import { getDefaultCurrencyCode } from "../../../utils/helper";

const ShopPerformanceSection = (props: any) => {
  const { dashboard, company, layout } = useSelector((state: any) => state);

  const warehouseColumn = [
    { header: "Shops", key: "shop" },
    { header: `${props?.enteredDate} Quantity sold`, key: "qntyToday" },
    { header: `${props?.compareDate} Quantity sold`, key: "qntyYesterday" },
    {
      header: `${props?.enteredDate} Revenue (${getDefaultCurrencyCode(
        company
      )})`,
      key: "revenueToday",
    },
    {
      header: `${props?.compareDate} Revenue (${getDefaultCurrencyCode(
        company
      )})`,
      key: "revenueYesterday",
    },
  ];

  const data = props?.solddevice?.data?.map((d: any) => {
    return {
      shop: d?.shopName,
      qntyYesterday: d?.comparedDateQuantity?.toLocaleString(),
      qntyToday: d?.currentDateQuantity?.toLocaleString(),
      revenueYesterday: d?.comparedDateRevenue?.toLocaleString(),
      revenueToday: d?.currentDateRevenue?.toLocaleString(),
      perc:
        (d?.currentDateRevenue * 100) /
        (d?.currentDateRevenue + d?.comparedDateRevenue),
      dashPreview: layout?.dashboardPreview,
    };
  });
  const totalCurrentRevenue = props?.solddevice?.data?.reduce(
    (total: any, item: any) => total + item.currentDateRevenue,
    0
  );

  const totalComparedRevenue = props?.solddevice?.data?.reduce(
    (total: any, item: any) => total + item.comparedDateRevenue,
    0
  );

  const planData = props?.data?.data?.map((data: any) => ({
    value: data?.Applications,
    name: `${data?.duration || 0} Months Plan ( ${
      data?.Applications || 0
    } Applications)`,
  }));

  return (
    <div>
      <h1 className="pl-1 pr-4 py-6 mb-3 text-lg font-medium">
        Shop Performance and Plan Overview
      </h1>
      <div className="flex flex-col-reverse 2xl:flex-row gap-4 mt-4">
        <div className="2xl:w-[33%] relative mt-4 2xl:mt-0 x2xl:w-[50%] min-h-[450px] overflow-y-auto bg-white p-2  rounded-md">
          <div className="flex items-center justify-between pr-4 pt-2">
            <p className="text-gray-600 capitalize pl-4">Plan Overview</p>
          </div>
          <CustomizedPieChart
            data={planData}
            colors={COLORS}
            totalLabel={<h1 className="text-[15px]">Total</h1>}
            innerRad={70}
            height={"250px"}
            contentWidth={"w-full"}
            top={"40%"}
            left={"37%"}
            outerRad={90}
            totalValue={`${props?.data?.total} Plans`}
            pieHeight={200}
            pieWidth={300}
            gridNumber={1}
            hiddeValue={true}
          />
        </div>
        <div className="flex-1 relative w-full mt-4 2xl:mt-0 x2xl:w-[50%] min-h-[450px] overflow-y-auto bg-white p-2  rounded-md">
          <div className="flex items-center justify-between pr-4 pt-2">
            <div className="flex items-center gap-x-2">
              <p className="text-gray-600 capitalize pl-4">Sold Devices</p>
              {/* <Select defaultValue={"By Shop"}>
                <Select.Option value={"By Shop"}>By Shop</Select.Option>
              </Select> */}
            </div>
            {/* <Select defaultValue={"Filter By Region"}>
              <Select.Option value={"Filter By Region"}>
                Filter By Region
              </Select.Option>
            </Select> */}
          </div>
          <CustomTable
            columns={warehouseColumn}
            data={data}
            height={"300px"}
            isLoading={dashboard?.isFetching}
          />
        </div>
      </div>
    </div>
  );
};
export default ShopPerformanceSection;
