import { Stack } from "@mui/material";
import WareHouseOutGrid from "../../../../components/grids/warehouseOut/WareHouseOutGrid";
import StockOutGrid from "../../../../components/grids/warehouseOut/StockOutGrid";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box } from "@mui/system";
import {
  getAllWarehouseOutAction,
  getAllStockRequestSentAction,
  getStatusCountAction,
} from "../../../../store/wareHouseOut/actions";
import ScrollableFrame from "../../../../components/layout/ScrollableFrame";
import { myChannelActions } from "../../../../store/channel";
import { warehouseOutIncludeItem } from "../../../../assets/data/includedItem";
import { Radio } from "antd";
import SearchInput from "../../../../components/buttons/SearchButton";
import CurstomeCardSkeleton from "../../../../components/skeleton/CurstomeCardSkeleton";
import SubLayout from "../../../../components/layout/subLayout/SubLayout";
import InputSearch from "../../../../components/inputs/InputSearch";

const PageView = (props: any) => {
  const { wareHouseOut, auth, layout } = useSelector((state: any) => state);
  const dispatch = useDispatch();
  const [page, setPage] = React.useState(1);
  const [limit, setLimit] = React.useState(20);
  const [status, SetStatus] = useState<any>("");
  const statusChange = (e: any) => {
    SetStatus(e.target.value);
  };

  useEffect(() => {
    if (auth?.token) {
      getAllWarehouseOutAction(
        auth?.token,
        `?limit=${limit}${status ? `&status=${status}` : ""}&page=${
          page - 1
        }&field=${warehouseOutIncludeItem}`
      )(dispatch);

      // get all approved stock requests
      getAllStockRequestSentAction(
        auth?.token,
        `?limit=${limit}&page=${
          page - 1
        }&field=${warehouseOutIncludeItem}&status=approved`
      )(dispatch);
    }
  }, [auth?.token, dispatch, limit, page, status]);

  useEffect(() => {
    if (auth?.token) {
      getStatusCountAction(auth?.token)(dispatch);
    }
  }, [auth?.token, dispatch]);

  const stockOutData = wareHouseOut?.warehouseOut?.data?.map((el: any) => {
    return {
      id: el?._id,
      warehouseOutId: el?.warehouseOutId,
      shopName: el?.list[0]?.shop?.name,
      status: el?.status,
      data: el,
      date: el?.createdAt?.split("T")[0],
    };
  });

  const data = wareHouseOut?.allStockRequested?.data?.map((el: any) => {
    return {
      id: el.requestId,
      requestId: el?.requestId,
      shopName: el?.shopId?.name,
      status: el?.status,
      data: el,
      date: el?.createdAt?.split("T")[0],
      numberReq: el?.list?.reduce(
        (sum: number, a: any) => (sum = sum + a?.qtyRequested),
        0
      ),
    };
  });

  useEffect(() => {
    dispatch(myChannelActions.setRfsSelected(null));
  }, []);

  useEffect(() => {
    if (layout?.searchText === "") {
      auth?.token &&
        getAllWarehouseOutAction(
          auth?.token,
          `?limit=${limit}&page=${page - 1}&field=${warehouseOutIncludeItem}`
        )(dispatch);
    } else {
      auth?.token &&
        getAllWarehouseOutAction(
          auth?.token,
          `?limit=${limit}&page=${page - 1}&field=${warehouseOutIncludeItem}${
            layout?.searchText ? `&sk=${layout?.searchText}` : ""
          }`
        )(dispatch);
    }
  }, [layout?.searchText]);
  const statuses = [
    "Pending",
    "Ready For Pickup",
    "Pickup",
    "In-Transit",
    "Delivered",
  ];
  const statusData = [
    {
      title: "status",
      subItems: statuses.map((el: any) => ({
        label: el,
        value: el,
      })),
    },
  ];
  const handleSubmit = () => {
    if (auth?.token && layout?.selectedFilters?.status) {
      getAllWarehouseOutAction(
        auth?.token,
        `?limit=${limit}${
          layout?.selectedFilters?.status
            ? `&status=${layout?.selectedFilters?.status}`
            : ""
        }&page=${page - 1}&field=${warehouseOutIncludeItem}`
      )(dispatch);
    }
  };
  return (
    <SubLayout
      currentModule={props.route ?? "Warehouse out"}
      handleSubmit={handleSubmit}
      filterData={statusData}
      search={
        <div className="w-[20rem]">
          <InputSearch placeHolder="Search" />
        </div>
      }
      setPage={setPage}
      setLimit={setLimit}
      limit={limit}
      total={
        wareHouseOut?.warehouseOut?.total ||
        wareHouseOut?.allStockRequested?.total
      }
      count={Math.ceil(
        (wareHouseOut?.warehouseOut?.total ||
          wareHouseOut?.allStockRequested?.total) / limit
      )}
    >
      <div className="w-[99%]">
        <div>
          {wareHouseOut?.requestSentIsFetching ? (
            <div className="grid sm:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 w-full gap-4">
              {Array.from({ length: 8 })?.map((el: any) => (
                <CurstomeCardSkeleton />
              ))}
            </div>
          ) : (
            <div
              className={`w-full grid sm:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-4 text-[#030229]`}
            >
              <WareHouseOutGrid data={data} />
            </div>
          )}
        </div>
        <div className="items-start mt-4">
          {wareHouseOut?.isFetching ? (
            <div className="grid sm:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 w-full gap-4">
              {Array.from({ length: 8 })?.map((el: any) => (
                <CurstomeCardSkeleton />
              ))}
            </div>
          ) : (
            <div
              className={`w-full grid sm:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-4 text-[#030229]`}
            >
              <StockOutGrid data={stockOutData} />
            </div>
          )}
        </div>
      </div>
    </SubLayout>
  );
};

export default PageView;
