import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { ReactNode } from "react";

interface btnProps {
  btnName?: string;
  btnBgColor?: string;
  borderColor?: string;
  textColor?: string;
  paddingY?: string;
  width?: string;
  isLoading?: boolean;
  border?: any;
  icon?: ReactNode;
  loadingColor?: string;
  [key: string]: any;
  onClick?: () => void;
}
const PrimaryButton = ({
  btnName,
  btnBgColor = "[#605BFF]",
  borderColor = "#03022940",
  textColor = "white",
  isLoading,
  border,
  width,
  icon,
  loadingColor,
  paddingY = "2",
  onClick,
  ...props
}: btnProps) => {
  return (
    <button
      type="button"
      className={`text-${textColor} font-medium text-center ${
        border ? "border" : ""
      }  border-${borderColor} py-${paddingY} px-4 rounded-md ${
        isLoading ? "bg-gray-200" : `bg-${btnBgColor}`
      } text-sm width-${width}`}
      onClick={onClick}
      {...props}
    >
      <div className="flex gap-3 justify-center items-center">
        {isLoading && (
          <Spin
            indicator={<LoadingOutlined spin />}
            style={{ color: loadingColor }}
          />
        )}{" "}
        {icon} <p>{btnName}</p>
      </div>
    </button>
  );
};

export default PrimaryButton;
