import React from "react";
import { IconType } from "react-icons"; // Import IconType from react-icons

interface CustomIconProps {
  icon: IconType; // Change IconComponent to IconType
  color?: string;
  size?: number;
  fill?: string;
}

const CustomIcon: React.FC<CustomIconProps> = ({
  icon: Icon,
  fill,
  color,
  size = 25,
}) => {
  return <Icon color={color} size={size} fill={fill} />;
};

interface SettingProps {
  title?: any;
  details?: any;
  btn?: any;
  icon?: IconType;
  iconColor?: string;
  iconSize?: number;
  titleColor?: string;
  iconFill?: string;
}

const SettingComponent: React.FC<SettingProps> = ({
  title,
  details,
  btn,
  icon: Icon,
  iconColor,
  iconSize,
  titleColor,
  iconFill,
}) => {
  return (
    <>
      <div className="sm:flex justify-between mt-5 py-3">
        <div className="flex gap-3">
          <div className="mt-1">
            {Icon && (
              <CustomIcon
                icon={Icon}
                fill={iconFill}
                color={iconColor}
                size={iconSize}
              />
            )}
          </div>
          <div>
            <h1
              className={`text-${titleColor || "#030229"} font-medium text-lg`}
            >
              {title}
            </h1>
            <p className="text-[#030229d8] text-sm font-normal pt-2">
              {details}
            </p>
          </div>
        </div>
        <div className="mt-4 w-32">
          <h1>{btn}</h1>
        </div>
      </div>
    </>
  );
};

export default SettingComponent;
