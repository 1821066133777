import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import DashLayout from "../components/layout/Layout";

import TopNav from "../components/navitems/TopNavItems";
import { listManages } from "../assets/data/pages";
import { useSelector } from "react-redux";
import store from "store";
import { ArrowLeftOutlined } from "@ant-design/icons";

export const financeDropDownRoutes = [
  {
    caption: "Overview",
    value: "finance_overview",
    path: "/finance",
  },
  {
    caption: "Delivery Note",
    value: "finance_delivery_note",
    path: "/finance/delivery-note",
  },
  {
    caption: "Purchase Order",
    value: "finance_purchase_order",
    path: "/finance/po",
  },
  {
    caption: "No Sales",
    value: "finance_no_sales",
    path: "/finance/no-sales",
  },
  {
    caption: "Purchased",
    value: "finance_purchased",
    path: "/finance/assets",
  },
  {
    caption: "Myzaka Wallet",
    value: "finance_myzaka_wallet",
    path: "/finance/wallet",
  },
  // {
  //   caption: "Discount Mangement",
  //   path: "/finance/discount",
  //   skip: ["finance", "finance-manager"],
  // },
];

const FinanceRoutes = () => {
  const { auth } = useSelector((state: any) => state);
  const token = store.get("authToken");

  const allowedRoles = ["admin", "finance-manager", "dev"];
  const isAuthenticated = auth.token || token;
  const userRole = auth.user?.role;

  const pagesArray = [
    {
      caption: "Overview",
      path: "/finance",
      onClick: () => {
        console.log("finance:overview");
      },
    },
    {
      caption: "Orders",
      onClick: () => {
        console.log("orders:00");
      },
      sub: [
        {
          caption: "Delivery Note",
          path: "/finance/delivery-note",
          onClick: () => {
            console.log("Overview:00");
          },
          bread: [
            {
              href: "/finance",
              title: (
                <>
                  <ArrowLeftOutlined />
                  <span>Orders</span>
                </>
              ),
            },
            { title: "RFQ" },
          ],
        },
        {
          caption: "Purchase Order",
          path: "/finance/po",
          onClick: () => {
            console.log("Overview:00");
          },
        },
      ],
    },
    {
      caption: "Transactions",
      path: "/finance/transactions",
    },
    {
      caption: "No Sales",
      path: "/finance/no-sales",
    },
  ];
  if (isAuthenticated && allowedRoles.includes(userRole)) {
    return (
      <DashLayout
        allowSearch={true}
        nav={
          <TopNav
            nav={listManages?.find(
              (item) => item.title.toLowerCase() === "Finance".toLowerCase()
            )}
            pages={pagesArray}
          />
        }
        navSelected
        selectedNav={8}
      >
        <Outlet />
      </DashLayout>
    );
  } else {
    return <Navigate to="/dashboard" />;
  }
};

export default FinanceRoutes;
