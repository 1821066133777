import React, { useEffect } from "react";
import { useMediaQuery } from "@mui/material";
import CustomerCard from "../../components/grids/CustomerGridCard";
import { useDispatch, useSelector } from "react-redux";
import { getAllCustomersAction } from "../../store/customer/actions";
import { Tag } from "antd";
import { searchValue } from "../../utils/setColor";
import { useNavigate } from "react-router-dom";
import { customerIncludeItem } from "../../assets/data/includedItem";
import CSVExportButton from "../../components/cards/NoSale/CSVExportButton";
import { customerDetailsHeader } from "../../components/csvHeaders/Headers";
import { customerDetails_CSVAction } from "../../store/csvDownload/actions";
import CurstomeCardSkeleton from "../../components/skeleton/CurstomeCardSkeleton";
import SubLayout from "../../components/layout/subLayout/SubLayout";
import InputSearch from "../../components/inputs/InputSearch";
import CustomTable from "../../components/tables/CustomTable";

const PageView = () => {
  const { customer, auth, layout, CSV } = useSelector((state: any) => state);
  const [page, setPage] = React.useState(0);
  const [limit, setLimit] = React.useState(25);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const smallScreen = useMediaQuery("(max-width:768px)");

  const columns = [
    {
      label: "Name",
      key: "name",
      render: (text: any, record: any) => {
        return (
          <div className="flex gap-3 xl:items-center xl:flex-row flex-col w-full">
            <img
              className="w-10 xl:w-6 h-10 xl:h-6 rounded-full"
              src={record?.foto}
              alt="customer avatar"
            />
            <p> {text}</p>
          </div>
        );
      },
    },
    {
      label: "ID",
      key: "id",
    },
    {
      label: "Email",
      key: "email",
    },
    {
      label: "Phone",
      key: "phone",
    },
    {
      label: "Address",
      key: "adress",
    },
    {
      label: "Status",
      key: "status",
      render: (status: any) => {
        return (
          <>
            <Tag color={searchValue(status)}>{status}</Tag>
          </>
        );
      },
    },
  ];
  const customerdata = customer?.all?.data?.map((el: any) => {
    return {
      key: el?._id,
      name: el?.firstName
        ? `${el?.firstName || ""} ${el?.lastname || ""}`
        : el?.name,
      foto: el?.picture,
      id: el?.nid,
      email: el?.email,
      phone: el?.phone || el?.msisdn,
      adress: el?.address,
      status: el?.status,
    };
  });
  useEffect(() => {
    auth?.token &&
      getAllCustomersAction(
        auth?.token,
        customer?.query ||
          `?page=${page}&limit=${limit}&field=${customerIncludeItem}`
      )(dispatch);
  }, [
    auth?.token,
    customer.new,
    customer?.query,
    customer.updated,
    dispatch,
    limit,
    page,
  ]);

  useEffect(() => {
    if (layout?.searchText === null || layout?.searchText === "") {
      auth?.token &&
        getAllCustomersAction(
          auth?.token,
          customer?.query ||
            `?page=${page}&limit=${limit}&field=${customerIncludeItem}`
        )(dispatch);
    } else {
      auth?.token &&
        getAllCustomersAction(
          auth?.token,
          `?page=${page}&limit=${limit}&sk=${layout?.searchText}&field=${customerIncludeItem}`
        )(dispatch);
    }
  }, [layout?.searchText]);

  useEffect(() => {
    if (layout?.openCSVButton && auth?.token)
      customerDetails_CSVAction(
        auth?.token,
        `?limit=${customer?.all?.total}&page=0`
      )(dispatch);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [layout?.openCSVButton]);

  const csvData = CSV?.csvDownloadedData?.data?.map((el: any) => {
    return {
      customerID: el?.customerId,
      customerType: el?.customerType,
      fname: el?.firstName,
      lname: el?.lastname,
      customerPhone: el?.phone || el?.msisdn,
      gender: el?.gender || "N/A",
      dob: el?.dateOfBirth?.slice(0, 10) || "N/A",
      nationalID: el?.nationalId || "N/A",
      nationality: el?.nationality || "N/A",
      city: el?.city || "N/A",
      address: el?.address || "N/A",
      varified: el?.isVerified ? "Yes" : "No",
      tnc: el?.tnc,
      status: el?.status,
      comment: el?.comment,
    };
  });

  return (
    <SubLayout
      currentModule="Overview"
      buttons={[
        <CSVExportButton
          csvHeaders={customerDetailsHeader}
          csvData={csvData}
          filename={`Customers.csv`}
        />,
      ]}
      search={
        <div className="w-full xl:w-[25rem]">
          <InputSearch placeHolder="Search Customer" />
        </div>
      }
      loading={customer?.isFetching}
      setPage={setPage}
      setLimit={setLimit}
      limit={limit}
      total={customer?.all?.total}
      count={Math.ceil(customer?.all?.total / limit)}
      hasGridList
    >
      {(layout?.isGridView === "grid" || smallScreen) && (
        <>
          {customer?.allIsFetching ? (
            <div className="grid sm:grid-cols-2 xl:grid-cols-3 3xl:grid-cols-4 4xl:grid-cols-6 w-full gap-4">
              {Array.from({ length: 8 })?.map((el: any) => (
                <CurstomeCardSkeleton />
              ))}
            </div>
          ) : (
            <CustomerCard />
          )}
        </>
      )}
      {layout?.isGridView === "list" && !smallScreen && (
        <div className="w-full">
          <div className="w-full overflow-x-auto bg-white">
            <CustomTable
              columns={columns}
              data={customerdata}
              onRowClick={(record) => navigate(`/customers/${record?.key}`)}
              loading={customer?.isFetching}
            />
          </div>
        </div>
      )}
    </SubLayout>
  );
};
export default PageView;
