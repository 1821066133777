import { ReactComponent as Increase } from "../../../../icons/increaseIcon.svg";
import { ReactComponent as Earnings } from "../../../../icons/earning.svg";
import { ReactComponent as Tquantity } from "../../../../icons/quantity.svg";
import { ReactComponent as Tcustomer } from "../../../../icons/customers.svg";
import AgentChart from "./AgentChart";
import type { DatePickerProps } from "antd";
import { DatePicker } from "antd";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect } from "react";
import moment from "moment";
import AgentDateRange from "./AgentDateRange";
import { calculatePercentageChange } from "../../../../utils/converter";
import DetailsHeaderActionBar from "../../../../components/cards/DetailsPage/DetailsHeaderActionBar";
import { getOneUserAction } from "../../../../store/setting/actions";
import { useParams } from "react-router-dom";
import { getAllAgentRevenueAction } from "../../../../store/kpi/actions";
import GraphSkeleton from "../../../../components/skeleton/GraphSkeleton";
import CustomSkeleton from "../../../../components/skeleton/CustomSkeleton";
import ProfileSkeleton from "../../../../components/skeleton/ProfileSkeleton";
import CustomTypography from "../../../../styles/globalStyles/CustomTypography";
import SubLayout from "../../../../components/layout/subLayout/SubLayout";

const CustomerOverView = () => {
  const { auth, agentKPI, setting } = useSelector((state: any) => state);
  const dispatch = useDispatch();
  const { id } = useParams();
  let today = moment();
  const formattedDate = new Date().toISOString().slice(0, 10);
  let previousDate = today.subtract(1, "days");
  let formattedPreviousDate = previousDate.format("YYYY-MM-DD");
  const [enteredDate, setEnteredDate] = React.useState<any>(formattedDate);
  const [compareDate, setCompareDate] = React.useState<any>(
    formattedPreviousDate
  );

  const onChangeDate: DatePickerProps["onChange"] = async (
    date: any,
    dateString: any
  ) => {
    setEnteredDate(dateString);
  };

  const onChangePreviousDate: DatePickerProps["onChange"] = async (
    date: any,
    dateString: any
  ) => {
    setCompareDate(dateString);
  };

  const todayRevenue = agentKPI?.all?.data?.at(0)?.currentDateRevenue;
  const comparedRevenue = agentKPI?.all?.data?.at(0)?.comparedDateRevenue;

  const todayQuantity = agentKPI?.all?.data?.at(0)?.currentDateQuantity;
  const comparedQuantity = agentKPI?.all?.data?.at(0)?.comparedDateQuantity;

  const todayCustomer = agentKPI?.all?.data?.at(0)?.currentDateCustomer;
  const comparedCustomer = agentKPI?.all?.data?.at(0)?.comparedDateCustomer;

  const revenuePercentage = calculatePercentageChange(
    todayRevenue,
    comparedRevenue
  );

  const quantityPercentage = calculatePercentageChange(
    todayQuantity,
    comparedQuantity
  );

  const customerPercentage = calculatePercentageChange(
    todayCustomer,
    comparedCustomer
  );

  const cardData = [
    {
      title: "Earnings",
      amount: `BWP ${todayRevenue ?? 0}`,
      yesterdayAmount: `BWP ${comparedRevenue ?? 0}`,
      icon: <Earnings />,
      percent: (
        <div
          className={`flex px-1 ${
            revenuePercentage?.type === "increase"
              ? "bg-[#d4f8e56b]"
              : revenuePercentage?.type === "decrease"
              ? "bg-red-200"
              : "bg-blue-300"
          } rounded-[5px] py-[2px] absolute right-4 `}
        >
          <div className="mt-[2.5px] mr-1">
            {revenuePercentage?.type === "increase" ? (
              <Increase fill="#0FA958" />
            ) : revenuePercentage?.type === "decrease" ? (
              <Increase className="rotate-180 fill-red-500" />
            ) : (
              ""
            )}
          </div>
          <p
            className={`font-medium ${
              revenuePercentage?.type === "increase"
                ? "text-[#0FA958]"
                : revenuePercentage?.type === "decrease"
                ? "text-red-500"
                : "text-blue-900"
            } text-[blue-900] text-sm`}
          >
            {`${revenuePercentage?.percentage}%`}
          </p>
        </div>
      ),
    },
    {
      title: "Total Quantity",
      amount: `${todayQuantity ?? 0} Devices`,
      yesterdayAmount: `${comparedQuantity ?? 0} Devices`,
      icon: <Tquantity />,
      percent: (
        <div
          className={`flex px-1 ${
            quantityPercentage?.type === "increase"
              ? "bg-[#d4f8e56b]"
              : quantityPercentage?.type === "decrease"
              ? "bg-red-200"
              : "bg-blue-300"
          } rounded-[5px] py-[2px] absolute right-4 `}
        >
          <div className="mt-[2.5px] mr-1">
            {quantityPercentage?.type === "increase" ? (
              <Increase fill="#0FA958" />
            ) : quantityPercentage?.type === "decrease" ? (
              <Increase className="rotate-180 fill-red-500" />
            ) : (
              ""
            )}
          </div>
          <p
            className={`font-medium ${
              quantityPercentage?.type === "increase"
                ? "text-[#0FA958]"
                : quantityPercentage?.type === "decrease"
                ? "text-red-500"
                : "text-blue-900"
            } text-[blue-900] text-sm`}
          >
            {`${quantityPercentage?.percentage}%`}
          </p>
        </div>
      ),
    },
    {
      title: "Total Customers",
      amount: `${todayCustomer ?? 0} People`,
      yesterdayAmount: `${comparedCustomer ?? 0} People`,
      icon: <Tcustomer />,
      percent: (
        <div
          className={`flex px-1 ${
            customerPercentage?.type === "increase"
              ? "bg-[#d4f8e56b]"
              : customerPercentage?.type === "decrease"
              ? "bg-red-200"
              : "bg-blue-300"
          } rounded-[5px] py-[2px] absolute right-4 `}
        >
          <div className="mt-[2.5px] mr-1">
            {customerPercentage?.type === "increase" ? (
              <Increase fill="#0FA958" />
            ) : customerPercentage?.type === "decrease" ? (
              <Increase className="rotate-180 fill-red-500" />
            ) : (
              ""
            )}
          </div>
          <p
            className={`font-medium ${
              customerPercentage?.type === "increase"
                ? "text-[#0FA958]"
                : customerPercentage?.type === "decrease"
                ? "text-red-500"
                : "text-blue-900"
            } text-[blue-900] text-sm`}
          >
            {`${customerPercentage?.percentage}%`}
          </p>
        </div>
      ),
    },
  ];
  const disabledDate = (current: any) => {
    if (current && current > moment().endOf("day")) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    if (auth?.token) {
      getOneUserAction(id as string, auth?.token)(dispatch);
    }
  }, [id, auth?.token, dispatch]);

  useEffect(() => {
    if (auth?.token) {
      getAllAgentRevenueAction(
        auth?.token,
        `?date1=${enteredDate}&date2=${compareDate}&agentId=${id}`
      )(dispatch);
    }
  }, [auth?.token, compareDate, dispatch, enteredDate, id]);

  return (
    <SubLayout
      currentModule="Agent"
      subModule={setting?.selected?.names}
      hidePagination
      datePicker={
        <div className="pt-2 xl:pt-0 flex space-x-4 items-center">
          <DatePicker
            onChange={onChangeDate}
            defaultValue={dayjs()}
            style={{ width: 140 }}
            disabledDate={disabledDate}
            allowClear={false}
            size="large"
          />
          <div className="flex space-x-2 items-center">
            <p>Compared To</p>
            <DatePicker
              onChange={onChangePreviousDate}
              defaultValue={dayjs().subtract(1, "day")}
              style={{ width: 140 }}
              disabledDate={disabledDate}
              allowClear={false}
              size="large"
            />
          </div>
        </div>
      }
    >
      <div className="flex flex-col lg:flex-row justify-between gap-6 lg:gap-0 px-2 sm:px-0">
        <div className="bg-white shadow-[0px_4px_22px_0px_rgba(159,159,159,0.15)]  rounded-[12px]  py-6 p-6 w-full lg:w-[40%] 2xl:w-[50%] mr-4">
          <CustomTypography variant="h2"> Agent Profile</CustomTypography>
          {setting?.isFetching ? (
            <ProfileSkeleton />
          ) : (
            <div
              className={`flex flex-col sm:flex-row lg:flex-col gap-10 mt-5 2xl:flex-row`}
            >
              <img
                className="md:w-36 md:h-36 h-24 w-24 rounded-full xl:mr-14 object-cover"
                src={setting?.selected?.picture}
                alt="Profile pic"
              />
              <div className="flex flex-col gap-4">
                <span className="flex flex-row sm:flex-col gap-x-3">
                  <CustomTypography variant="label">Name</CustomTypography>
                  <CustomTypography variant="value" className="capitalize">
                    {setting?.selected?.names}
                  </CustomTypography>
                </span>
                <span className="flex flex-row sm:flex-col gap-x-3">
                  <CustomTypography variant="label">Email</CustomTypography>
                  <CustomTypography variant="value">
                    {setting?.selected?.email}
                  </CustomTypography>
                </span>
                <span className="flex flex-row sm:flex-col gap-x-3">
                  <CustomTypography variant="label">Phone</CustomTypography>
                  <CustomTypography variant="value">
                    {setting?.selected?.phone}
                  </CustomTypography>
                </span>
                <span className="flex flex-row sm:flex-col gap-x-3">
                  <CustomTypography variant="label">Role</CustomTypography>
                  <CustomTypography variant="value" className="capitalize">
                    {setting?.selected?.role?.split("-")?.join(" ")}
                  </CustomTypography>
                </span>
              </div>
            </div>
          )}
        </div>
        <div className="bg-white shadow-[0px_4px_22px_0px_rgba(159,159,159,0.15)] font-medium rounded-[12px] py-6 p-2 sm:p-6 w-full lg:flex-1">
          <CustomTypography variant="h2" className="mb-8">
            Agent Sales KPI
          </CustomTypography>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-1 xl:grid-cols-2 gap-3">
            {cardData?.map((data: any, index: number) => {
              return (
                <div
                  className=" bg-white py-5 px-4 sm:px-8 rounded-[8px] h-[10rem] shadow-[0px_4px_22px_0px_rgba(159,159,159,0.15)] text-sm relative"
                  key={index}
                >
                  {data?.percent}
                  <div className="flex gap-2 sm:gap-6 mt-7">
                    {data?.icon}
                    <span className="flex flex-col gap-[6px]">
                      <p className="font-normal text-[16px] opacity-90 "></p>
                      <CustomTypography variant="label">
                        {data?.title}
                      </CustomTypography>
                      {agentKPI?.isAllFetching ? (
                        <CustomSkeleton />
                      ) : (
                        <>
                          <CustomTypography variant="value">
                            {data?.amount?.toLocaleString()}
                          </CustomTypography>
                          <CustomTypography variant="label">
                            {data?.yesterdayAmount?.toLocaleString()}
                          </CustomTypography>
                        </>
                      )}
                    </span>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <div className="hidden sm:flex sm:flex-col-reverse 2xl:flex-row justify-between gap-4 px-2 sm:px-0 mt-5">
        <div className="shadow-[0px_4px_22px_0px_rgba(159,159,159,0.15)] w-full 2xl:w-[50%] bg-white rounded-[12px] px-10 py-1">
          <CustomTypography variant="h2" className="my-4">
            Earnings Analytics
          </CustomTypography>
          {agentKPI?.isFetching ? <GraphSkeleton /> : <AgentChart />}
        </div>
        <div className="shadow-[0px_4px_22px_0px_rgba(159,159,159,0.15)] w-full 2xl:w-[49.5%] bg-white rounded-[12px]">
          <AgentDateRange />
        </div>
      </div>
    </SubLayout>
  );
};

export default CustomerOverView;
