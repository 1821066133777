import React, { useEffect, useState } from "react";
import { Table } from "antd";
import type { ColumnsType } from "antd/es/table";
import { useSelector, useDispatch } from "react-redux";
import { getAllInventoryReportAction } from "../../../store/report/actions";
import { getAllStockReport_CSVAction } from "../../../store/csvDownload/actions";

interface DataType {
  [x: string]: any;
  key: React.Key;
  name: string;
  age: number;
  address: string;
}

const StockReportTable = (props: any) => {
  const { auth, report, layout } = useSelector((state: any) => state);
  const dispatch = useDispatch();
  const [selectedShop, setSelectedShop] = useState("");
  const [selectedModel, setSelectedBrand] = useState("");
  const [selectedRagion, setSelectedRagion] = useState("");
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);

  const encodedModel = selectedModel && encodeURIComponent(selectedModel);

  const columns: ColumnsType<DataType> = [
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
    },
    {
      title: "Shop",
      dataIndex: "shop",
      key: "shop",
    },
    {
      title: "Region",
      dataIndex: "region",
      key: "region",
    },
    {
      title: "Brand",
      dataIndex: "brand",
      key: "brand",
      render: (text: any, _) => <p className="capitalize">{text}</p>,
    },
    {
      title: "Model",
      dataIndex: "model",
      key: "model",
    },
    {
      title: "ROM",
      dataIndex: "capacity",
      key: "capacity",
    },
    {
      title: "RAM",
      dataIndex: "memory",
      key: "memory",
    },
    {
      title: "Opening Stock",
      dataIndex: "openingStock",
      key: "openingStock",
    },
    {
      title: "Closing Stock",
      dataIndex: "closingStock",
      key: "closingStock",
    },
  ];

  useEffect(() => {
    auth?.token &&
      getAllInventoryReportAction(
        auth?.token,
        `?date=${props?.dateFrom}&limit=${limit}&page=${page - 1}${
          selectedShop ? `&shop=${selectedShop}` : ""
        }${selectedModel ? `&model=${encodedModel}` : ""}`
      )(dispatch);
  }, [
    auth?.token,
    dispatch,
    selectedShop,
    selectedModel,
    selectedRagion,
    limit,
    page,
    props?.dateFrom,
    encodedModel,
  ]);

  useEffect(() => {
    if (layout?.openCSVButton)
      auth?.token &&
        getAllStockReport_CSVAction(
          auth?.token,
          `?date=${props?.dateFrom}&limit=${report?.stockReport?.total}&page=0${
            selectedShop ? `&shop=${selectedShop}` : ""
          }${selectedModel ? `&model=${encodedModel}` : ""}
          `
        )(dispatch);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [layout?.openCSVButton]);

  const dataToDisplay = report?.stockReport?.data?.map((el: any, i: number) => {
    return {
      key: i,
      date: el?.Date?.slice(0, 10),
      closingStock: el?.currentStoCK,
      openingStock: el?.OpeningStock,
      shop: el?.shopName,
      region: el?.region,
      brand: el?.Brand,
      model: el?.Model,
      capacity:
        el?.Storage?.filter((item: any) =>
          ["capacity", "capacity (rom)", "rom"]?.includes(
            item[0]?.toLowerCase()
          )
        )
          ?.map((item: any) => item[1])
          ?.at(0) ?? "N/A",
      memory:
        el?.Storage?.filter((item: any) =>
          ["memory", "memory (ram)", "ram"]?.includes(item[0]?.toLowerCase())
        )
          ?.map((item: any) => item[1])
          ?.at(0) ?? "N/A",
      data: el,
    };
  });

  useEffect(() => {
    if (selectedShop !== "") {
      setSelectedRagion("");
    }
  }, [selectedShop]);

  return (
    <Table
      id="report-table"
      columns={columns}
      dataSource={dataToDisplay}
      pagination={false}
      className="w-full bg-white overflow-x-auto"
    />
  );
};

export default StockReportTable;
