import React from "react";
import Loader from "../loading/Loader";
import CustomTypography from "../../styles/globalStyles/CustomTypography";

interface Column {
  key: string;
  label: string;
  render?: (
    value: any,
    record: Record<string, any>,
    index: number
  ) => React.ReactNode;
}

interface Action {
  label: any;
  key: string;
  className: string;
}

interface TableProps {
  columns: Column[];
  loading?: boolean;
  data: Record<string, any>[];
  actions?: Action[];
  onActionClick?: (action: string, row: Record<string, any>) => void;
  onRowClick?: (row: Record<string, any>) => void;
  isCheckBox?: boolean;
}

const CustomTable = ({
  columns,
  data,
  loading,
  actions = [],
  onActionClick,
  onRowClick,
  isCheckBox = false,
}: TableProps) => {
  return (
    <div className="relative overflow-x-auto shadow-sm sm:rounded-lg">
      <table className="w-full text-sm text-left rtl:text-right text-[#030229B2]">
        <thead className="text-xs text-[#030229] uppercase bg-[#F5F6FA]">
          <tr>
            {isCheckBox && (
              <th scope="col" className="p-4">
                <input
                  type="checkbox"
                  className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded-sm focus:ring-blue-500 focus:ring-2"
                />
              </th>
            )}
            {columns?.map((col) => (
              <th key={col.key} className="px-6 py-4">
                {col.label}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {!loading &&
            data?.map((row, index) => (
              <tr
                key={index}
                onClick={() => onRowClick && onRowClick(row)}
                className="bg-white border-b hover:bg-gray-50 cursor-pointer"
              >
                {isCheckBox && (
                  <td className="w-4 p-4">
                    <input
                      type="checkbox"
                      className="w-4 h-4 text-blue-600 bg-white border-gray-300 rounded-sm focus:ring-blue-500 focus:ring-2"
                    />
                  </td>
                )}
                {columns?.map((col) => (
                  <td key={col.key} className="px-6 py-4">
                    {col.render
                      ? col.render(row[col.key], row, index)
                      : row[col.key]}
                    {col.key === "action" && actions.length > 0 && (
                      <div className="flex space-x-2">
                        {actions?.map((action) => (
                          <button
                            key={action.key}
                            className={`${action.className} px-2 py-1 rounded-md`}
                            onClick={(e) => {
                              e.stopPropagation();
                              onActionClick && onActionClick(action.key, row);
                            }}
                          >
                            {action.label}
                          </button>
                        ))}
                      </div>
                    )}
                  </td>
                ))}
              </tr>
            ))}
          {loading && (
            <div className="min-h-64">
              <Loader />
            </div>
          )}
        </tbody>
      </table>
      {data?.length === 0 && !loading && (
        <div className="h-32 w-full mx-auto flex items-center justify-center bg-white/20 cursor-pointer">
          <CustomTypography variant="label"> No Data</CustomTypography>
        </div>
      )}
    </div>
  );
};

export default CustomTable;
