import React, { ReactNode } from "react";
import TopLeftSide from "./TopLeftSide";
import TopRightSide from "./TopRightSide";
import SearchAndGridBar from "./SearchAndGridBar";
import CustomTabs from "../../filters/CustomTabs";
import { TabsProps } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { PaginationControlled } from "../../filters/Paginate";
import FilterButton from "../../buttons/FilterButton";
interface SubItem {
  label: string;
  value: string;
}
import { myLayoutActions } from "../../../store/layout";
import GridListDisplayButton from "../../buttons/GridListDisplayButton";

interface Item {
  title: string;
  subItems: SubItem[];
}
interface SubLayoutProps {
  children: ReactNode;
  loading?: boolean;
  limit?: number;
  count?: number;
  total?: number;
  setLimit?: (limit: number) => void;
  setPage?: (page: number) => void;
  hidePagination?: boolean;
  customSize?: number;
  currentModule?: string;
  subModule?: string | string[];
  datePicker?: ReactNode;
  buttons?: ReactNode[];
  [key: string]: any;
  filterComponent?: ReactNode;
  StatusComponent?: ReactNode;
  hasGridList?: boolean;
  search?: ReactNode;
  tabs?: boolean;
  items?: TabsProps["items"];
  filterOption?: string;
  setFilterOption?: (value: any) => void;
  onChange?: (key: string) => void;
  handleSubmit?: () => void;
  filterData?: Item[];
}

const SubLayout = ({
  children,
  currentModule,
  subModule,
  datePicker,
  buttons,
  filterComponent,
  StatusComponent,
  hasGridList,
  search,
  tabs,
  filterOption,
  setFilterOption,
  loading,
  limit,
  count,
  total,
  setLimit,
  setPage,
  hidePagination,
  customSize,
  handleSubmit,
  filterData = [],
  items = [],
  ...props
}: SubLayoutProps) => {
  const { layout } = useSelector((state: any) => state);
  const dispatch = useDispatch();
  const handleTabChange = (key: string) => {
    dispatch(myLayoutActions.setSelectedTabIndex(key));
  };

  const activeTabContent = items.find(
    (tab) => tab.key === layout?.selectedTabIndex
  )?.children;

  const checkExist = search !== undefined || StatusComponent !== undefined;
  filterComponent !== undefined || tabs === true || hasGridList !== undefined;

  return (
    <div className="w-full h-full mt-3 text-[#030229]">
      <div className={`w-full max-h-[7.5rem] overflow-y-scroll scrollbar-hide`}>
        <div className="flex justify-between w-full flex-wrap">
          {currentModule || subModule ? (
            <TopLeftSide currentModule={currentModule} subModule={subModule} />
          ) : (
            <div className="flex gap-3">
              {filterData?.length > 0 && (
                <div>
                  <FilterButton
                    filterElement={filterData}
                    onSubmit={() => handleSubmit && handleSubmit()}
                  />
                </div>
              )}
              {tabs && <CustomTabs items={items} onChange={handleTabChange} />}
            </div>
          )}
          <TopRightSide datePicker={datePicker} buttons={buttons} {...props} />
        </div>
        <div className="flex justify-between w-full my-4 flex-wrap">
          {(currentModule || subModule) && (
            <div className="flex gap-3">
              {filterData?.length > 0 && (
                <div>
                  <FilterButton
                    filterElement={filterData}
                    onSubmit={() => handleSubmit && handleSubmit()}
                    loading={loading}
                  />
                </div>
              )}
              {tabs && <CustomTabs items={items} onChange={handleTabChange} />}
            </div>
          )}
          <div className="mt-3 lg:mt-0">
            <SearchAndGridBar
              search={search}
              gridList={hasGridList && <GridListDisplayButton />}
            />
          </div>
        </div>
      </div>
      <div
        className={`p-2 ${
          checkExist
            ? "h-[calc(100vh-240px)]"
            : `${
                hidePagination
                  ? "h-[calc(100vh-200px)]"
                  : "h-[calc(100vh-220px)]"
              }`
        } overflow-y-auto`}
      >
        {tabs ? (
          <div className="flex flex-col gap-y-6">
            {children}
            {activeTabContent}
          </div>
        ) : (
          children
        )}
      </div>
      {!hidePagination && (
        <PaginationControlled
          loading={loading}
          setPage={setPage}
          setLimit={setLimit}
          limit={limit}
          total={total}
          count={count}
        />
      )}
    </div>
  );
};

export default SubLayout;
