import React from "react";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import { Skeleton } from "antd";
import { calculateTotalAmountPaid } from "../../../../../utils/converter";
import Mascom from "../../../../../assets/icons/dashboard/logomascom.png";
import BillingCard from "./BillingCard";
import { getDefaultCurrencyCode } from "../../../../../utils/helper";
// import CustomerBillingCard from "../../../customer/Billings/BillingCard";

type DataItem = {
  title: string;
  value: any;
};

const HeadersComponent = ({ data }: { data: DataItem[] }) => {
  const { account, invoice, company, customer } = useSelector(
    (state: any) => state
  );

  const productsPrice = account?.selected?.deliveryNote?.list?.flatMap(
    (item: any) => item?.orderItem?.product?.prices?.map((d: any) => d?.value)
  );

  const totalProductsPrice =
    invoice?.all?.data &&
    invoice?.all?.data?.at(0)?.amountToBePaid *
      invoice?.all?.data?.at(0)?.account?.duration;

  const totalPaidAmount = calculateTotalAmountPaid(invoice?.all?.data);

  let totalAmount = 0;
  invoice?.all?.data
    .filter((item: any) => item.status !== "paid")
    .map((item: any) => {
      return (totalAmount = totalAmount + item?.amountToBePaid);
    });

  return (
    <>
      {account?.isFetching ? (
        <Skeleton />
      ) : (
        <div className="mb-4 mt-4 py-3  rounded-md text-gray-900  flex flex-col md:flex-row   h-auto md:h-[350px] capitalize font-poppins">
          <div className=" w-[100%]  h-auto md:h-40 ">
            <div className="bg-white rounded-md border border-[#EBEFF2] h-[220px]  pt-2 md:pt-auto">
              <div className="border-b px-4">
                <div className="flex flex-wrap items-center justify-between font-poppins font-medium text-base leading-6 ml-2 pt-1 pb-4">
                  <p> Current Plan Summary </p>
                </div>
              </div>
              <div className="bg-white w-[100%] h-[100px] rounded-md px-4 py-2 space-y-2 ">
                <div className="flex flex-row justify-between font-poppins font-medium text-base leading-6 ml-2 pt-2">
                  <div>
                    <div className="text-gray-500 font-poppins">Plan Type</div>
                    <div className="text-[#030229] font-poppins   leading-21">
                      {account?.selected?.businessProduct?.name}
                    </div>
                  </div>
                  <div>
                    <div className="text-gray-500 font-poppins">
                      Installment
                    </div>
                    <div className="font-poppins">
                      {account?.selected?.planType}
                    </div>
                  </div>
                  <div>
                    <div className="text-gray-500">amount</div>
                    <div className="font-poppins ">
                      {`${getDefaultCurrencyCode(
                        company
                      )} ${invoice?.all?.data[0]?.amountToBePaid?.toLocaleString()}`}
                    </div>
                  </div>
                </div>
                {account?.selected?.type?.toLowerCase() !== "b2b" && (
                  <div className="px-2">
                    <div className="text-gray-500 ">initial payment</div>
                    <div>{`${getDefaultCurrencyCode(company)} ${
                      productsPrice || 0
                    } `}</div>
                  </div>
                )}
              </div>
            </div>

            {/* //TODO:: Uncomment when API updated with device info */}
            {/* <div className=" bg-white flex-1 w-full rounded-md mt-0 md:mt-0 h-[220px]">
              <div className="flex justify-between border-b px-4 py-4">
                <h1 className="text-[#030229] text-base">
                  Current plan Summary
                </h1>
              </div>
              <div className="grid sm:grid-cols-2 lg:grid-cols-3 gap-2 p-4">
                <CustomerBillingCard
                  name={"Installment"}
                  secondName={account?.all?.data[0]?.planType}
                />
                <CustomerBillingCard
                  name={"Plan duration"}
                  secondName={
                    account?.all?.data[0]?.duration
                      ? `${account?.all?.data[0]?.duration} Months`
                      : ""
                  }
                />
                <CustomerBillingCard
                  name={"Plan Type"}
                  secondName={account?.selected?.businessProduct?.name}
                />
                <CustomerBillingCard
                  name={"Starting Date"}
                  secondName={dayjs(account?.all?.data[0]?.startingDate).format(
                    "YYYY-MM-DD"
                  )}
                />
                <CustomerBillingCard
                  name={"Ending Date"}
                  secondName={dayjs(account?.all?.data[0]?.endingDate).format(
                    "YYYY-MM-DD"
                  )}
                />
                <CustomerBillingCard
                  name={"Total Amount"}
                  secondName={`${getDefaultCurrencyCode(
                    company
                  )} ${customer?.customerMetrics?.totalExpectedAmount?.toLocaleString()}`}
                />
              </div>
            </div> */}
            <div className="grid xl:grid-cols-2  gap-4 pt-4">
              <BillingCard
                value={invoice?.all?.data[0]?.payment?.date?.slice(0, 10)}
                name={"previous date"}
              />
              <BillingCard
                value={`${getDefaultCurrencyCode(
                  company
                )} ${invoice?.all?.data[0]?.amountToBePaid?.toLocaleString()}`}
                name={"previous billing amount"}
              />
            </div>
          </div>
          <div className=" w-[100%]  h-auto md:h-40 ">
            <div className="bg-white border border-[#EBEFF2] rounded-md mx-4 h-[220px]  pt-1 md:pt-auto">
              <div className="border-b pb-[1.35rem] px-4">
                <div className="flex flex-row justify-between font-poppins font-medium text-base leading-6 ml-2 pt-2">
                  <p> Payment Method </p>
                  <p></p>
                </div>
              </div>
              <div className="bg-white w-[100%] h-[100px] rounded-md py-4 px-4 space-y-2">
                <div className="flex flex-row justify-between font-poppins rounded-md font-medium text-base leading-6 pt-2 h-16 border border-[#EBEFF2]">
                  <div className="p-2 flex flex-row gap-2">
                    <img src={Mascom} alt="Mascom" />
                    <p>MyZaka Mascom Money</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-col xl:flex-row mx-4 gap-4 pt-4">
              <BillingCard
                value={dayjs(invoice?.all?.data[0]?.dueDate).format(
                  "YYYY-MM-DD"
                )}
                name={"next billing date"}
              />
              <BillingCard
                value={`${getDefaultCurrencyCode(company)} ${
                  totalAmount.toLocaleString() ?? 0
                }`}
                name={"next bill amount"}
              />
            </div>
          </div>
          <div className="w-[100%]  h-auto md:h-40 space-y-3 ">
            <div className="flex flex-row   md:pt-auto gap-4 mt-2 xl:mt-0">
              <BillingCard
                value={dayjs(account?.selected?.startingDate).format(
                  "YYYY-MM-DD"
                )}
                name={"start date"}
              />
              <BillingCard
                value={dayjs(account?.selected?.endingDate).format(
                  "YYYY-MM-DD"
                )}
                name={"end date"}
              />
            </div>
            <div className="flex flex-row md:pt-auto gap-4">
              <BillingCard
                value={
                  account?.selected?.planType === "Weekly"
                    ? `${getDefaultCurrencyCode(company)} ${(
                        Number(invoice?.all?.data[0]?.amountToBePaid) *
                        Number(account?.selected?.numberOfWeeks)
                      ).toLocaleString()}`
                    : account?.selected?.planType === "Daily"
                    ? `${getDefaultCurrencyCode(company)} ${(
                        Number(invoice?.all?.data[0]?.amountToBePaid) *
                        Number(account?.selected?.numberOfDays)
                      ).toLocaleString()}`
                    : `${getDefaultCurrencyCode(company)} ${(
                        Number(invoice?.all?.data[0]?.amountToBePaid) *
                        Number(account?.selected?.duration)
                      ).toLocaleString()}`
                }
                name={"expected amount"}
              />
              <BillingCard
                value={`${account?.selected?.duration} months`}
                name={"installment period"}
              />
            </div>
            <div className="flex flex-col xl:flex-row md:pt-auto gap-4 ">
              <BillingCard
                value={`${getDefaultCurrencyCode(company)} ${
                  totalPaidAmount?.toLocaleString() ?? 0
                }`}
                name={"paid amount"}
              />
              <BillingCard
                value={`${getDefaultCurrencyCode(company)} ${
                  (totalProductsPrice - totalPaidAmount)?.toLocaleString() || 0
                } `}
                name={"Remain amount"}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default HeadersComponent;
