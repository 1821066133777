import { createSlice } from "@reduxjs/toolkit";

const mySlice = createSlice({
  name: "channel",
  initialState: {
    isFetching: false,
    isOneFetching: false,
    getShopIsFetching: false,
    shopReqIsFetching: false,
    gettingReqIsFetching: false,
    isGettingOverview: false,
    isAdding: false,
    all: { data: [] },
    selected: null,
    allAgent: null,
    allShop: null,
    allRegion: null,
    newProfile: null,
    allCommissionProfile: null,
    attributes: null,
    reqStock: null,
    new: null,
    updated: null,
    query: null,
    priceList: null,
    priceListItems: null,
    isPriceListUpdating: false,
    selectedRfs: null,
    products: null,
    priceListItemToUpdate: null,
    priceListSearch: null,
    onePriceListItem: null,
    subPriceListItems: null,
    allCommission: null,
    selectedPriceListItems: null,
    deviceWithCommission: null,
    shopOverView: null,
    salesStatiscs: null,
    newCommission: null,
    commissionTransaction: null,
    commissionTransactionByShop: null,
    allCommissionTransaction: null,
    commissionBychannel: null,
    oneCommission: null,
    subPriceList: null,
    subPlans: null,
    allRegions: null,
    selectedRegion: null,
    shopType: null,
    newShopType: null,
    overviewData: null,
  },
  reducers: {
    setAll(state, action) {
      state.all = action.payload;
    },
    setSubsPlans(state, action) {
      state.subPlans = action.payload;
    },
    setAttributes(state, action) {
      state.attributes = action.payload;
    },
    setAllRegion(state, action) {
      state.allRegions = action.payload;
    },
    setSelectedRegion(state, action) {
      state.selectedRegion = action.payload;
    },
    setQuery(state, action) {
      state.query = action.payload;
    },
    setNew(state, action) {
      state.new = action.payload;
    },
    setUpdated(state, action) {
      state.updated = action.payload;
    },
    setSelected(state, action) {
      state.selected = action.payload;
    },
    setAgents(state, action) {
      state.allAgent = action.payload;
    },
    setShops(state, action) {
      state.allShop = action.payload;
    },
    setShopType(state, action) {
      state.shopType = action.payload;
    },
    setRegion(state, action) {
      state.allRegion = action.payload;
    },
    setNewProfile(state, action) {
      state.newProfile = action.payload;
    },
    setAllCommissionProfile(state, action) {
      state.allCommissionProfile = action.payload;
    },
    setIsFetching(state, action) {
      state.isFetching = action.payload;
    },
    setPriceList(state, action) {
      state.priceList = action.payload;
    },
    setPriceListItems(state, action) {
      state.priceListItems = action.payload;
    },
    setSubPriceListItems(state, action) {
      state.subPriceListItems = action.payload;
    },
    setIsPriceListUpdating(state, action) {
      state.isPriceListUpdating = action.payload;
    },
    setRequestedStock(state, action) {
      state.reqStock = action.payload;
    },
    setRfsSelected(state, action) {
      state.selectedRfs = action.payload;
    },
    setProducts(state, action) {
      state.products = action.payload;
    },
    setPriceListItemToUpdate(state, action) {
      state.priceListItemToUpdate = action.payload;
    },
    setPriceListSearch(state, action) {
      state.priceListSearch = action.payload;
    },
    setOnePriceListItem(state, action) {
      state.onePriceListItem = action.payload;
    },
    setIsOneFetching(state, action) {
      state.isOneFetching = action.payload;
    },
    setAllCommission(state, action) {
      state.allCommission = action.payload;
    },
    setSelectedPriceListItems(state, action) {
      state.selectedPriceListItems = action.payload;
    },
    setDeviceWithCommission(state, action) {
      state.deviceWithCommission = action.payload;
    },
    setShopOverView(state, action) {
      state.shopOverView = action.payload;
    },
    setSalesStatiscs(state, action) {
      state.salesStatiscs = action.payload;
    },
    setNewCommission(state, action) {
      state.newCommission = action.payload;
    },
    setCommissionTransaction(state, action) {
      state.commissionTransaction = action.payload;
    },
    setCommissionTransactionByShop(state, action) {
      state.commissionTransactionByShop = action.payload;
    },
    setAllCommissionTransaction(state, action) {
      state.allCommissionTransaction = action.payload;
    },
    setAllCommissionByChannel(state, action) {
      state.commissionBychannel = action.payload;
    },
    setOneCommussionChannel(state, action) {
      state.oneCommission = action.payload;
    },
    setSubPriceList(state, action) {
      state.subPriceList = action.payload;
    },
    setNewShopType(state, action) {
      state.newShopType = action.payload;
    },
    setGetShopIsFetching(state, action) {
      state.getShopIsFetching = action.payload;
    },
    setShopReqIsFetching(state, action) {
      state.shopReqIsFetching = action.payload;
    },
    setGettingReqIsFetching(state, action) {
      state.gettingReqIsFetching = action.payload;
    },
    setIsAdding(state, action) {
      state.isAdding = action.payload;
    },
    setOverviewData(state, action) {
      state.overviewData = action.payload;
    },
    setIsGettingOverview(state, action) {
      state.isGettingOverview = action.payload;
    },
  },
});

export const myChannelActions = mySlice.actions;

export default mySlice.reducer;
