import React, { ReactNode } from "react";
import Carousel from "better-react-carousel";
import CurstomeCardSkeleton from "../../skeleton/CurstomeCardSkeleton";
import "../../../styles/cardLayout.css";

interface CardSliderProps {
  children: ReactNode;
  isLoading: boolean;
  initialItems?: number;
}

const CardLayout: React.FC<CardSliderProps> = ({
  children,
  initialItems,
  isLoading,
}) => {
  return (
    <div className="pt-2">
      {isLoading ? (
        <div className="w-full grid sm:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 gap-3 text-[#030229]">
          {Array.from({ length: 4 })?.map((el: any) => (
            <CurstomeCardSkeleton />
          ))}
        </div>
      ) : (
        <Carousel
          cols={initialItems || 4}
          scrollSnap
          responsiveLayout={[
            { breakpoint: 1600, cols: 3, rows: 1, scrollSnap: true },
            { breakpoint: 1200, cols: 2, rows: 1, scrollSnap: true },
            { breakpoint: 600, cols: 2, rows: 1, scrollSnap: true },
            { breakpoint: 480, cols: 1, rows: 1, scrollSnap: true },
          ]}
        >
          {React.Children.toArray(children)?.map((child, index) => (
            <Carousel.Item key={index}>{child}</Carousel.Item>
          ))}
        </Carousel>
      )}
    </div>
  );
};

export default CardLayout;
