import React, { useEffect, useState } from "react";
import { Button, Divider, Form, Input, Select } from "antd";
import { LeftCircleOutlined, MinusCircleOutlined } from "@ant-design/icons";
import { useSelector, useDispatch } from "react-redux";
import {
  createOrderAction,
  updateOneAccountAction,
} from "../../../../../store/account/actions";
import { getOneSubsOrderAction } from "../../../../../store/subscription/order/actions";
import {
  handleFoundCustomerByPhone,
  handleNewCustomer,
  handlerUpdateAccountAction,
  handleSetSubscriptionDealSteps,
} from "../../../../../store/layout/actions";
import PhoneInput from "react-phone-input-2";
import SearchCustomer from "./SearchCustomer";
import {
  createCustomerAction,
  updateCustomerAction,
} from "../../../../../store/customer/actions";
import { useParams } from "react-router-dom";
import UploadDocs from "./UploadDocs";
import { myChannelActions } from "../../../../../store/channel";

interface FormData {
  name: string;
  url: string;
  owner: string;
  type: string;
  approver: string;
  // dateTime: Moment | null;
  description: string;
}

const CustomerProfile = (props: any) => {
  const [form] = Form.useForm<FormData>();
  const dispatch = useDispatch();
  const { dealId } = useParams();
  const { orders, order, auth, layout, account, customer } = useSelector(
    (state: any) => state
  );

  const [selectedCustomerType, setSelectedCustomerType] = useState(null);
  const [contactsAdded, setContactsAdded] = useState(false);

  const onFinishAccount = async (values: any) => {
    const custmerPayload = {
      ...values,
      phone: values?.tel,
      subscriptionInfo: {
        customerType: values?.customerType,
        address: {
          country: values?.country,
        },
      },
    };

    const otherPayload = {
      contacts: values.contacts && [...values.contacts],
      customer: layout?.foundCustomerByPhone?._id,
      customerDetails: {
        ...values,
      },
    };
    delete otherPayload?.customerDetails?.contacts;
    // await handleSetSubscriptionDealSteps(1)(dispatch);

    if (layout?.isNewCustomer && !orders?.selected?.account?.customer) {
      const isCustomerCreated = await createCustomerAction(
        auth?.token,
        custmerPayload
      )(dispatch);

      if (isCustomerCreated?.type) {
        const payload = {
          contacts: values.contacts && [...values.contacts],
          customer: isCustomerCreated?.data?._id,
          customerDetails: {
            ...values,
          },
        };
        delete payload?.customerDetails?.contacts;

        const res = await createOrderAction(auth?.token, {
          order: dealId,
          ...payload,
        })(dispatch);
        if (res) {
          form.resetFields();
          await getOneSubsOrderAction(auth?.token, dealId as string)(dispatch);
          await handleSetSubscriptionDealSteps(1)(dispatch);
        }
      }
    } else {
      const isUpdated = await updateCustomerAction(
        auth?.token,
        layout?.foundCustomerByPhone?._id ||
          orders?.selected?.account?.customer?._id,
        custmerPayload
      )(dispatch);
      if (isUpdated) {
        if (!orders?.selected?.account?.customer) {
          const res = await createOrderAction(auth?.token, {
            order: dealId,
            ...otherPayload,
          })(dispatch);
          if (res) {
            form.resetFields();
            await getOneSubsOrderAction(
              auth?.token,
              dealId as string
            )(dispatch);
            await handleSetSubscriptionDealSteps(1)(dispatch);
          }
        } else {
          const res = await updateOneAccountAction(
            auth?.token,
            orders?.selected?.account?._id,
            otherPayload
          )(dispatch);
          if (res) {
            await handlerUpdateAccountAction(false)(dispatch);
            await getOneSubsOrderAction(
              auth?.token,
              dealId as string
            )(dispatch);
            await handleSetSubscriptionDealSteps(1)(dispatch);
          }
        }
      }
    }

    // if (!layout?.isNewCustomer && layout?.foundCustomerByPhone !== null) {
    //   const res = await createOrderAction(auth?.token, {
    //     order: dealId,
    //     ...otherPayload,
    //   })(dispatch);
    //   if (res) {
    //     form.resetFields();
    //     await getOneSubsOrderAction(auth?.token, dealId)(dispatch);
    //     await handleSetSubscriptionDealSteps(1)(dispatch);
    //   }
    // }

    // if (layout?.isNewCustomer && orders?.selected?.account?.customer) {
    //   const res = await updateOneAccountAction(
    //     auth?.token,
    //     orders?.selected?.account?._id,
    //     otherPayload
    //   )(dispatch);
    //   if (res) {
    //     await handlerUpdateAccountAction(false)(dispatch);
    //     await getOneSubsOrderAction(auth?.token, dealId)(dispatch);
    //     await handleSetSubscriptionDealSteps(1)(dispatch);
    //   }
    // }

    await handleFoundCustomerByPhone(null)(dispatch);
  };

  const initialData = {
    ...orders?.selected?.account?.customerDetails,
    ...orders?.selected?.account?.customer,
    ...layout?.foundCustomerByPhone,
    country:
      layout?.foundCustomerByPhone?.subscriptionInfo?.address?.country ??
      orders?.selected?.account?.customer?.subscriptionInfo?.address?.country,
    tel:
      layout?.foundCustomerByPhone?.phone ??
      orders?.selected?.account?.customerDetails?.tel,
  };

  useEffect(() => {
    if (
      orders?.selected?.account !== undefined ||
      layout?.foundCustomerByPhone !== null
    ) {
      form.setFieldsValue({
        ...orders?.selected?.account?.customerDetails,
        ...orders?.selected?.account?.customer,
        ...layout?.foundCustomerByPhone,
        country:
          layout?.foundCustomerByPhone?.subscriptionInfo?.address?.country ??
          orders?.selected?.account?.customer?.subscriptionInfo?.address
            ?.country,
        tel:
          layout?.foundCustomerByPhone?.phone ??
          orders?.selected?.account?.customerDetails?.tel,
      });
    } else {
      form.resetFields();
    }
  }, [form, orders?.selected?.account, layout?.foundCustomerByPhone]);

  const handleBack = () => {
    handleNewCustomer(false)(dispatch);
    handleFoundCustomerByPhone(null)(dispatch);
  };

  const defaultContactsData = orders?.selected?.account?.contacts?.map(
    (el: any) => {
      return {
        name: el?.name,
        email: el?.email,
        phone: el?.phone,
      };
    }
  );

  useEffect(() => {
    dispatch(myChannelActions.setSubsPlans(null));
    dispatch(myChannelActions.setSelected(null));
  }, [dispatch]);

  return (
    <>
      <div className="flex items-center justify-end mb-6">
        {orders?.selected?.status !== "created" && (
          <>
            {(layout?.foundCustomerByPhone !== null ||
              layout?.isNewCustomer) && (
              <div
                className="rounded-full h-6 w-6 text-center bg-blue-500 text-white cursor-pointer"
                onClick={handleBack}
              >
                <LeftCircleOutlined />
              </div>
            )}
          </>
        )}
      </div>
      {layout?.foundCustomerByPhone === null && !layout?.isNewCustomer ? (
        <SearchCustomer />
      ) : (
        <Form
          form={form}
          layout="vertical"
          onFinish={onFinishAccount}
          initialValues={initialData || {}}
        >
          <div className="h-[calc(100vh-340px)] overflow-y-auto">
            <div className="flex-1 h-full">
              <div className="grid md:grid-cols-2 gap-x-4 pl-18">
                <Form.Item
                  label={
                    <span className="font-medium text-sm">Customer Name</span>
                  }
                  name="name"
                  rules={[
                    { required: true, message: "Please enter customer name" },
                  ]}
                >
                  <Input style={{ height: 45 }} />
                </Form.Item>
                <Form.Item
                  label={
                    <span className="font-medium text-sm">Phone Number</span>
                  }
                  name="tel"
                  rules={[
                    { required: true, message: "Please enter Phone Number" },
                  ]}
                >
                  <PhoneInput
                    country={"bw"}
                    buttonStyle={{
                      border: "none",
                      borderRight: "3px solid #fff",
                    }}
                    inputStyle={{
                      width: "100%",
                      height: "45px",
                      backgroundColor: "#f3f4f6",
                      border: "none",
                      color: "#000",
                    }}
                    containerClass="phone-container"
                  />
                </Form.Item>
                <Form.Item
                  label={<span className="font-medium text-sm">Country</span>}
                  name="country"
                  rules={[{ required: true, message: "" }]}
                >
                  <Input
                    placeholder="Please enter Country"
                    style={{ height: 45 }}
                  />
                </Form.Item>
                <Form.Item
                  label={<span className="font-medium text-sm">Address</span>}
                  name="address"
                  rules={[
                    {
                      required: true,
                      message: "Please enter customer address",
                    },
                  ]}
                >
                  <Input
                    placeholder="Please enter customer address"
                    style={{ height: 45 }}
                  />
                </Form.Item>
                <Form.Item
                  label={<span className="font-medium text-sm">Email</span>}
                  name="email"
                  rules={[{ required: true, message: "Please enter Email" }]}
                >
                  <Input
                    placeholder="Please enter Email"
                    style={{ height: 45 }}
                  />
                </Form.Item>
                <Form.Item
                  name={"customerType"}
                  label={
                    <span className="font-medium text-sm">Customer Type</span>
                  }
                  style={{ minWidth: 200 }}
                >
                  <Select
                    showSearch
                    allowClear
                    style={{ height: 45 }}
                    placeholder="Select"
                    optionFilterProp="children"
                    onChange={(value) => setSelectedCustomerType(value)}
                  >
                    {[
                      "Individual",
                      "Private Business",
                      "Telecom Business",
                    ]?.map((cust: any) => {
                      return (
                        <Select.Option key={cust} value={cust}>
                          {cust}
                        </Select.Option>
                      );
                    })}
                  </Select>
                </Form.Item>
                <Form.Item
                  label={<span className="font-medium text-sm">TIN</span>}
                  name="tin"
                >
                  <Input
                    placeholder="Please enter TIN"
                    style={{ height: 45 }}
                  />
                </Form.Item>
                {selectedCustomerType === "Individual" && (
                  <Form.Item
                    label={<span className="font-medium text-sm">NID</span>}
                    name="nationalId"
                  >
                    <Input
                      placeholder="Please enter NID"
                      style={{ height: 45 }}
                    />
                  </Form.Item>
                )}
              </div>

              <Divider orientation="center">Add Contacts</Divider>
              <Form.List
                name="contacts"
                initialValue={defaultContactsData ?? []}
              >
                {(
                  fields: { [x: string]: any; key: any; name: any }[],
                  { add, remove }: any
                ) => (
                  <>
                    <Form.Item>
                      <Button
                        onClick={() => {
                          add();
                          setContactsAdded(true);
                        }}
                        className="p-[23px] text-blue-400 border-blue-200"
                      >
                        Add Contacts
                      </Button>
                    </Form.Item>
                    {fields.map(({ key, name, ...restField }) => (
                      <div className="grid sm:grid-cols-2 2xl:grid-cols-3 gap-x-4 flex-1 ">
                        <Form.Item
                          {...restField}
                          label={
                            <span className="font-medium text-sm">Names</span>
                          }
                          name={[name, "name"]}
                          rules={[{ required: true, message: "Missing names" }]}
                          style={{ minWidth: 200 }}
                        >
                          <Input placeholder="Names" style={{ height: 45 }} />
                        </Form.Item>
                        <Form.Item
                          {...restField}
                          label={
                            <span className="font-medium text-sm">
                              Phone Number
                            </span>
                          }
                          name={[name, "phone"]}
                          rules={[
                            {
                              required: true,
                              message: "Phone is required!",
                              whitespace: true,
                            },
                          ]}
                          style={{ minWidth: 200 }}
                        >
                          <PhoneInput
                            country={"bw"}
                            buttonStyle={{
                              border: "none",
                              borderRight: "3px solid #fff",
                            }}
                            inputStyle={{
                              width: "100%",
                              height: "45px",
                              backgroundColor: "#f3f4f6",
                              border: "none",
                              color: "#000",
                            }}
                            containerClass="phone-container"
                          />
                        </Form.Item>
                        <div className="flex">
                          <Form.Item
                            {...restField}
                            label={
                              <span className="font-medium text-sm">Email</span>
                            }
                            name={[name, "email"]}
                            rules={[
                              {
                                required: true,
                                message: "email is required!",
                                type: "email",
                              },
                            ]}
                            style={{ minWidth: 200 }}
                          >
                            <Input
                              placeholder="enter your email"
                              style={{ height: 45 }}
                            />
                          </Form.Item>
                          <MinusCircleOutlined
                            onClick={() => remove(name)}
                            className="text-red-500 p-2 w-32"
                          />
                        </div>
                      </div>
                    ))}
                  </>
                )}
              </Form.List>
            </div>
          </div>
          <div className="2xl:flex xitems-center justify-between mt-2">
            <UploadDocs />
            <Form.Item className="mt-5 2xl:mt-0">
              <Button
                htmlType="submit"
                className="bg-[#6F57FF] text-white py-6"
                disabled={
                  account?.isFetching ||
                  customer?.isFetching ||
                  order?.isFetching ||
                  account?.isFetching
                }
                loading={
                  account?.isFetching ||
                  customer?.isFetching ||
                  order?.isFetching ||
                  account?.isFetching
                }
              >
                Save and continue
              </Button>

              {orders?.selected?.account?.customer && (
                <Button
                  className="border border-blue-400 text-blue-500 px-10 ml-4 py-6"
                  onClick={() => handleSetSubscriptionDealSteps(1)(dispatch)}
                >
                  Skip
                </Button>
              )}
            </Form.Item>
          </div>
        </Form>
      )}
    </>
  );
};

export default CustomerProfile;
