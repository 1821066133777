import { Dropdown, MenuProps } from "antd";
import React from "react";
import SettingsIcon from "@mui/icons-material/Settings";
import ExportCSVFile from "./ExportCSVFile";

const CsvDownloadButton: React.FC<{
  csvHeaders: any;
  csvData: any;
  filename: any;
}> = ({ csvHeaders, csvData, filename }) => {
  return (
    <div>
      <ExportCSVFile
        csvHeaders={csvHeaders}
        csvData={csvData || []}
        filename={filename}
      />
    </div>
  );
};

export default CsvDownloadButton;
