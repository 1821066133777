import React from "react";
import { Divider } from "antd";
import CustomTypography from "../../../styles/globalStyles/CustomTypography";

const DelivererIn: React.FC<{
  data: any;
}> = ({ data }) => {
  return (
    <div>
      <CustomTypography variant="h2">Deliverer In</CustomTypography>
      <div className="flex justify-between py-1">
        <CustomTypography variant="label">Name</CustomTypography>
        <CustomTypography variant="value">{data?.name}</CustomTypography>
      </div>
      <div className="flex justify-between py-1">
        <CustomTypography variant="label">Company</CustomTypography>
        <CustomTypography variant="value">{data?.company}</CustomTypography>
      </div>

      <div className="flex justify-between py-1">
        <CustomTypography variant="label">Telephone</CustomTypography>
        <CustomTypography variant="value">{data?.phone}</CustomTypography>
      </div>
      <div className="flex justify-between py-1">
        <CustomTypography variant="label">Email</CustomTypography>
        <CustomTypography variant="value">{data?.email}</CustomTypography>
      </div>
      <div className="flex justify-between py-1">
        <CustomTypography variant="label">date</CustomTypography>
        <CustomTypography variant="value">
          {data?.date?.slice(0, 10)}
        </CustomTypography>
      </div>

      <Divider />
    </div>
  );
};

export default DelivererIn;
