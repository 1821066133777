import { Paper, Stack, Box, Skeleton } from "@mui/material";
import SupplierTable from "../../../components/tables/SupplierTable";
import SupplierGrid from "../../../components/grids/SupplierGrid";
import React, { useState, useEffect } from "react";
import AddNewSupplier from "../../../components/forms/NewSupplierForm";
import { useDispatch, useSelector } from "react-redux";
import { getAllSuppliersAction } from "../../../store/supplier/actions";
import { getAllAttributesAction } from "../../../store/product/actions";
import { mySupplierActions } from "../../../store/supplier";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { supplierIncludeItem } from "../../../assets/data/includedItem";
import SubLayout from "../../../components/layout/subLayout/SubLayout";
import GeneralModal from "../../../components/Modals/GeneralModal";
import PrimaryButton from "../../../components/buttons/PrimaryButton";

const PageView = (props: any) => {
  const { auth, supplier, layout } = useSelector((state: any) => state);
  const dispatch = useDispatch();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [limit, setLimit] = useState(20);
  const [page, setPage] = useState(1);

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const handleOpen = () => {
    setIsModalOpen(true);
  };

  useEffect(() => {
    auth?.token &&
      getAllSuppliersAction(
        auth?.token,
        `?${
          layout?.selectedFilters?.type
            ? `type=${layout?.selectedFilters?.type}`
            : ""
        }&field=${supplierIncludeItem}&limit=${limit}&page=${page - 1}`
      )(dispatch);
    auth?.token && getAllAttributesAction(auth?.token, "?")(dispatch);
  }, [auth, dispatch, limit, page]);

  dispatch(
    mySupplierActions.setQuery(
      `?${layout?.selectedFilters && `type=${layout?.selectedFilters?.type}`}&`
    )
  );
  const statusData = [
    {
      title: "type",
      subItems: [
        {
          label: `International`,
          value: "international",
        },
        {
          label: `Local`,
          value: "local",
        },
      ],
    },
  ];
  const handleSubmit = () => {
    auth?.token &&
      getAllSuppliersAction(
        auth?.token,
        `?${
          layout?.selectedFilters?.type
            ? `type=${layout?.selectedFilters?.type}`
            : ""
        }&field=${supplierIncludeItem}&limit=${limit}&page=${page - 1}`
      )(dispatch);
  };
  return (
    <SubLayout
      currentModule="Suppliers"
      filterData={statusData}
      handleSubmit={handleSubmit}
      buttons={[
        <PrimaryButton
          btnName="Add Supplier"
          icon={<AddCircleIcon />}
          onClick={handleOpen}
        />,
      ]}
      setPage={setPage}
      setLimit={setLimit}
      limit={limit}
      total={supplier?.all?.total}
      count={Math.ceil(supplier?.all?.total / limit)}
      hasGridList
    >
      {layout?.isGridView === "grid" && (
        <Box sx={{ pt: 2 }}>
          <div className="grid sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 gap-4 ">
            {supplier.isFetching && (
              <>
                {[1, 2, 3, 1, 2, 3, 1, 2, 3, 1, 2, 3].map(() => (
                  <Box sx={{ p: 1 }}>
                    <Stack>
                      <Stack
                        direction={"row"}
                        spacing={1}
                        justifyContent="center"
                        alignItems={"center"}
                      >
                        <Skeleton
                          animation="wave"
                          variant="circular"
                          width={40}
                          height={40}
                        />

                        <Box sx={{ width: "100%" }}>
                          <Stack>
                            <Skeleton
                              animation="wave"
                              height={10}
                              width="100%"
                              style={{ marginBottom: 6 }}
                            />
                            <Skeleton
                              animation="wave"
                              height={10}
                              width="100%"
                              style={{ marginBottom: 6 }}
                            />
                          </Stack>
                        </Box>
                      </Stack>
                      <Skeleton animation="wave" height={120} width="100%" />
                    </Stack>
                  </Box>
                ))}
              </>
            )}
            {!supplier.isFetching && (
              <SupplierGrid data={supplier?.all?.data} />
            )}
          </div>
        </Box>
      )}
      {layout?.isGridView === "list" && !supplier.isFetching && (
        <SupplierTable
          data={supplier?.all?.data}
          loading={supplier?.isFetching}
        />
      )}
      <GeneralModal
        onOpen={isModalOpen}
        onClose={handleCancel}
        width={700}
        title={
          <h1 className="text-center text-[#030229] text-lg py-4 font-medium">
            Add Supplier
          </h1>
        }
        component={<AddNewSupplier onCancel={handleCancel} />}
      />
    </SubLayout>
  );
};

export default PageView;
