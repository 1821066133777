import { Stack, Button } from "@mui/material";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import { Select } from "antd";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { myProductActions } from "../../store/product";
import {
  getAllProductsAction,
  getAllAttributesAction,
} from "../../store/product/actions";
import { getPricelistItemAction } from "../../store/channel/actions";
const { Option } = Select;

const DealFiltering = (props: any) => {
  const { product, auth, channel, orders } = useSelector((state: any) => state);
  const dispatch = useDispatch();
  const [selectedFilters, setSelectedFilters] = useState(true);
  const [type, setType] = useState("");
  const [brand, setBrand] = useState("");
  const [productId, setProductId] = useState("");
  const [page, setPage] = React.useState(1);
  const [limit, setLimit] = React.useState(40);

  useEffect(() => {
    dispatch(
      myProductActions.setQuery(
        `?${type && `type=${type}${brand && `&brand=${brand}`}&`}`
      )
    );
  }, [brand, dispatch, type]);

  const resetFilter = () => {
    if (selectedFilters) {
      setType("");
      setBrand("");
      setProductId("");
      props?.onSelectedModel(null);
    }
  };

  useEffect(() => {
    if (auth?.token) {
      const fetchingData = async () => {
        getAllProductsAction(
          auth?.token,
          product?.query + `limit=${product?.all?.total}&page=${page - 1}` ||
            "?"
        )(dispatch);

        await getAllAttributesAction(
          auth?.token,
          product?.query || "?"
        )(dispatch);
      };
      fetchingData();
    }
  }, [auth, product?.query, dispatch, limit, page]);

  useEffect(() => {
    if (auth?.token && orders?.selected?.account?.channel?._id) {
      getPricelistItemAction(
        auth?.token,
        `?channel=${orders?.selected?.account?.channel?._id}${
          productId && `&product=${productId}`
        }`
      )(dispatch);
    }
  }, [
    auth?.token,
    orders?.selected?.account?.channel?._id,
    dispatch,
    productId,
  ]);

  return (
    <div className="pr-2 py-2 ">
      <div className="flex ">
        <div className="bg-[#f0efff] w-20 mr-1 py-[3.5px] rounded-md h-10">
          <Button
            startIcon={
              selectedFilters ? <FilterAltIcon /> : <FilterAltOutlinedIcon />
            }
            onClick={() => {
              setSelectedFilters(!selectedFilters);
              resetFilter();
            }}
          >
            Filter
          </Button>
        </div>
        <div className="flex-1 grid sm:grid-cols-2 lg:grid-cols-3 gap-2">
          {selectedFilters && (
            <>
              {/* // <Stack
          //   spacing={1}
          //   direction="row"
          //   justifyContent={"center"}
          //   alignItems={"center"}
          // > */}
              <Select
                showSearch
                style={{ height: 40 }}
                allowClear
                placeholder="Filter By Type"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  (option?.label ?? "").toString().includes(input)
                }
                loading={product.isFetching}
                disabled={product.isFetching}
                filterSort={(optionA, optionB) =>
                  (optionA?.label ?? "")

                    .toString()
                    .toLowerCase()
                    .localeCompare(
                      (optionB?.label ?? "").toString().toLowerCase()
                    )
                }
                options={product?.attributes?.types}
                onChange={(value: any) => {
                  !value && setType("");
                  !value && setBrand("");
                  !value && setProductId(" ");
                  value && setType(value);
                }}
              />
              {/* {type && ( */}
              <Select
                loading={product.isFetching}
                disabled={product.isFetching}
                showSearch
                allowClear
                style={{ height: 40 }}
                placeholder="Filter By Brand"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  (option?.label ?? "").toString().includes(input)
                }
                filterSort={(optionA, optionB) =>
                  (optionA?.label ?? "")

                    .toString()
                    .toLowerCase()
                    .localeCompare(
                      (optionB?.label ?? "").toString().toLowerCase()
                    )
                }
                options={product?.attributes?.brands}
                onChange={(value: any) => {
                  !value && setBrand("");
                  !value && setProductId(" ");
                  value && setBrand(value);
                }}
              />
              {/* )}
              {brand &&  ( */}
              <Select
                loading={product.isFetching || channel.isFetching}
                disabled={product.isFetching || channel.isFetching}
                style={{ height: 40 }}
                showSearch
                placeholder="Filter By Model"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  (option?.label ?? "").toString().includes(input)
                }
                filterSort={(optionA, optionB) =>
                  (optionA?.label ?? "")

                    .toString()
                    .toLowerCase()
                    .localeCompare(
                      (optionB?.label ?? "").toString().toLowerCase()
                    )
                }
                onChange={(value: any) => {
                  !value && setProductId(" ");
                  !value && props?.onSelectedModel(null);
                  value && setProductId(value);
                  value && props?.onSelectedModel(value);
                }}
                // onSearch={onSearch}
              >
                {product?.all?.data?.map((el: any) => (
                  <Option value={el?._id}>{el?.model}</Option>
                ))}
              </Select>
              {/* )} */}
              {/* // </Stack> */}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default DealFiltering;
