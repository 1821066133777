import React, { useEffect, useState } from "react";
import DetailsHeaderActionBar from "../../details/supplier/DetailsPage/DetailsHeaderActionBar";
import { Badge, Carousel, Modal, Tag } from "antd";
import {
  deletePriceListItemAction,
  getSinglePricelistItemsAction,
} from "../../../store/channel/actions";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { searchValue } from "../../../utils/setColor";
import { MdDelete } from "react-icons/md";
import { GoPlus } from "react-icons/go";
import AssignPriceListCommissionForm from "../../forms/assignPriceListCommissionForm";
import UpdateVariantForm from "../../forms/UpdateVariantForm";
import DeleteModal from "../../Modals/DeleteModal";
import PricePopover from "../../Modals/PricePopover";
import UpdatePriceForm from "../../forms/UpdatePriceForm";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import { getDefaultCurrencyCode } from "../../../utils/helper";
import SubscriptionPriceList from "../../../pages/dashboard/channels/subscription/SubscriptionPriceList";
import AddItem from "../../../pages/dashboard/channels/subscription/AddItems";
import { mySubScritionPriceList } from "../../../store/subscription/priceList";
import { Button } from "@mui/material";
import CustomTypography from "../../../styles/globalStyles/CustomTypography";
import CustomTag from "../../buttons/Tag";
import ViewLayout from "../../layout/subLayout/ViewLayout";
import PrimaryButton from "../../buttons/PrimaryButton";

const PriceListDetails = (props: any) => {
  const { auth, channel, company } = useSelector((state: any) => state);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { priceId } = useParams();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isViewModalOpen, setIsViewModalOpen] = useState(false);
  const [priceListData, setPriceListId] = useState(null);
  const [prodDataId, setProdDataId] = useState(null);
  const [visible, setVisible] = useState(false);
  const [openUpdatePopover, setOpenUpdatePopover] = useState(false);
  const [activeKey, setActiveKey] = useState("1");
  const [isPlanModalOpen, setIsPlanModalOpen] = useState(false);

  const goBack = () => {
    navigate(-1);
  };

  const handlePlanModalCancel = () => {
    setIsPlanModalOpen(false);
    dispatch(mySubScritionPriceList.setUpdated(null));
  };

  const itemPrice = channel?.selectedPriceListItems?.data?.prices
    ?.find((price: any) => price.isActive)
    ?.value?.toLocaleString();
  const handleOpenUpdatePopover = (newOpen: boolean) => {
    setOpenUpdatePopover(newOpen);
  };

  const handleClickDelete = async () => {
    setVisible(true);
  };
  const handleDeleteModalCancels = () => {
    setVisible(false);
  };

  const deleteProduct = async () => {
    auth?.token &&
      (await deletePriceListItemAction(
        auth?.token,
        channel?.selectedPriceListItems?.data?._id,
        `?channel=${channel.priceList?.data[0]?.channel?._id}`
      )(dispatch));
    navigate(`/channel/${channel?.selected?.name}/pos/prices`);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleOnClickView = (value: any, e: any) => {
    e.preventDefault();
    setIsViewModalOpen(true);
    if (value) {
      setPriceListId(value);
      setProdDataId(value?.product?._id);
    }
  };

  useEffect(() => {
    if (!isViewModalOpen) {
      setPriceListId(null);
      setProdDataId(null);
    }
  }, [isViewModalOpen]);

  const handleOnCancel = () => {
    setIsViewModalOpen(false);
  };

  const showPlanModal = () => {
    setIsPlanModalOpen(true);
  };

  const styles = {
    border: "1px solid rgba(3, 2, 41, 0.10)",
  };

  useEffect(() => {
    if (auth?.token && priceId) {
      getSinglePricelistItemsAction(auth?.token, priceId as string)(dispatch);
    }
  }, [auth?.token, priceId, dispatch]);

  return (
    <ViewLayout
      currentModule={channel?.selectedPriceListItems?.data?.product?.model}
      subModule={"plans"}
      buttons={[
        auth?.user?.tag !== "mascom" && (
          <PrimaryButton
            btnName="Add New Subscription Plan"
            onClick={showPlanModal}
          />
        ),
      ]}
    >
      <div className="flex md:flex-col flex-col lg:flex-row gap-5 w-full mb-4">
        {auth?.user?.tag === "mascom" && (
          <Badge.Ribbon
            color="green"
            text={
              <p className="">
                <span className="text-white text-sm pl-1">
                  {itemPrice} {getDefaultCurrencyCode(company)}
                </span>
              </p>
            }
          >
            <div
              className={`flex gap-5 w-full h-full rounded-md py-9 px-8`}
              style={styles}
            >
              <div className="bg-[#E0E0E0] w-[106px] p-2 h-[120px] rounded-md">
                <Carousel autoplay>
                  {channel?.selectedPriceListItems?.data?.product.images?.map(
                    (img: any) => (
                      <img src={img} alt="" />
                    )
                  )}
                </Carousel>
              </div>
              <div className="flex justify-between  w-full">
                <div>
                  <CustomTypography variant="h1">
                    {channel?.selectedPriceListItems?.data?.product?.model}
                  </CustomTypography>

                  <p className="mt-4">
                    <CustomTypography variant="label">type:</CustomTypography>
                    <CustomTypography variant="value">
                      {channel?.selectedPriceListItems?.data?.product?.type}
                    </CustomTypography>
                  </p>
                  <p className="mt-3">
                    <CustomTypography variant="label">Brand:</CustomTypography>
                    <CustomTypography variant="value">
                      {channel?.selectedPriceListItems?.data?.product?.brand}
                    </CustomTypography>
                  </p>
                  <p className="mt-3">
                    {channel?.selectedPriceListItems?.data?.specification
                      ?.slice(2, 6)
                      ?.map((d: any) => (
                        <span className="text-black">
                          <CustomTypography variant="label">
                            {" "}
                            {d[0]}:{" "}
                          </CustomTypography>
                          <CustomTypography variant="value">
                            {d[1]},{" "}
                          </CustomTypography>
                        </span>
                      ))}
                  </p>
                </div>
                <div className="hidden sm:block ml-10">
                  <CustomTag
                    title={
                      channel?.selectedPriceListItems?.data?.isActive
                        ? "Active"
                        : "Inactive"
                    }
                    color={
                      channel?.selectedPriceListItems?.data?.isActive
                        ? "Active"
                        : "Inactive"
                    }
                    size="large"
                  />
                </div>
              </div>
            </div>
          </Badge.Ribbon>
        )}

        {auth?.user?.tag !== "mascom" && (
          <div
            className={`flex gap-5 flex-1 w-full rounded-md h-[208px] py-6 px-8`}
            style={styles}
          >
            <div className="bg-[#E0E0E0] w-[106px] p-2 h-[120px] rounded-md">
              <Carousel autoplay>
                {channel?.selectedPriceListItems?.data?.product.images?.map(
                  (img: any) => (
                    <img src={img} alt="" />
                  )
                )}
              </Carousel>
            </div>
            <div className="flex justify-between w-full">
              <div>
                <CustomTypography variant="h1">
                  {channel?.selectedPriceListItems?.data?.product?.model}
                </CustomTypography>
                <p className="mt-4">
                  <CustomTypography variant="label">type:</CustomTypography>
                  <CustomTypography variant="value">
                    {channel?.selectedPriceListItems?.data?.product?.type}
                  </CustomTypography>
                </p>
                <p className="mt-3">
                  <CustomTypography variant="label">Brand:</CustomTypography>
                  <CustomTypography variant="value">
                    {channel?.selectedPriceListItems?.data?.product?.brand}
                  </CustomTypography>
                </p>
                <p className="mt-3">
                  {channel?.selectedPriceListItems?.data?.specification
                    ?.slice(2, 6)
                    ?.map((d: any) => (
                      <span className="text-black">
                        <CustomTypography variant="label">
                          {d[0]}:
                        </CustomTypography>
                        <CustomTypography variant="value">
                          {d[1]},{" "}
                        </CustomTypography>
                      </span>
                    ))}
                </p>
              </div>
              <div className="hidden sm:block ml-10">
                <CustomTag
                  title={
                    channel?.selectedPriceListItems?.data?.isActive
                      ? "Active"
                      : "Inactive"
                  }
                  color={
                    channel?.selectedPriceListItems?.data?.isActive
                      ? "Active"
                      : "Inactive"
                  }
                  size="large"
                />
              </div>
            </div>
          </div>
        )}

        {auth?.user?.tag !== "mascom" && (
          <div
            className={`w-full lg:w-[600px] h-[208px] rounded-md py-6 px-8`}
            style={styles}
          >
            <p>
              <CustomTypography variant="label">
                Default Cash Price:
              </CustomTypography>
              <CustomTypography variant="value">
                {itemPrice} {getDefaultCurrencyCode(company)}
              </CustomTypography>
            </p>
            <p className="mt-3">
              <CustomTypography variant="label">
                Extended Warranty:
              </CustomTypography>
              <CustomTypography variant="value">
                {channel?.selectedPriceListItems?.data?.extendedWarranty}%
              </CustomTypography>
            </p>
            <div className="mt-10 flex gap-5">
              {["admin", "finance", "finance-manager", "dev"].includes(
                auth?.user?.role?.toLowerCase()
              ) && (
                <>
                  <div
                    className="flex gap-2 border border-gray-300 rounded-md justify-center items-center px-6 cursor-pointer"
                    onClick={(e: any) =>
                      handleOnClickView(
                        channel?.selectedPriceListItems?.data,
                        e
                      )
                    }
                  >
                    <GoPlus fill="green" size={20} />
                    <p className="text-[#030229] text-sm">Model</p>
                  </div>
                  <PricePopover
                    title="Update price"
                    handleOpen={handleOpenUpdatePopover}
                    open={openUpdatePopover}
                    content={
                      <UpdatePriceForm
                        handleOpenUpdatePopover={handleOpenUpdatePopover}
                        priceListItemId={
                          channel?.selectedPriceListItems?.data?._id
                        }
                        dataToUpdate={{
                          value: parseInt(itemPrice?.split(",")?.join("")),
                          extendedWarranty:
                            channel?.selectedPriceListItems?.data
                              ?.extendedWarranty,
                        }}
                      />
                    }
                    icon={<AttachMoneyIcon fontSize="small" />}
                  />

                  <MdDelete
                    fill="red"
                    size={20}
                    className="mt-1 cursor-pointer"
                    onClick={handleClickDelete}
                  />
                </>
              )}
            </div>
          </div>
        )}

        <Modal
          title="Assign Commission"
          footer={null}
          open={isModalOpen}
          onOk={handleOk}
          onCancel={handleCancel}
        >
          <AssignPriceListCommissionForm
            data={channel}
            onCancel={handleCancel}
          />
        </Modal>
        {["admin", "dev"].includes(auth?.user?.role?.toLowerCase()) && (
          <Modal
            open={isViewModalOpen}
            onCancel={handleOnCancel}
            key={isViewModalOpen ? "modal-open" : "modal-closed"}
            footer={null}
            className="min-w-max"
          >
            <UpdateVariantForm
              prodDataId={prodDataId}
              priceListData={priceListData}
              handleOnCancel={handleOnCancel}
            />
          </Modal>
        )}

        {["admin", "finance", "finance-manager", "dev"].includes(
          auth?.user?.role?.toLowerCase()
        ) && (
          <>
            <DeleteModal
              visible={visible}
              onOk={deleteProduct}
              isLoading={channel.isFetching}
              onCancel={handleDeleteModalCancels}
              itemName="Product"
            />
          </>
        )}
      </div>
      <SubscriptionPriceList />
      <Modal
        title={"Add New Subscription Plan"}
        open={isPlanModalOpen}
        onCancel={handlePlanModalCancel}
        footer={null}
        className="min-w-min !max-h-[80vh] overflow-auto"
        // icon={props?.icon || <HomeIcon />}
      >
        <div>
          <div className="w-[100vh]">
            {["admin", "finance", "finance-manager", "dev"]?.includes(
              auth?.user?.role?.toLowerCase()
            ) ? (
              <AddItem
                setSelectedModelId={priceId}
                onCancel={handlePlanModalCancel}
                selectePlanPlanType={activeKey === "1" ? "subscription" : "net"}
              />
            ) : (
              <></>
            )}
          </div>
        </div>
      </Modal>
    </ViewLayout>
  );
};

export default PriceListDetails;
