import React from "react";
import * as convert from "../../assets/data/productExport";
import ProductCardGrid from "./ProductCardGrid";
import { useSelector } from "react-redux";

const App = (props: any) => {
  const { product } = useSelector((state: any) => state);
  const data = props?.data?.map((d: any) =>
    convert.createExportData(
      d._id,
      d.type,
      d.brand,
      d.model,
      d.storage?.join(", "),
      d.colors?.join(", "),
      d.status,
      d.images,
      d.specs
    )
  );

  return data.map((d: any) => (
    <ProductCardGrid
      data={d}
      handleSelected={props?.handleSelected}
      types={product?.attributes?.types}
      brands={product?.attributes?.brands}
    ></ProductCardGrid>
  ));
};

export default App;
