import { useState } from "react";
import { Stack, IconButton } from "@mui/material";
import { generatePath, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Modal } from "antd";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { myChannelActions } from "./../../store/channel";
import store from "store";
import ChannelForm from "../../components/forms/newChannelForm";
import DeleteModal from "./../../components/Modals/DeleteModal";
import { deleteChannelsAction } from "../../store/channel/actions";
import CustomTypography from "../../styles/globalStyles/CustomTypography";

const Component = (props: any) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [channelId, setChannelId] = useState(false);
  const [deleteId, setDeleteId] = useState("false");
  const [data, setData] = useState<any>("");
  const navigate = useNavigate();
  const [visible, setVisible] = useState(false);
  const { auth, channel } = useSelector((state: any) => state);
  const dispatch = useDispatch();
  const handleCancels = () => {
    setVisible(false);
  };

  const style = {
    width: "100vh",
    color: "black",
    p: 2,
  };

  const updateModelOpen = (data: any, id: any) => {
    setIsModalOpen(true);
    setData(data);
    setChannelId(id);
  };
  const deleteModal = (itemId: any) => {
    setDeleteId(itemId);
    setVisible(true);
  };

  const deleteChannel = async () => {
    auth?.token &&
      (await deleteChannelsAction(deleteId, auth?.token, {
        isDeleted: "true",
      })(dispatch));
    setVisible(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const showChannelId = (items: any) => {
    store.set("channelId", items?.data?._id);
    localStorage.setItem("channel_index", JSON.stringify(0));
    dispatch(myChannelActions.setSelected(items.data));
    const Id = items?.data?._id;
    const path = generatePath(
      `${
        props?.routes === "channel"
          ? auth.user?.tag === "mascom"
            ? `/channel/${items?.name}/pos/shops`
            : `/channel/${items?.name}`
          : `/subscription/devices/${items?.name}/${Id}`
      }`,
      { Id }
    );

    navigate(path);
  };

  return (
    <>
      <div className="min-h-[125px] bg-white border-gray-100 text-black border rounded-md p-2 duration-200 hover:border-gray-300">
        <div className="flex-1 w-full">
          <div
            className="pl-2 pb-1.5 min-h-[10rem]"
            onClick={() => showChannelId(props.data)}
          >
            <Stack
              direction="row"
              justifyContent={"space-between"}
              alignItems="center"
              sx={{ width: "100%" }}
            >
              <Stack spacing={0}>
                <CustomTypography variant="h1">
                  {props?.data?.data?.name}
                </CustomTypography>
              </Stack>

              <img
                className="w-24 h-20  rounded-sm"
                src={props?.data?.data?.logo}
                alt=""
              />
            </Stack>

            <div className="text-wrap">
              <div className="grid grid-cols-2 w-full gap-2 text-balance h-full">
                {props?.data?.contact?.map(
                  (el: any, index: any) =>
                    index < 2 && (
                      <div className="flex flex-col w-full text-wrap h-full ">
                        <CustomTypography
                          variant="value"
                          className="capitalize sm:text-sm"
                        >
                          {el?.name}
                        </CustomTypography>
                        <CustomTypography
                          variant="value"
                          className="capitalize sm:text-sm"
                        >
                          {el?.position}
                        </CustomTypography>
                        <CustomTypography
                          variant="value"
                          className="capitalize sm:text-sm"
                        >
                          {el?.phone}
                        </CustomTypography>
                      </div>
                    )
                )}
              </div>
            </div>
          </div>

          {props?.routes === "channel" &&
            auth?.user?.tag !== "mascom" &&
            !["sales-supervisor"]?.includes(auth?.user?.role) && (
              <div className="h-6 flex justify-end items-end">
                <IconButton aria-label="delete" size="small" color="secondary">
                  <RemoveRedEyeIcon
                    fontSize="inherit"
                    onClick={() =>
                      updateModelOpen(props.data.data, props.data.data._id)
                    }
                  />
                </IconButton>
                <IconButton
                  aria-label="edit"
                  size="small"
                  onClick={() =>
                    updateModelOpen(props.data.data, props.data.data._id)
                  }
                >
                  <EditIcon fontSize="inherit" />
                </IconButton>

                <IconButton aria-label="delete" size="small" color="error">
                  <DeleteIcon
                    fontSize="inherit"
                    onClick={() => deleteModal(props.data.data._id)}
                  />
                </IconButton>
              </div>
            )}
        </div>
      </div>
      <DeleteModal
        visible={visible}
        onOk={deleteChannel}
        isLoading={channel.isFetching}
        onCancel={handleCancels}
        itemName="Channel"
      />
      <Modal
        title={"Update Channel"}
        open={isModalOpen}
        onCancel={handleCancel}
        footer={null}
        className="min-w-min"
      >
        <div style={style}>
          <ChannelForm
            data={data}
            channelId={channelId}
            onCancel={handleCancel}
          />
        </div>
      </Modal>
    </>
  );
};

export default Component;
