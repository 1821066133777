import React from "react";
import ShopCard from "../../../components/grids/ShopCard";
import AddNewShop from "../../../components/forms/NewShopForm";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllRegionAction,
  getAllShopAction,
} from "../../../store/channel/actions";
import { Box, Skeleton } from "@mui/material";
import ScrollableFrame from "../../../components/layout/ScrollableFrame";
import ExportCSVFile from "../../../components/buttons/ExportCSVFile";
import { AllShopsDataHeaders } from "../../../components/csvHeaders/Headers";
import { myChannelActions } from "../../../store/channel";
import SubLayout from "../../../components/layout/subLayout/SubLayout";
import PrimaryButton from "../../../components/buttons/PrimaryButton";
import GeneralModal from "../../../components/Modals/GeneralModal";
import InputSearch from "../../../components/inputs/InputSearch";

const Shops = () => {
  const { auth, channel, layout } = useSelector((state: any) => state);
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const handleShowModal = () => {
    setIsModalOpen(true);
  };

  /* This state section is For Paginations */
  const [page, setPage] = React.useState(1);
  const [limit, setLimit] = React.useState(
    ["sales-supervisor"].includes(auth?.user?.role?.toLowerCase()) ? 50 : 15
  );

  const dispatch = useDispatch();
  const channelId = channel?.selected?._id;

  React.useEffect(() => {
    auth?.token &&
      channelId &&
      getAllShopAction(
        auth?.token,
        `channel=${channelId}&limit=${limit}&page=${page - 1}`
      )(dispatch);
    dispatch(myChannelActions.setDeviceWithCommission(null));
  }, [auth?.token, channelId, page, limit, dispatch]);

  React.useEffect(() => {
    if (layout?.searchText === "") {
      auth?.token &&
        channelId &&
        getAllShopAction(
          auth?.token,
          `channel=${channelId}&limit=${limit}&page=${page - 1}`
        )(dispatch);
    } else {
      auth?.token &&
        channelId &&
        getAllShopAction(
          auth?.token,
          `channel=${channelId}&limit=${limit}&page=${page - 1}&sk=${
            layout?.searchText
          }`
        )(dispatch);
    }
  }, [layout?.searchText]);

  React.useEffect(() => {
    auth?.token &&
      channelId &&
      getAllRegionAction(auth?.token, `channel=${channelId}`)(dispatch);
  }, [dispatch, auth?.token, channelId]);

  const data = channel?.allShop?.data?.map((el: any) => {
    if (
      !auth?.user?.regions?.includes(el?._id) &&
      ["sales-supervisor"].includes(auth?.user?.role)
    )
      return null;
    return {
      id: el._id,
      name: el.name,
      address: el.address,
      msisdn: el.msisdn,
      momocode: el.momocode,
      merchantName: el.merchantName,
      status: el.status,
      type: el.type,
      data: el,
      region: el.region,
      // agents: [{ name: el.agent.fname, phone: el.agent.phone }],
    };
  });

  const csvData = channel?.allShop?.data?.map((record: any) => ({
    name: record?.name || "N/A",
    type: record?.type?.split("-")?.join(" ") || "N/A",
    address: record?.address?.split("-")?.join(" ") || "N/A",
    region: record?.region?.split("-")?.join(" "),
  }));

  return (
    <>
      <SubLayout
        currentModule="Shop"
        buttons={[
          ["admin", "finance-manager", "dev"]?.includes(
            auth?.user?.role?.toLowerCase()
          ) ? (
            <PrimaryButton
              btnName="Add new Channel"
              onClick={handleShowModal}
            />
          ) : (
            ""
          ),

          <ExportCSVFile
            csvHeaders={AllShopsDataHeaders}
            csvData={csvData || []}
            filename={`Shops.csv`}
          />,
        ]}
        search={
          <div className="w-full xl:w-[25rem]">
            <InputSearch placeHolder="Search shop" />
          </div>
        }
        setPage={setPage}
        setLimit={setLimit}
        limit={limit}
        total={channel?.allShop?.total}
        count={Math.ceil(channel?.allShop?.total / limit)}
      >
        {channel?.getShopIsFetching && (
          <div
            className={`w-full grid sm:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-4 text-[#030229]`}
          >
            {Array.from({ length: 12 }).map((d: any) => (
              <Box sx={{}}>
                <Skeleton animation="wave" />
                <Skeleton variant="rectangular" height={110} />
              </Box>
            ))}
          </div>
        )}
        {!channel?.getShopIsFetching && (
          <div
            className={`w-full grid sm:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-4 text-[#030229]`}
          >
            {data?.map((shop: any, _index: number) => {
              if (shop === null) return null;
              return <ShopCard data={shop} />;
            })}
          </div>
        )}
        <GeneralModal
          onOpen={isModalOpen}
          onClose={handleCancel}
          width={700}
          title={"Add New Shop"}
          component={<AddNewShop id={channelId} onCancel={handleCancel} />}
        />
      </SubLayout>
    </>
  );
};

export default Shops;
