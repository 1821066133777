import React, { useState } from "react";
import SettingButton from "./SettingButton";
import { Tabs, Modal } from "antd";
import type { TabsProps } from "antd";
import IntegrationCards from "./IntegrationCard";
import * as bsIcon from "react-icons/bs";
import { getIntegrationAction } from "../../../store/setting/actions";
import { useDispatch, useSelector } from "react-redux";
import CreateIntegrationsForm from "../../../components/forms/CreateIntegration";
import IntergrationDeatails from "./intergrationDeatails";
const onChange = (key: string) => {
  console.log(key);
};
const Integrations = () => {
  const { auth } = useSelector((state: any) => state);
  const dispatch = useDispatch();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [integrationData, setIntegrationData] = useState("");
  const [showDetails, setShowDetails] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const items: TabsProps["items"] = [
    {
      key: "1",
      label: <h1 className="text-lg">All Integrations</h1>,
      children: (
        <IntegrationCards
          setShowDetails={setShowDetails}
          showDetails={showDetails}
          setIntegrationData={setIntegrationData}
        />
      ),
    },
  ];

  React.useEffect(() => {
    auth?.token && getIntegrationAction(auth?.token, "?")(dispatch);
  }, [auth.token, dispatch]);
  return (
    <>
      {showDetails ? (
        <IntergrationDeatails
          data={integrationData}
          setShowDetails={setShowDetails}
        />
      ) : (
        <>
          <header className="py-2 px-8 ">
            <div className="sm:flex justify-between">
              <div>
                <h1 className="text-[20px] text-[#0F0F47] font-[600]">
                  Integrations and connected apps
                </h1>
                <p className="text-[#0F0F47B2] pt-2 font-normal text-sm">
                  Supercharge your workflow and connected the tool you use every
                  day.
                </p>
              </div>
              <div className="mt-4 sm:mt-0">
                <Modal
                  width={700}
                  footer={null}
                  title={
                    <h1 className="text-[#030229] pt-2 font-medium text-lg">
                      Create New Integrations
                    </h1>
                  }
                  open={isModalOpen}
                  onOk={handleOk}
                  onCancel={handleCancel}
                >
                  <CreateIntegrationsForm onCancel={handleCancel} />
                </Modal>
                <div className=" mb-2 flex gap-3">
                  {(auth?.user?.role?.toLowerCase() === "dev" ||
                    auth?.user?.role?.toLowerCase() === "admin") && (
                    <SettingButton
                      btnName="New integration"
                      textColor="white"
                      btnBgColor="[#605BFF]"
                      icon={<bsIcon.BsPlusLg />}
                      onClick={showModal}
                    />
                  )}
                </div>
              </div>
            </div>
          </header>
          <div className="mt-6 ">
            <Tabs defaultActiveKey="1" items={items} onChange={onChange} />
          </div>
        </>
      )}
    </>
  );
};

export default Integrations;
