import { LoadingButton } from "@mui/lab";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import DetailsHeaderActionBar from "../../../components/cards/DetailsPage/DetailsHeaderActionBar";
import DetailsTabs from "../../../components/cards/DetailsPage/DetailsTabs";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import ShopStockTable from "../../../components/tables/shop/ShopStockTable";
import ShopAgentTable from "../../../components/tables/shop/ShopAgentTable";
import {
  createRequestStockAction,
  getAllStockSentAction,
} from "../../../store/channel/actions";
import {
  getOneShopAction,
  getShopOverviewAction,
} from "../../../store/shop/actions";
import RequestStockView from "./RequestStockView";
import ShopProfile from "./shopProfile";
import SubLayout from "../../../components/layout/subLayout/SubLayout";
import PrimaryButton from "../../../components/buttons/PrimaryButton";
import ExportCSVFile from "../../../components/buttons/ExportCSVFile";
import { ShopDetailsHeaders } from "../../../components/csvHeaders/Headers";

type TAB = {
  title: string;
  component: any;
  button?: any;
};

const ShopDetails = () => {
  const { channel, auth, shop, pos, layout } = useSelector(
    (state: any) => state
  );
  const { shopId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showCommissionDevices, setShowCommissionDevices] = useState(false);
  const handleShowCommissionDevices = () => {
    setShowCommissionDevices(true);
  };
  const tabsData = [
    {
      key: "1",
      label: "Profile",
      children: (
        <ShopProfile
          handleShowCommissionDevices={handleShowCommissionDevices}
          data={shop}
        />
      ),
    },
    { key: "2", label: "Stock", children: <ShopStockTable /> },
    {
      key: "3",
      label: "Stock Requests",
      children: <RequestStockView />,
    },
    { key: "4", label: "Agents", children: <ShopAgentTable /> },
  ];
  useEffect(() => {
    if (auth?.token && shopId) {
      getOneShopAction(shopId as string, auth?.token)(dispatch);
      getAllStockSentAction(auth?.token, `?shopId=${shopId}`)(dispatch);
      getShopOverviewAction(shopId as string, auth?.token)(dispatch);
    }
  }, [auth?.token, channel.query, dispatch, shopId]);

  const csvData = pos?.stock?.data?.map((record: any) => ({
    shop: shop?.selected?.name || "N/A",
    type: record.productSpecs?.product?.type || "N/A",
    brand: record.productSpecs?.product?.brand || "N/A",
    model: record.productSpecs?.product?.model || "N/A",
    specification:
      record?.productSpecs?.specification
        ?.map((d: any) => d[1] && `${d[0]}: ${d[1]}`)
        ?.join(", ") || "N/A",
    stock: record?.countsByStatus
      ?.filter((status: any) => status.status === "pending")
      ?.map((status: any) => status.count),
    transfer: record?.countsByStatus
      ?.filter((status: any) => status.status === "transfer")
      ?.map((status: any) => status.count),
    sold: record?.countsByStatus
      ?.filter((status: any) => status.status === "sold")
      ?.map((status: any) => status.count),
  }));

  return (
    <SubLayout
      currentModule="Shop"
      subModule={shop?.selected?.name}
      tabs={true}
      items={tabsData}
      hidePagination
      buttons={[
        layout?.selectedTabIndex === "2" && (
          <ExportCSVFile
            csvHeaders={ShopDetailsHeaders}
            csvData={csvData || []}
            filename={`${shop?.selected?.name}-Details.csv`}
          />
        ),
        layout?.selectedTabIndex === "3" && (
          <PrimaryButton
            btnName=" Request Stock"
            // className="float-right"
            icon={<AddCircleIcon />}
            onClick={async () => {
              if (auth?.token) {
                const res: any = await createRequestStockAction(auth?.token, {
                  shopId: shopId,
                  channel: channel?.selected?._id,
                })(dispatch);
                if (res) {
                  navigate(
                    `/channel/${channel?.selected?.name}/pos/shops/${shopId}/${res?.requestId}`
                  );
                }
              }
            }}
            isLoading={channel?.isFetching}
          />
        ),
      ]}
    >
      <div className="hidden"></div>
    </SubLayout>
  );
};

export default ShopDetails;
