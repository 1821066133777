import React from "react";
import { Table } from "antd";
import { useSelector } from "react-redux";

const CustomerDevicesTable = () => {
  const { customer } = useSelector((state: any) => state);

  const columns = [
    { title: "Type", dataIndex: "type" },
    { title: "Brand", dataIndex: "brand" },
    { title: "Model", dataIndex: "model" },
    { title: "IMEI", dataIndex: "imei" },
    { title: "Specifications", dataIndex: "specs" },
  ];

  const data = customer?.customerDevices?.data?.map((el: any) => {
    const product = el?.warehouseProduct
      ? el?.warehouseProduct?.product
      : el?.wareHouseItem?.product;

    return {
      key: el._id,
      type: product?.map((d: any) => d?.type).join(", "),
      brand: product?.map((d: any) => d?.brand).join(", "),
      model: product?.map((d: any) => d?.model).join(", "),
      specs: product
        ?.map((d: any) =>
          d?.specs
            ?.map((spec: any) => {
              const values = Array.isArray(spec.value)
                ? spec.value.join(", ")
                : spec.value;
              return `${spec.label}: ${values}`;
            })
            .join(", ")
        )
        .join(", "),
      imei:
        el?.warehouseProduct?.serialNumber ?? el?.wareHouseItem?.serialNumber,
      data: el,
    };
  });

  return (
    <div className="overflow-x-auto bg-white">
      <Table
        columns={columns}
        dataSource={data}
        loading={customer?.isFetching}
        pagination={false}
      />
    </div>
  );
};

export default CustomerDevicesTable;
