import React from "react";
import { useSelector } from "react-redux";
import CustomTable from "./CustomTable";

const SupplierByBrandTable = () => {
  const { supplier } = useSelector((state: any) => state);

  const columns = [
    {
      label: "Type",
      key: "type",
      render: (text: any, _record: any) => <p className="capitalize">{text}</p>,
    },
    {
      label: "Name",
      key: "name",
    },
    {
      label: "Telephone",

      key: "telephone",
    },
    {
      label: "Email",

      key: "email",
    },
    {
      label: "Location",

      key: "location",
      render: (text: any, _record: any) => <p className="capitalize">{text}</p>,
    },
  ];

  const formData = supplier?.suppliersByBrand?.data?.map((el: any) => {
    return {
      type: el?.type,
      name: el?.name,
      telephone: el?.phone,
      email: el?.email,
      location: el?.address,
      data: el,
    };
  });

  return (
    <CustomTable
      columns={columns}
      data={formData}
      loading={supplier?.isFetching}
    />
  );
};

export default SupplierByBrandTable;
