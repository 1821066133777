import RequestGrid from "../../../components/grids/RequestGrid";
import React from "react";
import { useSelector } from "react-redux";
import ScrollableFrame from "../../../components/layout/ScrollableFrame";
import CurstomeCardSkeleton from "../../../components/skeleton/CurstomeCardSkeleton";

const PageView = () => {
  const { channel } = useSelector((state: any) => state);

  return (
    <div className="mt-2">
      <ScrollableFrame hidePagination>
        {channel.shopReqIsFetching ? (
          <div className="grid sm:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 w-full gap-4">
            {Array.from({ length: 8 })?.map((el: any) => (
              <CurstomeCardSkeleton />
            ))}
          </div>
        ) : (
          <div
            className={`w-full grid sm:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-4 text-[#030229]`}
          >
            <RequestGrid data={channel?.selectedRfs?.data} />
          </div>
        )}
      </ScrollableFrame>
    </div>
  );
};

export default PageView;
