import react, { ReactNode } from "react";
interface buttonProps {
  btnName?: any;
  btnBgColor?: string;
  btnWidth?: string;
  textColor?: string;
  icon?: ReactNode;
  onClick?: any;
}
const SettingButton = ({
  btnName,
  btnBgColor,
  btnWidth,
  textColor,
  icon,
  onClick,
}: buttonProps) => {
  return (
    <>
      <button
        className={`bg-${btnBgColor} text-${textColor} min-w-${btnWidth} border border-[#605BFF] font-normal text-sm sm:text-base  rounded-md py-2 px-6 flex`}
        onClick={onClick}
      >
        {icon && <span className="pr-2 md:pt-[6px]">{icon}</span>}
        {btnName}
      </button>
    </>
  );
};
export default SettingButton;
