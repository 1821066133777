import React from "react";
import { useNavigate } from "react-router-dom";
import CustomTable from "./CustomTable";
import { Tag } from "antd";
import { searchValue } from "../../utils/setColor";

const App = (props: any) => {
  const columns = [
    {
      label: "Type",
      key: "type",
    },
    {
      label: "Name",
      key: "name",
    },
    {
      label: "Phone",
      key: "phone",
    },
    {
      label: "Email",
      key: "email",
      defaultSortOrder: "descend",
    },
    {
      label: "Tin",
      key: "tin",
    },
    {
      label: "Status",
      key: "status",
      render: (_: any, record: any) => (
        <Tag
          color={searchValue(record.status)}
          className="w-[6rem] flex py-1 justify-center"
        >
          {record.status}
        </Tag>
      ),
    },
  ];

  const supplierData = props?.data?.map((d: any) => {
    return {
      name: d?.name,
      type: d?.type,
      phone: d?.phone,
      email: d?.email,
      tin: d?.tin,
      status: d?.status,
      id: d?._id,
      data: d,
    };
  });
  const navigate = useNavigate();

  return (
    <CustomTable columns={columns} data={supplierData} onRowClick={(record) => navigate(`/purchase/supplier/${record?.id}`)} loading={props?.loading}/>
  );
};

export default App;
