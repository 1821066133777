import { Paper, Stack, Button, Skeleton } from "@mui/material";
import { Table } from "antd";
import AddItem from "./AddItems";
import AddSupplier from "./AddSuppliers";
import SendIcon from "@mui/icons-material/Send";
import { useParams, useLocation } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { LoadingButton } from "@mui/lab";
import { useDispatch, useSelector } from "react-redux";
import {
  getSelectedQuotationAction,
  sendEmailAction,
  updateQuotationAction,
} from "../../../../store/quotation/actions";
import { myQuotationActions } from "../../../../store/quotation";
import { quotationColumns } from "../../../../components/reUsableTable/colmns";
import { useNavigate } from "react-router-dom";
import DetailsHeaderActionBar from "../../../../components/cards/DetailsPage/DetailsHeaderActionBar";

const UpdateSaveButtons = (props: any) => {
  const { auth, quotation } = useSelector((state: any) => state);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleSendEmail = async () => {
    console.log(quotation.allRfqSupplier.data.map((d: any) => d._id));
    if (auth?.token && quotation.allRfqSupplier.data.length > 0) {
      const isEmailSent = await sendEmailAction(auth.token, {
        quotationToSuppliers: quotation.allRfqSupplier.data.map(
          (d: any) => d._id
        ),
      })(dispatch);

      isEmailSent && navigate("/purchase/rfq");
    }
  };

  const handleCancelQuotation = async () => {
    auth?.token &&
      quotation.selected._id &&
      (await updateQuotationAction(
        quotation.selected._id,
        { status: "Cancelled" },
        auth?.token
      )(dispatch));
    navigate("/inventory/rfq");
  };

  return (
    <Stack direction={"row"} spacing={2} className="px-8 mt-4">
      {quotation?.allRfqSupplier?.data.length !== 0 && (
        <LoadingButton
          loading={quotation?.isSendingEmail}
          loadingPosition="start"
          variant="contained"
          onClick={async () => {
            await handleSendEmail();
          }}
        >
          Send by Email
        </LoadingButton>
      )}
      <Button
        variant="contained"
        color="error"
        onClick={async () => await handleCancelQuotation()}
      >
        Cancel
      </Button>
      {/* )} */}
      {!props.isCancel && props?.sendButtonDefault && (
        <LoadingButton
          loading={props.isLoading}
          loadingPosition="start"
          variant="contained"
          endIcon={<SendIcon />}
          color="success"
          onClick={() => props?.onClick()}
        >
          Send Quotation
        </LoadingButton>
      )}
    </Stack>
  );
};

const PageView = () => {
  const { auth, quotation } = useSelector((state: any) => state);
  const [suppliers, setSuppliers] = useState([""]);
  const [isCancel, setIsCancel] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [rowToDelete, setRowToDelete] = useState("");
  const { id } = useParams();
  const dispatch = useDispatch();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const rfqId = searchParams.get("rfqId");
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(myQuotationActions.setNew(null));
    dispatch(myQuotationActions.setSelected(null));
    auth?.token &&
      getSelectedQuotationAction(auth?.token, `?quotationId=${id}`)(dispatch);
  }, [id, auth?.token, dispatch]);

  const goBack = () => {
    navigate(-1);
  };

  const data = quotation?.selected?.listOfProducts?.map((el: any) => {
    return {
      key: el._id,
      type: el.product.model,
      brand: el.product.brand,
      model: el.product.model,
      specification: el.specification
        .map((el: any) => el[1] && `${el[0]}: ${el[1]}`)
        .join(", "),
      quantity: el.quantity,
      visible: visible,
      setVisible: setVisible,
      token: auth?.token,
      isLoading: false,
      dispatch: dispatch,
      quotId: id,
      rowToDelete: rowToDelete,
      setRowToDelete: setRowToDelete,
      status: quotation.selected.status,
    };
  });

  const values = quotation?.selected?.listOfProducts;

  const handleSendEmail = async () => {
    // sendEmailAction(auth.token, {
    //   quotationToSuppliers: suppliers,
    // })(dispatch);
    console.log(quotation?.allRfqSupplier?.data?.map((d: any) => d._id));
  };

  return (
    <div className="py-4 space-y-4 h-full overflow-y-auto scrollbar-hide text-[#030229] opacity-80 px-4">
      <Stack spacing={1}>
        <DetailsHeaderActionBar
          pageName="Request For Quotation"
          title={id}
          goBack={goBack}
        />
        <Paper elevation={0} style={{ marginBottom: "20px" }}>
          <Stack spacing={0}>
            {quotation?.isFetching ? (
              <div className="w-full flex justify-between py-20 px-[5rem]">
                <Skeleton animation="wave" width={500} height={80} />
                <Skeleton animation="wave" width={250} height={80} />
              </div>
            ) : (
              quotation?.selected?.status?.toLowerCase() === "draft" && (
                <AddItem
                  onClickAdd={() => {
                    setIsCancel(true);
                  }}
                />
              )
            )}
            <div className="mt-8 mx-8 w-full overflow-x-auto">
              <Table
                columns={quotationColumns}
                dataSource={data}
                loading={quotation?.isFetching}
              />
            </div>
          </Stack>
        </Paper>

        {[
          "admin",
          "dev",
          "finance",
          "sales-manager",
          "finance-manager",
        ].includes(auth?.user?.role?.toLowerCase()) && (
          <>
            {quotation?.selected?.listOfProducts?.length > 0 && (
              <Paper elevation={0}>
                {!rfqId && quotation?.selected?.status === "draft" && (
                  <AddSupplier
                    onClickAdd={() => {
                      setIsCancel(true);
                    }}
                    setSuppliers={setSuppliers}
                  />
                )}
              </Paper>
            )}

            {values?.length >= 1 &&
              quotation?.selected?.status?.toLowerCase() === "draft" &&
              !rfqId && (
                <UpdateSaveButtons
                  //  isCancel={isCancel}
                  isLoading={isLoading}
                  sendButtonDefault={false}
                  onClick={() => {
                    setIsCancel(false);
                  }}
                  handleSendEmail={handleSendEmail}
                />
              )}
          </>
        )}
      </Stack>
    </div>
  );
};
export default PageView;
