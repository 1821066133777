/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { useState } from "react";
import { Stack } from "@mui/material";
import { useSelector } from "react-redux";
import { Badge, Carousel, Modal } from "antd";
import AddItem from "../../../pages/dashboard/channels/priceList/AddItems";
import { useNavigate } from "react-router";
import { getDefaultCurrencyCode } from "../../../utils/helper";
import CustomTypography from "../../../styles/globalStyles/CustomTypography";

const Component = (props: any) => {
  const { channel, company } = useSelector((state: any) => state);
  const navigate = useNavigate();

  const itemPrice = props?.data?.prices
    ?.find((price: any) => price.isActive)
    ?.value?.toLocaleString();

  const [showEdit, setShowEdit] = useState(false);

  const handleCancel = () => {
    setShowEdit(false);
  };

  const handleNavigate = (priceListId: string) => {
    navigate(`/channel/${channel?.selected?.name}/pos/prices/${priceListId}`);
  };

  return (
    <>
      <div className="rounded-md relative border border-white hover:border-gray-300 duration-500">
        <Badge.Ribbon
          color="green"
          text={`${itemPrice} ${getDefaultCurrencyCode(company)}`}
        >
          <div
            className="relative cursor-pointer w-full h-52 p-2 pt-10 bg-white text-black rounded-md"
            onClick={() => {
              handleNavigate(props?.data?._id);
            }}
          >
            <div className="flex w-fit">
              <div style={{ width: "5rem" }}>
                <Carousel autoplay>
                  {props?.data?.product?.images?.map((im: any, idex: any) => (
                    <div key={idex}>
                      <img src={im} width={"100%"} alt="" />
                    </div>
                  ))}
                </Carousel>
              </div>
              <Badge className="site-badge-count-10 text-sm h-1">
                <div className="flex flex-col pl-2 gap-1">
                  <CustomTypography variant="h1">
                    {props?.data?.product?.model}
                  </CustomTypography>
                  <div className=" gap-1">
                    {props?.data &&
                      props?.data?.specification?.slice(2)?.map(
                        (data: any, index: number) =>
                          data[1] && (
                            <div className="flex" key={index}>
                              <CustomTypography variant="label">
                                {data[0]}:
                              </CustomTypography>
                              <CustomTypography variant="value">
                                {data[1]}
                              </CustomTypography>
                            </div>
                          )
                      )}
                  </div>
                </div>
              </Badge>
            </div>
          </div>
        </Badge.Ribbon>
      </div>

      <Modal
        title={"Edit Pricelist Item"}
        open={showEdit}
        onCancel={handleCancel}
        footer={null}
        className="min-w-min"
      >
        <div>
          <div className="w-[100vh]">
            {
              <AddItem
                selectedModelId={props?.data?._id}
                onCancel={handleCancel}
              />
            }
          </div>
        </div>
      </Modal>
    </>
  );
};

export default Component;
