import React, { ReactNode } from "react";
interface subLayoutProps {
  currentModule?: string;
  datePicker?: ReactNode;
  buttons?: ReactNode[];
  [key: string]: any;
}
const TopRightSide = ({ datePicker, buttons, ...props }: subLayoutProps) => {
  return (
    <div className="flex gap-5 justify-end">
      {datePicker}
      <div className="flex gap-5">
        {buttons?.map((btn, index) => (
          <div key={index}>{btn}</div>
        ))}
      </div>
      {Object.keys(props).map((key) => (
        <React.Fragment key={key}>{props[key]}</React.Fragment>
      ))}
    </div>
  );
};

export default TopRightSide;
