import React from "react";
import { PiListBulletsLight } from "react-icons/pi";
import { TbGridDots } from "react-icons/tb";
import { useDispatch, useSelector } from "react-redux";
import { myLayoutActions } from "../../store/layout";

const GridListDisplayButton = () => {
  const { layout } = useSelector((state: any) => state);
  const dispatch = useDispatch();

  return (
    <div className="flex gap-2 border border-[#03022940] p-1 rounded-md">
      <div
        className={`${
          layout?.isGridView === "grid" && "bg-[#EBEBEB]"
        } p-1 rounded-md cursor-pointer`}
      >
        <TbGridDots
          size={20}
          onClick={() => dispatch(myLayoutActions.setIsGridView("grid"))}
        />
      </div>
      <div
        className={`${
          layout?.isGridView === "list" && "bg-[#EBEBEB]"
        } p-1 rounded-md cursor-pointer`}
      >
        <PiListBulletsLight
          size={23}
          onClick={() => dispatch(myLayoutActions.setIsGridView("list"))}
        />
      </div>
    </div>
  );
};

export default GridListDisplayButton;
