import React from "react";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { Link } from "react-router-dom";
import CustomTable from "../../reUsableTable/CustomTable";
import CustomizedPieChart from "../../../pages/dashboard/subscription/overview/CustomizedPieChart";
import { Select } from "antd";
import { COLORS } from "../../../assets/data/data";
import {
  DefaultColumn,
  DefaultData,
  stockShopColumn,
} from "../../../assets/data/dummyData";
import { useSelector } from "react-redux";

const InventorySection = (props: any) => {
  const { dashboard } = useSelector((state: any) => state);

  const stockWarehouseColumn = [
    { header: "Brand", key: "brand" },
    { header: "In Warehouse", key: "inWarehouse" },
    { header: "In Shop", key: "inShops" },
    { header: "Sold", key: "sold" },
    { header: "Total", key: "total" },
  ];
  // Live Stock At Warehouse
  const dataToDisplay = dashboard?.currentStock?.data?.map((el: any) => {
    return {
      brand: el?.name,
      inShops: el?.status?.out ?? 0,
      inWarehouse: el?.status?.in ?? 0,
      sold: el?.status?.sold ?? 0,
      total:
        (el?.status?.out ? el?.status?.out : 0) +
        (el?.status?.in ? el?.status?.in : 0) +
        (el?.status?.sold ? el?.status?.sold : 0),
    };
  });

  // Live Stock At Shop
  const stockShopData = props?.data?.shopStock?.data?.map(
    (el: any, index: any) => {
      const sold = el?.statuses
        ?.filter((s: any) => s?.status === "sold")
        ?.reduce((acc: number, s: any) => acc + (s?.count || 0), 0);

      const inShop = el?.statuses
        ?.filter((s: any) => s?.status === "pending")
        ?.reduce((acc: number, s: any) => acc + (s?.count || 0), 0);
      return {
        index,
        shop: el?.shop?.name,
        sold,
        in_shop: inShop,
        received: sold + inShop,
      };
    }
  );

  // Vaults Stats
  const vaultData = props?.data?.vaultStats?.data?.map((el: any) => {
    const status = Object.keys(el)[0];
    const value = Object.values(el)[0];
    return {
      name: status,
      value: value,
    };
  });

  return (
    <div>
      <h1 className="pl-1 pr-4 py-6 mb-3 text-lg font-medium">
        Inventory Overview
      </h1>
      {/* Vault Status and Live Stock At Shop */}
      <div className="flex flex-col-reverse 2xl:flex-row w-full gap-4">
        <div className="2xl:w-[33%] bg-white rounded-md p-5">
          <div className="flex items-center justify-between p-1">
            <p className="text-gray-600 capitalize pl-3">Vault Status</p>
          </div>
          <CustomizedPieChart
            data={vaultData}
            colors={COLORS}
            totalLabel={<h1 className="text-[15px]">Total</h1>}
            innerRad={70}
            height={"250px"}
            contentWidth={"w-full"}
            top={"40%"}
            left={"37%"}
            outerRad={90}
            totalValue={props?.data?.vaultStats?.total}
            pieHeight={200}
            pieWidth={300}
            gridNumber={2}
          />
        </div>
        <div className="flex-1">
          <div className="relative w-full mt-4 2xl:mt-0 x2xl:w-[50%] min-h-[450px] overflow-y-auto bg-white p-2  rounded-md">
            <div className="flex items-center justify-between pr-4 pt-2">
              <p className="text-gray-600 capitalize pl-4">
                Live Stock At Shop
              </p>
              {/* <Select defaultValue={"By Region"}>
                <Select.Option value={"By Region"}>By Region</Select.Option>
              </Select> */}
            </div>

            <CustomTable
              columns={stockShopColumn}
              data={stockShopData}
              height={"300px"}
              isLoading={dashboard?.isFetching}
            />
          </div>
        </div>
      </div>
      {/* Default Devices and Live Stock At Warehouse */}
      <div className="flex flex-col-reverse 2xl:flex-row gap-4 mt-4">
        {/* <div className="2xl:w-[33%] relative mt-4 2xl:mt-0 x2xl:w-[50%] min-h-[450px] overflow-y-auto bg-white p-2  rounded-md">
          <div className="flex items-center justify-between pr-4 pt-2">
            <p className="text-gray-600 capitalize pl-4">Default Devices</p>
            <Link to="/dashboard">
              <OpenInNewIcon />
            </Link>
          </div>
          <p className="text-2xl font-semibold pl-4">{`50`}</p>
          <p className="text-gray-400 pl-4 capitalize mb-2">{`50`}</p>

          <CustomTable
            columns={DefaultColumn}
            data={DefaultData}
            height={"300px"}
            isLoading={dashboard?.isFetching}
          />
        </div> */}
        <div className="flex-1 relative w-full mt-4 2xl:mt-0 x2xl:w-[50%] min-h-[450px] overflow-y-auto bg-white p-2  rounded-md">
          <div className="flex items-center justify-between pr-4 pt-2">
            <p className="text-gray-600 capitalize pl-4">
              Live Stock At Warehouse
            </p>
            {/* <Select defaultValue={"By Region"}>
              <Select.Option value={"By Region"}>By Region</Select.Option>
            </Select> */}
          </div>
          <CustomTable
            columns={stockWarehouseColumn}
            data={dataToDisplay}
            height={"300px"}
            isLoading={dashboard?.isFetching}
          />
        </div>
      </div>
    </div>
  );
};
export default InventorySection;
