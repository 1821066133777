import React from "react";
import Completed from "../../../assets/images/subscription/completed.png";
import Pending from "../../../assets/images/subscription/pending.png";
import Inactive from "../../../assets/images/subscription/inactive.png";
import Cards from "../subscription/overview/Cards";
import { useSelector } from "react-redux";
import CardLayout from "../../../components/cards/Layout/CardLayout";

const Statistics = () => {
  const { report } = useSelector((state: any) => state);
  return (
    <CardLayout isLoading={report?.isFetching} initialItems={3}>
      <Cards
        cardIcon={Completed}
        value={report?.saveTheChildren?.data?.counts?.paid}
        status="Paid Invoices"
      />
      <Cards
        cardIcon={Inactive}
        value={report?.saveTheChildren?.data?.counts?.["partial-paid"]}
        status="Partial Paid Invoices"
      />
      <Cards
        cardIcon={Pending}
        value={report?.saveTheChildren?.data?.counts?.pending}
        status="Pending Invoices"
      />
    </CardLayout>
  );
};
export default Statistics;
