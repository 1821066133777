import React, { useEffect, useState } from "react";
import Statistics from "./Statistics";
import { DatePicker } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllSaveTheChildrenAction,
  getTransactionByCustomersReportAction,
} from "../../../store/report/actions";
import {
  CustomerTransactionReportHeaders,
  CustomerTransactionReportSumaryHeaders,
} from "../../../components/csvHeaders/Headers";
import dayjs, { Dayjs } from "dayjs";
import CSVExportButton from "../../../components/cards/NoSale/CSVExportButton";
import { getTransactionGroupedByCustomer_CSVAction } from "../../../store/csvDownload/actions";
const { RangePicker } = DatePicker;
const monthFormat = "YYYY/MM";
import CustomerTransactionTable from "../../../components/tables/report/customerTransactionTable";
import SavetheChildrentable from "../../../components/tables/saveTheChildrenTable/SavetheChildrentable";
import SubLayout from "../../../components/layout/subLayout/SubLayout";
import PrimaryButton from "../../../components/buttons/PrimaryButton";
import { LuRefreshCcw } from "react-icons/lu";
import { extractedStatus, summaryStatus } from "../../../assets/data/data";

const OverView = () => {
  const { auth, layout, CSV, report } = useSelector((state: any) => state);

  const currentYear = new Date().getFullYear();
  const firstDate = new Date(currentYear, 0, 1).toISOString().slice(0, 7);
  const secondDate = new Date(currentYear + 1, 11, 31)
    .toISOString()
    .slice(0, 7);

  const [selectedMonth, setSelectedMonth] = useState<string[]>([
    firstDate,
    secondDate,
  ]);
  const [limit, setLimit] = useState(15);
  const [page, setPage] = useState(1);
  const [selectedStatus, setSelectedStatus] = useState("");

  const dispatch = useDispatch();

  const isSummaryStatus = ["active", "closed", "ongoing", "draft"]?.includes(
    layout?.selectedFilters?.status
  );
  const isExtractedStatus = [
    "pending",
    "paid",
    "overpaid",
    "partial-paid",
    "late",
  ]?.includes(layout?.selectedFilters?.status);

  const handleGetItems = async () => {
    if (auth?.token) {
      await getTransactionByCustomersReportAction(
        auth?.token,
        `?month1=${selectedMonth[0]}&month2=${selectedMonth[1]}${
          isSummaryStatus ? `&status=${layout?.selectedFilters?.status}` : ""
        }&limit=${limit}&page=0`
      )(dispatch);
      await getAllSaveTheChildrenAction(
        auth?.token,
        `?month1=${selectedMonth[0]}&month2=${selectedMonth[1]}${
          isExtractedStatus ? `&status=${layout?.selectedFilters?.status}` : ""
        }&limit=${limit}&page=0`
      )(dispatch);
    }
  };

  useEffect(() => {
    if (layout?.openCSVButton && auth?.token)
      getTransactionGroupedByCustomer_CSVAction(
        auth?.token,
        `?month1=${selectedMonth[0]}&month2=${selectedMonth[1]}${
          layout?.selectedFilters?.status
            ? `&status=${layout?.selectedFilters?.status}`
            : ""
        }&limit=${report?.customerTransaction?.total}&page=0`
      )(dispatch);
  }, [layout?.openCSVButton]);

  useEffect(() => {
    handleGetItems();
  }, [auth?.token, dispatch, selectedMonth, limit, page]);

  const csvData = CSV?.csvDownloadedData?.data?.flatMap((item: any) => {
    return (
      item?.allInvoices?.map((el: any) => {
        return {
          mPayment: el?.amountToBePaid,
          customerName: `${el?.account?.customer?.firstName ?? " "} ${
            el?.account?.customer?.lastname ?? ""
          }`,
          mPaid: el?.amountPaid,
          transactionId: el?.transaction,
          mPaidremain: el?.amountToBePaid - el?.amountPaid,
          accountType: el?.account?.type,
          paidAt: dayjs(el?.payment?.date)?.format("YYYY-MM-DD HH:mm:ss"),
          customerTel:
            el?.account?.customer?.phone || el?.account?.customer?.msisdn,
          date: dayjs(el?.dueDate)?.format("YYYY-MM-DD HH:mm:ss"),
          status: el?.status,
        };
      }) || []
    );
  });

  const csvDataSummary = CSV?.csvDownloadedData?.data?.flatMap((item: any) => {
    return {
      amountPaid: item?.AmountPaid,
      amountToBePaid: item?.amountToBePaid,
      AmountRemain: item?.AmountRemain,
      endingDate: item?.endingDate
        ? dayjs(item?.endingDate).format("YYYY-MM-DD HH:mm:ss")
        : "N/A",
      customer: `${item?.customer?.firstName ?? " "} ${
        item?.customer?.lastname ?? ""
      }`,
      phone: item?.customer?.otherMsisdns?.at(0),
    };
  });

  const onRangeChange = (
    dates: null | (Dayjs | null)[],
    dateStrings: string[]
  ) => {
    if (dates) {
      setSelectedMonth(dateStrings);
    }
  };

  const itemToFilter = [
    {
      title: "status",
      subItems:
        layout?.selectedTabIndex === "1" ? summaryStatus : extractedStatus,
    },
  ];

  const items = [
    {
      key: "1",
      label: "Summary",
      children: (
        <CustomerTransactionTable
          setPage={setPage}
          limit={limit}
          selectedStatus={selectedStatus}
          setSelectedStatus={setSelectedStatus}
          setLimit={setLimit}
        />
      ),
    },
    {
      key: "2",
      label: "Extracted",
      children: (
        <SavetheChildrentable
          setPage={setPage}
          limit={limit}
          selectedStatus={selectedStatus}
          setSelectedStatus={setSelectedStatus}
          setLimit={setLimit}
        />
      ),
    },
  ];

  return (
    <SubLayout
      hidePagination
      currentModule="Loan Book"
      datePicker={
        <RangePicker
          picker="month"
          onChange={onRangeChange}
          allowClear={false}
          defaultValue={[
            dayjs(firstDate, monthFormat),
            dayjs(secondDate, monthFormat),
          ]}
          className="h-10"
        />
      }
      buttons={[
        layout?.selectedTabIndex === "1" ? (
          <CSVExportButton
            csvHeaders={CustomerTransactionReportSumaryHeaders}
            csvData={csvDataSummary}
            filename={`Transaction_Report_Summary.csv`}
          />
        ) : (
          <CSVExportButton
            csvHeaders={CustomerTransactionReportHeaders}
            csvData={csvData}
            filename={`Transaction_Report_extracted.csv`}
          />
        ),
        <PrimaryButton
          btnName="Refresh"
          icon={<LuRefreshCcw />}
          onClick={handleGetItems}
          isLoading={report?.isFetching}
        />,
      ]}
      filterData={itemToFilter}
      handleSubmit={handleGetItems}
      tabs
      items={items}
    >
      <Statistics />
    </SubLayout>
  );
};

export default OverView;
