import React, { ReactNode } from "react";

interface searchProps {
  gridList?: ReactNode;
  search?: ReactNode;
}
const SearchAndGridBar = ({ gridList, search }: searchProps) => {
  return (
    <div className="flex gap-2 flex-wrap">
      <div>{search}</div>
      <div>{gridList}</div>
    </div>
  );
};

export default SearchAndGridBar;
