import React, { useState } from "react";
import MetricsCard from "../../../../components/cards/purchase/MetricsCard";
import { ReactComponent as LocalAtmIcon } from "../../../../assets/icons/new/icon1.svg";
import { ReactComponent as DoneAllIcon } from "../../../../assets/icons/new/icon2.svg";
import { ReactComponent as AutoGraphOutlinedIcon } from "../../../../assets/icons/new/icon1.svg";
import { ReactComponent as NotificationsOutlinedIcon } from "../../../../assets/icons/new/icon3.svg";
import { useDispatch, useSelector } from "react-redux";
import { getRecentPurchaseOrdersAction } from "../../../../store/purchase/actions";
import DetailsHeaderActionBar from "../../../../components/cards/DetailsPage/DetailsHeaderActionBar";
import { useNavigate } from "react-router-dom";
import { LineChart } from "react-chartkick";
import { getDefaultCurrencyCode } from "../../../../utils/helper";
import { DateRangePicker } from "react-date-range";
import { enUS } from "date-fns/locale";
import CardLayout from "../../../../components/cards/Layout/CardLayout";
import { getChannelStatisticAction } from "../../../../store/channel/actions";
import SubLayout from "../../../../components/layout/subLayout/SubLayout";

const getTotalValue = (agents: any) => {
  const totalObj = agents?.find((agent: any) => agent?.total !== undefined);
  return totalObj ? totalObj.total : 0;
};

const SubscriptionOverview = () => {
  const { auth, company, channel } = useSelector((state: any) => state);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const getLastWeekDate = () => {
    const today = new Date();
    const lastWeek = new Date(today);
    lastWeek.setDate(lastWeek.getDate() - 6);
    lastWeek.setHours(0, 0, 0, 0);
    return lastWeek;
  };
  const [selectedDateRange, setSelectedDateRange] = useState({
    startDate: getLastWeekDate(),
    endDate: new Date(),
    key: "selection",
  });
  const handleSelect = (ranges: any) => {
    setSelectedDateRange(ranges.selection);
  };

  React.useEffect(() => {
    if (auth?.token && channel?.selected?._id) {
      getRecentPurchaseOrdersAction(auth?.token, "?")(dispatch);
      getChannelStatisticAction(
        auth?.token,
        `?channel=${channel?.selected?._id}`
      )(dispatch);
    }
  }, [auth?.token, dispatch, channel?.selected?._id]);

  const data = [
    {
      name: "Today",
      data: {
        "7AM": 20000,
        "10AM": 15000,
        "12AM": 17000,
        "13PM": 21000,
        "16PM": 10000,
        "18PM": 9000,
        "20AM": 16000,
      },
      color: "#2943D6",
    },
    {
      name: "Yesterday",
      data: {
        "7AM": 1000,
        "10AM": 25000,
        "12AM": 6000,
        "13PM": 15000,
        "16PM": 29000,
        "18PM": 5000,
        "20AM": 5000,
      },
      color: "#C4C4C4",
    },
  ];

  const lineOptions = {
    colors: data.map((line: any) => line.color),
  };

  return (
    <SubLayout
      currentModule={channel?.selected?.name}
      hidePagination
      subModule={"Overview"}
    >
      <CardLayout
        isLoading={channel?.isGettingOverview || channel?.isFetching}
        initialItems={3}
      >
        <MetricsCard
          title={"Total Shops"}
          number={getTotalValue(channel?.overviewData?.data?.shops)}
          icon={<LocalAtmIcon className="w-9 h-9 border rounded-full p-1.5" />}
        />
        <MetricsCard
          title={"Total agents"}
          number={getTotalValue(channel?.overviewData?.data?.agents)}
          icon={<DoneAllIcon className="w-9 h-9 border rounded-full p-1.5" />}
        />
        <MetricsCard
          title={"Plans"}
          number={getTotalValue(channel?.overviewData?.data?.plans)}
          icon={
            <AutoGraphOutlinedIcon className="w-9 h-9 border rounded-full p-1.5" />
          }
        />
      </CardLayout>
      <div className="mt-6 w-full hidden">
        <div className="hidden sm:block flex-1 bg-white mr-4 w-full p-3">
          <h2 className="opacity-45 mb-2">Total Revenue</h2>
          <div className="space-y-0.5">
            <h1 className="font-semibold">
              {`${getDefaultCurrencyCode(company)} 0 / 0 Pieces`}
            </h1>
            <h4 className="opacity-40 font-semibold">
              {`${getDefaultCurrencyCode(company)} 0 / 0 Pieces`}
            </h4>
          </div>
          <LineChart
            data={data}
            prefix={`${getDefaultCurrencyCode(company)}`}
            thousands=","
            xtitle="Hours"
            loading="Loading..."
            options={lineOptions}
            legend={true}
          />
        </div>
        <div className="hidden 2xl:block w-full xl:w-[50%] 2xl:w-[30%] overflow-x-auto pb-4 bg-white">
          <DateRangePicker
            onChange={handleSelect}
            showPreview
            months={1}
            ranges={[selectedDateRange]}
            direction="vertical"
            locale={enUS}
            scroll={true}
          />
        </div>
      </div>
    </SubLayout>
  );
};

export default SubscriptionOverview;
