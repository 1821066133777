import { Form, InputNumber, Modal, Select, Space, Switch } from "antd";
import type { FormProps } from "antd";
import { Button } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllChannelAction,
  getAllShopAction,
  getPricelistItemAction,
} from "../../store/channel/actions";
import { roles } from "../../utils/roles";
import {
  createInventoryTrackctions,
  getAllInventoryTrackctions,
} from "../../store/inventory/actions";

const AddStockAlertForm = (props: any) => {
  const { auth, channel, product, inventory } = useSelector(
    (state: any) => state
  );
  const [selectedShop, setSelectedShop] = useState("");
  const [allShopsApplied, setAllShopsApplied] = useState(false);
  const [role, setRole] = useState<any>([]);
  const dispatch = useDispatch();
  const [productId, setProductId] = useState("");
  const [selectedChannel, setSelectedChannel] = useState("");
  const [form] = Form.useForm();

  const onFinish: FormProps["onFinish"] = async (values) => {
    await createInventoryTrackctions(auth?.token, {
      ...values,
      device: [productId],
      allShops: allShopsApplied,
    })(dispatch);
    await getAllInventoryTrackctions(auth?.token, `?`)(dispatch);
    form.resetFields();
    setAllShopsApplied(false);
    props?.setIsModalOpen(false);
  };
  const onChange = (value: string) => {
    setSelectedChannel(value);
  };
  const onShopChange = (value: string) => {
    setSelectedShop(value);
  };

  const onSearch = (value: string) => {
    console.log("search:", value);
  };
  const handleSwitches = (checked: boolean) => {
    setAllShopsApplied(checked);
  };

  useEffect(() => {
    auth?.token && getAllChannelAction(auth?.token)(dispatch);
  }, [auth?.token, dispatch]);

  useEffect(() => {
    if (auth?.token && selectedChannel) {
      getAllShopAction(auth?.token, `channel=${selectedChannel}`)(dispatch);
    }
  }, [auth, selectedChannel, dispatch]);

  const channels = channel?.all?.data?.map((el: any) => ({
    value: el?._id,
    label: el?.name,
  }));
  const allShops = channel?.allShop?.data?.map((el: any) => ({
    value: el?._id,
    label: el?.name,
  }));
  const allProducts = channel?.priceListItems?.data?.map((el: any) => ({
    value: el?._id,
    label: el?.product?.model,
  }));

  const roleOption = roles?.map((el: any) => ({
    value: el?.toLowerCase(),
    label: el?.split("-").join(" "),
  }));
  const handleChange = (value: string[]) => {
    setRole(value);
  };

  useEffect(() => {
    if (selectedChannel) {
      auth?.token &&
        getPricelistItemAction(
          auth?.token,
          `?channel=${selectedChannel}&limit=15&type=pos`
        )(dispatch);
    }
  }, [productId, selectedChannel, dispatch]);

  const dynamicHeight = selectedShop.length > 0 ? "100%" : "50px";
  const dynamicHeights = role.length > 0 ? "100%" : "50px";

  return (
    <>
      <Form
        name="basic"
        form={form}
        style={{ maxWidth: "100%" }}
        initialValues={{ remember: true }}
        onFinish={onFinish}
        autoComplete="off"
      >
        <div className="flex justify-end ">
          <div className="flex gap-2 border border-[#D2D2D2] rounded-md justify-center items-center p-3 w-fit ">
            <p className=" text-sm text-[#030229]">Apply to All Shops</p>
            <Switch
              defaultValue={allShopsApplied}
              onChange={handleSwitches}
              checked={allShopsApplied}
            />
          </div>
        </div>
        <div className="sm:flex gap-5 mt-5">
          <Form.Item
            label={null}
            name="channel"
            className="capitalize  w-full sm:w-1/2 h-full"
            rules={[{ required: true, message: "Please channel required!" }]}
          >
            <Select
              showSearch
              className="capitalize w-1/2 h-12"
              placeholder="Select Channel"
              optionFilterProp="label"
              allowClear
              loading={channel.isFetching}
              disabled={channel.isFetching}
              onChange={onChange}
              onSearch={onSearch}
              options={channels}
            />
          </Form.Item>
          {!allShopsApplied && (
            <Form.Item
              label={null}
              name="shops"
              className="capitalize  w-full sm:w-1/2  h-full"
              rules={[{ required: true, message: "Please Shop is required!" }]}
            >
              <Select
                showSearch
                mode="multiple"
                allowClear
                loading={channel.isFetching}
                disabled={channel.isFetching}
                className="capitalize  w-full"
                style={{ height: dynamicHeight }}
                placeholder="Select Shop"
                onChange={onShopChange}
                options={allShops}
                optionRender={(option) => <Space>{option.label}</Space>}
              />
            </Form.Item>
          )}
        </div>
        <div className="mt-5 sm:flex gap-5">
          <Form.Item
            label={null}
            name="devices"
            className="capitalize  w-full sm:w-1/2 h-full"
            rules={[{ required: true, message: "Please model is required!" }]}
          >
            <Select
              loading={product.isFetching}
              disabled={product.isFetching}
              showSearch
              allowClear
              className="capitalize  w-1/2 h-12"
              placeholder="Filter By model"
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option?.label ?? "").toString().includes(input)
              }
              filterSort={(optionA, optionB) =>
                (optionA?.label ?? "")

                  .toString()
                  .toLowerCase()
                  .localeCompare(
                    (optionB?.label ?? "").toString().toLowerCase()
                  )
              }
              options={allProducts}
              onChange={(value: any) => {
                !value && setProductId(" ");
                value && setProductId(value);
              }}
            />
          </Form.Item>
          <Form.Item
            label={null}
            name="roleToNotify"
            className="capitalize  w-full sm:w-1/2 h-full"
            rules={[{ required: true, message: "Please users are required!" }]}
          >
            <Select
              mode="multiple"
              allowClear
              showSearch
              className="capitalize  w-full"
              style={{ height: dynamicHeights }}
              placeholder="Select Users to notify"
              onChange={handleChange}
              options={roleOption}
              optionRender={(option) => <Space>{option.label}</Space>}
            />
          </Form.Item>
        </div>
        <div className="mt-5 sm:flex gap-5">
          <Form.Item
            name={"stockLimit"}
            label={<h1 className="text-sm  text-[#030229]"> Device Limit</h1>}
            rules={[{ required: true, type: "number", min: 0 }]}
          >
            <InputNumber className="h-12 w-full" />
          </Form.Item>
        </div>
        <Form.Item
          label={null}
          className="w-full flex justify-center items-center mt-10"
        >
          <Button
            type="primary"
            htmlType="submit"
            className="w-64 h-12"
            loading={inventory?.isFetching}
          >
            Submit
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};
export default AddStockAlertForm;
