import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Paper, Stack } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { Select, Form, InputNumber } from "antd";
import AddIcon from "@mui/icons-material/Add";
import ProductFilter from "../../../../components/filters/ProductFiltering";
import { addItemToSelectedQuotationAction } from "../../../../store/quotation/actions";
import { getOneProductAction } from "../../../../store/product/actions";
import { myProductActions } from "../../../../store/product";

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

const PageView = (props: any) => {
  const [form] = Form.useForm();
  const { product, auth, quotation } = useSelector((state: any) => state);
  const dispatch = useDispatch();
  const [selectedModelId, setSelectedModelId] = useState(null);

  const onFinish = async (values: any) => {
    const val = {
      ...values,
      specification: [
        ["Type", product?.selected?.type],
        ["Brand", product?.selected?.brand],
        ...Object.entries(values.specification),
      ],
    };
    if (auth?.token) {
      const res = await addItemToSelectedQuotationAction(
        quotation?.selected?._id,
        val,
        auth.token
      )(dispatch);
      if (res) {
        dispatch(myProductActions.setSelected(null));
        setSelectedModelId(null);
        form.resetFields();
      }
    }
  };

  useEffect(() => {
    dispatch(myProductActions.setSelected(null));
    auth?.token &&
      selectedModelId &&
      getOneProductAction(auth?.token, selectedModelId)(dispatch);
  }, [selectedModelId, quotation.selected, dispatch, auth?.token]);

  return (
    <Stack spacing={0}>
      <ProductFilter />
      <Paper elevation={0}>
        <Stack spacing={0}>
          <Paper elevation={0} className="md:pr-4 pt-3 pb-2">
            <Form
              {...formItemLayout}
              form={form}
              name="register"
              onFinish={onFinish}
              initialValues={{}}
              style={{ maxWidth: "100%" }}
              scrollToFirstError
            >
              <div className="md:flex justify-between">
                <div>
                  <Form.Item
                    name="product"
                    label="Product Model"
                    tooltip="Please select modal of the product!"
                    rules={[
                      {
                        required: true,
                        message: "Model is required!",
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      allowClear
                      loading={product.isFetching}
                      disabled={product.isFetching}
                      className="w-full"
                      value={selectedModelId === "" ? null : selectedModelId}
                      placeholder="Select By Model"
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        (option?.label ?? "")
                          .toString()
                          .toLowerCase()
                          .includes(input)
                      }
                      filterSort={(optionA, optionB) =>
                        (optionA?.label ?? "")
                          .toString()
                          .toLowerCase()
                          .localeCompare(
                            (optionB?.label ?? "").toString().toLowerCase()
                          )
                      }
                      options={product?.all?.data?.map((pro: any) => ({
                        label: `${pro?.model} ~  ${pro?.type}`,
                        value: pro._id,
                      }))}
                      onChange={(value: any) => {
                        !value && setSelectedModelId(null);
                        value && setSelectedModelId(value);
                      }}
                    />
                  </Form.Item>
                  {selectedModelId !== null && (
                    <div className=" flex flex-wrap gap-3 md:ml-[10.8rem]">
                      {product?.selected &&
                        product?.selected?.specs.map(
                          (spec: any) =>
                            spec.value.length >= 1 && (
                              <Form.Item name={["specification", spec?.label]}>
                                <Select
                                  showSearch
                                  allowClear
                                  loading={product.isFetching}
                                  disabled={product.isFetching}
                                  style={{ width: 195 }}
                                  placeholder={`Select  ${spec?.label}`}
                                  optionFilterProp="children"
                                  filterOption={(input, option) =>
                                    (option?.label ?? "")
                                      .toString()
                                      .includes(input)
                                  }
                                  filterSort={(optionA, optionB) =>
                                    (optionA?.label ?? "")
                                      .toString()
                                      .toLowerCase()
                                      .localeCompare(
                                        (optionB?.label ?? "")
                                          .toString()
                                          .toLowerCase()
                                      )
                                  }
                                  options={spec?.value?.map((pro: any) => ({
                                    label: pro,
                                    value: pro,
                                  }))}
                                  onChange={(value: any) => {
                                    console.log("::", value);
                                  }}
                                />
                              </Form.Item>
                            )
                        )}
                    </div>
                  )}
                </div>
                <Stack direction={"column"} spacing={0}>
                  <Form.Item
                    name="quantity"
                    label="Quantity"
                    tooltip="Please enter number of quantity of the product you selected!"
                    rules={[
                      {
                        required: true,
                        message: "Quantity is required!",
                      },
                    ]}
                  >
                    <InputNumber
                      min={1}
                      max={100000}
                      style={{ width: "80%" }}
                    />
                  </Form.Item>

                  <LoadingButton
                    type="submit"
                    variant="contained"
                    onClick={() => {
                      props?.onClickAdd();
                    }}
                    startIcon={<AddIcon />}
                    sx={{ width: "89%", bgcolor: "#605BFF" }}
                    loading={quotation?.isAddingItem}
                  >
                    Add Product
                  </LoadingButton>
                </Stack>
              </div>
            </Form>
          </Paper>
        </Stack>
      </Paper>
    </Stack>
  );
};
export default PageView;
