import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Button, Tag } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { mySubscritionActions } from "../../../../store/subscription/order";
import CreateOrder from "./Createorder";
import { Table } from "antd";
import {
  createOrderAction,
  deleteSubsorderAction,
  getAllSubscriptionOrdersAction,
  getOneSubsOrderAction,
} from "../../../../store/subscription/order/actions";
import { searchValue } from "../../../../utils/setColor";
import type { ColumnsType } from "antd/es/table";
import ThreeDotDropdown from "../Accounts/accountactions/threeDotDropdown";
import DeleteModal from "../../../../components/Modals/DeleteModal";
import CurstomeCardSkeleton from "../../../../components/skeleton/CurstomeCardSkeleton";
import {
  handleFoundCustomerByPhone,
  handleNewCustomer,
  handlerUpdateAccountAction,
  handleSetSubscriptionDealSteps,
} from "../../../../store/layout/actions";
import { myCustomerActions } from "../../../../store/customer";
import SubLayout from "../../../../components/layout/subLayout/SubLayout";
import CustomTypography from "../../../../styles/globalStyles/CustomTypography";
import CustomTag from "../../../../components/buttons/Tag";
import { useMediaQuery } from "@mui/material";

const OrdersCard = (props: any) => {
  const navigate = useNavigate();
  const [open, setOpen] = useState<boolean>(false);
  const [orderId, setOrderId] = useState("");
  const { auth, orders, layout } = useSelector((state: any) => state);
  const dispatch = useDispatch();
  const [limit, setLimit] = useState(20);
  const [page, setPage] = useState(1);
  const [deleteDataId, setDeleteDataId] = useState<any>("");
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const smallDevices = useMediaQuery("(max-width:900px)");

  const showDrawer = async () => {
    await dispatch(mySubscritionActions.setSelected(null));
    handleFoundCustomerByPhone(null)(dispatch);
    handleNewCustomer(false)(dispatch);
    dispatch(myCustomerActions.setCustomerByPhonenNumber(null));
    const res = await createOrderAction(auth?.token, {})(dispatch);
    if (res?.type) {
      navigate(`/subscription/order/new/${res?.data?._id}`);
      await getAllSubscriptionOrdersAction(
        auth?.token,
        `?status=created&status=pending&status=draft&limit=${limit}&page=${
          page - 1
        }`
      )(dispatch);
    }
  };

  const onClose = async () => {
    setOpen(false);
    setOrderId("");
    dispatch(mySubscritionActions.setSelected(null));
    handlerUpdateAccountAction(false)(dispatch);
    handleSetSubscriptionDealSteps(0)(dispatch);
  };

  const handleGetItems = () => {
    if (layout?.selectedFilters?.status) {
      auth?.token &&
        getAllSubscriptionOrdersAction(
          auth?.token,
          `?status=${layout?.selectedFilters?.status}&limit=${limit}&page=${
            page - 1
          }`
        )(dispatch);
    } else {
      auth?.token &&
        getAllSubscriptionOrdersAction(
          auth?.token,
          `?status=finance-review&status=created&status=pending&status=draft&limit=${limit}&page=${
            page - 1
          }`
        )(dispatch);
    }
  };

  useEffect(() => {
    handleGetItems();
  }, [auth?.token, dispatch, limit, page]);

  useEffect(() => {
    orderId &&
      auth?.token &&
      getOneSubsOrderAction(auth?.token, orderId)(dispatch);
  }, [auth.token, dispatch, orderId]);

  const columns: ColumnsType<any> = [
    {
      title: "Customer Name",
      dataIndex: "customerName",
      width: 150,
      render: (text: string) => <p className="capitalize">{text}</p>,
    },
    {
      title: "Customer Number",
      dataIndex: "customerNumber",
      width: 200,
    },
    {
      title: "Number of Devices",
      dataIndex: "deviceNumber",
      width: 200,
    },
    {
      title: "Created by",
      dataIndex: "createdBy",
      render: (text: string) => <p className="capitalize">{text}</p>,
    },
    {
      title: "Created Date",
      dataIndex: "date",
    },
    {
      title: "Created Time",
      dataIndex: "time",
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (status) => {
        return (
          <>
            <Tag
              color={searchValue(status)}
              className="text-md rounded-md min-w-20 h-[26px] py-[2px] px-6 capitalize text-center"
            >
              {status}
            </Tag>
          </>
        );
      },
    },
  ];

  const dataToDisplay = orders?.all?.data?.map((el: any) => {
    return {
      dataIndex: el._id,
      customerName: el?.account?.customer?.firstName
        ? `${el?.account?.customer?.firstName} ${
            el?.account?.customer?.lastname || ""
          }`
        : el?.account?.customer?.name,
      customerNumber: el?.account?.customer?.customerId,
      deviceNumber: el?.list?.length,
      createdBy: el?.createdBy?.names,
      date: el?.createdAt?.substring(0, 10),
      time: el?.createdAt?.substring(11, 16),
      status: el?.status,
      data: el,
    };
  });

  const openDeleteModel = (value: any) => {
    setDeleteDataId(value);
    setIsDeleteModalOpen(true);
  };

  const handleUpdate = (value: any) => {
    navigate(`/subscription/order/new/${value}`);
  };

  const onCancelDeleteModel = () => {
    setDeleteDataId("");
    setIsDeleteModalOpen(false);
  };

  const deleteOrder = async () => {
    if (auth?.token) {
      await deleteSubsorderAction(auth?.token, deleteDataId)(dispatch);
      onCancelDeleteModel();
      await getAllSubscriptionOrdersAction(
        auth?.token,
        `?status=created&status=pending&status=draft&limit=${limit}&page=${
          page - 1
        }`
      )(dispatch);
    }
  };

  useEffect(() => {
    handleSetSubscriptionDealSteps(0)(dispatch);
    dispatch(myCustomerActions.setCustomerByPhonenNumber(null));
    handleFoundCustomerByPhone(null)(dispatch);
    handleNewCustomer(false)(dispatch);
    dispatch(mySubscritionActions.setNew(null));
    dispatch(myCustomerActions.setNew(null));
    dispatch(mySubscritionActions.setSelected(null));
  }, [dispatch]);

  const itemToFilter = [
    {
      title: "status",
      subItems: ["draft", "created"]?.map((el: string) => {
        return {
          label: el,
          value: el,
        };
      }),
    },
  ];

  return (
    <>
      <SubLayout
        currentModule="Deals"
        buttons={[
          <Button
            className="bg-[#2943D6]  text-white px-[23px] py-5"
            onClick={showDrawer}
          >
            <PlusOutlined />
            <span className="font-semibold">Create Deal</span>
          </Button>,
        ]}
        filterData={itemToFilter}
        handleSubmit={handleGetItems}
        loading={orders?.isFetching}
        hasGridList
        setLimit={setLimit}
        setPage={setPage}
        limit={limit}
        total={orders?.all?.total}
        count={Math.ceil(orders?.all?.total / limit)}
      >
        <div>
          {(layout?.isGridView === "grid" || smallDevices) && (
            <div
              className={`grid ${
                layout?.isSideNavOpen
                  ? "sm:grid-cols-2 xl:grid-cols-3"
                  : "sm:grid-cols-2 xl:grid-cols-3"
              } 2xl:grid-cols-4 gap-3 px-2 text-white`}
            >
              <>
                {orders?.isFetching ? (
                  <>
                    {[1, 2, 3, 4, 1, 2, 3, 4, 1, 2, 3, 4]?.map((el: any) => (
                      <CurstomeCardSkeleton />
                    ))}
                  </>
                ) : (
                  <>
                    {orders?.all?.data
                      ?.filter(
                        (item: any) =>
                          item?.account?.customer !== undefined &&
                          item?.account?.customer !== null
                      )
                      ?.map((item: any) => {
                        const Qnty = item?.list?.reduce(
                          (curr: any, item: any) => curr + item?.quantity,
                          0
                        );

                        return (
                          <div
                            key={item?._id}
                            className={`${
                              item?._id === orders?.selected?._id
                                ? "bg-slate-200"
                                : "bg-white"
                            } rounded-lg  xw-[360px] min-h-[156px] p-[16px] shadow relative`}
                            onClick={(event: any) => {
                              const isDisabledDiv = event.target.closest(
                                ".ant-dropdown-trigger"
                              );
                              const isDisabledlabel =
                                event.target.closest(".ant-dropdown-menu");
                              if (!isDisabledDiv && !isDisabledlabel) {
                                if (item?.status === "draft") {
                                  navigate(
                                    `/subscription/order/new/${item?._id}`
                                  );
                                } else {
                                  navigate(`/subscription/order/${item?._id}`);
                                }
                              }
                            }}
                          >
                            <div className="flex  justify-between">
                              <div className="space-y-1">
                                <CustomTypography variant="h2">
                                  {" "}
                                  {item?.account?.customer?.firstName
                                    ? `${item?.account?.customer?.firstName} ${
                                        item?.account?.customer?.lastname || ""
                                      }`
                                    : item?.account?.customer?.name ||
                                      "Undefined"}
                                </CustomTypography>
                                <CustomTypography
                                  variant="p"
                                  className="sm:text-[#605BFF] text-sm"
                                >
                                  {item?.SOID}
                                </CustomTypography>
                              </div>
                              <div>
                                <div className="flex items-center">
                                  <CustomTag
                                    color={item?.status}
                                    size="large"
                                    title={item?.status}
                                  />

                                  <ThreeDotDropdown
                                    onDelete={() => openDeleteModel(item?._id)}
                                    onUpdate={() => handleUpdate(item?._id)}
                                    status={item?.status}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="pt-8">
                              <CustomTypography variant="label">
                                Created By:
                              </CustomTypography>
                              <CustomTypography variant="value">
                                {item?.createdBy?.names || "-"}
                              </CustomTypography>
                            </div>
                            <div className=" mt-2 flex justify-between">
                              <div className="flex">
                                <CustomTypography variant="label">
                                  On:
                                </CustomTypography>
                                <CustomTypography variant="value">
                                  {item?.createdAt?.substring(0, 16)}
                                </CustomTypography>
                              </div>
                              <div>
                                <CustomTypography variant="value">
                                  {`${item?.list?.length} Model(s) /`}
                                </CustomTypography>
                                <CustomTypography variant="value">
                                  {`${Qnty} Qnty`}
                                </CustomTypography>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                  </>
                )}
              </>
            </div>
          )}
          {layout?.isGridView === "list" && !smallDevices && (
            <Table
              onRow={(record, _rowIndex) => {
                return {
                  onClick: (event) => {
                    record?.status === "draft"
                      ? navigate(`/subscription/order/new/${record?._id}`)
                      : navigate(`/subscription/order/${record?._id}`);
                  },
                };
              }}
              columns={columns}
              dataSource={dataToDisplay}
              pagination={false}
              loading={orders?.isFetching}
              className="bg-white overflow-x-auto"
            />
          )}
        </div>
      </SubLayout>
      <CreateOrder onClose={onClose} open={open} setOpen={setOpen} />
      <DeleteModal
        visible={isDeleteModalOpen}
        onOk={deleteOrder}
        onCancel={onCancelDeleteModel}
        itemName={"Order"}
        isLoading={orders?.isFetching}
      />
    </>
  );
};

export default OrdersCard;
