import { Divider } from "antd";
import React from "react";
import CustomTypography from "../../../styles/globalStyles/CustomTypography";

const Overviewcard = ({
  name,
  icon,
  currentNumber,
  prevNumber,
  percentage,
  footer,
  color,
  level,
  hiddePercentage,
}: any) => {
  return (
    <div className="bg-white rounded-lg pt-5 px-6 w-full">
      <div className="flex items-center justify-between">
        <CustomTypography variant="label">{name}</CustomTypography>
        <span>
          <img src={icon} alt="" />
        </span>
      </div>

      <div className="mt-3 text-base">
        <CustomTypography variant="h2">{currentNumber}</CustomTypography>
        <CustomTypography variant="h2">{prevNumber}</CustomTypography>
      </div>

      {!hiddePercentage && (
        <div className="flex items-center text-gray-600 text-sm font-normal mt-2.5 gap-x-2">
          <span className="text-[13px]">
            {level === "up" ? "Increased By " : "Decreased By "}
          </span>
          <span className={`text-${color}-500 text-sm`}>{percentage}</span>
        </div>
      )}
      <Divider dashed />
      <div className="text-gray-700 text-sm -mt-3">{footer}</div>
    </div>
  );
};

export default Overviewcard;
