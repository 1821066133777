import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ProductGrid from "../../../../components/grids/warehouse/ProductGridStock";
import { Box, Skeleton } from "@mui/material";
import { getStockStatsAction } from "../../../../store/wareHouse/actions";
import { WarehouseHeaders } from "../../../../components/csvHeaders/Headers";
import CSVExportButton from "../../../../components/cards/NoSale/CSVExportButton";
import { getStock_CSVAction } from "../../../../store/csvDownload/actions";
import {
  getAllAttributesAction,
} from "../../../../store/product/actions";
import { myProductActions } from "../../../../store/product";
import SubLayout from "../../../../components/layout/subLayout/SubLayout";

const WareHouseStock = () => {
  const { auth, wareHouse, layout, CSV, product } = useSelector(
    (state: any) => state
  );
  const dispatch = useDispatch();

  dispatch(
    myProductActions.setQuery(
      `?${
        layout?.selectedFilters?.type &&
        `type=${layout?.selectedFilters?.type}${
          layout?.selectedFilters?.brand &&
          `&brand=${layout?.selectedFilters?.brands}`
        }&`
      }`
    )
  );

  useEffect(() => {
    if (auth?.token) {
      getAllAttributesAction(
        auth?.token,
        `?${
          layout?.selectedFilters?.type
            ? `type=${layout?.selectedFilters?.type}`
            : ""
        }`
      )(dispatch);
    }
  }, [auth, layout?.selectedFilters?.type, dispatch]);

  const handleSubmit = async () => {
    auth?.token &&
      getStockStatsAction(
        auth?.token,
        `?${
          layout?.selectedFilters?.type
            ? `type=${layout?.selectedFilters?.type}`
            : ""
        }${
          layout?.selectedFilters?.brands
            ? `&brand=${layout?.selectedFilters?.brands}`
            : ""
        }`
      )(dispatch);
  };
  useEffect(() => {
    auth?.token && getStockStatsAction(auth?.token, `?`)(dispatch);
  }, [auth?.token, dispatch]);

  React.useEffect(() => {
    if (layout?.openCSVButton)
      auth?.token &&
        getStock_CSVAction(
          auth?.token,
          `?${
            layout?.selectedFilters?.type
              ? `type=${layout?.selectedFilters?.type}`
              : ""
          }${
            layout?.selectedFilters?.brands
              ? `&brand=${layout?.selectedFilters?.brands}`
              : ""
          }` + `&limit=${wareHouse?.allStockStats?.total}&page=0`
        )(dispatch);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [layout?.openCSVButton]);

  const csvData = CSV?.csvDownloadedData?.data?.map((record: any) => ({
    model: record?.model || "N/A",
    brand: record?.brand || "N/A",
    type: record?.type || "N/A",
    inShop: record?.statuses
      .filter((status: any) => status.status === "out")
      .map((status: any) => status.count),
    inWarehouse: record?.statuses
      .filter((status: any) => status.status === "in")
      .map((status: any) => status.count),
    sold: record?.statuses
      .filter((status: any) => status.status === "sold")
      .map((status: any) => status.count),
  }));

  const statusData = [
    {
      title: "type",
      subItems: product?.attributes?.types?.map((el: any) => ({
        label: `${el?.value}`,
        value: el?.value,
      })),
    },
    {
      title: "brands",
      subItems: product?.attributes?.brands?.map((el: any) => ({
        label: `${el?.value}`,
        value: el?.value,
      })),
    },
  ];
  
  return (
    <SubLayout
      currentModule="Stock"
      filterData={statusData}
      hidePagination
      buttons={[
        <CSVExportButton
          csvHeaders={WarehouseHeaders}
          csvData={csvData ? csvData : ""}
          filename={`Warehouse/stock.csv`}
        />,
      ]}
      loading={product?.isFetching}
      handleSubmit={handleSubmit}
    >
      {wareHouse?.isFetching && wareHouse?.allStockStats?.data?.length === 0 ? (
        <div className="flex flex-wrap gap-4 justify-start items-start">
          {Array.from({ length: 9 }).map((d: any) => (
            <Box sx={{ width: 330 }}>
              <Skeleton animation="wave" />
              <Skeleton variant="rectangular" height={150} />
            </Box>
          ))}
        </div>
      ) : (
        <>
            <div className="flex flex-wrap gap-6 justify-start items-start ">
              {wareHouse?.allStockStats?.status === 200 && (
                <ProductGrid
                  // handleSelected={handleSelected}
                  data={wareHouse?.allStockStats?.data}
                />
              )}
            </div>
        </>
      )}
    </SubLayout>
  );
};
export default WareHouseStock;
