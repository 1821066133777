import React from "react";
import { Table, Tag } from "antd";
import { useSelector } from "react-redux";
import { searchValue } from "../../utils/setColor";
import { getDefaultCurrencyCode } from "../../utils/helper";

const SubscriptionRequestTable = () => {
  const { myZaka, company } = useSelector((state: any) => state);

  const dataToDisplay = myZaka?.all?.data?.map((el: any) => {
    return {
      key: el._id,
      date: el?.createdAt?.slice(0, 10),
      fname: el?.cart?.customer?.firstName || "N/A",
      lname: el?.cart?.customer?.lastname || "N/A",
      shop: el?.cart?.shop?.name || "N/A",
      msisdn: el?.msisdn,
      loanAppID: el?.loanapplicationid,
      plan: "Monthly",
      upfront: el?.cart?.payment?.at(0)?.amount?.toLocaleString() || "",
      planPeriod: `${el?.cart?.account?.duration || "N/A"} Months` || "N/A",
      businessProductType: el?.cart?.selectedPlan?.businessProductType || "N/A",
      pickedUpDevice: el?.cart?.status?.toLowerCase() === "paid" ? "Yes" : "No",
      status: el?.status,
      data: el,
    };
  });

  const columns = [
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
    },
    {
      title: "Shop",
      dataIndex: "shop",
      key: "shop",
    },
    {
      title: "First Name",
      dataIndex: "fname",
      key: "fname",
      render: (text: any) => (
        <div>
          <p className="capitalize">{`${text}`}</p>
        </div>
      ),
    },
    {
      title: "Last Name",
      dataIndex: "lname",
      key: "lname",
      render: (text: any) => (
        <div>
          <p className="capitalize">{`${text}`}</p>
        </div>
      ),
    },
    {
      title: "MSISDN",
      dataIndex: "msisdn",
      key: "msisdn",
      render: (text: any) => (
        <div>
          <p className="capitalize">{`${text}`}</p>
        </div>
      ),
    },
    {
      title: "Loan Application ID",
      dataIndex: "loanAppID",
      key: "loanAppID",
    },
    {
      title: `Upfront (${getDefaultCurrencyCode(company)})`,
      dataIndex: "upfront",
      key: "upfront",
      render: (text: any) => (
        <div>
          <p className="capitalize">{text}</p>
        </div>
      ),
    },
    {
      title: "Loan Tenure",
      dataIndex: "planPeriod",
      key: "planPeriod",
      render: (text: any) => <p className="capitalize">{`${text}`}</p>,
    },
    {
      title: "Business Product Type",
      dataIndex: "businessProductType",
      key: "businessProductType",
      render: (text: any) => <p className="capitalize">{`${text}`}</p>,
    },
    {
      title: "Picked up device",
      dataIndex: "pickedUpDevice",
      key: "pickedUpDevice",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text: any) => (
        <Tag
          color={searchValue(text)}
          className="capitalize min-w-20 text-center py-0.5"
        >
          {text}
        </Tag>
      ),
    },
  ];

  const scroll = { x: true as const };
  return (
    <Table
      columns={columns}
      dataSource={dataToDisplay}
      pagination={false}
      scroll={scroll}
      loading={myZaka?.isFetching}
      className="scrollbar-hide"
    />
  );
};

export default SubscriptionRequestTable;
