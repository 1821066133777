import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal, Skeleton, Tag } from "antd";
import AssignShopForm from "../../../../components/forms/AssignShopForm";
import ScrollableFrame from "../../../../components/layout/ScrollableFrame";
import AssignChannelForm from "../../../../components/forms/AssignChannelForm";
import BsThreeDotsDropdown from "./BsThreeDotsDropdown";
import ChangePasswordModal from "../../../../components/Modals/ChangePasswordModal";
import ResetPassword from "../../../../components/forms/ResetPassword";
import { viewUserDetails } from "../../../../store/layout/actions";
import CustomTypography from "../../../../styles/globalStyles/CustomTypography";

const UserGrid = (props: any) => {
  const { layout, setting } = useSelector((state: any) => state);
  const dispatch = useDispatch();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isChannelModalOpen, setIsChannelModalOpen] = useState(false);
  const [changePassword, setChangePassword] = useState(false);
  const [userData, setUserData] = useState("");
  const [data, setData] = useState<any>([]);
  const [userIdData, setUserIdData] = useState<any>([]);

  const allUsers = setting?.all?.data;
  const AssignShopModelOpen = (value: any) => {
    setIsModalOpen(true);
    setData(value);
  };
  const AssignChannelModelOpen = (value: any) => {
    setIsChannelModalOpen(true);
    setUserIdData(value);
  };

  const handleChannelCancel = () => {
    setIsChannelModalOpen(false);
    setUserIdData(null);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setData(null);
  };

  const OpenChangePassword = (value: any) => {
    setChangePassword(!changePassword);
    setUserData(value);
  };

  const handleCloseChangePassword = () => {
    setChangePassword(false);
  };

  const style = {
    width: "100vh",
    color: "black",
    p: 2,
  };

  const handleViewUser = (value: any) => {
    viewUserDetails(true, value)(dispatch);
  };

  return (
    <>
      <ScrollableFrame
        setPage={props?.setPage}
        setLimit={props?.setLimit}
        limit={props.limit}
        total={setting?.all?.total}
        count={Math.ceil(setting?.all?.total / props?.limit)}
      >
        <div
          className={`grid ${
            layout.isSideNavOpen
              ? "md:grid-cols-2 2xl:grid-cols-3"
              : "md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4"
          } gap-3`}
        >
          {allUsers?.map((user: any) => (
            <>
              {setting?.isFetching ? (
                <div className="bg-white rounded-lg h-[140px] shadow-md p-4 border border-gray-300">
                  <Skeleton />
                </div>
              ) : (
                <div className="bg-white min-h-[11rem] p-4 rounded-md cursor-pointer relative">
                  <div className="flex gap-4">
                    <img
                      src={user?.picture}
                      alt="profile"
                      className="rounded-full w-[3rem] h-[3rem]"
                    />
                    <div className="flex justify-between  w-full">
                      <div>
                        <CustomTypography variant="h2">
                          {user?.names}
                        </CustomTypography>
                        <h1 className="text-base font-normal capitalize">
                          <p className=" text-[blue] pt-1 capitalize">
                            {user?.role?.split("-")?.join(" ")}
                          </p>
                          {user?.channel && (
                            <p className=" text-[blue] pt-1">
                              {user?.channel?.at(0)?.name}
                            </p>
                          )}
                        </h1>
                      </div>
                      <div className="flex gap-x-2">
                        <button className="bg-green-500  h-7 text-white px-2 rounded-md text-sm font-normal">
                          {user?.isActive === true ? "Active" : "Inactive"}
                        </button>
                        <BsThreeDotsDropdown
                          onViewDetails={() => handleViewUser(user)}
                          onChangePassword={() => OpenChangePassword(user)}
                          onAssignChannel={() => AssignChannelModelOpen(user)}
                          onAssignShop={() => AssignShopModelOpen(user)}
                          userData={user}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="w-full pt-6">
                    <div className="flex items-center">
                      <CustomTypography variant="label">
                        Email:
                      </CustomTypography>
                      <CustomTypography variant="value">
                        {user?.email}
                      </CustomTypography>
                    </div>
                    <div className="flex items-center">
                      <CustomTypography variant="label">
                        Msisdn:
                      </CustomTypography>
                      <CustomTypography variant="value">
                        {user?.phone}
                      </CustomTypography>
                    </div>
                    <p className="text-sm pt-2">{}</p>
                    {user?.role === "sales-agent" && user?.tag && (
                      <Tag className="text-sm px-3 mt-1">{user?.tag}</Tag>
                    )}
                  </div>
                </div>
              )}
            </>
          ))}
        </div>
      </ScrollableFrame>
      <Modal
        title={`Assign Shop To ${data?.names}`}
        open={isModalOpen}
        onCancel={handleCancel}
        footer={null}
        className="min-w-min"
      >
        <div>
          <div style={style}>
            <AssignShopForm onCancel={handleCancel} agentId={data?._id} />
          </div>
        </div>
      </Modal>
      <Modal
        title={`Assign Channel To ${userIdData?.names}`}
        open={isChannelModalOpen}
        onCancel={handleChannelCancel}
        footer={null}
        className="50vh"
      >
        <div style={style}>
          <AssignChannelForm
            onCancel={handleChannelCancel}
            userId={userIdData?._id}
          />
        </div>
      </Modal>

      {changePassword && (
        <ChangePasswordModal
          handleOpenChangePasswordModel={changePassword}
          handleChangePasswordModel={handleCloseChangePassword}
          content={
            <ResetPassword
              userData={userData}
              onCancel={handleCloseChangePassword}
            />
          }
        />
      )}
    </>
  );
};
export default UserGrid;
