import { Box, Stack, IconButton } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Divider } from "antd";
import DeleteIcon from "@mui/icons-material/Delete";
import DeleteModal from "./../../components/Modals/DeleteModal";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { deleteRequestStockAction } from "../../store/channel/actions";
import { getActiveShop } from "../../utils/converter";
import { getAllStockRequestSentAction } from "../../store/wareHouseOut/actions";
import CustomTypography from "../../styles/globalStyles/CustomTypography";
import CustomTag from "../buttons/Tag";

const Component = (props: any) => {
  const [visible, setVisible] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { auth, channel, shop } = useSelector((state: any) => state);
  const data = {
    name: props?.data?.shopId?.name || "New RFS",
    status: props.data?.status,
    id: props.data?.requestId,
    product:
      props.data?.list?.map((item: any) => item?.product?.product?.model) || "",
    quantity: props.data?.list?.map((item: any) => item?.qtyRequested) || "0",
  };

  const shopId =
    shop?.selected?._id ||
    getActiveShop(auth?.user?.shop?.assigned)[0]?.shop?._id;

  const deleteModal = () => {
    setVisible(true);
  };
  const deleteRequest = async () => {
    if (auth?.token) {
      await deleteRequestStockAction(
        auth?.token,
        props?.data?._id,
        `?shopId=${shopId}`
      )(dispatch);
      await getAllStockRequestSentAction(
        auth?.token,
        "?status=review&limit=20&page=0"
      )(dispatch);
    }
    setVisible(false);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  return (
    <>
      <div className="relative custom-backface-visibilty bg-white border-gray-100  py-2 pb-4  shadow text-black min-h-[126px] rounded-md !duration-200">
        <Stack
          spacing={2}
          onClick={() => {
            navigate(data.id);
          }}
          sx={{ p: 1 }}
        >
          <div className="flex justify-between">
            <div>
              <CustomTypography variant="h2">{data?.name}</CustomTypography>
              <CustomTypography
                variant="value"
                className="sm:text-xs text-blue-500"
              >
                {data?.id}
              </CustomTypography>
            </div>
            <div>
              <CustomTag title={data.status} color={data.status} size="large" />
            </div>
          </div>
          <Divider />
          <Box sx={{ width: "100%" }}>
            <Stack
              direction="row"
              justifyContent={"space-between"}
              alignItems="flex-end"
              sx={{ width: "100%", paddingLeft: 1 }}
            >
              <Stack spacing={0}>
                <div>
                  <CustomTypography variant="label" className="sm:text-sm">
                    Requested By:
                  </CustomTypography>
                  <CustomTypography variant="value" className="sm:text-sm">
                    {props?.data?.createdBy?.names}
                  </CustomTypography>
                </div>

                {props?.data?.status === "done" && (
                  <>
                    <div>
                      <CustomTypography variant="label" className="sm:text-sm">
                        Approved By:
                      </CustomTypography>
                      <CustomTypography variant="value" className="sm:text-sm">
                        {props?.data?.approvedBy?.names}
                      </CustomTypography>
                    </div>
                    <div>
                      <CustomTypography variant="label" className="sm:text-sm">
                        Approved On:
                      </CustomTypography>
                      <CustomTypography variant="value" className="sm:text-sm">
                        {props?.data?.updatedAt?.split("T")[0]}
                      </CustomTypography>
                    </div>
                  </>
                )}
              </Stack>
            </Stack>
          </Box>
        </Stack>
        {["request", "review"].includes(props?.data?.status) && (
          <Stack
            direction="row"
            alignItems="flex-end"
            spacing={0}
            className="absolute right-2 bottom-0"
          >
            <IconButton aria-label="delete" size="small">
              <DeleteIcon fontSize="inherit" onClick={deleteModal} />
            </IconButton>
          </Stack>
        )}
      </div>
      <DeleteModal
        visible={visible}
        onOk={deleteRequest}
        isLoading={channel.isFetching}
        onCancel={handleCancel}
        itemName="Stock Request"
      />
    </>
  );
};

export default Component;
