import React from "react";

type TypographyProps = {
  variant?: "h1" | "h2" | "p" | "span" | "label" | "value";
  children: React.ReactNode;
  className?: string;
};

const styles = {
  h1: "text-base sm:text-lg font-semibold capitalize",
  h2: "text-base font-medium capitalize",
  p: "text-base leading-relaxed",
  span: "text-sm",
  label:
    "opacity-50 text-sm sm:text-[13px] text-[#03022980] font-medium mr-2 capitalize",
  value: "opacity-80 text-sm sm:text-[15px] font-medium ",
};

const CustomTypography: React.FC<TypographyProps> = ({
  variant = "p",
  children,
  className = "",
}) => {
  const Tag = variant as keyof JSX.IntrinsicElements;
  return (
    <Tag className={`${styles[variant]} ${className} text-black font-poppins`}>
      {children}
    </Tag>
  );
};

export default CustomTypography;
