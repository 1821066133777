import React from "react";
import DashLayout from "../../../components/layout/Layout";
import "chartkick/chart.js";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DatePickerProps, Select } from "antd";
import DashboardTopSection from "../../../components/dashboard/Sections/DashboardTopSection";
import moment from "moment";
import NosalesSection from "../../../components/dashboard/Sections/NosalesSection";
import EligibilitySection from "../../../components/dashboard/Sections/EligibilitySection";
import InventorySection from "../../../components/dashboard/Sections/InventorySection";
import ShopPerformanceSection from "../../../components/dashboard/Sections/ShopPerformanceSection";
import DatePickerCustom from "../../../components/buttons/DatePicker";
import { FaCaretDown } from "react-icons/fa";
import {
  getAllNoSaleAction,
  getApplicationStatsAction,
  getPlanOverviewAction,
  getRevenueQuantityAction,
  getShopStockAction,
  getTotalRevenueAction,
  getVaultStatsAction,
} from "../../../store/subscription/overview/actions";
import {
  getCurrentStockActions,
  getShopStatsActions,
} from "../../../store/dashboard/actions";
import SubLayout from "../../../components/layout/subLayout/SubLayout";

const PageView = () => {
  const { auth, overView, dashboard } = useSelector((state: any) => state);
  const dispatch = useDispatch();
  const formattedDate = new Date().toISOString().slice(0, 10);
  let todayDate = moment();
  let previousDate = todayDate.subtract(1, "days");
  let formattedPreviousDate = previousDate.format("YYYY-MM-DD");
  const [enteredDate, setEnteredDate] = useState(formattedPreviousDate);
  const [enteredComparedDate, setComparedDate] = useState(formattedDate);
  const [selectedKeyValue, setSelecteKeyValue] = React.useState("brand");
  const [selectedChannel, setSelectedChannel] = useState("");
  const [periodValue, setPeriodValue] = useState("range");
  const today = moment().format("YYYY-MM-DD");
  const yesterday = moment().subtract(1, "days").format("YYYY-MM-DD");
  const firstDayOfMonth = moment().startOf("month").format("YYYY-MM-DD");
  const mondayOfWeek = moment().startOf("isoWeek").format("YYYY-MM-DD");
  const currentYear = moment().format("YYYY");

  const periodOptions = [
    {
      label: (
        <p className="font-normal  rounded-md text-center text-[14px]">Daily</p>
      ),
      value: "daily",
    },
    {
      label: (
        <p className="font-normal  rounded-md text-center text-[14px]">
          Weekly
        </p>
      ),
      value: "weekly",
    },
    {
      label: (
        <p className="font-normal  rounded-md text-center text-[14px]">
          Monthly
        </p>
      ),
      value: "month",
    },
    {
      label: (
        <p className="font-normal  rounded-md text-center text-[14px]">
          Yearly
        </p>
      ),
      value: "year",
    },
    {
      label: (
        <p className="font-normal  rounded-md text-center text-[14px]">Range</p>
      ),
      value: "range",
    },
  ];

  // Total Revenue
  useEffect(() => {
    if (auth?.token) {
      if (periodValue === "daily") {
        getTotalRevenueAction(
          auth?.token,
          `?day=daily&date1=${yesterday}&date2=${today}`
        )(dispatch);
      }
      if (periodValue === "weekly") {
        getTotalRevenueAction(
          auth?.token,
          `?date1=${mondayOfWeek}&date2=${today}`
        )(dispatch);
      }
      if (periodValue === "month") {
        getTotalRevenueAction(
          auth?.token,
          `?date1=${firstDayOfMonth}&date2=${today}`
        )(dispatch);
      }
      if (periodValue === "year") {
        getTotalRevenueAction(auth?.token, `?year=${currentYear}`)(dispatch);
      }
      if (periodValue === "range") {
        getTotalRevenueAction(
          auth?.token,
          `?date1=${enteredDate}&date2=${enteredComparedDate}`
        )(dispatch);
      }
    }
  }, [
    dispatch,
    auth.token,
    periodValue,
    yesterday,
    today,
    currentYear,
    mondayOfWeek,
    firstDayOfMonth,
    enteredDate,
    enteredComparedDate,
    selectedChannel,
  ]);

  // No Sales
  useEffect(() => {
    if (auth?.token) {
      if (periodValue === "daily") {
        getAllNoSaleAction(
          auth?.token,
          `?day=daily&date2=${yesterday}&date1=${today}`
        )(dispatch);
      }
      if (periodValue === "weekly") {
        getAllNoSaleAction(
          auth?.token,
          `?date2=${mondayOfWeek}&date1=${today}`
        )(dispatch);
      }
      if (periodValue === "month") {
        getAllNoSaleAction(
          auth?.token,
          `?date2=${firstDayOfMonth}&date1=${today}`
        )(dispatch);
      }
      if (periodValue === "year") {
        getAllNoSaleAction(auth?.token, `?year=${currentYear}`)(dispatch);
      }
      if (periodValue === "range") {
        getAllNoSaleAction(
          auth?.token,
          `?date1=${enteredDate}&date2=${enteredComparedDate}`
        )(dispatch);
      }
    }
  }, [
    enteredDate,
    dispatch,
    auth?.token,
    periodValue,
    yesterday,
    currentYear,
    firstDayOfMonth,
    today,
    mondayOfWeek,
    enteredComparedDate,
    selectedChannel,
  ]);

  // Quantity financed & Amount
  useEffect(() => {
    if (auth?.token) {
      if (periodValue === "daily") {
        getRevenueQuantityAction(
          auth?.token,
          `?date1=${yesterday}&date2=${today}`
        )(dispatch);
      }
      if (periodValue === "range") {
        getRevenueQuantityAction(
          auth?.token,
          `?date1=${enteredDate}&date2=${enteredComparedDate}`
        )(dispatch);
      }
    }
  }, [
    dispatch,
    auth.token,
    periodValue,
    enteredDate,
    enteredComparedDate,
    selectedChannel,
    yesterday,
    today,
  ]);

  // Plan Overview
  useEffect(() => {
    if (auth?.token) {
      getPlanOverviewAction(auth?.token, `?`)(dispatch);
    }
  }, [dispatch, auth.token]);

  // Shop Stock
  useEffect(() => {
    if (auth?.token) {
      getShopStockAction(auth?.token, `?`)(dispatch);
    }
  }, [dispatch, auth.token]);

  // Vaults stats
  useEffect(() => {
    if (auth?.token) {
      getVaultStatsAction(auth?.token, `?`)(dispatch);
    }
  }, [dispatch, auth.token]);

  // Live Stock At Warehouse
  useEffect(() => {
    if (auth?.token) {
      getCurrentStockActions(
        auth?.token,
        `${selectedKeyValue}=$product.${selectedKeyValue}`
      )(dispatch);
    }
  }, [dispatch, auth?.token, selectedKeyValue]);

  // Device sold
  useEffect(() => {
    if (auth?.token) {
      getShopStatsActions(
        auth?.token,
        `?date1=${enteredDate}&date2=${enteredComparedDate}`
      )(dispatch);
    }
  }, [dispatch, auth?.token, enteredDate, enteredComparedDate]);

  // Application stats
  useEffect(() => {
    if (auth?.token) {
      if (periodValue === "daily") {
        getApplicationStatsAction(
          auth?.token,
          `?date1=${yesterday}&date2=${today}`
        )(dispatch);
      }
      if (periodValue === "range") {
        getApplicationStatsAction(
          auth?.token,
          `?date1=${enteredDate}&date2=${enteredComparedDate}`
        )(dispatch);
      }
    }
  }, [
    dispatch,
    auth?.token,
    enteredDate,
    enteredComparedDate,
    periodValue,
    yesterday,
    today,
  ]);

  const onChange = (value: string) => {
    setPeriodValue(value);
  };
  const onChangeDate: DatePickerProps["onChange"] = async (
    date: any,
    dateString: any
  ) => {
    setEnteredDate(dateString);
  };
  const onChangeComparedDate: DatePickerProps["onChange"] = async (
    date: any,
    dateString: any
  ) => {
    setComparedDate(dateString);
  };
  return (
    <DashLayout>
      <div className="py-10  text-[#030229] piece_sold w-full h-full">
        <div className="float-right">
          {periodValue === "range" ? (
            <div className="flex gap-3 border border-[#0302291A] rounded-md items-center p-1">
              <DatePickerCustom
                onChangeDate={onChangeDate}
                enteredDate={enteredDate}
                borderStyle="none"
              />
              <div className="text-[#0302291A]">-</div>
              <DatePickerCustom
                onChangeDate={onChangeComparedDate}
                enteredDate={enteredComparedDate}
                borderStyle="none"
              />
              <FaCaretDown
                onClick={() => setPeriodValue("")}
                color="#03022980"
                className="mt-1 cursor-pointer"
                size={18}
              />
            </div>
          ) : (
            <Select
              placeholder="Select Period"
              style={{ width: 150 }}
              defaultValue="range"
              optionFilterProp="label"
              onChange={onChange}
              options={periodOptions}
            />
          )}
        </div>
        <div className="pl-1 pr-4 py-4 text-[20px] font-normal">

          <h1 className="pl-1 pr-4  text-[20px] font-normal">
            Financial Overview and Transaction Summary
          </h1>

          <div className="mx-auto">
            <DashboardTopSection
              data={overView?.totalRevenue?.data}
              periodValue={periodValue}
            />
            <div className="my-4 flex flex-col gap-y-4">
              <NosalesSection periodValue={periodValue} data={overView} />
              <EligibilitySection />
              <InventorySection data={overView} />
              <ShopPerformanceSection
                data={overView?.planOverview}
                solddevice={dashboard?.shopStats}
                enteredDate={enteredDate}
                compareDate={enteredComparedDate}
              />
            </div>
            <>
              <div className="my-4"></div>
            </>
          </div>
          <div></div>
        </div>
      </div>
    </DashLayout>
  );
};

export default PageView;
