import { DatePicker } from "antd";
import dayjs from "dayjs";
import React from "react";
interface dateProps {
  onChangeDate: any;
  disabledDate?: any;
  enteredDate: any;
  height?: any;
  borderStyle?: string;
}
const DatePickerCustom = ({
  onChangeDate,
  disabledDate,
  enteredDate,
  height = 9,
  borderStyle,
}: dateProps) => {
  const dateFormat = "YYYY/MM/DD";
  return (
    <div>
      <DatePicker
        onChange={onChangeDate}
        defaultValue={dayjs(enteredDate, dateFormat)}
        className={`w-[140px]} h-${height}`}
        style={{ border: borderStyle }}
        disabledDate={disabledDate}
      />
    </div>
  );
};

export default DatePickerCustom;
