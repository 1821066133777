import React, { useState } from "react";
import DangerButton from "../../../buttons/DangerButton";
import PrimaryButton from "../../../buttons/PrimaryButton";
import ProductDetailsModal from "../../../Modals/ProductDetailsModal";
import UpdateProduct from "../../../../components/forms/NewProductForm";
import { useDispatch, useSelector } from "react-redux";
import { deleteProductsAction } from "../../../../store/product/actions";
import { useNavigate } from "react-router-dom";
import { Avatar, Carousel, Image, List, Tag } from "antd";
import { Stack } from "@mui/material";

const SidePanelDetails = (props: any) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [dataToUpdate, setDataToupdate] = useState({});
  const [visible, setVisible] = useState(false);
  const { auth } = useSelector((state: any) => state);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleOnEdit = () => {
    setIsModalOpen(true);
  };

  const handleOnCancel = () => {
    setIsModalOpen(false);
  };
  React.useEffect(() => {
    if (props?.simpleDetails) {
      setDataToupdate({ ...props?.simpleDetails, specs: props?.specsData });
    }
  }, [props?.simpleDetails, props?.specsData]);

  return (
    <div>
      <div
        className={`xl:flex gap-5  p-3 border-solid border-[1px] border-[#0302291A] rounded-xl bg-white h-full mb-5`}
      >
        <Carousel
          autoplay
          className="bg-white  w-[100px]  rounded-full"
        >
          {props?.simpleDetails?.logo && (
            <div>
              <Image
                className="h-full w-full rounded-full m-auto"
                src={props?.simpleDetails?.logo}
                alt="description"
              />
            </div>
          )}
        </Carousel>
        <div className="w-full">
          <div className="flex flex-col sm:flex-row gap-5 sm:justify-between w-full ">
            <div>
              <h1 className="text-[#030229] font-medium text-lg ">
                {props?.simpleDetails?.name}
              </h1>
              <div className="flex flex-col sm:flex-row gap-2">
                <p>{props?.simpleDetails?.email}</p>
                <p>{props?.simpleDetails?.phone}</p>
                <p>{props?.simpleDetails?.tin}</p>
              </div>
              <div className="flex rounded-md gap-4 w-full my-1">
                <p className="text-sm font-semibold">Brands:</p>
                {props?.simpleDetails &&
                  props?.simpleDetails?.brands.map((item: any) => (
                   
                      <p className="text-sm">{item}</p>
                  ))}
              </div>
              <p className="text-sm font-light">
                {props?.simpleDetails?.address}
              </p>
              <span className="text-center uppercase font-medium pb-4">
                {props?.simpleDetails?.type}
              </span>
            </div>
            <div className="">
              <p className="text-sm font-semibold">Contacts:</p>
              {props?.simpleDetails?.employees?.map((el: any) => {
                return (
                  <div>
                    <p className="text-[12px] font-semibold text-black">
                      {el?.name}
                    </p>
                    <p className="text-[12px] font-light text-black">
                      {el?.position}
                    </p>
                    <p className="text-[12px] font-light text-slate-500">
                      {el?.phone}
                    </p>
                    <p className="text-[12px] font-light text-slate-500">
                      {el?.email}
                    </p>
                  </div>
                );
              })}
            </div>
          </div>

          <div className="flex flex-col justify-censter items-csenter rounded-lg px-2 bg-white space-y-6 mt-3">
            <div className="flex justify-centzer gap-2">
              <PrimaryButton btnName="Edit" onClick={handleOnEdit} />
              <DangerButton name="Delete" onClick={props?.handleClickDelete} />
            </div>
          </div>
        </div>
      </div>

      {/* product modal here */}
      {
        <ProductDetailsModal
          // component={<props.component />}
          component={
            <props.UpdateForm
              data={dataToUpdate}
              // id={dataToUpdate}
              dataToUpdate={dataToUpdate}
              action={"update"}
              onCancel={handleOnCancel}
            />
          }
          isModalOpen={isModalOpen}
          handleOnCancel={handleOnCancel}
        />
      }

      <div style={{ display: "none" }}>
        <Image.PreviewGroup
          preview={{ visible, onVisibleChange: (vis) => setVisible(vis) }}
        >
          {props?.simpleDetails?.images?.map((img: any) => (
            <Image src={img} />
          ))}
        </Image.PreviewGroup>
      </div>
    </div>
  );
};

export default SidePanelDetails;
