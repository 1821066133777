/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Divider, Form, Input, notification, Select } from "antd";
import { useDispatch, useSelector } from "react-redux";
import WarehouseOutTable from "../../../../components/tables/WarehouseOutTable";
import WarehouseOutTableView from "../../../../components/tables/WarehouseOutTableView";
import { LoadingOutlined } from "@ant-design/icons";
import { Button } from "@mui/material";
import { Spin, Tag, Modal } from "antd";
import {
  getAllWarehouseOutAction,
  updateStockOutAction,
  getAllWarehouseOutItemsAction,
  updateOneStockOutDetailsAction,
  createShippingDetailsAction,
} from "../../../../store/wareHouseOut/actions";
import DetailsHeaderActionBar from "../../../../components/cards/DetailsPage/DetailsHeaderActionBar";
import LoadingButton from "@mui/lab/LoadingButton";
import ShopFromDrower from "./ShopFromDrower";
import {
  createDeliveryActivityAction,
  createOtpActions,
  getDelivererAction,
  getOneDelivererAction,
  getTrackingTypeAction,
  updateParkingAction,
} from "../../../../store/delivery/actions";
import CreateDelivererForm from "./createDelivererForm";
import { getOneCompanyAction } from "../../../../store/setting/company/actions";
import VerifyOtpForm from "./verifyOtpForm";
import { COMPANY_PROFILE_ID } from "../../../../utils/constants";
import { warehouseOutIncludeItem } from "../../../../assets/data/includedItem";
import ViewLayout from "../../../../components/layout/subLayout/ViewLayout";

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const WsCreate = (props: any) => {
  const { auth, wareHouseOut, tracking, company } = useSelector(
    (state: any) => state
  );
  const [open, setOpen] = React.useState<any>(false);
  const [selectedProductId, setSelectedProductId] = React.useState<any>("");
  const [selectedProduct, setSelectedProduct] = React.useState<any>("");
  const [showDeliverer, setShowDeliverer] = React.useState<any>("");
  const [delivererId, setDeliverer] = React.useState<any>("");
  const [trackingType, setTrackingType] = React.useState<any>("");
  const [serialNoString, setSerialNoString] = React.useState<any>([]);
  const [duplicateItems, setDuplicateItems] = React.useState<any>([]);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isOtpModalOpen, setIsOtpModalOpen] = useState(false);
  localStorage.setItem("pr_id", selectedProduct?._id);
  const productIdFromLocalStorage = localStorage.getItem("pr_id");
  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleOtpCancel = () => {
    setIsOtpModalOpen(false);
  };

  const showOtpModal = () => {
    setIsOtpModalOpen(true);
  };

  const handleOtpOk = () => {
    setIsOtpModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const trId = wareHouseOut?.shippingDetails?.data?._id;
  const warehouseOutData = wareHouseOut?.warehouseOut?.data[0];
  function calculateDistance(lat1: any, lon1: any, lat2: any, lon2: any) {
    const R = 6371;
    const toRadians = (degrees: any) => degrees * (Math.PI / 180);
    const dLat = toRadians(lat2 - lat1);
    const dLon = toRadians(lon2 - lon1);
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(toRadians(lat1)) *
        Math.cos(toRadians(lat2)) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const distance = R * c;
    return distance;
  }
  const companyId = COMPANY_PROFILE_ID;
  const distances = calculateDistance(
    company?.selected?.warehouse[0]?.shopLocation?.coordinate?.lat,
    company?.selected?.warehouse[0]?.shopLocation?.coordinate?.lng,
    warehouseOutData?.stockRequest?.shopId?.shopLocation?.coordinate?.lat,
    warehouseOutData?.stockRequest?.shopId?.shopLocation?.coordinate?.lng
  );
  useEffect(() => {
    if (auth?.token && companyId) {
      getOneCompanyAction(companyId as string, auth.token)(dispatch);
    }
    auth?.token && getTrackingTypeAction(auth?.token)(dispatch);
  }, [dispatch, auth?.token]);
  useEffect(() => {
    auth?.token && getDelivererAction(auth?.token)(dispatch);
  }, [dispatch, auth?.token]);
  const IntransitParchage = async () => {
    await updateOneStockOutDetailsAction(
      auth?.token,
      wareHouseOut?.warehouseOut?.data[0]?._id,
      productIdFromLocalStorage,
      {
        status: "In-Transit",
      }
    )(dispatch);
    await updateParkingAction(
      auth?.token,
      {
        trackingStatus: "In-Transit",
      },
      wareHouseOut?.warehouseOut?.data[0]?.trackingId
    )(dispatch);
    await createDeliveryActivityAction(auth?.token, {
      name: "In-Transit",
      status: "In-Transit",
      details: "The Parkage is in Transit",
      trackId: wareHouseOut?.warehouseOut?.data[0]?.trackingId,
    })(dispatch);
    notification.success({ message: "updated successfully" });
    if (auth?.token && productIdFromLocalStorage) {
      getAllWarehouseOutItemsAction(
        auth?.token,
        `requestedItem=${productIdFromLocalStorage}&limit=2000`
      )(dispatch);
    }
    auth?.token &&
      id &&
      getAllWarehouseOutAction(auth?.token, `?warehouseOutId=${id}`)(dispatch);
    // navigate(-1);
  };
  const deliveredParchage = async () => {
    await updateOneStockOutDetailsAction(
      auth?.token,
      wareHouseOut?.warehouseOut?.data[0]?._id,
      productIdFromLocalStorage,
      {
        status: "Delivered",
      }
    )(dispatch);
    await updateParkingAction(
      auth?.token,
      {
        trackingStatus: "Delivered",
      },
      wareHouseOut?.warehouseOut?.data[0]?.trackingId
    )(dispatch);
    await createDeliveryActivityAction(auth?.token, {
      name: "Delivered",
      status: "Delivered",
      details: "The Parkage is delivered",
      trackId: wareHouseOut?.warehouseOut?.data[0]?.trackingId,
    })(dispatch);
    notification.success({ message: "updated successfully" });
    if (auth?.token && productIdFromLocalStorage) {
      await getAllWarehouseOutItemsAction(
        auth?.token,
        `requestedItem=${productIdFromLocalStorage}&limit=2000`
      )(dispatch);
    }
    if (auth?.token) {
      const res = await getAllWarehouseOutAction(
        auth?.token,
        `?limit=15&page=0&field=${warehouseOutIncludeItem}`
      )(dispatch);
      if (res) {
        navigate("/inventory/ws/out");
      }
    }
  };
  const onFinish = async (values: any) => {
    // const handleDeliverer = async () => {
    if (auth?.token) {
      const res = await createShippingDetailsAction(auth?.token, {
        deliver: delivererId,
        deliveryNote: {
          id: wareHouseOut?.warehouseOut?.data[0]?._id,
          getLink: `/shopstock/one/${wareHouseOut?.warehouseOut?.data[0]?._id}`,
        },
        locationFrom: {
          name: company?.selected?.name,
          coordinate: {
            lat: company?.selected?.warehouse[0]?.shopLocation?.coordinate?.lat,
            lng: company?.selected?.warehouse[0]?.shopLocation?.coordinate?.lng,
          },
        },
        locationTo: {
          name: warehouseOutData?.stockRequest?.shopId?.name,
          coordinate: {
            lat: warehouseOutData?.stockRequest?.shopId?.shopLocation
              ?.coordinate?.lat,
            lng: warehouseOutData?.stockRequest?.shopId?.shopLocation
              ?.coordinate?.lng,
          },
        },
        distance: distances,
        trackingType: trackingType,
        type: "To shop",
        ...values,
      })(dispatch);
      if (res?.type) {
        await updateOneStockOutDetailsAction(
          auth?.token,
          wareHouseOut?.warehouseOut?.data[0]?._id,
          selectedProduct?._id,
          {
            deliverer: {
              name: tracking?.selected?.name,
              phone: tracking?.selected?.phone,
              company: tracking?.selected?.company,
              email: tracking?.selected?.email,
            },
            status: "Ready For Pickup",
            trackingId: res?.data?._id,
            // status: "In-Transit",
          }
        )(dispatch);

        await createDeliveryActivityAction(auth?.token, {
          name: "Initialization",
          status: "Ready For Pickup",
          details: "Device is ready for Pickup",
          trackId: res?.data?._id,
        })(dispatch);
        await createOtpActions(auth?.token, {
          email: tracking?.selected?.email,
          phoneNumber: tracking?.selected?.phone,
          name: tracking?.selected?.name,
        })(dispatch);
        // res && navigate(-2);
      } else {
        notification.error({ message: "Error Occured Please try again" });
      }
    }
    if (auth?.token && productIdFromLocalStorage) {
      getAllWarehouseOutItemsAction(
        auth?.token,
        `requestedItem=${productIdFromLocalStorage}&limit=2000`
      )(dispatch);
    }

    auth?.token &&
      id &&
      getAllWarehouseOutAction(auth?.token, `?warehouseOutId=${id}`)(dispatch);
    form.resetFields();
  };
  useEffect(() => {
    setShowDeliverer(
      wareHouseOut?.selectedWarehouseOut &&
        !wareHouseOut?.selectedWarehouseOut?.stockRequest?.list
          ?.map((item: any) => item?.qtyApproved === item?.qtyRequested)
          ?.includes(false)
    );
  }, [wareHouseOut?.selectedWarehouseOut]);

  useEffect(() => {
    auth?.token &&
      getAllWarehouseOutAction(auth?.token, `?warehouseOutId=${id}`)(dispatch);
  }, [auth?.token, dispatch, id]);

  const handleSetSelected = (product_id: string) => {
    setSelectedProductId(product_id);
    setSelectedProduct(
      wareHouseOut?.selectedWarehouseOut?.stockRequest?.list?.filter(
        (item: any) => item?._id === product_id
      )[0]
    );
    if (auth?.token && product_id) {
      getAllWarehouseOutItemsAction(
        auth?.token,
        `requestedItem=${product_id}&limit=2000`
      )(dispatch);
    }
  };

  const handleAddProducts = async () => {
    if (
      serialNoString?.trim().split(" ")?.length <=
      selectedProduct?.qtyApproved -
        wareHouseOut?.warehouseOutItem?.data?.length
    ) {
      if (
        new Set(serialNoString?.trim().split(" ")).size !==
        serialNoString?.trim().split(" ")?.length
      ) {
        setDuplicateItems(
          serialNoString
            .trim()
            ?.split(" ")
            ?.filter(
              (value: any, index: any) =>
                serialNoString.trim()?.split(" ").indexOf(value) !== index
            )
        );
        return notification.warning({
          message: "IMEI has been duplicated",
          description: `${serialNoString
            ?.split(" ")
            ?.filter(
              (value: any, index: any) =>
                serialNoString?.toString()?.trim().split(" ").indexOf(value) !==
                index
            )
            .join(" , ")}`,
        });
      }

      await updateStockOutAction(
        wareHouseOut?.selectedWarehouseOut?._id,
        {
          action: wareHouseOut?.selectedWarehouseOut?.isTransferred
            ? "transfer"
            : "warehouseout",
          product: selectedProduct?.product?.product?._id,
          serialNumbers: serialNoString?.toString()?.trim().split(" "),
          productPriceList: selectedProduct?.product?._id,
          requestedItem: selectedProduct?._id,
          stockRequest: wareHouseOut?.selectedWarehouseOut?.stockRequest?._id,
          shop: wareHouseOut?.selectedWarehouseOut?.stockRequest?.shopId?._id,
        },
        auth?.token
      )(dispatch);
    } else {
      notification.error({
        message: `Please Verify only ${
          selectedProduct?.qtyApproved -
          (wareHouseOut?.warehouseOutItem?.data?.length ?? 0)
        } Devices`,
      });
    }
  };

  const [form] = Form.useForm();
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };
  const onChangeDeliverer = (value: any) => {
    setDeliverer(value);
    auth?.token && getOneDelivererAction(value, auth?.token)(dispatch);
    console.log(value, "value", delivererId);
  };
  const onChangeType = (value: string) => {
    setTrackingType(value);
  };
  const trackingTypeOptions = tracking?.trackingType?.data?.map((el: any) => ({
    value: el?._id,
    label: el?.name,
  }));

  const delivererOptions =
    tracking?.allDeliverer?.data?.map((el: any) => ({
      value: el?._id,
      label: `${el?.name} ${el?.phone}`,
    })) || [];

  const getDelivererBySearch = async (searchValue: any) => {
    await getDelivererAction(auth?.token)(dispatch);
  };

  return (
    <>
      <ViewLayout currentModule={"Warehouse Out"} subModule={wareHouseOut?.selectedWarehouseOut?.warehouseOutId}>
        <div className="p-5 bg-white rounded-lg space-y-6">
          {wareHouseOut?.selectedWarehouseOut?.status !==
            "Ready For Pickup" && (
            <div className="md:flex gap-5">
              <div className="mb-5 flex gap-x-2">
                <p className="text-gray-400">
                  {`${warehouseOutData?.stockRequest?.shopId?.name} ~`}
                </p>
                <p className="text-gray-400">
                  {warehouseOutData?.stockRequest?.shopId?.channel?.name}
                </p>
              </div>
              <Tag
                className="px-4 py-0.5 h-8 flex text-center items-center justify-center"
                color={
                  wareHouseOut?.selectedWarehouseOut?.isTransferred
                    ? "blue"
                    : "yellow"
                }
              >
                {wareHouseOut?.selectedWarehouseOut?.isTransferred
                  ? "As Transfer"
                  : "From Warehouse"}
              </Tag>
              <p className="text-gray-400 border border-[#FFC727] w-fit py-0.5 mt-5 md:mt-0 px-4 rounded-md text-sm h-8 flex text-center items-center justify-center">
                {wareHouseOut?.selectedWarehouseOut?.status}
              </p>
            </div>
          )}
          {wareHouseOut?.selectedWarehouseOut?.status !==
            "Ready For Pickup" && (
            <div className="flex flex-col lg:flex-row reladtive gap-10 2xl:gap-20 w-full justify-between">
              <div>
                <p className="w-fit font-bold text-lg">Model Name</p>
                <div className="flex gap-5 text-gray-300 items-center">
                  <Select
                    style={{ width: "100%" }}
                    className="h-10"
                    placeholder="Choose Product Model"
                    onChange={(data: any) => {
                      handleSetSelected(data);
                    }}
                    options={wareHouseOut?.selectedWarehouseOut?.stockRequest?.list?.map(
                      (item: any, _index: number) => ({
                        label:
                          `${
                            item?.qtyApproved === item?.qtyRequested
                              ? "✔️ "
                              : ""
                          }  ` + item?.product?.product?.model,
                        value: item?._id,
                      })
                    )}
                  />

                  {wareHouseOut.isFetching && (
                    <div className="flex h-fit ">
                      <Spin indicator={antIcon} />
                    </div>
                  )}

                  {!wareHouseOut.isFetching && (
                    <span>{`${
                      wareHouseOut?.selectedWarehouseOut?.stockRequest?.list?.filter(
                        (item: any) => item?.qtyApproved === item?.qtyRequested
                      )?.length
                    }/${
                      wareHouseOut?.selectedWarehouseOut?.stockRequest?.list
                        ?.length
                    }`}</span>
                  )}
                </div>
                <div className="grid grid-cols-2 min-w-[288px] bg-slate-100 mt-2 rounded-md p-3">
                  <>
                    {selectedProduct?.product?.specification?.map(
                      (item: any, _index: number) =>
                        item[1] && (
                          <>
                            <span className="font-semibold">{item[0]}</span>
                            <span>{item[1]}</span>
                          </>
                        )
                    )}
                  </>
                </div>
              </div>

              <div className="flex flex-col w-full items-end gap-y-3 mt-10">
                {warehouseOutData?.isTransferred && (
                  <div>
                    <ShopFromDrower
                      showDrawer={showDrawer}
                      open={open}
                      onClose={onClose}
                    />
                  </div>
                )}

                {wareHouseOut?.warehouseOutItem?.data?.length <
                  selectedProduct?.qtyApproved &&
                  selectedProduct?.qtyRequested && (
                    <div className="flex gap-2 w-full items-end justify-end">
                      <span className="pt-1">{`${
                        wareHouseOut?.warehouseOutItem?.data?.length || 0
                      }/${selectedProduct?.qtyApproved}`}</span>
                      <Input
                        className="w-full xl:w-1/2 h-10"
                        placeholder="Search Serial Number"
                        onChange={(e: any) => setSerialNoString(e.target.value)}
                      />
                      <div>
                        <LoadingButton
                          variant="contained"
                          className="h-10 w-20"
                          disabled={wareHouseOut?.isFetching}
                          loading={wareHouseOut?.isFetching}
                          onClick={() => handleAddProducts()}
                        >
                          Verify
                        </LoadingButton>
                      </div>
                    </div>
                  )}
              </div>
            </div>
          )}

          <div className="w-full overflow-x-auto">
            {wareHouseOut?.selectedWarehouseOut?.status ===
            "Ready For Pickup" ? (
              <WarehouseOutTableView />
            ) : (
              <WarehouseOutTable selectedProductId={selectedProductId} />
            )}
          </div>
          {wareHouseOut?.selectedWarehouseOut?.status === "Pending" &&
            (selectedProduct || productIdFromLocalStorage) &&
            wareHouseOut?.warehouseOutItem?.data?.length > 0 &&
            wareHouseOut?.warehouseOutItem?.data?.length ===
              selectedProduct?.qtyApproved && (
              <div className="space-y-4">
                <p className="text-[#030229] text-lg">Deliverer Details</p>
                <Form
                  onFinish={onFinish}
                  form={form}
                  className="w-full"
                  layout="vertical"
                >
                  <div className="w-full md:w-1/2 lg:w-[35%] flex gap-20">
                    <div className="w-full">
                      <Form.Item
                        name="trackingType"
                        label={
                          <h1 className="text-[#030229B2] text-base">
                            Tracking Type
                          </h1>
                        }
                        rules={[
                          {
                            required: true,
                            message: "tracking type is required!",
                            whitespace: true,
                          },
                        ]}
                      >
                        <Select
                          className="h-10"
                          showSearch
                          placeholder="Select a tracking type"
                          optionFilterProp="label"
                          onSearch={getDelivererBySearch}
                          onChange={onChangeType}
                          options={trackingTypeOptions}
                          loading={tracking?.isFetching}
                        />
                      </Form.Item>
                      <Form.Item
                        name="deliverer"
                        label={
                          <h1 className="text-[#030229B2] text-base">
                            Select deliverer
                          </h1>
                        }
                        // initialValue={deliverer?.phone}
                        rules={[
                          {
                            required: true,
                            message: "Deliverer is required!",
                            whitespace: true,
                          },
                        ]}
                      >
                        <Select
                          className="h-10"
                          showSearch
                          placeholder="Select deliverer"
                          optionFilterProp="label"
                          onChange={onChangeDeliverer}
                          options={delivererOptions}
                          dropdownRender={(menu) => (
                            <div>
                              {menu}
                              <Divider></Divider>
                              <Button
                                disabled={
                                  wareHouseOut?.isFetching ||
                                  tracking?.isFetching
                                }
                                style={{
                                  backgroundColor: "#605BFF",
                                  color: "white",
                                  textAlign: "center",
                                  width: "100%",
                                }}
                                onClick={showModal}
                                className="bg-[#605BFF] h-10 text-base text-white text-center w-full rounded-md"
                              >
                                + Create New Deliverer
                              </Button>
                            </div>
                          )}
                        />
                      </Form.Item>
                    </div>
                  </div>
                  <LoadingButton
                    type="submit"
                    disabled={wareHouseOut?.isFetching || tracking?.isFetching}
                    variant="contained"
                    className="w-full md:w-1/2 lg:w-[35%] h-10"
                    loading={wareHouseOut?.isFetching}
                  >
                    Save
                  </LoadingButton>
                </Form>
              </div>
            )}
          {wareHouseOut?.selectedWarehouseOut?.status ===
            "Ready For Pickup" && (
            <Button
              disabled={wareHouseOut?.isFetching || tracking?.isFetching}
              className="bg-[#605BFF] text-white px-4 py-1 rounded-md"
              style={{
                backgroundColor: "#605BFF",
                color: "white",
                textAlign: "center",
                // width:"100%"
              }}
              onClick={showOtpModal}
            >
              Confirm Pickup
            </Button>
          )}
          {wareHouseOut?.selectedWarehouseOut?.status === "Pickup" && (
            <LoadingButton
              disabled={wareHouseOut?.isFetching || tracking?.isFetching}
              loading={wareHouseOut?.isFetching || tracking?.isFetching}
              variant="contained"
              onClick={IntransitParchage}
              className="h-10"
            >
              Release for Transit
            </LoadingButton>
          )}
          {wareHouseOut?.selectedWarehouseOut?.status === "In-Transit" && (
            <LoadingButton
              variant="contained"
              disabled={wareHouseOut?.isFetching || tracking?.isFetching}
              loading={wareHouseOut?.isFetching || tracking?.isFetching}
              className=" text-white px-4 py-1 rounded-md h-10"
              onClick={deliveredParchage}
            >
              Confirm Delivery
            </LoadingButton>
          )}
        </div>
      </ViewLayout>
      <Modal
        title={
          <h1 className="text-[#030229] text-lg py-2">Create Deliverer</h1>
        }
        width={800}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
      >
        <CreateDelivererForm onOk={handleOk} />
      </Modal>
      <Modal
        title={
          <h1 className="text-[#030229] text-lg py-2">
            Confirm Pickup With OTP
          </h1>
        }
        width={800}
        open={isOtpModalOpen}
        onOk={handleOtpOk}
        onCancel={handleOtpCancel}
        footer={null}
      >
        <VerifyOtpForm
          onOk={handleOtpOk}
          selectedP={selectedProduct?._id}
          phone={tracking?.selected?.phone}
          data={tracking?.selected}
          from="warehouseOut"
        />
      </Modal>
    </>
  );
};

export default WsCreate;
