import React, { useEffect, useState } from "react";
import SubscriptionRequestTable from "../../../components/tables/SubscriptionRequestTable";
import SearchInput from "../../../components/buttons/SearchButton";
import { getAllApplicationAction } from "../../../store/myZaka/actions";
import { useDispatch, useSelector } from "react-redux";
import CSVExportButton from "../../../components/cards/NoSale/CSVExportButton";
import { applicantRequest_CSVAction } from "../../../store/csvDownload/actions";
import { applicantRequestHeader } from "../../../components/csvHeaders/Headers";
import SubLayout from "../../../components/layout/subLayout/SubLayout";
import PrimaryButton from "../../../components/buttons/PrimaryButton";
import { LuRefreshCcw } from "react-icons/lu";

const SubscriptionRequest = (_props: any) => {
  const { auth, layout, CSV, myZaka } = useSelector((state: any) => state);

  const dispatch = useDispatch();
  const [searchKey, setSearchKey] = useState("");

  const [limit, setLimit] = useState(15);
  const [page, setPage] = useState(1);

  const handleSearch = () => {
    if (searchKey && auth?.token) {
      getAllApplicationAction(auth?.token, `?sk=${searchKey}`)(dispatch);
    }
  };

  useEffect(() => {
    if (layout?.openCSVButton && auth?.token)
      applicantRequest_CSVAction(
        auth?.token,
        `?limit=${myZaka?.all?.total}&page=0`
      )(dispatch);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [layout?.openCSVButton]);

  const handleGetItems = () => {
    if (auth?.token) {
      getAllApplicationAction(
        auth?.token,
        `?limit=${limit}&page=${page - 1}`
      )(dispatch);
    }
  };

  useEffect(() => {
    handleGetItems();
  }, [auth?.token, dispatch, limit, page]);

  useEffect(() => {
    if ([""]?.includes(searchKey)) {
      getAllApplicationAction(
        auth?.token,
        `?limit=${limit}&page=${page - 1}`
      )(dispatch);
    }
  }, [auth?.token, searchKey, dispatch, limit, page]);

  const csvData = CSV?.csvDownloadedData?.data?.map((el: any) => {
    return {
      date: el?.createdAt?.slice(0, 10),
      shop: el?.cart?.shop?.name || "N/A",
      customerId: el?.cart?.customer?.customerId,
      fname: el?.cart?.customer?.firstName,
      lname: el?.cart?.customer?.lastname,
      msisdn: el?.msisdn,
      city: el?.cart?.customer?.city,
      currentResidence: el?.cart?.customer?.currentResidence,
      tnc: el?.cart?.customer?.tnc,
      loanAppID: el?.loanapplicationid,
      plan: "Monthly",
      upfront: el?.cart?.payment?.at(0)?.amount?.toLocaleString() || "",
      planPeriod: `${el?.cart?.account?.duration || "N/A"} Month` || "N/A",
      businessProductType: el?.cart?.selectedPlan?.businessProductType || "N/A",
      pickedUpDevice: el?.cart?.status?.toLowerCase() === "paid" ? "Yes" : "No",
      status: el?.status,
    };
  });

  return (
    <SubLayout
      currentModule="Application Requests"
      search={
        <SearchInput
          onSearch={handleSearch}
          placehorder="Search by MSISDN or Loarn ID"
          onChange={(e: any) => {
            e.preventDefault();
            setSearchKey(e.target.value?.trimStart());
          }}
        />
      }
      buttons={[
        <CSVExportButton
          csvHeaders={applicantRequestHeader}
          csvData={csvData}
          filename={`application_request.csv`}
        />,
        <PrimaryButton
          btnName="Refresh"
          icon={<LuRefreshCcw />}
          onClick={handleGetItems}
          isLoading={myZaka?.isFetching}
        />,
      ]}
      setPage={setPage}
      setLimit={setLimit}
      limit={limit}
      total={myZaka?.all?.total}
      count={Math.ceil(myZaka?.all?.total / limit)}
    >
      <SubscriptionRequestTable />
    </SubLayout>
  );
};

export default SubscriptionRequest;
