import React, { useEffect, useState } from "react";
import { Table, Tag, Modal } from "antd";
import type { ColumnsType, TableProps } from "antd/es/table";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { searchValue } from "../../../utils/setColor";
import { getCustomerPurchaseAction } from "../../../store/customer/actions";
import { getAllRepairsAction } from "../../../store/repair/actions";
import RepairDrower from "../pages/RepairDrower";
import RepairDelivererOutForm from "../../../components/forms/RepairDelivererOutForm";
import DetailsModel from "../../../components/Modals/DetailsModel";
import SwapDeviceForm from "../../../components/forms/SwapDeviceForm";
import CSVExportButton from "../../../components/cards/NoSale/CSVExportButton";
import { RepairDetailsHeader } from "../../../components/csvHeaders/Headers";
import { repairReport_CSVAction } from "../../../store/csvDownload/actions";
import dayjs from "dayjs";
import SubLayout from "../../../components/layout/subLayout/SubLayout";
import {
  getAllChannelAction,
  getAllShopAction,
} from "../../../store/channel/actions";

interface DataType {
  [x: string]: any;
  key: any;
  name: string;
  age: number;
  address: string;
}

const onChange: TableProps<DataType>["onChange"] = (
  pagination,
  filters,
  sorter,
  extra
) => {
  console.log("params", pagination, filters, sorter, extra);
};

const ReportRepair = () => {
  const { auth, repairs, layout, CSV, channel } = useSelector(
    (state: any) => state
  );
  const [openModel, setOpenModel] = React.useState(false);
  const [openSwpModel, setOpenSwapModel] = React.useState(false);
  const [completedDataId, setCompletedDataId] = useState("");
  const [rejectReqId, setRejectReqId] = useState<any>("");
  const [dataToRevert, setDataToRevert] = useState<any>("");
  const [dataToUpdate, setDataToUpdate] = useState<any>([]);
  const dispatch = useDispatch();
  const { customerId } = useParams();

  const [limit, setLimit] = useState(15);
  const [page, setPage] = useState(1);

  const [open, setOpen] = useState(false);
  const [data, setData] = useState("");

  const onloseSwapModal = (value: any) => {
    setOpenSwapModel(false);
    setDataToRevert("");
  };

  const handleCancelCompleteModal = () => {
    setOpenModel(false);
  };

  const onClose = () => {
    setOpen(false);
    setDataToUpdate([]);
  };

  const columns: ColumnsType<DataType> = [
    {
      title: "Date",
      dataIndex: "date_time",
      key: "date_time",
    },
    {
      title: "Shop",
      dataIndex: "shop",
      key: "shop",
      render: (text, record) => (
        <>
          <p className="capitalize">{text}</p>
          <p className="capitalize">{record?.channel} </p>
        </>
      ),
    },
    {
      title: "Agent",
      dataIndex: "agent",
      key: "agent",
    },
    {
      title: "Model",
      dataIndex: "model",
      key: "model",
    },
    {
      title: "IMEI",
      dataIndex: "imei",
      key: "imei",
    },
    {
      title: "Specifications",
      dataIndex: "specifications",
      key: "specifications",
      width: 200,
    },
    {
      title: "Customer",
      dataIndex: "customer",
      key: "customer",
      render: (text: any, record: any) => (
        <div className="flex flex-col">
          <p>{text}</p>
          <p>{record?.msisdn}</p>
        </div>
      ),
    },
    {
      title: "Repaired At",
      dataIndex: "repairedAt",
      key: "repairedAt",
      render: (text: any, _record: any) => <p className="capitalize">{text}</p>,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text: any, _record: any) => (
        <Tag color={searchValue(text)} className="capitalize">
          {text}
        </Tag>
      ),
    },
  ];

  React.useEffect(() => {
    auth?.token &&
      customerId &&
      getCustomerPurchaseAction(
        auth?.token,
        `?customer=${customerId}`
      )(dispatch);
  }, [auth?.token, customerId, dispatch]);

  const handleFilterSubmit = async () => {
    if (auth?.token) {
      getAllRepairsAction(
        auth?.token,
        `?limit=${limit}&page=${page - 1}${
          layout?.selectedFilters?.shop
            ? `&shop=${layout?.selectedFilters?.shop}`
            : ""
        }`
      )(dispatch);
    }
  };

  React.useEffect(() => {
    auth?.token &&
      getAllRepairsAction(
        auth?.token,
        `?limit=${limit}&page=${page - 1}${
          layout?.selectedFilters?.shop !== undefined
            ? `&shop=${layout?.selectedFilters?.shop}`
            : ""
        }`
      )(dispatch);
  }, [auth.token, dispatch, limit, page]);

  useEffect(() => {
    if (auth?.token) {
      getAllChannelAction(auth?.token)(dispatch);
    }
  }, [auth?.token, dispatch]);

  useEffect(() => {
    if (auth?.token && layout?.selectedFilters?.Channel) {
      getAllShopAction(
        auth?.token,
        `channel=${layout?.selectedFilters?.Channel}`
      )(dispatch);
    }
  }, [auth, layout?.selectedFilters?.Channel, dispatch]);

  useEffect(() => {
    if (layout?.openCSVButton && auth?.token)
      repairReport_CSVAction(
        auth?.token,
        `?limit=${repairs?.all?.total}&page=0`
      )(dispatch);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [layout?.openCSVButton]);

  const formData = repairs?.all.data?.map((el: any) => {
    return {
      key: el._id,
      date_time: dayjs(el?.createdAt).format("YYYY-MM-DD HH:mm:ss"),
      shop: el?.shop?.name,
      agent: el?.createdBy?.names,
      channel: el?.shop?.channel?.name,
      model: el?.cartItemId?.priceProduct?.product?.model,
      imei: el?.cartItemId?.warehouseProduct?.serialNumber,
      msisdn:
        el?.cartItemId?.cart?.customer?.msisdn ||
        el?.cartItemId?.cart?.customer?.phone,
      specifications:
        el?.cartItemId?.shopStockItem?.requestedItem?.product?.specification
          ?.slice(2, 6)
          ?.map((d: any) => d[1] && `${d[0]} : ${d[1]}`)
          ?.join(", "),
      customer: el?.cartItemId?.cart?.customer?.firstName
        ? `${el?.cartItemId?.cart?.customer?.firstName} ${
            el?.cartItemId?.cart?.customer?.lastname || ""
          }`
        : el?.cartItemId?.cart?.customer?.name,
      status: el?.status,
      handleCancelCompleteModal: handleCancelCompleteModal,
      setRejectReqId: setRejectReqId,
      dispatch: dispatch,
      auth: auth,
      repairedAt: el?.repairedAt,
      cartItemId: el?.cartItemId?._id,
      data: el,
    };
  });

  const csvData = CSV?.csvDownloadedData?.data?.map((el: any) => {
    return {
      key: el?._id,
      model: el?.cartItemId?.priceProduct?.product?.model,
      serialNumber: el?.cartItemId?.warehouseProduct?.serialNumber,
      status: el?.status,
      shop: el?.shop?.name,
      customer: el?.cartItemId?.cart?.customer?.firstName
        ? `${el?.cartItemId?.cart?.customer?.firstName} ${
            el?.cartItemId?.cart?.customer?.lastname || ""
          }`
        : el?.cartItemId?.cart?.customer?.name,
      repairedAt: el?.repairedAt,
      date: dayjs(el?.createdAt).format("YYYY-MM-DD HH:mm:ss"),
      data: el,
    };
  });

  const itemData = [
    {
      title: "Channel",
      subItems: channel?.all?.data?.map((el: any) => {
        return {
          label: el?.name,
          value: el?._id,
        };
      }),
    },
    layout?.selectedFilters?.Channel && {
      title: "shop",
      subItems: channel?.allShop?.data?.map((el: any) => {
        return {
          label: el?.name,
          value: el?._id,
        };
      }),
    },
  ];

  return (
    <>
      <SubLayout
        hidePagination
        currentModule="Repair report"
        setPage={setPage}
        setLimit={setLimit}
        limit={limit}
        total={repairs?.all?.total}
        count={Math.ceil(repairs?.all?.total / limit)}
        buttons={[
          <CSVExportButton
            csvHeaders={RepairDetailsHeader}
            csvData={csvData}
            filename={`repair.csv`}
          />,
        ]}
        filterData={itemData}
        loading={channel?.isFetching}
        handleSubmit={handleFilterSubmit}
      >
        <Table
          columns={columns}
          dataSource={formData}
          onChange={onChange}
          pagination={false}
          loading={repairs?.isFetching}
          className="bg-white w-full overflow-x-auto"
        />
      </SubLayout>
      <RepairDrower
        open={open}
        onClose={onClose}
        data={data}
        dataToUpdate={dataToUpdate}
        setDataToUpdate={setDataToUpdate}
      />
      {}
      <Modal
        open={openModel}
        onCancel={handleCancelCompleteModal}
        cancelButtonProps={{ style: { display: "none" } }}
        okButtonProps={{ style: { display: "none" } }}
      >
        <RepairDelivererOutForm
          onCancel={handleCancelCompleteModal}
          completedDataId={completedDataId}
        />
      </Modal>
      <DetailsModel
        isModalOpen={openSwpModel}
        handleOnCancel={onloseSwapModal}
        component={
          <SwapDeviceForm data={dataToRevert} onClose={onloseSwapModal} />
        }
      />
    </>
  );
};

export default ReportRepair;
