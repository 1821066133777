import React, { useState } from 'react';
import { Button, Modal } from 'antd';

interface modalProps{
    onOpen?:any,
    onClose?:any,
    component?:any,
    title?:any,
    width?:any,
}
const GeneralModal= ({onOpen,onClose,title,width,component}:modalProps) => {
  return (
    <>
      <Modal title={<h1 className="text-center text-[#030229] text-lg py-4 font-medium">{title}</h1>} open={onOpen} onOk={onClose} width={width} onCancel={onClose} footer={null}>
       {component}
      </Modal>
    </>
  );
};

export default GeneralModal;