import { Dropdown, MenuProps } from "antd";
import React from "react";
import { MdOutlineAssignmentInd } from "react-icons/md";
import { BsThreeDotsVertical } from "react-icons/bs";
import * as grIcon from "react-icons/gr";
import { AiTwotoneEdit } from "react-icons/ai";

const BsThreeDotsDropdown = (props: any) => {
  const items: MenuProps["items"] = [
    {
      key: "1",
      label: (
        <label
          onClick={() => props?.onViewDetails()}
          className="flex gap-2 bg-[#0f0f4712] p-1 rounded-md cursor-pointer"
        >
          <grIcon.GrFormView color="#0F0F47" size={20} />{" "}
          <span className="text-[#0F0F47] text-[10px] font-normal pt-[5px]">
            {" "}
            View User Activity
          </span>
        </label>
      ),
    },
    {
      key: "2",
      label: (
        <label
          onClick={() => props?.onChangePassword()}
          className="flex gap-2 bg-[#2943d612] p-1 rounded-md"
        >
          <AiTwotoneEdit className=" fill-[#2943d692]" size={18} />{" "}
          <span className="text-[#2943d692] text-[10px] font-normal pt-[5px]">
            Reset Password
          </span>
        </label>
      ),
    },
    {
      key: "3",
      label: props?.userData?.role?.toLowerCase() === "sales-agent" && (
        <label className="flex gap-2 border border-green-900 rounded-md p-1">
          <MdOutlineAssignmentInd className="pt-1 fill-gray-600" size={20} />{" "}
          <span
            className="text-gray-700 text-[12px] font-normal pt-[5px]"
            onClick={() => props.onAssignShop()}
          >
            {props?.userData?.reference ? "Re-Assign shop" : "Assign shop"}
          </span>
        </label>
      ),
    },
  ];
  return (
    <Dropdown menu={{ items }} trigger={["click"]} className="float-right mt-1">
      <BsThreeDotsVertical color="black" size={20} />
    </Dropdown>
  );
};

export default BsThreeDotsDropdown;
