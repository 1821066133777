import React, { useEffect } from "react";
import { Checkbox, Form } from "antd";
import { updateRepairStatusAction } from "../../../store/repair/actions";
import { useDispatch, useSelector } from "react-redux";
import CustomTypography from "../../../styles/globalStyles/CustomTypography";

const DeviceIssues: React.FC<{
  issues: any;
  setDataToUpdate: any;
  dataToUpdate: any;
  setChecked: any;
  checked: any;
}> = ({ issues, setDataToUpdate, dataToUpdate, setChecked, checked }) => {
  const { auth, repairs } = useSelector((state: any) => state);
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  useEffect(() => {
    issues?._id &&
      checked?.length > 0 &&
      updateRepairStatusAction(auth?.token, issues?._id, {
        deviceIssues: [...dataToUpdate],
      })(dispatch);
  }, [auth?.token, checked?.length, dataToUpdate, dispatch, issues?._id]);

  const handleCheckboxChange = (index: any) => {
    setDataToUpdate((prevData: any) => {
      const updatedData = [...prevData];
      setChecked(updatedData);
      updatedData[index] = {
        ...prevData[index],
        isFixed: !prevData[index].isFixed,
      };
      return updatedData;
    });
  };

  return (
    <Form form={form} layout="vertical" autoComplete="off">
      {dataToUpdate.map((item: any, index: any) => (
        <div>
          <Checkbox
            disabled={
              issues?.status === "done" ||
              issues?.status === "completed" ||
              repairs?.isFetching
            }
            onChange={() => handleCheckboxChange(index)}
            defaultChecked={item.isFixed}
          >
            <CustomTypography variant="label"> {item.name}</CustomTypography>
          </Checkbox>
        </div>
      ))}
    </Form>
  );
};

export default DeviceIssues;
