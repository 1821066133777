import { Stack, Skeleton, useMediaQuery } from "@mui/material";
import WareHouseInGrid from "../../../components/grids/WareHouseInGrid";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box } from "@mui/system";
import {
  getAllDeliveryNoteAction,
  getDeliveryNoteStatusCountAction,
} from "../../../store/wareHouse/actions";
import { warehouseInIncludeItem } from "../../../assets/data/includedItem";
import SubLayout from "../../../components/layout/subLayout/SubLayout";
import FilterButton from "../../../components/buttons/FilterButton";

const PageView = (_props: any) => {
  const { wareHouse, auth, layout } = useSelector((state: any) => state);
  const dispatch = useDispatch();
  const [limit, setLimit] = useState(25);
  const [page, setPage] = useState(1);
  const isOnTablet = useMediaQuery("(max-width: 960px)");

  useEffect(() => {
    auth?.token &&
      getAllDeliveryNoteAction(
        auth?.token,
        `?limit=${limit}&page=${page - 1}&field=${warehouseInIncludeItem}`
      )(dispatch);
  }, [auth?.token, dispatch, limit, page]);

  const values = wareHouse?.all?.data;
  const data: {
    id: any;
    deliveryID: any;
    pOrderID: any;
    arrivalDate: any;
    status: any;
    qnt: any;
    warehouseID: any;
    data?: any;
  }[] = [];

  values &&
    values?.forEach((el: any) => {
      data?.push({
        id: el._id,
        deliveryID: el.deliveryID,
        pOrderID: el.pOrderID.pOrderID,
        arrivalDate: el.arrivalDate,
        status: el?.status,
        warehouseID: el?.warehouseID,
        qnt: el?.listOfItems?.reduce(
          (sum: number, a: any) => (sum = sum + a?.qtySent),
          0
        ),
        data: el,
      });
    });

  useEffect(() => {
    if (auth?.token) {
      getDeliveryNoteStatusCountAction(auth?.token)(dispatch);
    }
  }, [auth?.token, dispatch]);

  const statusData = [
    {
      title: "status",
      subItems: wareHouse?.warehouseInTabs?.data?.map((el: any) => ({
        label: `${el?.status} (${el?.count})`,
        value: el?.status,
      })),
    },
  ];

  const handleSubmit = () => {
    if (layout?.selectedFilters?.status !== undefined && auth?.token) {
      getAllDeliveryNoteAction(
        auth?.token,
        `?limit=${limit}&page=${
          page - 1
        }&field=${warehouseInIncludeItem}&status=${
          layout?.selectedFilters?.status
        }`
      )(dispatch);
    }
  };

  return (
    <SubLayout
      currentModule="Warehouse In"
      handleSubmit={handleSubmit}
      filterData={statusData}
      setPage={setPage}
      setLimit={setLimit}
      limit={limit}
      total={wareHouse?.all?.total}
      count={Math.ceil(wareHouse?.all?.total / limit)}
      customSize={300}
    >
      <Stack spacing={1} className="w-[99%]">
        <Box>
          <div className="">
            {wareHouse.isFetching && (
              <Stack direction={"row"} spacing={2}>
                {[1, 2, 3].map(() => (
                  <Box sx={{ width: 270 }}>
                    <Skeleton sx={{ height: 70, m: 0 }} />
                    <Stack direction={"row"} justifyContent="space-between">
                      <Skeleton animation="wave" sx={{ width: "40%" }} />
                      <Skeleton animation={false} sx={{ width: "30%" }} />
                    </Stack>
                  </Box>
                ))}
              </Stack>
            )}
            {!wareHouse.isFetching && (
              <div
                className={` grid sm:grid-cols-2 md:${
                  isOnTablet ? "grid-cols-2" : "grid-cols-2"
                } xl:grid-cols-3 2xl:grid-cols-4 gap-5 ${
                  layout?.isSideNavOpen ? "" : " "
                }`}
              >
                <WareHouseInGrid data={data} />
              </div>
            )}
          </div>
        </Box>
      </Stack>
    </SubLayout>
  );
};

export default PageView;
