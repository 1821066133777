import React from "react";
import { XAxis, Tooltip, ResponsiveContainer } from "recharts";
import "./styles.css";
import { LineChart, Line, YAxis, CartesianGrid } from "recharts";
import { getDefaultCurrencyCode } from "../../../../utils/helper";
import { useSelector } from "react-redux";
import CustomTypography from "../../../../styles/globalStyles/CustomTypography";

const OverviewChart = () => {
  const { company } = useSelector((state: any) => state);
  const datas = [{}];

  return (
    <div className="bg-[#fff] w-full h-[26.5rem] p-6 rounded-md">
      <div className="flex justify-between">
        <div className="flex flex-col gap-y-2">
          <CustomTypography variant="label">Total Revenue</CustomTypography>
          <div className="text-[#0F0F47] font-medium text-base flex space-x-2">
            <CustomTypography variant="h2">
              {`${getDefaultCurrencyCode(company)} 0/`}
            </CustomTypography>
            <CustomTypography variant="value">0 Pieces</CustomTypography>
          </div>
          <div className="text-[#0F0F47] font-medium text-base flex space-x-2">
            <CustomTypography variant="label">
              {`${getDefaultCurrencyCode(company)} 0/`}
            </CustomTypography>
            <CustomTypography variant="label">0 Pieces</CustomTypography>
          </div>
        </div>
        <div className="flex gap-5">
          <div className="flex gap-2">
            <div className="w-4 h-4 rounded-sm bg-[#605BFF] mt-1"></div>{" "}
            <CustomTypography variant="label" className="mt-0.5">
              Today
            </CustomTypography>
          </div>
          <div className="flex gap-2">
            <div className="w-4 h-4 rounded-sm bg-[#BDBDBD] mt-1"></div>{" "}
            <CustomTypography variant="label" className="mt-0.5">
              Yesterday
            </CustomTypography>
          </div>
        </div>
      </div>
      <ResponsiveContainer width="100%" height={300} className="mt-5">
        <LineChart data={datas}>
          <defs>
            <filter id="shadow" x="-50%" y="-50%" width="200%" height="200%">
              <feDropShadow
                dx="0"
                dy="5"
                stdDeviation="4"
                floodColor="#605BFF"
                floodOpacity="0.5"
              />
            </filter>
          </defs>
          <defs>
            <filter id="shadow2" x="-50%" y="-50%" width="200%" height="200%">
              <feDropShadow
                dx="0"
                dy="5"
                stdDeviation="4"
                floodColor="#BDBDBD"
                floodOpacity="0.5"
              />
            </filter>
          </defs>
          <CartesianGrid strokeDasharray="" opacity={0.1} />
          <XAxis dataKey="name" padding={{ left: 30, right: 30 }} />
          <YAxis />
          <Tooltip />
          <Line
            type="monotone"
            dataKey="ToDay"
            stroke="#605BFF"
            strokeWidth={3}
            dot={false}
            activeDot={{ r: 8 }}
            filter="url(#shadow)"
          />
          <Line
            type="monotone"
            dataKey="Yesterday"
            stroke="#BDBDBD"
            strokeWidth={3}
            dot={false}
            filter="url(#shadow2)"
          />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

export default OverviewChart;
