import React from "react";
import { useSelector } from "react-redux";
import CustomTypography from "../../styles/globalStyles/CustomTypography";

const CustomerDetals = () => {
  const { customer } = useSelector((state: any) => state);

  return (
    <div className="text-gray-700">
      <CustomTypography variant="h2" className="mb-4">
        Personal details
      </CustomTypography>
      <div className="border border-gray-400 rounded-md p-3">
        <div className="flex w-full gap-10 mt-3">
          <div className="flex flex-col space-y-2">
            <CustomTypography variant="label">Names</CustomTypography>
            <CustomTypography variant="label">Gender</CustomTypography>
            <CustomTypography variant="label">DOB</CustomTypography>
            <CustomTypography variant="label">Customer ID</CustomTypography>
            <CustomTypography variant="label">National ID</CustomTypography>
            <CustomTypography variant="label">Customer Type</CustomTypography>
          </div>
          <div className="flex flex-col space-y-2">
            <CustomTypography variant="value">
              {customer?.selected?.firstName
                ? `${customer?.selected?.firstName} ${
                    customer?.selected?.lastname || ""
                  }`
                : customer?.selected?.name}
            </CustomTypography>
            <CustomTypography variant="value">
              {customer?.selected?.gender || ""}
            </CustomTypography>
            <CustomTypography variant="value">
              {customer?.selected?.dateOfBirth?.slice(0, 10) || ""}
            </CustomTypography>
            <CustomTypography variant="value">
              {customer?.selected?.customerId || ""}
            </CustomTypography>
            <CustomTypography variant="value">
              {customer?.selected?.nationalId || ""}
            </CustomTypography>
            <CustomTypography variant="value" className="capitalize">
              {customer?.selected?.customerType || ""}
            </CustomTypography>
          </div>
        </div>
      </div>
      <CustomTypography variant="h2" className="my-4">
        Address details
      </CustomTypography>
      <div className="border border-gray-400 rounded-md p-3">
        <div className="flex w-full gap-10 mt-3">
          <div className="flex flex-col space-y-2">
            <CustomTypography variant="label">Nationality</CustomTypography>
            <CustomTypography variant="label">City</CustomTypography>
            <CustomTypography variant="label">
              Current Residence
            </CustomTypography>
          </div>
          <div className="flex flex-col space-y-2">
            <CustomTypography variant="value">
              {customer?.selected?.nationality}
            </CustomTypography>
            <CustomTypography variant="value">
              {customer?.selected?.city}
            </CustomTypography>
            <CustomTypography variant="value">
              {customer?.selected?.currentResidence}
            </CustomTypography>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomerDetals;
