import React, { useState, useEffect, useRef } from "react";
import DangerButton from "../../buttons/DangerButton";
import PrimaryButton from "../../buttons/PrimaryButton";
import ProductDetailsModal from "../../Modals/ProductDetailsModal";
import { Carousel, Image, QRCode, Button, Dropdown } from "antd";
// import QRCode from "qrcode.react";
import { IconButton, useMediaQuery } from "@mui/material";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { AiTwotoneEdit } from "react-icons/ai";
import { MdClosedCaptionDisabled, MdDelete } from "react-icons/md";
import { BiDotsHorizontalRounded } from "react-icons/bi";
const downloadQRCode = () => {
  const canvas = document
    .getElementById("myqrcode")
    ?.querySelector<HTMLCanvasElement>("canvas");
  if (canvas) {
    const url = canvas.toDataURL();
    const a = document.createElement("a");
    a.download = "QRCode.png";
    a.href = url;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }
};

const SidePanelDetails = (props: any) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [dataToUpdate, setDataToupdate] = useState({});
  const [visible, setVisible] = useState(false);

  const handleOnEdit = () => {
    setIsModalOpen(true);
  };

  const userData = {
    email: props?.simpleDetails?.email,
    // phone: props?.simpleDetails?.phone,
    // nid: props?.simpleDetails?.nid,
    names: props?.simpleDetails?.names,
    // role: props?.simpleDetails?.role,
  };

  const userDataJson = JSON.stringify(userData);
  const handleOnCancel = () => {
    setIsModalOpen(false);
  };
  React.useEffect(() => {
    if (props?.simpleDetails) {
      setDataToupdate({ ...props?.simpleDetails });
    }
  }, [props?.simpleDetails]);
  const smallDevices = useMediaQuery("(max-width:1024px)");
  const verySmallDevices = useMediaQuery("(max-width:640px)");
  const isTablet = useMediaQuery("(max-width:814px)");
  const items = [
    {
      key: "1",
      label: (
        <label onClick={handleOnEdit} className="flex">
          {" "}
          <AiTwotoneEdit className=" fill-green-500" size={25} /> Edit
        </label>
      ),
    },
    {
      key: "2",
      label: (
        <label onClick={props?.handleClickDelete} className="flex">
          {" "}
          <MdDelete className=" fill-red-500" size={25} />
          Delete
        </label>
      ),
    },
    {
      key: "3",
      label: (
        <label onClick={props?.showDeactivateModal} className="flex">
          {/* <DangerButton name="Deactivate" /> */}
          <MdClosedCaptionDisabled className=" fill-red-900" size={25} />{" "}
          Deactivate
        </label>
      ),
    },
  ];
  return (
    <div>
      <div className="lg:flex flex-col  justify-center pb-5 lg:pb-0 items-center rounded-lg px-2  bg-white xw-[380px] w-[full]  space-y-6">
        <div className="lg:-mt-10 sm:flex lg:flex-col  ">
          <div className="flex">
            <div className="flex lg:ml-24 items-center justify-center lg:bg-slate-200 w-[120px] h-[120px] rounded-full">
              <div className="flex items-center justify-center lg:bg-slate-300 rounded-full w-[110px] h-[110px]">
                <Carousel
                  autoplay
                  className="bg-white  w-[100px] h-[100px]  rounded-full overflow-clip flex  justify-center items-center "
                >
                  {props?.simpleDetails?.picture && (
                    <div>
                      <Image
                        className="h-auto w-[100%] rounded-lg m-auto"
                        src={props?.simpleDetails?.picture}
                        alt="description"
                      />
                    </div>
                  )}
                </Carousel>
              </div>
            </div>
            {verySmallDevices && (
              <Dropdown
                menu={{
                  items,
                }}
                placement="bottom"
                arrow
                className="ml-[15%] mt-8"
              >
                <BiDotsHorizontalRounded size={32} />
              </Dropdown>
            )}
          </div>
          <div className="w-full ml-5 sm:ml-10 ">
            <div className="lg:text-center  mt-4 lg:mt-0 lg:-ml-20 sfont-semibold capitalize">
              <p className="text-lg lg:mt-2.5  font-helv">
                {props?.simpleDetails?.names}
              </p>
              <p className="text-md font-normal xtext-blue-500">
                {props?.simpleDetails?.role}
              </p>
            </div>
            <div
              className={`md:flex ${isTablet && "-ml-10"} ${
                verySmallDevices && " pl-10"
              } gap-[12%] flex-wrap  lg:flex-col`}
            >
              <div className="flex flex-col  rounded-md pt-4  lg:p-4 ">
                <p className="text-sm  font-semibold break-words mr-6 mb-2.5">
                  Email:{" "}
                  <span className="font-normal break-words">
                    {props?.simpleDetails?.email}
                  </span>
                </p>
                <p className="text-sm font-semibold mr-6 mb-2.5">
                  Telephone:{" "}
                  <span className="font-normal">
                    {props?.simpleDetails?.phone}
                  </span>
                </p>
                <p className="text-sm font-semibold mr-6 mb-2.5">
                  National ID:{" "}
                  <span className="font-normal">
                    {props?.simpleDetails?.nid}
                  </span>
                </p>
                <p className="text-sm font-semibold mr-6 mb-2.5">
                  Company:{" "}
                  <span className="font-normal capitalize">
                    {props?.simpleDetails?.tag}
                  </span>
                </p>
              </div>
              {/* <QRCode id="qrcode" value={userDataJson} size={128} /> */}
              <div id="myqrcode ">
                <QRCode
                  value={userDataJson}
                  style={{
                    marginBottom: 16,
                  }}
                />
                <IconButton
                  aria-label="download"
                  size="small"
                  onClick={downloadQRCode}
                >
                  <FileDownloadIcon fontSize="inherit" />
                </IconButton>
                {/* <PrimaryButton tbnName="primary">Download</PrimaryButton> */}
              </div>
            </div>
          </div>
          {smallDevices && (
            <div className="md:mr-5 mr-10 -ml-32  mt-5">
              <Dropdown
                menu={{
                  items,
                }}
                placement="bottom"
                arrow
              >
                <BiDotsHorizontalRounded size={25} />
              </Dropdown>
            </div>
          )}
        </div>
        {!smallDevices && (
          <div className="flex justify-center gap-2">
            <PrimaryButton btnName="Edit" onClick={handleOnEdit} />
            <DangerButton name="Delete" onClick={props?.handleClickDelete} />
            <DangerButton
              name="Deactivate"
              onClick={props?.showDeactivateModal}
            />
            {/* <PrimaryButton btnName="Download QR"  /> */}
          </div>
        )}

        <span className="text-center uppercase font-medium pb-4">
          <p className="text-sm font-light">{props?.simpleDetails?.address}</p>
          {props?.simpleDetails?.type}
        </span>
      </div>
      {/* product modal here */}
      {
        <ProductDetailsModal
          // component={<props.component />}
          component={
            <props.UpdateForm
              data={dataToUpdate}
              // id={dataToUpdate}
              dataToUpdate={dataToUpdate}
              action={"update"}
              onCancel={handleOnCancel}
            />
          }
          isModalOpen={isModalOpen}
          handleOnCancel={handleOnCancel}
        />
      }

      <div style={{ display: "none" }}>
        <Image.PreviewGroup
          preview={{ visible, onVisibleChange: (vis) => setVisible(vis) }}
        >
          {props?.simpleDetails?.images?.map((img: any) => (
            <Image src={img} />
          ))}
        </Image.PreviewGroup>
      </div>
    </div>
  );
};

export default SidePanelDetails;
