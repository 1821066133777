import React from "react";
import { CSVLink } from "react-csv";
import { DownloadOutlined } from "@ant-design/icons";
import { Button, Tooltip } from "antd";
import { LiaFileExportSolid } from "react-icons/lia";
import PrimaryButton from "./PrimaryButton";

interface CSVTableDownloadProps {
  columns?: any[];
  csvHeaders: { label: string; key: string }[];
  csvData: any[];
  filename: any;
}

const ExportCSVFile: React.FC<CSVTableDownloadProps> = ({
  csvHeaders,
  csvData,
  filename,
}) => {
  const csvLinkRef = React.useRef<any>(null);

  const handleButtonClick = () => {
    if (csvLinkRef && csvLinkRef.current) {
      csvLinkRef.current.link.click();
    }
  };
  return (
    <>
      <PrimaryButton
        onClick={handleButtonClick}
        loadingColor="white"
        btnName="Export CSV"
        icon={<LiaFileExportSolid size={20} />}
      ></PrimaryButton>
      <CSVLink
        data={csvData}
        headers={csvHeaders}
        ref={csvLinkRef}
        filename={filename}
      />
    </>
  );
};

export default ExportCSVFile;
