import React from "react";
import QuotationCard from "./QuotationGridCard";

const App = (props: any) => {
  return props?.draft
    ? props?.data.map((d: any) => <QuotationCard data={{ quotation: d }} />)
    : props?.data.map((d: any) => {
        return <QuotationCard data={d} />;
      });
};

export default App;
