import { Drawer } from "antd";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import CustomerDetals from "./CustomerDetals";
import CustomTypography from "../../styles/globalStyles/CustomTypography";

const CustomerCard = (props: any) => {
  const { layout, auth } = useSelector((state: any) => state);
  const [openCustomerDetails, setOpenCustomerDetails] = useState(false);

  const showDrawer = () => {
    setOpenCustomerDetails(true);
  };

  const onClose = () => {
    setOpenCustomerDetails(false);
  };

  const styles = {
    customShadow: {
      boxShadow: "0px 10px 20px 0px rgba(194, 194, 194, 0.16)",
    },
    border: {
      border: "0px 1px 0px 0px solid rgba(3, 2, 41, 0.04)",
    },
  };

  return (
    <>
      <div
        className={`bordedr borderd-[#0302290A] bg-white p-5  ${
          layout?.isSideNavOpen ? "2xl:w-[430px]" : "2xl:w-[500px]"
        } w-full rounded-md px-7`}
        style={styles.customShadow}
      >
        <div className="md:flex justify-between">
          <CustomTypography variant="h2">Customer Details</CustomTypography>
          <button
            className="text-[#605BFF] text-base cursor-pointer"
            onClick={showDrawer}
          >
            See more
          </button>
        </div>
        <div className="sm:flex gap-5 mb-5 mt-5">
          <div className="w-[52px] h-[52px] ">
            <img src={props?.data?.picture} alt="" className="w-full h-full" />
          </div>
          <div>
            <CustomTypography variant="h2">
              {" "}
              {props?.data?.firstName
                ? `${props?.data?.firstName || ""} ${
                    props?.data?.lastname || ""
                  }`
                : props?.data?.name}
            </CustomTypography>
            <button className="text-white bg-[#0FA958] text-base px-5 mt-2 py-1 rounded-md">
              {props?.data?.status}
            </button>
          </div>
        </div>
        <div className="flex w-full gap-10 mt-3">
          <div className="flex flex-col space-y-2">
            <CustomTypography variant="label">Customer ID</CustomTypography>
            {props?.data?.email && (
              <CustomTypography variant="label">Email</CustomTypography>
            )}
            <CustomTypography variant="label">Phone Number</CustomTypography>
            <CustomTypography variant="label">Starting Date</CustomTypography>
            <CustomTypography variant="label">Customer Type</CustomTypography>
          </div>
          <div className="flex flex-col space-y-2">
            <CustomTypography variant="value" className="sm:text-[#605BFF]">
              {props?.data?.customerId ?? "N/A"}
            </CustomTypography>
            {props?.data?.email && (
              <CustomTypography variant="value">
                {props?.data?.email}
              </CustomTypography>
            )}
            <CustomTypography variant="value">
              {" "}
              {props?.data?.phone || props?.data?.msisdn || "N/A"}
            </CustomTypography>
            <CustomTypography variant="value">
              {props?.data?.createdAt?.substring(0, 10)}
            </CustomTypography>
            <CustomTypography variant="value" className="capitalize">
              {props?.data?.subscriptionInfo?.customerType}
            </CustomTypography>
          </div>
        </div>
      </div>

      <Drawer
        title={
          <div className="text-center font-semibold text-lg font-poppins">
            {auth?.user?.tag === "mascom"
              ? "Applicants Details"
              : "Customer Details"}
          </div>
        }
        placement={"right"}
        onClose={onClose}
        open={openCustomerDetails}
        key={"right"}
        width={720}
      >
        <CustomerDetals />
      </Drawer>
    </>
  );
};

export default CustomerCard;
