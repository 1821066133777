import React, { ReactNode } from "react";

interface FilterBarProps {
  filterComponent?: ReactNode;
  StatusComponent?: ReactNode;
}

const FilterBar = ({ filterComponent, StatusComponent}: FilterBarProps) => {
  return (
    <>
    <div className="flex gap-2">
      <div>{filterComponent}</div>
      <div>{StatusComponent}</div>
    </div>
    </>
  );
};

export default FilterBar;
