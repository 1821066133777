import React from "react";
import { useSelector } from "react-redux";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Cell,
} from "recharts";

const AgentChart = () => {
  const { agentKPI } = useSelector((state: any) => state);
  const formattedDate = new Date().toISOString().slice(0, 10);

  const data =
    agentKPI?.allEarning?.data?.results?.length > 0 &&
    agentKPI?.allEarning?.data?.results?.map((el: any) => ({
      date: el?.date,
      Revenue: el?.revenue,
    }));

  const formatYAxis = (value: any) => {
    if (value >= 1000000) {
      return `${value / 1000000}M`;
    }
    if (value >= 1000) {
      return `${value / 1000}k`;
    }
    return value;
  };

  return (
    <div className="h-[38vh]">
      <ResponsiveContainer width="100%" height="100%">
        <BarChart
          width={500}
          height={300}
          data={data}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
          barSize={40}
        >
          <XAxis
            dataKey="date"
            scale="point"
            padding={{ left: 50, right: 10 }}
          />
          <YAxis tickFormatter={formatYAxis} />
          <Tooltip />
          <CartesianGrid strokeDasharray="2 2" vertical={false} />
          <Bar dataKey="Revenue" fill="#3f38ff">
            {data?.length > 0 &&
              data?.map((entry: any, index: any) => (
                <Cell
                  key={`cell-${index}`}
                  fill={entry.date === formattedDate ? "#3f38ff" : "#C8C8C8"}
                  stroke={entry.date === formattedDate ? "#3f38ff" : "none"}
                />
              ))}
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};
export default AgentChart;
