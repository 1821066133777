import React from "react";
import { FaArrowUpLong } from "react-icons/fa6";
import CustomButton from "../../../../components/buttons/CustomButton";
import CustomTypography from "../../../../styles/globalStyles/CustomTypography";

interface cardProps {
  title?: any;
  icon?: any;
  amount?: any;
  comparedAmount?: any;
  parcent?: any;
  icon2?: any;
  description?: any;
  btn?: any;
  compareText?: any;
  textColor?: string;
}
const OverViewCard = ({
  title,
  icon,
  amount,
  comparedAmount,
  parcent,
  icon2,
  description,
  btn,
  compareText,
  textColor,
}: cardProps) => {
  return (
    <div className="bg-white rounded-md p-5 w-[384pdx] h-[200px] relative">
      <div className="flex justify-between items-center">
        <CustomTypography variant="label">{title}</CustomTypography>
        {icon}
      </div>
      <div className="border-dotted border-b-2 border-[#03022940] pb-2">
        <CustomTypography variant="h2">{amount}</CustomTypography>
        <CustomTypography variant="label">{comparedAmount}</CustomTypography>
      </div>
      <div className="flex justify-between pt-2">
        <CustomTypography variant="value">{description}</CustomTypography>
      </div>
    </div>
  );
};

export default OverViewCard;
