import React, { useState } from "react";
import DetailsTabs from "./DetailsPage/DetailsTabs";
import SidePanelDetails from "./DetailsPage/SidePanelDetails";
import ViewLayout from "../../layout/subLayout/ViewLayout";

const DetailsPage = (props: any) => {
  const [activeTab, setActiveTab] = useState(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };

  return (
    <ViewLayout currentModule={props?.pageName} subModule={props?.title}>
      <div className="fzlex flex-row-xreverse gap-2">
        <SidePanelDetails
          UpdateForm={props?.UpdateForm}
          handleClickDelete={props?.handleClickDelete}
          handleClickEdit={props?.handleClickEdit}
          simpleDetails={props?.simpleDetails}
          specsData={props?.specsData}
        />

        <DetailsTabs
          tabs={props?.tabs}
          activeTab={activeTab}
          handleChange={handleChange}
        />
      </div>
    </ViewLayout>
  );
};

export default DetailsPage;
