import { Modal, Typography } from "antd";
import React, { useEffect, useState } from "react";
import Card from "./card";
import totslIcon from "../../../assets/icons/dashboard/channel/box-tick.svg";
import profileIcon from "../../../assets/icons/dashboard/channel/profile-2user.svg";
import CustomChart from "./customChart";
import DateRangeSelector from "./dateRangePicke";
import { useDispatch, useSelector } from "react-redux";
import NewShopForm from "../../../components/forms/NewShopForm";
import {
  deleteShopAction,
  getShopOverViewAction,
} from "../../../store/channel/actions";
import DeleteModal from "../../../components/Modals/DeleteModal";
import { CiEdit } from "react-icons/ci";
import { MdDelete } from "react-icons/md";
import { useNavigate, useParams } from "react-router-dom";
import CustomSkeleton from "../../../components/skeleton/CustomSkeleton";
import { getDefaultCurrencyCode } from "../../../utils/helper";
import CustomTypography from "../../../styles/globalStyles/CustomTypography";

const ShopProfile = (props: any) => {
  const { channel, auth, shop, company } = useSelector((state: any) => state);
  const [data, setData] = React.useState<any>("");
  const [deleteId, setDeleteId] = React.useState("false");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [visible, setVisible] = React.useState(false);
  const { shopId } = useParams();
  const { Paragraph } = Typography;

  const styles = {
    boxShadow: "1px 4px 24px 0px rgba(3, 2, 41, 0.06)",
  };

  const deleteModal = (itemId: any) => {
    setDeleteId(itemId);
    setVisible(true);
  };

  const handleDelete = async () => {
    auth?.token &&
      (await deleteShopAction(deleteId, auth?.token, {
        isDeleted: "true",
      })(dispatch));
    setVisible(false);
    navigate(`/channel/${channel?.selected?.name}/pos/shops`);
  };

  const handleCancels = () => {
    setVisible(false);
  };

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    auth?.token &&
      getShopOverViewAction(auth?.token, shopId as string)(dispatch);
  }, [auth?.token, dispatch, shopId]);

  const updateModelOpen = (datas: any) => {
    setIsModalOpen(true);
    setData(datas);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <div className="flex xl:flex-row flex-col gap-2 w-full">
        <div
          className="py-5 w-full flex-1 bg-white mt-5 rounded-md "
          style={styles}
        >
          <div className="flex px-7 justify-between">
            <CustomTypography variant="h2">Shop Profile</CustomTypography>
            {["admin", "dev", "finance", "finance-manager"]?.includes(
              auth?.user?.role
            ) && (
              <div className="mtx-3 flex gap-3">
                <CiEdit
                  onClick={() => updateModelOpen(shop?.selected)}
                  className="float-right cursor-pointer"
                  size={23}
                  color="#A9A9A9"
                />
                <MdDelete
                  onClick={() => deleteModal(shop?.selected?._id)}
                  size={23}
                  fill="#A9A9A9"
                  className="cursor-pointer"
                />
              </div>
            )}
          </div>
          <div className="divide-y">
            <div className="flex px-7 justify-between w-[100%] mt-5 ">
              <div>
                <div className="flex flex-col">
                  <CustomTypography variant="label" className="text-[12px]">
                    Name:
                  </CustomTypography>
                  <CustomTypography variant="value">
                    {shop?.selected?.name}
                  </CustomTypography>
                </div>
                <div className="mt-4 flex flex-col">
                  <CustomTypography variant="label" className="text-[12px]">
                    Type:
                  </CustomTypography>
                  <CustomTypography variant="value">
                    {shop?.selected?.type}
                  </CustomTypography>
                </div>
                <div className="mt-4 flex flex-col">
                  <CustomTypography variant="label" className="text-[12px]">
                    MSISDN:
                  </CustomTypography>
                  <CustomTypography variant="value">
                    {shop?.selected?.msisdn}
                  </CustomTypography>
                </div>
                <div className="mt-3">
                  <Paragraph
                    copyable={{
                      text: async () =>
                        new Promise((resolve) => {
                          setTimeout(() => {
                            resolve(
                              shop?.selected?.shopLocation?.coordinate?.lng
                            );
                          }, 500);
                        }),
                    }}
                  >
                    <CustomTypography variant="value">
                      Longitude
                    </CustomTypography>
                  </Paragraph>
                </div>
              </div>
              <div>
                <div className="flex flex-col">
                  <CustomTypography variant="label" className="text-[12px]">
                    Myzaka Code:
                  </CustomTypography>
                  <CustomTypography variant="value">
                    {shop?.selected?.momocode}
                  </CustomTypography>
                </div>
                <div className="mt-4 flex flex-col">
                  <CustomTypography variant="label" className="text-[12px]">
                    Address:
                  </CustomTypography>
                  <CustomTypography variant="value">
                    {shop?.selected?.address}
                  </CustomTypography>
                </div>
                <div className="mt-4 flex flex-col">
                  <CustomTypography variant="label" className="text-[12px]">
                    Region:
                  </CustomTypography>
                  <CustomTypography variant="value">
                    {shop?.selected?.region}
                  </CustomTypography>
                </div>
                <div className="mt-4">
                  <Paragraph
                    copyable={{
                      text: async () =>
                        new Promise((resolve) => {
                          setTimeout(() => {
                            resolve(
                              shop?.selected?.shopLocation?.coordinate?.lat
                            );
                          }, 500);
                        }),
                    }}
                  >
                    <CustomTypography variant="value">
                      Latitude
                    </CustomTypography>
                  </Paragraph>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="gap-4 mt-5 grid sm:grid-cols-2 lg:grid-cols-4 xl:grid-cols-2 w-full xl:w-[60%]">
          <Card
            cardIcon={totslIcon}
            title={"Total Devices"}
            value={shop?.shopOverview?.totalDevices ?? 0}
          />
          <Card
            cardIcon={totslIcon}
            title={"Total Sold Devices"}
            value={shop?.shopOverview?.totalDevicesSold ?? 0}
          />
          <Card
            cardIcon={totslIcon}
            title={"Total Stock Devices"}
            value={shop?.shopOverview?.totalCurrentDevices}
          />
          <Card
            cardIcon={profileIcon}
            title={"Number of Agents"}
            value={shop?.shopOverview?.agents}
          />
        </div>
      </div>
      <div className="relative flex xl:flex-row flex-col-reverse gap-5 mt-5">
        <div className=" bg-white p-5 rounded-md w-full flex-1">
          <div className="flex justify-between">
            <div>
              <CustomTypography variant="label">Total Revenue</CustomTypography>
              {shop?.isFetching ? (
                <CustomSkeleton />
              ) : (
                <CustomTypography variant="h2" className="mt-1">
                  {getDefaultCurrencyCode(company)}{" "}
                  {shop?.shopStat?.data?.revenue?.currentRevenue?.allTotalRevenue?.toLocaleString() ||
                    0}{" "}
                  <span className="text-sm font-medium text-[#030229]">
                    {" "}
                    /{" "}
                    {shop?.shopStat?.data?.quantities?.currentQuantities
                      ?.allTotalQuantities || 0}{" "}
                    Pieces
                  </span>
                </CustomTypography>
              )}
            </div>
            <div className="flex gap-5">
              <div className="flex gap-2">
                {" "}
                <div className="w-3 h-3 rounded-sm bg-[#605BFF] mt-1 pt-[2px]"></div>
                <p>Revenue</p>
              </div>
            </div>
            <div />
          </div>
          <div className="mt-7">
            <CustomChart />
          </div>
        </div>
        <div className="w-full xl:w-[40%] 2xl:w-[29.9%] bg-white">
          <DateRangeSelector />
        </div>

        <DeleteModal
          visible={visible}
          onOk={handleDelete}
          isLoading={channel.isFetching}
          onCancel={handleCancels}
          itemName="Shop"
        />
        <Modal
          title={""}
          open={isModalOpen}
          onCancel={handleCancel}
          footer={null}
          className="min-w-min !max-h-[80vh] overflow-auto"
        >
          <div>
            <div className="w-full xl:w-[100vh]">
              <NewShopForm data={data} onCancel={handleCancel} />
            </div>
          </div>
        </Modal>
      </div>
    </>
  );
};

export default ShopProfile;
