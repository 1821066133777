import ArrowBack from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";

interface backProps {
  onClick?: () => void;
}
const BackButton = ({ onClick, ...props }: backProps) => {
  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  };
  return (
    <ArrowBack
      {...props}
      onClick={onClick ? onClick : goBack}
      className="cursor-pointer mt-1"
    />
  );
};

export default BackButton;
