import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import DetailsPage from "../../../components/DetailsPage";
import ProductForm from "../../../components/forms/NewProductForm";
import DeleteModal from "../../../components/Modals/DeleteModal";
import StockProductTable from "./Tables/StockProductTable";
import {
  deleteProductsAction,
  getOneProductAction,
  getOneProductStoreAction,
  getPricelistItemByProductAction,
} from "../../../store/product/actions";
import ProductDetailsTable from "../../../components/tables/ProductDetailsTable";
import SupplierByBrandTable from "../../../components/tables/SupplierByBrandTable";
import { getAllSuppliersByBrandAction } from "../../../store/supplier/actions";

type TAB = {
  title: string;
  component: any;
};

const tabs: TAB[] = [
  {
    title: `Stock`,
    component: <StockProductTable />,
  },
  {
    title: "Prices",
    component: <ProductDetailsTable />,
  },
  {
    title: "Suppliers",
    component: <SupplierByBrandTable />,
  },
];

const ProductDetails = (props: any) => {
  const { productId } = useParams();
  const { auth, product } = useSelector((state: any) => state);
  const [visible, setVisible] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleClickDelete = async () => {
    setVisible(true);
  };

  const deleteProduct = async () => {
    auth?.token &&
      (await deleteProductsAction(auth?.token, {
        selectedIds: [product?.selected?._id],
      })(dispatch));
    navigate("/inventory/pr");
  };

  const handleCancels = () => {
    setVisible(false);
  };

  useEffect(() => {
    const fetchOnProduct = async () => {
      if (auth?.token && productId) {
        getOneProductAction(auth?.token, productId)(dispatch);
        getOneProductStoreAction(auth?.token, productId)(dispatch);
        getPricelistItemByProductAction(
          auth?.token,
          `?product=${productId}`
        )(dispatch);
        getAllSuppliersByBrandAction(
          auth?.token,
          `?brand=${product?.selected?.brand}`
        )(dispatch);
      }
    };
    fetchOnProduct();
  }, [productId, auth, dispatch]);

  return (
    <div>
      <DetailsPage
        UpdateForm={ProductForm}
        pageItemId={productId}
        specsData={product.selected && product.selected?.specs}
        tabs={tabs}
        pageName={"Device"}
        title={product?.selected?.model}
        simpleDetails={product && product.selected}
        handleClickDelete={handleClickDelete}
      />

      <DeleteModal
        visible={visible}
        onOk={deleteProduct}
        isLoading={product.isFetching}
        onCancel={handleCancels}
        itemName="Device"
      />
    </div>
  );
};

export default ProductDetails;
