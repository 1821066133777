import React, { useEffect, useRef, useState } from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import { Button, Divider, Form, Input, InputRef, Select, Space } from "antd";
import { useSelector, useDispatch } from "react-redux";
import {
  createShopAction,
  createShopTypeAction,
  getAllRegionAction,
  getAllShopAction,
  getOneRegionAction,
  getShopTypeAction,
  updateShopAction,
} from "../../store/channel/actions";
import AddressDetailsForm from "./AddressDetailsForm";
import { PlusOutlined } from "@ant-design/icons";
import { getOneCompanyAction } from "../../store/setting/company/actions";
import { useMediaQuery } from "@mui/material";

const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
};

const App = (props: any) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { channel, auth, layout, company } = useSelector((state: any) => state);
  const { Option } = Select;
  const channelId =
    channel?.selected?._id || layout?.channelInfo?._id || props?.id;
  const inputRef = useRef<InputRef>(null);
  const [temporaryShopTypes, setTemporaryShopTypes] = useState<any[]>([]);

  const smallScreen = useMediaQuery("(min-width:700px) and (max-width:1279px)");

  useEffect(() => {
    auth.token &&
      getShopTypeAction(
        auth.token,
        `?channel=${channel?.selected?._id}`
      )(dispatch);
  }, [dispatch, auth?.token, channel?.selected?._id]);

  useEffect(() => {
    auth?.token &&
      channelId &&
      getAllRegionAction(auth?.token, `channel=${channelId}`)(dispatch);
  }, [dispatch, auth?.token, channelId]);

  useEffect(() => {
    if (auth?.token && company?.selected?._id) {
      getOneCompanyAction(
        company?.selected?._id as string,
        auth.token
      )(dispatch);
    }
  }, [auth.token, dispatch, company?.selected?._id]);

  const isUpdate = !!props?.data?._id;

  const onChange = async (value: any) => {
    await getOneRegionAction(value as string, auth?.token)(dispatch);
  };

  const onFinish = async (values: any) => {
    if (auth?.token) {
      const shopData = {
        ...values,
        region: channel?.selectedRegion?.name,
        regionId: values.region,
        channel: layout?.channelInfo?._id ?? props?.id,
        shopLocation: {
          province: values?.province,
          district: values?.district,
          sector: values?.sector,
          cell: values?.cell,
          village: values?.village,
          coordinate: { lat: values?.lat, lng: values?.lng },
        },
      };

      if (isUpdate) {
        await updateShopAction(
          props?.data?._id,
          shopData,
          auth?.token
        )(dispatch);
        await getAllShopAction(auth?.token, `channel=${channelId}`)(dispatch);
      } else {
        await createShopAction(auth?.token, shopData)(dispatch);
      }

      form.resetFields();
      props?.onCancel();
    }
  };

  useEffect(() => {
    form.setFieldsValue(props?.data);
  }, [props?.data]);

  const handleAddShopType = async () => {
    const name = inputRef.current?.input?.value?.trim();
    if (name && auth.token) {
      const tempId = `temp_${Date.now()}`; // Temporary ID to identify the newly added item
      const newShopType = { _id: tempId, name };

      setTemporaryShopTypes([...temporaryShopTypes, newShopType]);

      try {
        const res: any = await createShopTypeAction(auth.token, {
          name,
          channel: channel?.selected?._id,
        })(dispatch);

        if (res?.status === 201) {
          const createdShopType = res.data;
          form.setFieldsValue({ type: createdShopType._id });
          await getShopTypeAction(auth.token)(dispatch);

          setTemporaryShopTypes((prev) =>
            prev.filter((type) => type._id !== tempId)
          );
        }
      } catch (error) {
        console.error("Error creating shop type:", error);
        setTemporaryShopTypes((prev) =>
          prev.filter((type) => type._id !== tempId)
        );
      }

      if (inputRef.current?.input) {
        inputRef.current.input.value = "";
      }
    }
  };

  return (
    <Form
      form={form}
      name="register"
      onFinish={onFinish}
      initialValues={props.data || {}}
      scrollToFirstError
      style={smallScreen ? { minWidth: 650 } : {}}
      layout="vertical"
    >
      <h1 className="font-semibold text-sm mb-8 text-center ">{`${
        isUpdate ? "Update Shop Information" : "Add New Shop"
      }`}</h1>
      <div className="grid md:grid-cols-2 xl:grid-cols-3 gap-x-4 w-full">
        <Form.Item
          name="name"
          label="Shop Name"
          tooltip="Please enter name of the Shop?"
          rules={[
            { required: true, message: "Shop is required!", whitespace: true },
          ]}
        >
          <Input className="h-10" />
        </Form.Item>
        <Form.Item name="email" label="Shop's Email">
          <Input className="h-10" type="email" />
        </Form.Item>

        <Form.Item
          name="type"
          label="Type"
          rules={[
            {
              required: true,
              message: "Shop type is required!",
              whitespace: true,
            },
          ]}
        >
          <Select
            showSearch
            placeholder="Select shop Type"
            className="capitalize h-10"
            optionFilterProp="children"
            dropdownRender={(menu) => (
              <>
                {menu}
                <Divider style={{ margin: "8px 0" }} />
                <Space style={{ padding: "0 8px 4px" }}>
                  <Input
                    placeholder="Add Type"
                    ref={inputRef}
                    onPressEnter={handleAddShopType}
                  />
                  <Button onClick={handleAddShopType}>
                    <PlusOutlined />
                  </Button>
                </Space>
              </>
            )}
          >
            {channel?.shopType?.data
              ?.concat(temporaryShopTypes)
              ?.map((type: any) => (
                <Option
                  key={type?._id}
                  value={type?.name}
                  className="capitalize"
                >
                  {type?.name}
                </Option>
              ))}
          </Select>
        </Form.Item>

        <Form.Item name="msisdn" label="MSISDN" tooltip="">
          <Input className="h-10" />
        </Form.Item>

        <Form.Item name="momocode" label="Site Code">
          <Input className="h-10" />
        </Form.Item>
        <Form.Item
          name="region"
          label="Region"
          rules={[
            {
              required: true,
              message: "Shop region is required!",
              whitespace: true,
            },
          ]}
        >
          <Select
            showSearch
            placeholder="Select region"
            className="capitalize h-10"
            onChange={onChange}
          >
            {channel?.allRegion?.data?.map((el: any) => (
              <Select.Option value={el?._id} key={el?._id}>
                {el?.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="address"
          label="Address"
          tooltip="Where our Shop is located"
        >
          <Input className="h-10" />
        </Form.Item>

        <Form.Item
          name="manager"
          label="Manager names"
          rules={[
            {
              required: true,
              message: "Manager is required!",
              whitespace: true,
            },
          ]}
        >
          <Input className="h-10" />
        </Form.Item>

        <Form.Item
          name="IPaddress"
          label="IP Address"
          rules={[
            {
              required: true,
              message: "IP Address is required!",
              whitespace: true,
            },
          ]}
        >
          <Input className="h-10" />
        </Form.Item>
      </div>
      <Divider />

      <h2 className="font-semibold text-sm">Location</h2>
      <AddressDetailsForm dataToUpdate={props?.data} />

      <div className="grid grid-cols-2 gap-x-4 mx-6">
        <Form.Item
          label="Latitude"
          name="lat"
          rules={[{ required: true, message: "Please input latitude!" }]}
        >
          <Input className="h-10" />
        </Form.Item>
        <Form.Item
          label="Longitude"
          name="lng"
          rules={[{ required: true, message: "Please input longitude!" }]}
        >
          <Input className="h-10" />
        </Form.Item>
      </div>
      <Divider />
      <Form.Item {...tailFormItemLayout}>
        <LoadingButton
          type="submit"
          className="bg-primary h-10"
          variant="contained"
          sx={{ minWidth: 300 }}
        >
          {isUpdate ? "Update" : "Submit"}
        </LoadingButton>
      </Form.Item>
    </Form>
  );
};

export default App;
