import React from "react";
import { Badge, Divider, Space } from "antd";
import { useNavigate } from "react-router-dom";
import ProfileAvatal from "../buttons/ProfileAvatal";
import { searchValue } from "../../utils/setColor";
import { useSelector } from "react-redux";
import CustomTypography from "../../styles/globalStyles/CustomTypography";

const Component = () => {
  const { customer } = useSelector((state: any) => state);
  const navigate = useNavigate();

  return (
    <div
      className={`w-full grid sm:grid-cols-2 xl:grid-cols-3 3xl:grid-cols-4 4xl:grid-cols-6 gap-4 text-[#030229]`}
    >
      {customer?.all?.data?.map((el: any) => (
        <Badge.Ribbon
          text={
            <span
              style={{
                fontSize: "12px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                fontWeight: 400,
                paddingTop: "1.5px",
              }}
            >
              {el?.status}
            </span>
          }
          color={searchValue(el?.status)}
          style={{ width: "5rem", padding: "4px 1px" }}
        >
          <div
            className="h-[210px] cursor-pointer text-[#030229] bg-white border-[.5px] border-[#D9DBE930] rounded-[10px] shadow-[1px_3px_16px_0px_rgba(0,0,0,0.07)] p-6 font-normal"
            onClick={() => {
              navigate(`/customers/${el?._id}`);
            }}
          >
            <div className="flex gap-6 pb-2">
              <div style={{ width: "2rem" }}>
                <ProfileAvatal
                  picture={el?.picture}
                  name={el?.name || "N/A"}
                  noActiveBadge={true}
                />
              </div>
              <div className="flex flex-col">
                <CustomTypography variant="h2">
                  {el?.firstName
                    ? `${el?.firstName} ${el?.lastname || ""}`
                    : el?.name}
                </CustomTypography>
                <CustomTypography variant="label" className="sm:text-[#605BFF]">
                  {`NID: ${el?.nationalId || "N/A"}`}
                </CustomTypography>
              </div>
            </div>
            <Divider />
            <div className="flex flex-col text-[14px]">
              <Space>
                <CustomTypography variant="label">MSISDN:</CustomTypography>
                <CustomTypography variant="value">
                  {el?.phone || el?.msisdn || "N/A"}
                </CustomTypography>
              </Space>
              {el?.email ? (
                <Space>
                  <CustomTypography variant="label">Email:</CustomTypography>
                  <CustomTypography variant="value">
                    {el?.email}
                  </CustomTypography>
                </Space>
              ) : (
                ""
              )}
              {el?.address && (
                <Space>
                  <CustomTypography variant="label">Country:</CustomTypography>
                  <CustomTypography variant="value" className="capitalize">
                    {el.address.split(",").pop().trim()}
                  </CustomTypography>
                </Space>
              )}
            </div>
          </div>
        </Badge.Ribbon>
      ))}
    </div>
  );
};

export default Component;
