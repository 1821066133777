import React, { useEffect, useState } from "react";
import { LuSlidersHorizontal } from "react-icons/lu";
import { Checkbox } from "antd";
import PrimaryButton from "./PrimaryButton";
import { myLayoutActions } from "../../store/layout";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../loading/Loader";

interface SubItem {
  label: string;
  value: string;
}

interface Item {
  title: string;
  subItems: SubItem[];
}
interface btnProps {
  filterElement: Item[];
  loading?: boolean;
  onSubmit: () => void;
}

const FilterButton = ({ filterElement, loading, onSubmit }: btnProps) => {
  const layout = useSelector((state: any) => state?.layout);
  const [filterOption, setFilterOption] = useState(false);
  const [resetLoading, setResetLoading] = useState(false);
  const dispatch = useDispatch();

  const handleCheckboxChange = (item: Item, el: SubItem) => {
    const updatedFilters = layout?.selectedFilters
      ? { ...layout.selectedFilters }
      : {};

    if (updatedFilters[item.title] === el.value) {
      delete updatedFilters[item.title];
    } else {
      updatedFilters[item.title] = el.value;
    }

    dispatch(myLayoutActions.setSelectedFilters(updatedFilters));
  };

  const handlerOpenFilter = () => {
    setFilterOption(!filterOption);
  };

  const handleSubmit = () => {
    onSubmit();
    setFilterOption(false);
  };

  const handleReset = async () => {
    setResetLoading(true);
    dispatch(myLayoutActions.setSelectedFilters({}));
  };

  useEffect(() => {
    if (
      resetLoading &&
      Object.keys(layout?.selectedFilters || {}).length === 0
    ) {
      handleSubmit();
      setResetLoading(false);
    }
  }, [layout?.selectedFilters]);

  return (
    <div className="flex gap-5">
      <PrimaryButton
        btnName="Filter"
        onClick={handlerOpenFilter}
        textColor="[#605BFF]"
        icon={<LuSlidersHorizontal size={20} />}
        className={`border ${
          filterOption ? "border-[#605BFF] text-[#605BFF]" : "border-[#E4E4E7]"
        }  p-2 rounded-md`}
      />

      {filterOption && (
        <div className="bg-white rounded-md p-5 absolute top-[10rem] z-20 min-w-[20rem] h-[27rem] text-black border border-[#E4E4E7]">
          <h2 className="text-[#71717A] text-lg">Filter By:</h2>
          <div className="flex flex-col h-full">
            <div className="flex gap-3 flex-1 overflow-y-auto">
              {filterElement?.map((item: Item) => (
                <div key={item?.title}>
                  <h1 className="capitalize py-2">{item?.title}</h1>

                  {item?.subItems?.map((el: SubItem) => (
                    <div className="block mt-2 ">
                      <Checkbox
                        key={el?.value}
                        value={el?.value}
                        checked={
                          layout?.selectedFilters[item?.title] === el?.value
                        }
                        onChange={() => handleCheckboxChange(item, el)}
                        className="capitalize"
                      >
                        {el?.label}
                      </Checkbox>
                    </div>
                  ))}
                </div>
              ))}
            </div>
            <div className="h-14 py-1">
              <div className="flex justify-between">
                <PrimaryButton
                  btnName={"Reset"}
                  border
                  className={`border border-[#605BFF] text-[#605BFF] rounded-md p-1 px-3`}
                  onClick={handleReset}
                  isLoading={resetLoading}
                />
                <PrimaryButton
                  btnName={"Submit"}
                  textColor={"white"}
                  onClick={handleSubmit}
                />
              </div>
            </div>
          </div>
          {loading && (
            <div>
              <Loader />
            </div>
          )}
        </div>
      )}
    </div>
  );
};
export default FilterButton;
