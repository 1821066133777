import React from "react";
import BackButton from "../../buttons/BackButton";
import { GrNext } from "react-icons/gr";
import { Breadcrumb } from "antd";

interface TopLeftSideProps {
  currentModule?: string;
  subModule?: string | string[];
  onClick?: () => void;
}

const TopLeftSide = ({
  currentModule,
  subModule,
  onClick,
}: TopLeftSideProps) => {
  return (
    <div className="flex gap-2">
      {subModule && subModule.length > 0 && <BackButton onClick={onClick} />}

      <Breadcrumb
        separator={<GrNext size={15} className="mt-1.5" />}
        items={[
          { title: currentModule },
          ...(Array.isArray(subModule)
            ? subModule
            : subModule
            ? [subModule]
            : []
          ).map((module) => ({
            title: module,
          })),
        ]}
      />
    </div>
  );
};

export default TopLeftSide;
