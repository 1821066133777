import React, { useEffect } from "react";
import { Table } from "antd";
import type { ColumnsType } from "antd/es/table";
import { useSelector, useDispatch } from "react-redux";
import { getAllTransferReport_CSVAction } from "../../../store/csvDownload/actions";
import ScrollableFrame from "../../layout/ScrollableFrame";

interface DataType {
  [x: string]: any;
  key: React.Key;
  name: string;
  age: number;
  address: string;
}

const TransferReportTable = (props: any) => {
  const { auth, report, layout } = useSelector((state: any) => state);
  const dispatch = useDispatch();

  const columns: ColumnsType<DataType> = [
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
    },
    {
      title: "Transfered by (Names)",
      dataIndex: "transferedBy",
      key: "transferedBy",
      render: (text: any, _) => <p className="capitalize">{text}</p>,
    },
    {
      title: "brand",
      dataIndex: "brand",
      key: "brand",
    },
    {
      title: "Model",
      dataIndex: "model",
      key: "model",
    },
    {
      title: "ROM",
      dataIndex: "capacity",
      key: "capacity",
    },
    {
      title: "RAM",
      dataIndex: "memory",
      key: "memory",
    },
    {
      title: "IMEI",
      dataIndex: "imei",
      key: "imei",
    },
    {
      title: "From Shop",
      dataIndex: "fromShop",
      key: "fromShop",
    },
    {
      title: "To Shop",
      dataIndex: "toShop",
      key: "toShop",
    },
  ];

  useEffect(() => {
    if (layout?.openCSVButton)
      auth?.token &&
        getAllTransferReport_CSVAction(
          auth?.token,
          `?startingDate=${props?.startDate}&endingDate=${
            props?.endDate
          }&limit=${report?.transerReport?.total}&page=0${
            layout?.selectedFilters?.shops
              ? `&fromShop=${layout?.selectedFilters?.shops}`
              : ""
          }`
        )(dispatch);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [layout?.openCSVButton]);

  const dataToDisplay = report?.transerReport?.data?.map(
    (el: any, index: any) => {
      return {
        key: index,
        date: el?.date?.slice(0, 10),
        brand: el?.brand,
        model: el?.model,
        imei: el?.imei,
        fromShop: el?.fromShopName?.name,
        toShop: el?.toShopName?.name,
        transferedBy: el?.transferedBy,
        capacity: el?.storage
          ?.filter((item: any) =>
            ["capacity (rom)", "capacity(rom)", "rom", "capacity"]?.includes(
              item[0]?.toLowerCase()
            )
          )
          ?.map((item: any) => item[1]),
        memory: el?.storage
          ?.filter((item: any) =>
            ["memory (ram)", "memory (ram)", "ram", "memory"]?.includes(
              item[0]?.toLowerCase()
            )
          )
          ?.map((item: any) => item[1]),
        data: el,
      };
    }
  );

  return (
    <div className="relative text-black mt-4">
      <ScrollableFrame
        setPage={props?.setPage}
        setLimit={props?.setLimit}
        limit={props?.limit}
        total={report?.transerReportt?.total}
        count={Math.ceil(report?.transerReport?.total / props?.limit)}
      >
        <Table
          columns={columns}
          dataSource={dataToDisplay}
          loading={report?.isFetching}
          pagination={false}
        />
      </ScrollableFrame>
    </div>
  );
};

export default TransferReportTable;
