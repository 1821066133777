import React, { useState } from "react";
import DangerButton from "../buttons/DangerButton";
import PrimaryButton from "../buttons/PrimaryButton";
import ProductDetailsModal from "../Modals/ProductDetailsModal";
import { Carousel, Image } from "antd";
import { useSelector } from "react-redux";
import CustomTypography from "../../styles/globalStyles/CustomTypography";
import CurstomeCardSkeleton from "../skeleton/CurstomeCardSkeleton";

const SidePanelDetails = (props: any) => {
  const { auth, product } = useSelector((state: any) => state);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [dataToUpdate, setDataToupdate] = useState({});
  const [visible, setVisible] = useState(false);

  const handleOnEdit = () => {
    setIsModalOpen(true);
  };

  const handleOnCancel = () => {
    setIsModalOpen(false);
  };
  React.useEffect(() => {
    if (props?.simpleDetails) {
      setDataToupdate({ ...props?.simpleDetails, specs: props?.specsData });
    }
  }, [props?.simpleDetails, props?.specsData]);

  return (
    <>
      <div className="flex gap-5 p-3 border-solid border-2 border-[#0302291A] rounded-xl bg-white h-full mb-5">
        {product?.productIsFetching ? (
          <div className="w-96">
            <CurstomeCardSkeleton />
          </div>
        ) : (
          <>
            <Carousel
              autoplay
              className="bg-white w-32 h-32 rounded-full overflow-clip flex  justify-center items-center"
            >
              {!props?.simpleDetails?.logo &&
                props?.simpleDetails?.images?.map((img: any) => (
                  <div className="bg-white  w-[110px] h-[110px] rounded-full overflow-clip flex  justify-center items-center p-2 ">
                    <Image
                      style={{
                        maxWidth: "100%",
                        maxHeight: "100%",
                        display: "block",
                        objectFit: "contain",
                      }}
                      preview={{ visible: false }}
                      src={img}
                      alt="description"
                      onClick={() => setVisible(true)}
                    />
                  </div>
                ))}
              {props?.simpleDetails?.logo && (
                <div>
                  <Image
                    className="h-auto w-[100%] rounded-lg m-auto"
                    src={props?.simpleDetails?.logo}
                    alt="description"
                  />
                </div>
              )}
            </Carousel>
            <div>
              <CustomTypography variant="h2">
                {" "}
                {props?.simpleDetails?.model}
              </CustomTypography>
              <div className="flex w-full gap-10 mt-3 mb-6">
                <div className="flex flex-col space-y-2">
                  <CustomTypography variant="label">Type</CustomTypography>
                  <CustomTypography variant="label">Brand</CustomTypography>

                  {props?.specsData &&
                    props?.specsData?.map((el: any) => (
                      <CustomTypography variant="label">
                        {el?.label}
                      </CustomTypography>
                    ))}
                </div>
                <div className="flex flex-col space-y-2">
                  <CustomTypography variant="value">
                    {props?.simpleDetails?.type}
                  </CustomTypography>
                  <CustomTypography variant="value" className="capitalize">
                    {props?.simpleDetails?.brand}
                  </CustomTypography>
                  {props?.specsData &&
                    props?.specsData?.map((el: any) => (
                      <CustomTypography variant="value">
                        {el?.value?.join(", ")}
                      </CustomTypography>
                    ))}
                </div>
              </div>

              {auth?.user?.tag !== "mascom" && (
                <div className="flsex gap-2">
                  <PrimaryButton
                    btnName="Edit"
                    onClick={handleOnEdit}
                    paddingY="2.5"
                    style={{ width: "90px", marginRight: "10px" }}
                  />
                  <DangerButton
                    name="Delete"
                    onClick={props?.handleClickDelete}
                  />
                </div>
              )}
            </div>
          </>
        )}
      </div>

      {/* product modal here */}
      <ProductDetailsModal
        component={
          <props.UpdateForm
            data={dataToUpdate}
            // id={dataToUpdate}
            dataToUpdate={dataToUpdate}
            action={"update"}
            onCancel={handleOnCancel}
          />
        }
        isModalOpen={isModalOpen}
        handleOnCancel={handleOnCancel}
      />

      <div style={{ display: "none" }}>
        <Image.PreviewGroup
          preview={{ visible, onVisibleChange: (vis) => setVisible(vis) }}
        >
          {props?.simpleDetails?.images?.map((img: any) => (
            <Image src={img} />
          ))}
        </Image.PreviewGroup>
      </div>
    </>
  );
};

export default SidePanelDetails;
