import React from "react";
import { useSelector } from "react-redux";
import CustomSkeleton from "../../../components/skeleton/CustomSkeleton";
import CustomTypography from "../../../styles/globalStyles/CustomTypography";
type btnProps = {
  cardIcon?: any;
  value?: any;
  title?: any;
  percentage?: any;
};
const Card = ({ cardIcon, value, title, percentage }: btnProps) => {
  const { shop } = useSelector((state: any) => state);
  return (
    <div className="bg-white flex gap-5 p-6 rounded-md">
      <div className="border border-[#615bff25] rounded-full w-[2.6rem] h-[2.6rem] pl-2 pt-2 mt-2">
        <img src={cardIcon} alt="*images" />
      </div>
      <div>
        <CustomTypography variant="h1">
          {shop.isOverviewFetching ? <CustomSkeleton /> : value}
        </CustomTypography>
        <CustomTypography variant="label">{title}</CustomTypography>
      </div>
    </div>
  );
};

export default Card;
