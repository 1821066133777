import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Carousel, Modal, Tag } from "antd";
import DeleteModal from "../../Modals/DeleteModal";
import { deletePlanAction } from "../../../store/channel/actions";
import defaultPic from "../../../assets/nophoto.png";
import AddItem from "../../../pages/dashboard/channels/subscription/AddItems";
import ViewSubscriptionForm from "../../forms/ViewSubscriptionForm";
import ThreeDotDropdown from "./threeDotDropdown";
import { useParams } from "react-router-dom";
import { getDefaultCurrencyCode } from "../../../utils/helper";
import CustomTypography from "../../../styles/globalStyles/CustomTypography";

const SubProductCardGrid = (props: any) => {
  const { auth, channel, layout, company } = useSelector((state: any) => state);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [visible, setVisible] = useState(false);
  const [editSubPrice, setEditSubPrice] = useState(false);
  const dispatch = useDispatch();
  const { subChannelId, priceId } = useParams();

  const handleClickDelete = async () => {
    setVisible(true);
  };

  const handleEditSubPrice = async () => {
    setEditSubPrice(true);
  };
  const handleCancelSubPrice = () => {
    setEditSubPrice(false);
  };
  const handleDeleteModalCancels = () => {
    setVisible(false);
  };

  const deleteProduct = async () => {
    await deletePlanAction(
      auth?.token,
      props?.data._id,
      `?channel=${
        channel?.selected?._id ?? subChannelId
      }&page=0&limit=15&type=${layout?.subscriptionType}`
    )(dispatch);
  };

  const handleOnCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <div
        className={`group shadow rounded-lg p-2 md:p-4 xmx-auto text-gray-900 bg-white relative h-[23rem]`}
      >
        <div className="flex items-center justify-between">
          <CustomTypography variant="h1">
            {props?.data?.pricelistItem?.product?.model}
          </CustomTypography>

          {[
            "admin",
            "finance",
            "finance-manager",
            "dev",
            "b2b-relation",
          ].includes(auth?.user?.role?.toLowerCase()) && (
            <div>
              {auth?.user?.tag !== "mascom" && (
                <ThreeDotDropdown
                  onUpdate={handleEditSubPrice}
                  onDelete={handleClickDelete}
                />
              )}
            </div>
          )}
        </div>
        <p className="text-sm font-normal text-gray-600 flex flex-wrap leading-4">
          {props?.data?.pricelistItem?.specification?.slice(2)?.map(
            (data: any, _index: number) =>
              data[1] && (
                <p className="gapx-3 flex items-center">
                  <CustomTypography variant="label" className="sm:text-sm">
                    {`${data[0]}: `}
                  </CustomTypography>
                  <CustomTypography variant="value" className="sm:text-sm mr-2">
                    {`${data[1]} | `}
                  </CustomTypography>
                </p>
              )
          )}
        </p>
        <div className="flex justify-center mt-4 mb-6">
          {props?.data?.pricelistItem?.product?.images?.length === 0 ? (
            <div>
              <img src={defaultPic} className="w-32 h-32" alt="" />
            </div>
          ) : (
            <Carousel autoplay style={{ height: "128px", width: "128px" }}>
              {props?.data?.pricelistItem?.product?.images?.map((im: any) => (
                <div>
                  <img
                    src={im}
                    width={"100%"}
                    className=" h-36 object-cover"
                    alt=""
                  />
                </div>
              ))}
            </Carousel>
          )}
        </div>
        <div className="mt-6">
          <p className="text-center text-sm text-gray-600">
            {props?.data?.monthlyInstalment && (
              <Tag className="py-0.5">
                <CustomTypography
                  variant="value"
                  className="sm:text-sm font-semibold"
                >
                  {`${props?.data?.monthlyInstalment?.toLocaleString()} ${getDefaultCurrencyCode(
                    company
                  )} `}
                </CustomTypography>
                <CustomTypography variant="label" className="sm:text-xs">
                  /Month
                </CustomTypography>
              </Tag>
            )}
          </p>
          <hr className="my-2" />
          <div className="flex justify-center gap-x-2">
            <div className="flex">
              <CustomTypography
                variant="label"
                className="text-center sm:text-sm"
              >
                Bundle:
              </CustomTypography>
              <CustomTypography
                variant="value"
                className="text-center sm:text-sm"
              >
                {props?.data?.bundle?.[0] || 0}
              </CustomTypography>
            </div>
            <div className="flex">
              <CustomTypography
                variant="label"
                className="text-center sm:text-sm"
              >
                SMS:
              </CustomTypography>
              <CustomTypography
                variant="value"
                className="text-center sm:text-sm"
              >
                {props?.data?.sms?.[0] || 0}
              </CustomTypography>
            </div>
            <div className="flex">
              <CustomTypography
                variant="label"
                className="text-center sm:text-sm"
              >
                Calls:
              </CustomTypography>
              <CustomTypography
                variant="value"
                className="text-center sm:text-sm"
              >
                {props?.data?.call?.[0] || 0}
              </CustomTypography>
            </div>
          </div>
          <hr className="my-2" />
          <CustomTypography variant="h2" className="text-center font-semibold">
            {`For ${props?.data?.duration} Months`}
          </CustomTypography>
        </div>
        <div className="absolute bottom-1 right-0">
          <Tag className="bg-green-200 mx-0 rounded-none border-0">
            {props?.data?.businessProductType}
          </Tag>
        </div>
      </div>

      <Modal
        title={"Edit Pricelist"}
        open={editSubPrice}
        onCancel={handleCancelSubPrice}
        footer={null}
        width={700}
      >
        <AddItem
          dataToUpdate={props?.data}
          onCancel={handleCancelSubPrice}
          setSelectedModelId={priceId}
          selectePlanPlanType={"subscription"}
        />
      </Modal>

      <DeleteModal
        visible={visible}
        onOk={deleteProduct}
        isLoading={channel.isFetching}
        onCancel={handleDeleteModalCancels}
        itemName="Product"
      />
      <Modal
        open={isModalOpen}
        onCancel={handleOnCancel}
        key={isModalOpen ? "modal-open" : "modal-closed"}
        footer={null}
        width={1000}
      >
        <ViewSubscriptionForm data={props?.data} />
      </Modal>
    </>
  );
};

export default SubProductCardGrid;
