import React from "react";
import { Table } from "antd";
import { useSelector } from "react-redux";
import {
  getDefaultCurrencyCode,
  getNewestDateByKey,
} from "../../../utils/helper";
import CSVExportButton from "../../cards/NoSale/CSVExportButton";
import { transactionsByCustomerHeaders } from "../../csvHeaders/Headers";

const TransactionTable = () => {
  const { myZaka, company, CSV, layout, customer } = useSelector(
    (state: any) => state
  );

  const columns = [
    { title: "Date", dataIndex: "date" },
    { title: "Transaction ID", dataIndex: "transactionId" },
    { title: "EWP Transaction ID", dataIndex: "ewpLoanTransactionId" },
    { title: "MSISDN", dataIndex: "msisdn" },
    {
      title: `Amount (${getDefaultCurrencyCode(company)})`,
      dataIndex: "amount",
    },
    {
      title: "Type",
      dataIndex: "type",
      render: (text: any) => <p className="capitalize">{text}</p>,
    },
    { title: "Status", dataIndex: "status" },
  ];
  const data = myZaka?.customerTransaction?.data?.map((el: any) => {
    return {
      key: el._id,
      date: getNewestDateByKey(el?.paymentAttempts, "attemptDate")?.substring(
        0,
        10
      ),
      transactionId: el?.transactionId,
      ewpLoanTransactionId: el?.ewpLoanTransactionId,
      msisdn: el?.msisdn,
      amount: el?.amount,
      type: el?.type?.split("_")?.join(" "),
      status: el?.status,
    };
  });

  return (
    <>
      <div className="overflow-x-auto bg-white">
        <Table
          columns={columns}
          dataSource={data}
          loading={myZaka?.isFetchingTransaction}
        />
      </div>
    </>
  );
};

export default TransactionTable;
