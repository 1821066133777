import React from "react";
import { Skeleton } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import PurchaseOrderGrid from "../../../components/grids/PurchaseOrderGrid";
import { getAllPurchaseOrdersAction } from "../../../store/purchase/actions";
import { PurchaseOrdersHeaders } from "../../../components/csvHeaders/Headers";
import CSVExportButton from "../../../components/cards/NoSale/CSVExportButton";
import { getAllPO_CSVAction } from "../../../store/csvDownload/actions";
import { purchaseIncludeItem } from "../../../assets/data/includedItem";
import SubLayout from "../../../components/layout/subLayout/SubLayout";
import CustomTable from "../../../components/tables/CustomTable";
import { useNavigate } from "react-router-dom";
import { Tag } from "antd";
import { searchValue } from "../../../utils/setColor";

const PurchaseOrder = () => {
  const { auth, purchase, layout, CSV } = useSelector((state: any) => state);
  const [purchaseOrders, setPurchaseOrders] = React.useState<any>([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [page, setPage] = React.useState(1);
  const [limit, setLimit] = React.useState(20);

  React.useEffect(() => {
    auth?.token &&
      getAllPurchaseOrdersAction(
        auth?.token,
        `page=${page - 1}&limit=${limit}&field=${purchaseIncludeItem}`
      )(dispatch);
  }, [auth?.token, dispatch, limit, page]);

  React.useEffect(() => {
    if (layout?.openCSVButton)
      auth?.token &&
        getAllPO_CSVAction(
          auth?.token,
          `limit=${purchase?.all?.total}&page=0`
        )(dispatch);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [layout?.openCSVButton]);

  React.useEffect(() => {
    if (purchase?.all?.data) {
      const updatedPurchaseOrders = purchase?.all?.data?.map((quot: any) => {
        return {
          _id: quot?._id,
          supplier: quot?.supplier?.name,
          poId: quot?.pOrderID,
          poStatus: quot?.status,
          totalAmount: quot?.listOfProducts
            ?.map((product: any) => product?.quantity * product?.priceUnit)
            .reduce((sum: number, a: number) => sum + a),
          deliveryDeadline: quot?.createdAt?.split("T")[0],
          data: quot,
        };
      });
      setPurchaseOrders(updatedPurchaseOrders);
    }
  }, [purchase?.all, setPurchaseOrders]);

  const csvData = CSV?.csvDownloadedData?.data?.map((record: any) => ({
    date: record?.createdAt?.slice(0, 10),
    supplier: record.supplier?.name || "N/A",
    items: record?.listOfProducts[0]?.quotationItem?.product?.model || "N/A",
    quantity: record?.listOfProducts[0]?.quantity?.toLocaleString(),
    cost: parseInt(record?.listOfProducts[0]?.priceUnit)?.toLocaleString(),
    totalCost: record?.listOfProducts
      ?.map((product: any) => product?.quantity * product?.priceUnit)
      .reduce((sum: number, a: number) => sum + a)
      .toLocaleString(),
    deliveryDate: record?.createdAt?.split("T")[0],
  }));

  const columns = [
    { key: "name", label: "Supplier" },
    { key: "poId", label: "Purchase order Id" },
    { key: "deliveryDeadline", label: "delivery Deadline" },
    { key: "totalAmount", label: "Total Amount" },
    {
      key: "status",
      label: "Status",
      render: (_: any, record: any) => (
        <Tag color={searchValue(record.status)} className="w-[6rem] flex py-1 justify-center">{record.status}</Tag>
      ),
    },
  ];

  const purchaseData = purchaseOrders?.map((d: any) => {
    return {
      name: d?.supplier,
      totalAmount: d?.totalAmount,
      poId: d?.poId,
      deliveryDeadline: d?.deliveryDeadline,
      status: d?.poStatus,
      id: d?._id,
    };
  });
  const handleActionClick = (action: string, row: any) => {
    console.log(`Action: ${action}`, row);
  };
  const handleRowClick = (row: any) => {
    navigate(`details/${row?.id}`);
  };

  return (
    <SubLayout
      currentModule="Purchase Order"
      setPage={setPage}
      setLimit={setLimit}
      limit={limit}
      total={purchase?.all?.total}
      count={Math.ceil(purchase?.all?.total / limit)}
      buttons={[
        <CSVExportButton
          csvHeaders={PurchaseOrdersHeaders}
          csvData={csvData}
          filename={`Purchase Order Statistics.csv`}
        />,
      ]}
      hasGridList
    >
      {layout?.isGridView === "grid" ? (
        <div className="flex flex-wrap gap-4 justify-start items-start">
          {purchase?.isFetching ? (
            <div className="flex flex-wrap gap-2 justify-start items-start">
              {[1, 2, 3, 4, 1, 2, 3, 4, 1, 2, 3, 4, 1, 2, 3, 4, 1, 2, 3, 4].map(
                () => (
                  <div className="w-80">
                    <Skeleton animation="wave" />
                    <Skeleton variant="rectangular" height={150} />
                  </div>
                )
              )}
            </div>
          ) : (
            <PurchaseOrderGrid data={purchaseOrders} />
          )}
        </div>
      ) : (
        <CustomTable
          data={purchaseData}
          columns={columns}
          onActionClick={handleActionClick}
          onRowClick={handleRowClick}
          loading={purchase.isFetching}
        />
      )}
    </SubLayout>
  );
};

export default PurchaseOrder;
