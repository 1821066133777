import ProductGrid from "../../../components/grids/ProductGrid";
import React, { useState, useEffect } from "react";
import AddNewProduct from "../../../components/forms/NewProductForm";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllProductsAction,
  getAllAttributesAction,
} from "../../../store/product/actions";
import { myProductActions } from "../../../store/product";
import { ProductsHeaders } from "../../../components/csvHeaders/Headers";
import CSVExportButton from "../../../components/cards/NoSale/CSVExportButton";
import { getAllProducts_CSVAction } from "../../../store/csvDownload/actions";
import { productIncludeItem } from "../../../assets/data/includedItem";
import CurstomeCardSkeleton from "../../../components/skeleton/CurstomeCardSkeleton";
import SubLayout from "../../../components/layout/subLayout/SubLayout";
import InputSearch from "../../../components/inputs/InputSearch";
import PrimaryButton from "../../../components/buttons/PrimaryButton";
import GeneralModal from "../../../components/Modals/GeneralModal";
import CustomTable from "../../../components/tables/CustomTable";
import CustomTypography from "../../../styles/globalStyles/CustomTypography";
import { MdRemoveRedEye } from "react-icons/md";
import DetailsModel from "../../../components/Modals/DetailsModel";
import ProductDetailsCard from "../../../components/cards/ProductDetailsCard";
import { mySupplierActions } from "../../../store/supplier";

const PageView = () => {
  const { product, auth, layout, CSV } = useSelector((state: any) => state);
  const [isViewDetaile, setIsViewDetaile] = useState(false);
  const [itemToDisplay, setItemToDisplay] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [page, setPage] = React.useState(1);
  const [limit, setLimit] = React.useState(15);
  const [productsToDelete, setProductsToDelete] = useState<String[]>([]);
  const dispatch = useDispatch();

  const showModal = () => {
    setIsModalOpen(true);
  };

  dispatch(
    myProductActions.setQuery(
      `?${
        layout?.selectedFilters?.type &&
        `type=${layout?.selectedFilters?.type}${
          layout?.selectedFilters?.brand &&
          `&brand=${layout?.selectedFilters?.brands}`
        }&`
      }`
    )
  );

  useEffect(() => {
    if (auth?.token) {
      getAllAttributesAction(
        auth?.token,
        `?${
          layout?.selectedFilters?.type
            ? `type=${layout?.selectedFilters?.type}`
            : ""
        }`
      )(dispatch);
    }
  }, [auth, limit, page, layout?.selectedFilters?.type, dispatch]);

  useEffect(() => {
    if (auth?.token) {
      getAllProductsAction(
        auth?.token,
        `?limit=${limit}&page=${page - 1}&field=${productIncludeItem}`
      )(dispatch);
    }
  }, [auth, limit, page, dispatch]);

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const handleSelected = (e: any) => {
    const id: String = e.target.getAttribute("id");
    if (e.target.checked) {
      setProductsToDelete([...productsToDelete, id]);
    } else {
      setProductsToDelete(
        productsToDelete.filter((element) => {
          return element !== id;
        })
      );
    }
  };

  useEffect(() => {
    if (layout?.searchText === null) {
      auth?.token &&
        getAllProductsAction(
          auth?.token,
          `?limit=${limit}&page=${page - 1}&field=${productIncludeItem}`
        )(dispatch);
    } else {
      auth?.token &&
        getAllProductsAction(
          auth?.token,
          `?limit=${limit}&page=${page - 1}&sk=${
            layout?.searchText
          }&field=${productIncludeItem}`
        )(dispatch);
    }
  }, [layout?.searchText]);

  useEffect(() => {
    if (layout?.openCSVButton && auth?.token)
      getAllProducts_CSVAction(
        auth?.token,
        `?limit=${product?.all?.total}&page=0&field=${productIncludeItem}`
      )(dispatch);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [layout?.openCSVButton]);

  const csvData = CSV?.csvDownloadedData?.data?.map((record: any) => ({
    type: record.type,
    brand: record?.brand,
    model: record?.model,
    specification: `${record?.specs
      ?.map((d: any) => `${d?.label}: ${d?.value?.join(", ")}`)
      ?.join(", ")}`,
  }));

  const statusData = [
    {
      title: "type",
      subItems: product?.attributes?.types?.map((el: any) => ({
        label: `${el?.value}`,
        value: el?.value,
      })),
    },
    {
      title: "brands",
      subItems: product?.attributes?.brands?.map((el: any) => ({
        label: `${el?.value}`,
        value: el?.value,
      })),
    },
  ];
  const handleSubmit = async () => {
    if (auth?.token) {
      getAllProductsAction(
        auth?.token,
        `?limit=${limit}&page=${page - 1}${
          layout?.selectedFilters?.type &&
          `&type=${layout?.selectedFilters?.type}`
        }${
          layout?.selectedFilters?.brands &&
          `&brand=${layout?.selectedFilters?.brands}`
        }&field=${productIncludeItem}`
      )(dispatch);
      getAllAttributesAction(
        auth?.token,
        `?${
          layout?.selectedFilters?.type &&
          `type=${layout?.selectedFilters?.type}`
        }${
          layout?.selectedFilters?.brands &&
          `&brand=${layout?.selectedFilters?.brands}`
        }`
      )(dispatch);
    }
  };

  const columns = [
    {
      key: "type",
      label: "Type",
      render: (text: any, _record: any) => (
        <p className="capitalize">{text?.toLowerCase()}</p>
      ),
    },
    { key: "brand", label: "Brand" },
    { key: "model", label: "Model" },
    {
      key: "specifications",
      label: "Specifications",
      render: (_text: any, record: any) =>
        record?.specs?.map((data: any) => (
          <div className="flex">
            <CustomTypography variant="label" className="sm:text-[.7rem]">
              {data?.label?.toUpperCase()} :
            </CustomTypography>
            <CustomTypography variant="value" className="sm:text-[.7rem]">
              {data?.value?.join(", ")}
            </CustomTypography>
          </div>
        )),
    },
    { key: "action", label: "Action" },
  ];

  const productDataToDisplay = product?.all?.data?.map((el: any) => {
    return {
      type: el?.type,
      brand: el?.brand,
      model: el?.model,
      specs: el?.specs || [],
      images: el?.images,
    };
  });

  const actions = [
    {
      label: (
        <div>
          <MdRemoveRedEye size={20} />
        </div>
      ),
      key: "view",
      className: "",
    },
  ];

  const handleActionClick = (_action: string, row: any) => {
    setIsViewDetaile(true);
    setItemToDisplay(row);
  };

  useEffect(() => {
    dispatch(myProductActions.setSelected(null));
    dispatch(myProductActions.setProductStore(null));
    dispatch(myProductActions.setSelectedProduct(null));
    dispatch(mySupplierActions.setSuppliersByBrand(null));
  }, []);

  return (
    <>
      <SubLayout
        currentModule="Device"
        handleSubmit={handleSubmit}
        filterData={statusData}
        search={
          <div className="w-full xl:w-[25rem]">
            <InputSearch placeHolder="Search Product" />
          </div>
        }
        loading={product?.isAttributeLoading}
        buttons={[
          <PrimaryButton btnName="Add New Device" onClick={showModal} />,
          <CSVExportButton
            csvHeaders={ProductsHeaders}
            csvData={csvData}
            filename={`Products.csv`}
          />,
        ]}
        setPage={setPage}
        setLimit={setLimit}
        limit={limit}
        total={product?.all?.total}
        count={Math.ceil(product?.all?.total / limit)}
        hasGridList
      >
        <GeneralModal
          onOpen={isModalOpen}
          onClose={handleCancel}
          width={700}
          title={
            <h1 className="text-center text-[#030229] text-lg py-4 font-medium">
              Add Devices
            </h1>
          }
          component={
            <AddNewProduct
              dataToUpdate={null}
              action={"add"}
              onCancel={handleCancel}
            />
          }
        />

        {layout?.isGridView === "grid" && (
          <>
            {product?.productIsLoading ? (
              <div className="grid gap-4 sm:grid-cols-2 xl:grid-cols-3 3xl:grid-cols-4 4xl:grid-cols-6">
                {Array.from({ length: 8 }).map(() => (
                  <CurstomeCardSkeleton />
                ))}
              </div>
            ) : (
              <div className="grid sm:grid-cols-2 xl:grid-cols-3 3xl:grid-cols-4 4xl:grid-cols-6 gap-4">
                {product?.all?.status === 200 && (
                  <ProductGrid
                    handleSelected={handleSelected}
                    data={product?.all?.data}
                  />
                )}
              </div>
            )}
          </>
        )}
        {layout?.isGridView === "list" && (
          <CustomTable
            columns={columns}
            data={productDataToDisplay}
            actions={actions}
            onActionClick={handleActionClick}
            loading={product?.productIsLoading}
          />
        )}
      </SubLayout>
      <DetailsModel
        component={<ProductDetailsCard data={itemToDisplay} />}
        isModalOpen={isViewDetaile}
        handleOnCancel={() => setIsViewDetaile(false)}
      />
    </>
  );
};

export default PageView;
