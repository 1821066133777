import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  DatePicker,
  DatePickerProps,
  Modal,
  Table,
  MenuProps,
  Space,
  Dropdown,
  Badge,
} from "antd";
import { Divider, useMediaQuery } from "@mui/material";
import {
  deleteOneAccountAction,
  getAllAccountAction,
} from "../../../../store/account/actions";
import { useDispatch, useSelector } from "react-redux";
import UpdateAccountForm from "../../../../components/forms/UpdateAccountForm";
import DeleteModal from "../../../../components/Modals/DeleteModal";
import { ColumnsType } from "antd/es/table";
import { searchValue } from "../../../../utils/setColor";
import { myAccounts } from "../../../../store/account";
import { mySubscritionActions } from "../../../../store/subscription/order";
import { useParams } from "react-router-dom";
import { handleClearAllState } from "../../../../utils/converter";
import { myInvoices } from "../../../../store/invoice";
import { handlerSelectTabs } from "../../../../store/layout/actions";
import { accountIncludeItem } from "../../../../assets/data/includedItem";
import CurstomeCardSkeleton from "../../../../components/skeleton/CurstomeCardSkeleton";
import { getAllB2CAccount_CSVAction } from "../../../../store/csvDownload/actions";
import { ReactComponent as PeopleSvg } from "../../../../assets/icons/people.svg";
import { ReactComponent as NoteSvg } from "../../../../assets/icons/stickynote.svg";
import { ReactComponent as EditSvg } from "../../../../assets/edit.svg";
import { ReactComponent as TrashSvg } from "../../../../assets/trash.svg";
import { PiDotsThreeLight } from "react-icons/pi";
import { ReactComponent as DeleteSvg } from "../../../../assets/icons/deleteicon.svg";
import { ReactComponent as EditeSvg } from "../../../../assets/icons/editicon.svg";
import SubLayout from "../../../../components/layout/subLayout/SubLayout";
import CustomTypography from "../../../../styles/globalStyles/CustomTypography";
import PrimaryButton from "../../../../components/buttons/PrimaryButton";
import { LuRefreshCcw } from "react-icons/lu";
import { myLayoutActions } from "../../../../store/layout";

const DetailsPage = (props: any) => {
  const navigate = useNavigate();
  const { auth, account, layout, CSV } = useSelector((state: any) => state);
  const dispatch = useDispatch();
  const { accountId } = useParams();
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [updateData, setUpdateData] = useState<any>("");
  const [deleteDataId, setDeleteDataId] = useState<any>("");
  const [limit, setLimit] = useState<any>(15);
  const [page, setPage] = useState<any>(1);
  const onChange: DatePickerProps["onChange"] = (date, dateString) => {
    console.log(dateString);
  };

  const smallScreen = useMediaQuery("(max-width:900px)");
  const clearActions = [mySubscritionActions.setSelected, myInvoices?.setAll];

  const handleGetItems = () => {
    if (auth?.token) {
      getAllAccountAction(
        auth?.token,
        `?page=${page - 1}&limit=${limit}${
          layout?.selectedFilters?.status
            ? `&status=${layout?.selectedFilters?.status}`
            : ""
        }${
          layout?.selectedFilters?.accounts
            ? `&type=${layout?.selectedFilters?.accounts}`
            : ""
        }&field=${accountIncludeItem}${
          layout?.searchResult !== "" && layout?.searchFrom === "account"
            ? `&sk=${layout?.searchResult}`
            : ""
        }`
      )(dispatch);
    }
  };

  useEffect(() => {
    handleGetItems();
  }, [
    auth?.token,
    dispatch,
    limit,
    page,
    layout?.searchResult,
    layout?.searchFrom,
  ]);

  useEffect(() => {
    if (!accountId) {
      dispatch(myAccounts.setSelected(null));
      dispatch(mySubscritionActions.setSelected(null));
    }
  }, [accountId]);

  const openUpdateModel = (value: any) => {
    setUpdateData(value);
    setIsUpdateModalOpen(true);
  };

  const onCancelUpdateModel = () => {
    setUpdateData("");
    setIsUpdateModalOpen(false);
  };

  const openDeleteModel = (value: any) => {
    setDeleteDataId(value);
    setIsDeleteModalOpen(true);
  };

  const onCancelDeleteModel = () => {
    setDeleteDataId("");
    setIsDeleteModalOpen(false);
  };

  const deleteaccount = async () => {
    await deleteOneAccountAction(auth?.token, deleteDataId, {
      isDeleted: true,
    })(dispatch);
    onCancelDeleteModel();
  };

  interface DataType {
    key: React.Key;
    name: string;
    accountid: string;
    type: string;
    age: number;
    address: string;
  }

  const columns: ColumnsType<DataType> = [
    {
      key: "date",
      title: "Date",
      dataIndex: "date",
    },
    {
      key: "name",
      title: "Name",
      dataIndex: "name",
    },
    {
      key: "accountid",
      title: "Account Id",
      dataIndex: "accountid",
      render: (text) => {
        return <p className="text-[#605BFF]">{text}</p>;
      },
    },
    {
      key: "type",
      title: "Type",
      dataIndex: "type",
    },
    {
      key: "tel",
      title: "  Telephone",
      dataIndex: "tel",
    },
    {
      key: "device",
      title: "devices",
      dataIndex: "device",
    },
    {
      key: "status",
      title: "status",
      dataIndex: "status",
      render: (status) => {
        return (
          <>
            <button
              style={{ background: searchValue(status) }}
              className={`h-[1.5rem] w-[4rem] rounded-md `}
            >
              <p className="font-normal text-md text-white">{status}</p>
            </button>
          </>
        );
      },
    },
    {
      key: "action",
      title: "Action",
      dataIndex: "action",
      className: "disable-navigation",
      render: (_, record: any) => {
        const items: MenuProps["items"] = [
          {
            key: "1",
            label: (
              <label
                onClick={() => openUpdateModel(record?.data)}
                className="flex gap-3 text-[.83rem]"
              >
                <EditeSvg /> Edit
              </label>
            ),
          },
          {
            key: "2",
            label: (
              <label
                onClick={(e) => openDeleteModel(record?._id)}
                className="flex gap-3 text-[.83rem]"
              >
                <DeleteSvg /> Delete
              </label>
            ),
          },
        ];
        return (
          <>
            <div className="flex gap-2">
              <Space direction="vertical">
                <Dropdown menu={{ items }} placement="bottom">
                  <PiDotsThreeLight size={30} />
                </Dropdown>
              </Space>
            </div>
          </>
        );
      },
    },
  ];

  const formData = account?.all?.data?.map((el: any) => {
    return {
      key: el._id,
      name: el?.customer?.firstName
        ? `${el?.customer?.firstName} ${el?.customer?.lastname || ""}`
        : el?.customer?.name,
      accountid: el?.accountID,
      type: el?.type?.toUpperCase(),
      tel: el?.customer?.msisdn || el?.customer?.phone,
      device: el?.deliveryNote?.list?.length,
      date: el?.createdAt?.slice(0, 10),
      time: el?.createdAt?.substring(11, 16),
      status: el?.status,
      data: el,
      action: {
        openUpdateModel,
        openDeleteModel,
      },
    };
  });

  useEffect(() => {
    handleClearAllState(dispatch, clearActions);
    handlerSelectTabs(0)(dispatch);
    dispatch(myLayoutActions.setSelectedFilters({ accounts: "" }));
  }, []);

  useEffect(() => {
    if (layout?.openCSVButton && auth?.token)
      getAllB2CAccount_CSVAction(
        auth?.token,
        `?page=0&limit=${account?.all?.total}${
          layout?.selectedFilters?.status
            ? `&status=${layout?.selectedFilters?.status}`
            : ""
        }${
          layout?.selectedFilters?.accounts
            ? `&type=${layout?.selectedFilters?.accounts}`
            : ""
        }&field=${accountIncludeItem}`
      )(dispatch);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [layout?.openCSVButton]);

  const csvData = CSV?.csvDownloadedData?.data?.map(
    (item: any, index: number) => {
      return {
        no: index + 1,
        date: item?.createdAt?.slice(0, 10),
        name: item?.customerDetails?.name,
        type: item?.type,
        tel: item?.customerDetails?.tel,
        status: item?.status,
      };
    }
  );

  const itemToFilter = [
    {
      title: "accounts",
      subItems: [
        { label: "ALL", value: "" },
        { label: "B2B Accounts", value: "b2b" },
        { label: "B2C Accounts", value: "b2c" },
      ],
    },

    {
      title: "status",
      subItems: [
        { label: "Active", value: "active" },
        { label: "Inactive", value: "inactive" },
      ],
    },
  ];

  return (
    <>
      <SubLayout
        currentModule="Accounts"
        filterData={itemToFilter}
        handleSubmit={handleGetItems}
        datePicker={
          <DatePicker
            onChange={onChange}
            className={"w-60"}
            size="large"
            placeholder="Sort by date"
          />
        }
        buttons={[
          // <CSVExportButton
          //   csvHeaders={B2C_Account_Headers}
          //   csvData={csvData}
          //   filename={`B2C user_files.csv`}
          // />,
          <PrimaryButton
            btnName="Refresh"
            icon={<LuRefreshCcw />}
            onClick={handleGetItems}
            isLoading={account?.isFetching}
          />,
        ]}
        hasGridList
        setLimit={setLimit}
        setPage={setPage}
        limit={limit}
        total={account?.all?.total}
        count={Math.ceil(account?.all?.total / limit)}
      >
        <div className="h-full w-full">
          {(layout?.isGridView === "grid" || smallScreen) && (
            <>
              {account?.isFetching ? (
                <div className="w-full grid sm:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 text-[#030229] gap-4">
                  {Array.from({ length: 12 })?.map((el: any) => (
                    <CurstomeCardSkeleton />
                  ))}
                </div>
              ) : (
                <div className="w-full grid sm:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-4 text-[#030229] px-2">
                  {account?.all?.data?.map((items: any) => (
                    <Badge.Ribbon
                      text={
                        <span
                          style={{
                            fontSize: "12px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            width: "100%",
                            fontWeight: 400,
                            paddingTop: "1.5px",
                          }}
                        >
                          {items?.status}
                        </span>
                      }
                      color={searchValue(items?.status)}
                      style={{ width: "6rem", padding: "3px 1px" }}
                    >
                      <div className="h-[182px] rounded-[10px] border-[.1px] shadow-[1px_4px_24px_0_rgba(192,192,192,0.1)] p-3 px-4 relative bg-white">
                        <div
                          onClick={(event: any) => {
                            const isDisabledDiv = event.target.closest(
                              ".ant-dropdown-trigger"
                            );
                            const isDisabledlabel =
                              event.target.closest(".ant-dropdown-menu");
                            if (!isDisabledDiv && !isDisabledlabel) {
                              navigate(`/subscription/account/${items?._id}`);
                            }
                          }}
                        >
                          <div className=" flex gap-4 mb-4">
                            <div
                              className="w-[52px] h-[52px] rounded-[10px] font-medium text-[16px] text-center pt-3 bg-[#605BFF]"
                              style={{ background: searchValue(items?.type) }}
                            >
                              {items?.type?.toUpperCase()}
                            </div>
                            <div className="flex flex-col gap-2">
                              <CustomTypography
                                variant="value"
                                className="w-[10rem]"
                              >
                                {items?.customer?.firstName
                                  ? `${items?.customer?.firstName?.toUpperCase()} ${
                                      items?.customer?.lastname?.toUpperCase() ||
                                      ""
                                    }`
                                  : items?.customer?.name?.toUpperCase()}
                              </CustomTypography>
                              <p className="text-[#605BFF] text-[12px] font-normal">
                                {items?.accountID}
                              </p>
                            </div>
                          </div>
                          <div className="flex justify-between my-3 mt-4 font-medium">
                            <p className="text-[13px] font-normal">
                              {items?.createdAt?.slice(0, 10)}
                            </p>
                            <p className="text-[13px] font-normal">
                              {items?.deliveryNote?.list?.length || 0} Devices
                            </p>
                          </div>
                          <Divider />
                          <div className="flex gap-3 mt-5 font-medium">
                            <div className="w-[50px] h-[30px] rounded-[6px] border-[.1px] p-1">
                              <Space>
                                <PeopleSvg fontSize={15} />
                                {items?.customerDetails?.otherTel?.length || 0}
                              </Space>
                            </div>
                            <div className="w-[50px] h-[30px] rounded-[6px] border-[.1px] p-1">
                              <Space>
                                <NoteSvg fontSize={15} />
                                {items?.notes?.length || 0}
                              </Space>
                            </div>
                          </div>
                        </div>
                        <div className="flex gap-2 absolute right-4 bottom-4">
                          <EditSvg onClick={() => openUpdateModel(items)} />
                          <TrashSvg
                            onClick={() => openDeleteModel(items?._id)}
                          />
                        </div>
                      </div>
                    </Badge.Ribbon>
                  ))}
                </div>
              )}
            </>
          )}
          {layout?.isGridView === "list" && !smallScreen && (
            <Table
              onRow={(record: any, _rowIndex: any) => {
                return {
                  onClick: (event: any) => {
                    const isDisabledColumn = event.target.closest(
                      ".disable-navigation"
                    );
                    const isDisabledlabel =
                      event.target.closest(".ant-dropdown-menu");
                    if (!isDisabledColumn && !isDisabledlabel) {
                      navigate(`/subscription/account/${record?.key}`);
                    }
                  },
                };
              }}
              columns={columns}
              dataSource={formData}
              pagination={false}
              loading={account?.isFetching}
              className="bg-white overflow-x-auto"
            />
          )}
        </div>
      </SubLayout>
      <Modal
        style={{ maxWidth: "100%" }}
        title="Update Account"
        open={isUpdateModalOpen}
        onCancel={onCancelUpdateModel}
        footer={null}
      >
        <UpdateAccountForm
          dataToUpdate={updateData}
          onCancel={onCancelUpdateModel}
        />
      </Modal>

      <DeleteModal
        visible={isDeleteModalOpen}
        onOk={deleteaccount}
        onCancel={onCancelDeleteModel}
        itemName={"Account"}
        isLoading={account?.isFetching}
      />
    </>
  );
};
export default DetailsPage;
