import CustomTypography from "../../styles/globalStyles/CustomTypography";

const ProductDetailsCard = (props: any) => {
  return (
    <div className="py-10">
      <figure className="flex justify-center h-fit">
        <img
          className="h-60 rounded-lg"
          src={props?.data?.images[0]}
          alt={props?.data?.model}
        />
      </figure>

      <div className="flex w-full gap-10 mt-3 items-center justify-center">
        <div className="flex flex-col space-y-2">
          <CustomTypography variant="label">Type</CustomTypography>
          <CustomTypography variant="label">Brand</CustomTypography>
          <CustomTypography variant="label">Model</CustomTypography>
          {props?.data?.specs?.length > 0 &&
            props?.data?.specs?.map((el: any) => (
              <CustomTypography variant="label">{el?.label}</CustomTypography>
            ))}
        </div>
        <div className="flex flex-col space-y-2">
          <CustomTypography variant="value">
            {props?.data.type}
          </CustomTypography>
          <CustomTypography variant="value">
            {props?.data.brand}
          </CustomTypography>
          <CustomTypography variant="value" className="capitalize">
            {props?.data.model}
          </CustomTypography>
          {props?.data?.specs?.length > 0 &&
            props?.data?.specs?.map((el: any) => (
              <CustomTypography variant="value">
                {el?.value?.join(", ")}
              </CustomTypography>
            ))}
        </div>
      </div>
    </div>
  );
};
export default ProductDetailsCard;
