import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import defaultPic from "../../../assets/nophoto.png";
import CurstomeCardSkeleton from "../../../components/skeleton/CurstomeCardSkeleton";
import { searchValue } from "../../../utils/setColor";
import { Badge } from "antd";
import { getCustomerAccountStatisticsAction } from "../../../store/account/actions";
import { Divider, Stack } from "@mui/material";
import { getDefaultCurrencyCode } from "../../../utils/helper";
import CustomTypography from "../../../styles/globalStyles/CustomTypography";

const CustomerSubscription = () => {
  const navigate = useNavigate();
  const { auth, account, company } = useSelector((state: any) => state);
  const dispatch = useDispatch();
  const { customerId } = useParams();

  useEffect(() => {
    if (auth?.token && customerId) {
      getCustomerAccountStatisticsAction(auth?.token, customerId)(dispatch);
    }
  }, [auth?.token, dispatch, customerId]);

  return (
    <div className="h-full w-full sh relative">
      <div className="gap-4">
        {account?.customerStatIsFetching ? (
          <div className="w-full grid sm:grid-cols-2 xl:grid-cols-3 3xl:grid-cols-4 4xl:grid-cols-6 text-[#030229] gap-4">
            {Array.from({ length: 4 })?.map((el: any) => (
              <CurstomeCardSkeleton />
            ))}
          </div>
        ) : (
          <div className="w-full grid sm:grid-cols-2 xl:grid-cols-3 3xl:grid-cols-4 4xl:grid-cols-6 gap-4 px-2">
            {account?.customerAccountStat?.data?.map((items: any) => (
              <Badge.Ribbon
                key={items?._id}
                text={
                  <span
                    style={{
                      fontSize: "12px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "100%",
                      fontWeight: 400,
                      paddingTop: "1.5px",
                    }}
                    className="capitalize"
                  >
                    {items?.account?.status}
                  </span>
                }
                color={searchValue(items?.account?.status)}
                style={{ width: "5rem", padding: "3px 1px" }}
              >
                <div className="w-full h-[182px] bg-white rounded-[10px] border-[.1px] shadow-[1px_4px_24px_0_rgba(192,192,192,0.1)] p-3 px-4 relative cursor-pointer ">
                  <div
                    onClick={(event: any) => {
                      const isDisabledDiv = event.target.closest(
                        ".ant-dropdown-trigger"
                      );
                      const isDisabledlabel =
                        event.target.closest(".ant-dropdown-menu");
                      if (!isDisabledDiv && !isDisabledlabel) {
                        navigate(
                          `/subscription/account/${items?.account?._id}`
                        );
                      }
                    }}
                  >
                    <div className="flex gap-3 items-center">
                      <div>
                        <img
                          src={defaultPic}
                          className="w-16 h-14 rounded object-cover"
                          alt="Default"
                        />
                      </div>
                      <CustomTypography variant="value">
                        {items?.account?.accountID}
                      </CustomTypography>
                    </div>
                    <div className="flex  justify-between mt-3 text-[12px]">
                      <Stack direction="column" sx={{ alignItems: "center" }}>
                        <CustomTypography
                          variant="label"
                          className="sm:text-xs"
                        >
                          Expected Amount
                        </CustomTypography>
                        <CustomTypography variant="h2" className="mt-2">
                          {getDefaultCurrencyCode(company)}{" "}
                          {items?.totalExpectedAmount?.toLocaleString()}
                        </CustomTypography>
                      </Stack>
                      <Divider orientation="vertical" flexItem />
                      <Stack direction="column" sx={{ alignItems: "center" }}>
                        <CustomTypography
                          variant="label"
                          className="sm:text-xs"
                        >
                          Amount Paid
                        </CustomTypography>
                        <CustomTypography variant="h2" className="mt-2">
                          {getDefaultCurrencyCode(company)}{" "}
                          {items?.totalAmountPaid?.toLocaleString()}
                        </CustomTypography>
                      </Stack>
                      <Divider orientation="vertical" flexItem />
                      <Stack direction="column" sx={{ alignItems: "center" }}>
                        <CustomTypography
                          variant="label"
                          className="sm:text-xs"
                        >
                          Remaining Amount
                        </CustomTypography>
                        <CustomTypography variant="h2" className="mt-2">
                          {getDefaultCurrencyCode(company)}{" "}
                          {items?.remaining?.toLocaleString()}
                        </CustomTypography>
                      </Stack>
                    </div>

                    <CustomTypography className="absolute bottom-3 left-4 sm:text-[11px]">
                      {items?.account?.startingDate?.slice(0, 10)} /{" "}
                      {items?.account?.endingDate?.slice(0, 10)}
                    </CustomTypography>

                    <CustomTypography className="absolute bottom-3 right-4 sm:text-[11px]">
                      {items?.account?.duration} Months
                    </CustomTypography>
                  </div>
                </div>
              </Badge.Ribbon>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};
export default CustomerSubscription;
