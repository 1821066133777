import { useState } from "react";
import { Input, Spin } from "antd";
import type { GetProps } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllWarehouseOutAction,
  getAllWarehouseOutItemsAction,
  updateOneStockOutDetailsAction,
} from "../../../../store/wareHouseOut/actions";
import { useParams } from "react-router-dom";
import {
  createDeliveryActivityAction,
  createOtpActions,
  getOtpActions,
  updateParkingAction,
} from "../../../../store/delivery/actions";
import { IoRefreshCircleOutline } from "react-icons/io5";
import {
  getOneSubsOrderAction,
  updateSubsorderStatusAction,
} from "../../../../store/subscription/order/actions";
import {
  getOnesubDeliveryNoteAction,
  updateSubsDeliveryNoteAction,
} from "../../../../store/subscription/deliverynote/actions";
import { LoadingButton } from "@mui/lab";

type OTPProps = GetProps<typeof Input.OTP>;

const VerifyOtpForm = (props: any) => {
  const { auth, wareHouseOut, tracking, orders } = useSelector(
    (state: any) => state
  );
  const dispatch = useDispatch();
  const [otpValue, setOtpValue] = useState("");
  const [isConfirmedManullay, setIsConfirmedManullay] = useState(false);

  const onChange: OTPProps["onChange"] = (text) => {
    setOtpValue(text);
  };

  const sharedProps: OTPProps = {
    onChange,
  };
  const { id, orderId } = useParams();
  const deliverer =
    wareHouseOut?.warehouseOut?.data[0]?.deliverer ||
    orders?.selected?.account?.deliveryNote?.deliverer;
  const productIdFromLocalStorage = localStorage.getItem("pr_id");
  const deliveryNoteId = orders?.selected?.account?.deliveryNote?._id;
  const trackingId = orders?.selected?.account?.deliveryNote?.trackingId;

  const handleOtpVerification = async () => {
    return await getOtpActions(
      auth?.token,
      `?otp=${otpValue}&phoneNumber=${deliverer?.phone}`
    )(dispatch);
  };

  const handleWarehouseOut = async (hasOtp: string) => {
    if (hasOtp === "no") {
      setIsConfirmedManullay(true);
    } else {
      setIsConfirmedManullay(false);
    }
    await updateOneStockOutDetailsAction(
      auth?.token,
      wareHouseOut?.warehouseOut?.data[0]?._id,
      props?.selectedP,
      { status: "Pickup", otp: hasOtp === "no" ? "" : otpValue }
    )(dispatch);
    await updateParkingAction(
      auth?.token,
      {
        trackingStatus: "Pickup",
      },
      wareHouseOut?.warehouseOut?.data[0]?.trackingId
    )(dispatch);
    await createDeliveryActivityAction(auth?.token, {
      name: "Picked Up",
      status: "Pickup",
      details: "The Parkage is Picked Up",
      trackId: wareHouseOut?.warehouseOut?.data[0]?.trackingId,
    })(dispatch);
    props?.onOk();

    if (auth?.token && productIdFromLocalStorage) {
      getAllWarehouseOutItemsAction(
        auth?.token,
        `requestedItem=${productIdFromLocalStorage}&limit=2000`
      )(dispatch);

      if (id) {
        getAllWarehouseOutAction(
          auth?.token,
          `?warehouseOutId=${id}`
        )(dispatch);
      }
    }
  };

  const handleSubsOrder = async () => {
    if (auth?.token) {
      // await updateParkingAction(
      //   auth?.token,
      //   {
      //     name: "The devices have been picked up",
      //     trackingStatus: "Pickup",
      //     details: "The devices have been picked up",
      //   },
      //   trackingId
      // )(dispatch);
      await updateParkingAction(
        auth?.token,
        {
          trackingStatus: "Pickup",
        },
        wareHouseOut?.warehouseOut?.data[0]?.trackingId
      )(dispatch);
      await createDeliveryActivityAction(auth?.token, {
        name: "Pickup",
        status: "Pickup",
        details: "The Parkage is Picked Up",
        trackId: wareHouseOut?.warehouseOut?.data[0]?.trackingId,
      })(dispatch);
      await updateSubsorderStatusAction(
        orderId as string,
        { status: "pickup" },
        auth.token
      )(dispatch);

      props?.onOk();
      setOtpValue("");

      await updateSubsDeliveryNoteAction(
        deliveryNoteId as string,
        { status: "pickup" },
        auth.token
      )(dispatch);

      await getOneSubsOrderAction(auth?.token, orderId as string)(dispatch);
      await getOnesubDeliveryNoteAction(
        auth?.token,
        deliveryNoteId as string
      )(dispatch);
    }
  };

  const onFinish = async (decision: string) => {
    if (!auth?.token) return;

    const otpVerified = await handleOtpVerification();
    if (otpVerified) {
      if (props?.from === "warehouseOut") {
        await handleWarehouseOut(decision);
      } else {
        await handleSubsOrder();
      }
    }
  };

  const handleConfirmManually = async (decision: string) => {
    await handleWarehouseOut(decision);
  };

  const resendOtp = () => {
    createOtpActions(auth?.token, {
      email: deliverer?.email,
      phoneNumber: deliverer?.phone,
      name: deliverer?.name,
    })(dispatch);
    setOtpValue("");
  };

  return (
    <main className="w-full md:w-1/2 m-auto my-3">
      <h1 className="text-[#030229B2] text-base py-5">Confirmation Code</h1>
      <h1 className="text-[#030229B2] text-base py-5">
        Phone number : <span className="font-bold">{deliverer?.phone}</span>{" "}
      </h1>

      <Input.OTP
        length={4}
        {...sharedProps}
        className="w-32 h-10"
        value={otpValue}
      />
      <p className="text-[#030229B2] text-base py-5">
        Confirm Pickup with code from sms message
      </p>
      {!tracking?.isFetching && (
        <button
          className="flex gap-2 text-[#030229B2] text-lg border border-[#605BFF] py-1 px-3 rounded-md my-2 mb-5"
          onClick={resendOtp}
        >
          {tracking?.sendOtpIsFetching ? (
            <Spin indicator={<LoadingOutlined spin />} className="w-20 py-1" />
          ) : (
            <>
              <IoRefreshCircleOutline color="#605BFF" size={32} />
              <span className="pt-1">Resend Otp</span>
            </>
          )}
        </button>
      )}
      <div className="flex items-center gap-2">
        <LoadingButton
          variant="contained"
          type="submit"
          className="w-full h-12"
          onClick={() => onFinish("yes")}
          loading={
            (orders?.isFetching ||
              tracking?.isFetching ||
              wareHouseOut?.isFetching) &&
            !isConfirmedManullay
          }
          disabled={
            orders?.isFetching ||
            tracking?.isFetching ||
            tracking?.sendOtpIsFetching ||
            wareHouseOut?.isFetching
          }
        >
          Verify OTP
        </LoadingButton>
        {props?.from === "warehouseOut" && (
          <LoadingButton
            variant="outlined"
            className="w-full h-12"
            onClick={() => handleConfirmManually("no")}
            loading={
              (orders?.isFetching ||
                tracking?.isFetching ||
                wareHouseOut?.isFetching) &&
              isConfirmedManullay
            }
            disabled={
              orders?.isFetching ||
              tracking?.isFetching ||
              tracking?.sendOtpIsFetching ||
              wareHouseOut?.isFetching
            }
          >
            Confirm Manually
          </LoadingButton>
        )}
      </div>
    </main>
  );
};

export default VerifyOtpForm;
