import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { notification, Table } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  createStockOutAction,
  UpdateOneStockRequestStatusAction,
} from "../../../../store/wareHouseOut/actions";
import { LoadingButton } from "@mui/lab";
import { myWareHouseOutActions } from "../../../../store/wareHouseOut";

const columns = [
  {
    title: "Type",
    dataIndex: "type",
    key: "type",
  },
  {
    title: "Brand",
    dataIndex: "brand",
    key: "brand",
  },
  {
    title: "Models",
    dataIndex: "model",
    key: "model",
  },
  {
    title: "Description",
    dataIndex: "specification",
    key: "specification",
  },
  {
    title: "Quantity",
    dataIndex: "qty",
    key: "qty",
  },
  // {
  //   title: `Transfer From`,
  //   dataIndex: "shop",
  //   key: "shop",
  // },
];
const WsCreate = () => {
  const dataMapper: any[] = [];
  const [data, setData] = React.useState(dataMapper);
  const { auth, wareHouseOut } = useSelector((state: any) => state);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleWarehouseOut = async () => {
    dispatch(myWareHouseOutActions.setWarehouseOutItem(null));
    dispatch(myWareHouseOutActions.setWarehouseOut(null));
    if (auth?.token) {
      const res = await createStockOutAction(auth?.token, {
        isTransferred: wareHouseOut?.selectedRequest?.data[0]?.isTransferred,
        stockRequest: wareHouseOut?.selectedRequest?.data[0]?._id,
        shop: wareHouseOut?.selectedRequest?.data[0]?.shopId?._id,
      })(dispatch);
      if (res) {
        await UpdateOneStockRequestStatusAction(
          wareHouseOut?.selectedRequest?.data[0]?._id,
          { status: "done" },
          auth?.token
        )(dispatch);
        notification.success({
          message: "Warehouse Created!",
          description: `Id ${res?.warehouseOutId} has been created successfully.`,
        });
        navigate(`/inventory/ws/out/${res?.warehouseOutId}`);
      } else {
        notification.error({ message: "Failed to create Warehouse!" });
      }
    }
  };

  const handleRejectRequest = async () => {
    if (auth?.token) {
      const res = await UpdateOneStockRequestStatusAction(
        wareHouseOut?.selectedRequest?.data[0]?._id,
        { status: "review", isTransferred: "false" },
        auth?.token
      )(dispatch);
      res &&
        notification.success({
          message: "Request Rejected",
          description: "This request has been returned to under review!",
        });
      res && navigate(`/inventory/ws/out`);
    }
  };

  useEffect(() => {
    setData(
      wareHouseOut?.selectedRequest?.data[0]?.list?.map((element: any) => ({
        model: element?.product?.product.model,
        specification: element?.product?.specification
          .slice(2)
          ?.map((el: any) => el[1] && `${el[0]}: ${el[1]}`)
          .join(", "),
        type: element?.product?.product.type,
        brand: element?.product?.product.brand,
        qty: element.qtyApproved,
        shop: element?.shopToTransfer?.name,
      }))
    );
  }, [wareHouseOut.selectedRequest]);

  return (
    <div className="text-black p-5 mt-3 space-y-2">
      <div className="p-5 bg-white rounded-lg space-y-6">
        <Table columns={columns} dataSource={data} style={{ width: "100%" }} />
        {wareHouseOut?.selectedRequest?.data[0]?.status !== "done" && (
          <div>
            {wareHouseOut?.selectedRequest?.data[0]?.isTransferred ? (
              <LoadingButton
                style={{ marginLeft: "20px" }}
                variant="contained"
                onClick={() => handleWarehouseOut()}
                loading={wareHouseOut?.isFetching}
              >
                Create Transfer
              </LoadingButton>
            ) : (
              <LoadingButton
                variant="contained"
                onClick={() => handleWarehouseOut()}
                loading={wareHouseOut?.isFetching}
              >
                Create Warehouse Out
              </LoadingButton>
            )}

            <LoadingButton
              variant="contained"
              style={{ marginLeft: "20px", backgroundColor: "#F50721" }}
              onClick={() => handleRejectRequest()}
              loading={wareHouseOut?.isFetching}
            >
              Reject Request
            </LoadingButton>
          </div>
        )}
      </div>
    </div>
  );
};

export default WsCreate;
