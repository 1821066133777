import * as React from "react";
import { DatePicker } from "antd";
import StockReportTable from "../../../components/tables/report/StockReportTable";
import TransferReportTable from "../../../components/tables/report/TransferReportTable";
import SubLayout from "../../../components/layout/subLayout/SubLayout";
import { useDispatch, useSelector } from "react-redux";
import { DatePickerProps } from "antd/lib";
import type { TimeRangePickerProps } from "antd";
import dayjs, { Dayjs } from "dayjs";
import moment from "moment";
import FilesCard from "../../../components/cards/NoSale/FilesCard";
import CSVExportButton from "../../../components/cards/NoSale/CSVExportButton";
import {
  InventoryTransferReportHeader,
  ShopReportHeader,
} from "../../../components/csvHeaders/Headers";
import { VscFilePdf } from "react-icons/vsc";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import {
  getAllChannelAction,
  getAllShopAction,
} from "../../../store/channel/actions";
import { getAllTransferReportAction } from "../../../store/report/actions";

const { RangePicker } = DatePicker;
const dateFormat = "YYYY/MM/DD";

const InventoryReport = () => {
  const { auth, layout, CSV, channel } = useSelector((state: any) => state);
  const dispatch = useDispatch();

  let today = moment();
  const formattedDate = new Date().toISOString().slice(0, 10);
  let previousDate = today.subtract(1, "days");
  let formattedPreviousDate = previousDate.format("YYYY-MM-DD");

  const [dateFrom, setDateFrom] = React.useState<any>(formattedDate);
  const [startDate, setStartDate] = React.useState<any>(formattedPreviousDate);
  const [endDate, setEndDate] = React.useState<any>(formattedDate);
  const [limit, setLimit] = React.useState(15);
  const [page, setPage] = React.useState(1);

  const rangePresets: TimeRangePickerProps["presets"] = [
    { label: "Last 7 Days", value: [dayjs().add(-7, "d"), dayjs()] },
    { label: "Last 14 Days", value: [dayjs().add(-14, "d"), dayjs()] },
    { label: "Last 30 Days", value: [dayjs().add(-30, "d"), dayjs()] },
    { label: "Last 90 Days", value: [dayjs().add(-90, "d"), dayjs()] },
  ];

  const handleGetItems = () => {
    if (auth?.token) {
      getAllTransferReportAction(
        auth?.token,
        `?startingDate=${startDate}&endingDate=${endDate}&limit=${limit}&page=${
          page - 1
        }${
          layout?.selectedFilters?.shops
            ? `&fromShop=${layout?.selectedFilters?.shops}`
            : ""
        }`
      )(dispatch);
    }
  };

  React.useEffect(() => {
    handleGetItems;
  }, [auth?.token, dispatch, limit, page, startDate, endDate]);

  React.useEffect(() => {
    if (auth?.token) {
      getAllChannelAction(auth?.token)(dispatch);
    }
  }, [auth?.token, dispatch]);

  React.useEffect(() => {
    if (auth?.token && layout?.selectedFilters?.channels) {
      getAllShopAction(
        auth?.token,
        `channel=${layout?.selectedFilters?.channels}`
      )(dispatch);
    }
  }, [auth, layout?.selectedFilters?.channels, dispatch]);

  const onRangeChange = (
    dates: null | (Dayjs | null)[],
    dateStrings: string[]
  ) => {
    if (dates) {
      setStartDate(dateStrings[0]);
      setEndDate(dateStrings[1]);
    }
  };

  const onChange: DatePickerProps["onChange"] = (_date, dateString) => {
    setDateFrom(dateString);
  };

  const handlePDFDownload = async () => {
    const doc = new jsPDF();

    const input = document.getElementById("report-table");
    if (input) {
      const canvas = await html2canvas(input);
      const imgData = canvas.toDataURL("image/png");
      doc.addImage(imgData, "PNG", 10, 10, 190, 0);
      doc.save("Inventory_Report.pdf");
    }
  };

  const csvData = CSV?.csvDownloadedData?.data?.map((el: any) => {
    return {
      date: el?.Date?.slice(0, 10),
      closingStock: el?.currentStoCK,
      openingStock: el?.OpeningStock,
      shop: el?.shopName,
      region: el?.region,
      brand: el?.Brand,
      model: el?.Model,
      capacity:
        el?.Storage?.filter((item: any) =>
          ["capacity", "capacity (rom)", "rom"]?.includes(
            item[0]?.toLowerCase()
          )
        )
          ?.map((item: any) => item[1])
          ?.at(0) ?? "N/A",
      memory:
        el?.Storage?.filter((item: any) =>
          ["memory", "memory (ram)", "ram"]?.includes(item[0]?.toLowerCase())
        )
          ?.map((item: any) => item[1])
          ?.at(0) ?? "N/A",
    };
  });

  const csvDataTransfer = CSV?.csvDownloadedData?.data?.map((el: any) => {
    return {
      date: el?.date?.slice(0, 10),
      brand: el?.brand,
      model: el?.model,
      imei: el?.imei,
      fromShop: el?.fromShopName?.name,
      toShop: el?.toShopName?.name,
      transferedBy: el?.transferedBy,
      capacity: el?.storage
        ?.filter((item: any) =>
          ["capacity (rom)", "capacity(rom)", "rom", "capacity"]?.includes(
            item[0]?.toLowerCase()
          )
        )
        ?.map((item: any) => item[1]),
      memory: el?.storage
        ?.filter((item: any) =>
          ["memory (ram)", "memory (ram)", "ram", "memory"]?.includes(
            item[0]?.toLowerCase()
          )
        )
        ?.map((item: any) => item[1]),
    };
  });

  const items = [
    {
      key: "1",
      label: "Stock Report",
      children: <StockReportTable dateFrom={dateFrom} />,
    },
    {
      key: "2",
      label: "Transfer Report",
      children: (
        <TransferReportTable
          startDate={startDate}
          endDate={endDate}
          setLimit={setLimit}
          setPage={setPage}
          limit={limit}
        />
      ),
    },
  ];

  const itemToFilter = [
    {
      title: "channels",
      subItems: channel?.all?.data?.map((el: any) => {
        return {
          label: el?.name,
          value: el?._id,
        };
      }),
    },
    layout?.selectedFilters?.channels &&
      !channel?.isFetching && {
        title: "shops",
        subItems: channel?.allShop?.data?.map((el: any) => {
          return {
            label: el?.name,
            value: el?._id,
          };
        }),
      },
  ];

  return (
    <SubLayout
      hidePagination
      currentModule="Inventory Report"
      tabs
      items={items}
      datePicker={
        layout?.selectedTabIndex === "1" ? (
          <DatePicker onChange={onChange} allowClear={false} size="large" />
        ) : (
          <RangePicker
            size="large"
            presets={rangePresets}
            onChange={onRangeChange}
            defaultValue={[
              dayjs(startDate, dateFormat),
              dayjs(endDate, dateFormat),
            ]}
          />
        )
      }
      buttons={
        layout?.selectedTabIndex === "1"
          ? [
              <FilesCard
                Icon={VscFilePdf}
                onClick={handlePDFDownload}
                tailwindTextColor="text-white"
                tailwindBackgroundColor="bg-orange-400"
              />,
              <CSVExportButton
                csvHeaders={ShopReportHeader}
                csvData={csvData}
                filename={`Inventory Report.csv`}
              />,
            ]
          : [
              <CSVExportButton
                csvHeaders={InventoryTransferReportHeader}
                csvData={csvDataTransfer}
                filename={`Transfer Report.csv`}
              />,
            ]
      }
      filterData={layout?.selectedTabIndex === "2" ? itemToFilter : []}
      loading={channel?.isFetching}
      handleSubmit={handleGetItems}
    >
      <React.Fragment>
        <span className="sr-only">Inventory Report</span>
      </React.Fragment>
    </SubLayout>
  );
};

export default InventoryReport;
