import React, { useState, useEffect } from "react";
import {Box, useMediaQuery } from "@mui/material";
import ChannelGrid from "../../../components/grids/ChannelGrid";
import AddNewChannel from "../../../components/forms/newChannelForm";
import { useDispatch, useSelector } from "react-redux";
import { getAllChannelAction } from "../../../store/channel/actions";
import CurstomeCardSkeleton from "../../../components/skeleton/CurstomeCardSkeleton";
import SubLayout from "../../../components/layout/subLayout/SubLayout";
import PrimaryButton from "../../../components/buttons/PrimaryButton";
import GeneralModal from "../../../components/Modals/GeneralModal";

const PageView = (props: any) => {
  const { auth, channel, layout } = useSelector((state: any) => state);
  const dispatch = useDispatch();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const smallScreen = useMediaQuery("(min-width:900px) and (max-width:930px)");

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const handleShowModal = () => {
    setIsModalOpen(true);
  };
  useEffect(() => {
    auth?.token && getAllChannelAction(auth?.token)(dispatch);
  }, [auth?.token]);

  return (
    <SubLayout hidePagination
      buttons={
        props?.channelRoute === "channel" &&
        auth?.user?.tag !== "mascom" &&
        !["sales-supervisor"]?.includes(auth?.user?.role)
          ? [
              <PrimaryButton
                btnName="Add new Channel"
                onClick={handleShowModal}
              />,
            ]
          : []
      }
    >
      <Box sx={{ pt: 2 }}>
        {channel?.isFetching ? (
          <div className="grid sm:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 w-full gap-4">
            {Array.from({ length: 4 })?.map((el: any) => (
              <CurstomeCardSkeleton />
            ))}
          </div>
        ) : (
          <div
            className={`grid ${
              layout?.isSideNavOpen && smallScreen
                ? "sm:grid-cols-1 lg:grid-cols-2"
                : "sm:grid-cols-2 lg:grid-cols-3"
            } xl:grid-cols-3 2xl:grid-cols-4 gap-4 w-full`}
          >
            <ChannelGrid
              data={channel?.all?.data}
              route={props?.channelRoute}
            />
          </div>
        )}
      </Box>
      <GeneralModal
        onOpen={isModalOpen}
        onClose={handleCancel}
        width={700}
        title={"Add New Channel"}
        component={<AddNewChannel onCancel={handleCancel} />}
      />
    </SubLayout>
  );
};

export default PageView;
