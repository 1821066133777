import React from "react";
import { Table, Tag } from "antd";
import { searchValue } from "../../../utils/setColor";
import { useSelector } from "react-redux";
import ScrollableFrame from "../../layout/ScrollableFrame";
import dayjs from "dayjs";

const CustomerTransactionTable = (props: any) => {
  const { report } = useSelector((state: any) => state);

  const dataRow = report?.customerTransaction?.data?.map((el: any) => {
    return {
      amountPaid: el?.AmountPaid,
      amountToBePaid: el?.amountToBePaid,
      AmountRemain: el?.AmountRemain,
      customer: `${el?.customer?.firstName ?? " "} ${
        el?.customer?.lastname ?? ""
      }`,
      duration: el?.duration,
      status: el?.status,
      dueDate: dayjs(el?.endingDate).format("YYYY-MM-DD HH:mm:ss"),
      phone:
        el?.customer?.msisdn ||
        el?.customer?.phone ||
        el?.customer?.otherMsisdns?.at(0),
    };
  });

  const columns = [
    {
      title: "Customer Name",
      dataIndex: "customer",
      key: "customer",
    },
    {
      title: "MSISDN",
      dataIndex: "phone",
      key: "phone",
    },
    {
      title: "Amount to be paid",
      dataIndex: "amountToBePaid",
      key: "amountToBePaid",
    },
    {
      title: "Amount paid",
      dataIndex: "amountPaid",
      key: "amountPaid",
    },
    {
      title: "Amount remain",
      dataIndex: "AmountRemain",
      key: "AmountRemain",
    },
    {
      title: "Duration",
      dataIndex: "duration",
      key: "duration",
    },
    {
      title: "Due date",
      dataIndex: "dueDate",
      key: "dueDate",
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (text: any, record: any) => (
        <Tag
          color={searchValue(text)}
          className="capitalize py-0.5 min-w-[60px] text-center"
        >
          {text}
        </Tag>
      ),
    },
  ];

  return (
    <div className="bg-white p-4 text-gray-900">
      <ScrollableFrame
        customSize={500}
        setPage={props?.setPage}
        setLimit={props?.setLimit}
        limit={props?.limit}
        total={report?.customerTransaction?.total}
        count={Math.ceil(report?.customerTransaction?.total / props?.limit)}
      >
        <Table
          dataSource={dataRow}
          columns={columns}
          loading={report?.isFetching}
          pagination={false}
        />
      </ScrollableFrame>
    </div>
  );
};

export default CustomerTransactionTable;
