import React, { useState } from "react";
import SettingButton from "../SettingButton";
import { HiOutlinePlus } from "react-icons/hi";
import { useDispatch, useSelector } from "react-redux";
import BusinessProductModal from "../../../../components/Modals/NewBussiProductModal";
import AddCompanyCurrency from "../../../../components/forms/NewCurrencyForm";
import { Stack } from "@mui/material";
import { Space, Switch } from "antd";
import {
  getOneCompanyAction,
  updateCompanyActions,
} from "../../../../store/setting/company/actions";
import { COMPANY_PROFILE_ID } from "../../../../utils/constants";

const CompanyCurrency = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { auth,company } = useSelector((state: any) => state);
  const dispatch = useDispatch();
  const companyId = COMPANY_PROFILE_ID;

  const handleOpenModal = (values: any) => {
    setIsModalOpen(true);
  };
  const handleCloseModal = (values: any) => {
    setIsModalOpen(false);
  };

  const handleDefaulted = async (selectedCurrencyId: string) => {
    if (!auth?.token) return;

    const existingCurrencies = company?.selected?.currency || [];

    const updatedCurrencies = existingCurrencies.map((currency: any) => ({
      ...currency,
      isDefault: currency._id === selectedCurrencyId,
    }));

    await updateCompanyActions(
      companyId as string,
      { currency: updatedCurrencies },
      auth?.token
    )(dispatch);


    await getOneCompanyAction(companyId as string, auth.token)(dispatch);
  };

  return (
    <div>
      <div className="bg-white mt-6 rounded-md text-[#0F0F47]">
        <div className="sm:flex justify-between mb-6">
          <h1 className="text-sm md:text-base lg:text-lg font-[600] py-4 px-2">
            Company Currency
          </h1>
          <div className=" my-4 mr-6 floaft-right">
            <SettingButton
              btnName="New Currency"
              btnBgColor="[#605BFF]"
              textColor="white"
              icon={<HiOutlinePlus size={20} />}
              onClick={handleOpenModal}
            />
          </div>
        </div>
        <div className="flex flex-wap gap-4">
          {company?.selected?.currency?.map((data: any, _index: any) => (
            <div className="w-[22rem] h-[10rem]  border border-[#030229] border-opacity-10 rounded-[10px] p-5 relative">
              <h1 className="text-[16px] font-medium ">{data?.country}</h1>
              <div className="flex gap-4 absolute top-5 right-6 border border-[#030229] border-opacity-10 px-2 py-1 rounded-md text-sm font-medium bg-gray-100">
                <p>{data?.isDefault ? "On" : "Off"}</p>
                <Switch
                  checked={data?.isDefault}
                  onChange={() => handleDefaulted(data?._id)}
                  size="small"
                />
              </div>
              <div className="mt-6">
                <Stack direction="column" justifyContent={"space-between"}>
                  <Space>
                    <p className="text-sm text-[#030229] opacity-80 font-medium py-1">
                      Currency Code :{" "}
                    </p>
                    <p className="text-sm text-[#03022980]">
                      {data?.currencyCode?.toUpperCase()}
                    </p>
                  </Space>
                  <Space>
                    <p className="text-sm text-[#030229] opacity-80 font-medium py-1">
                      Exchange Rate :{" "}
                    </p>
                    <p className="text-sm text-[#03022980]">
                      {data?.exchangeRate}
                    </p>
                  </Space>
                </Stack>
              </div>
            </div>
          ))}
        </div>
      </div>
      <BusinessProductModal
        title="Add Currency Product"
        cancel={handleCloseModal}
        isModalOpen={isModalOpen}
        content={<AddCompanyCurrency onCancel={handleCloseModal} />}
      />
    </div>
  );
};
export default CompanyCurrency;
