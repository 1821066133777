import React from "react";
import { Tag } from "antd";
import { useSelector } from "react-redux";
import { searchValue } from "../../../../utils/setColor";
import CustomTable from "../../../../components/tables/CustomTable";

const POSStockTable = () => {
  const { product } = useSelector((state: any) => state);
  const columns = [
    {
      key: "price",
      label: "Date",
      render: (text: any, record: any) => (
        <p className="min-w-[80px] text-left">
          {record?.data?.updatedAt?.split("T")[0]}
        </p>
      ),
    },
    {
      key: "specification",
      label: "Specifications",
    },
    {
      key: "barCode",
      label: "IMEI 1",
    },
    {
      key: "imei",
      label: "IMEI 2",
      render: (text: any, record: any) => (
        <>
          <p>{record?.data?.imei2 || "N/A"}</p>
        </>
      ),
    },
    {
      key: "type",
      label: "Supplier",
      render: (text: any, record: any) => (
        <p>{record?.data?.warehouseItem?.po?.supplier?.name ?? "N/A"}</p>
      ),
    },
    {
      key: "station",
      label: "Station",
      render: (text: any, record: any) => (
        <p>
          {record?.data?.shopStock
            ? record?.data?.shopStock?.channel?.name +
              "~" +
              record?.data?.shopStock?.name
            : "In Warehouse"}
        </p>
      ),
    },
    {
      key: "status",
      label: "Status",
      render: (text: any, record: any) => (
        <Tag
          color={searchValue(record?.data?.status)}
          className="capitalize min-w-[60px] text-center"
        >
          {record?.data?.status}{" "}
        </Tag>
      ),
    },
  ];

  const formData = product?.productStore?.map((el: any) => {
    return {
      specification: `${el?.quotationItem?.specification
        ?.slice(2)
        ?.map((d: any) => d[1] && `${d[0]}: ${d[1]}`)
        ?.join(", ")}`,
      status: el?.status,
      barCode: el?.serialNumber,
      data: el,
    };
  });

  return (
    <CustomTable
      columns={columns}
      data={formData}
      loading={product?.currentStockIsFetching}
    />
  );
};

export default POSStockTable;
