import { Paper, Stack, Box } from "@mui/material";
import {
  Table,
  Tag,
  InputNumber,
} from "antd";
import type { ColumnsType } from "antd/es/table";
import { searchValue } from "../../../utils/setColor";
import React, { useState } from "react";
import PrimaryButton from "../../../components/buttons/PrimaryButton";

import { useNavigate } from "react-router-dom";
import DetailsHeaderActionBar from "../../../components/cards/DetailsPage/DetailsHeaderActionBar";

const LabelValue = (props: any) => (
  <Stack direction="row" spacing={2}>
    <span className="font-bold">{props?.label}</span>
    <span> {props?.value}</span>
  </Stack>
);

const ConfirmButtons = (props: any) => {
  return (
    <Stack direction={"row"} spacing={2}>
      <div>
        <PrimaryButton btnName="Validate" />
        <PrimaryButton btnName="Done " />
      </div>
    </Stack>
  );
};

const columns: ColumnsType<any> = [
  {
    title: "Type",
    dataIndex: "type",
    key: "type",
    render: (text) => <a href="/#">{text}</a>,
  },
  {
    title: "Brand",
    dataIndex: "brand",
    key: "brand",
  },
  {
    title: "Models",
    dataIndex: "model",
    key: "model",
    render: (text) => <a href="/#">{text}</a>,
  },
  {
    title: "Description",
    dataIndex: "specification",
    key: "specification",
  },
  {
    title: "Quantity",
    dataIndex: "qty",
    key: "qty",
  },
  {
    title: "Received Quantity",
    dataIndex: "qtyReceived",
    key: "qtyReceived",
    render: (text, record) => (
      <InputNumber
        min={0}
        // disabled= {record.status!=="sent"}
        defaultValue={parseInt(text)}
        onChange={(value: any) => {
          record.qtyReceived = value;
        }}
      />
    ),
  },
];

const PageView = (props: any) => {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const datas = [
    {
      id: 1,
      type: "SmartPhone",
      brand: "Tecno",
      model: "WIN",
      specification: "Capacity:",
      qty: "10",
    },
    {
      id: 2,
      type: "SmartPhone",
      brand: "Tecno",
      model: "WIN",
      specification: "ROM:",
      qty: "20",
    },
  ];

  const goBack = () => {
    navigate(-1);
  };
  return (
    <Stack spacing={1}>
      <div className="text-black pt-2">
        <DetailsHeaderActionBar
          pageName="Warehouse/WIN_0323-001/Validation"
          goBack={goBack}
        />
      </div>
      <Paper elevation={0} style={{ marginBottom: "20px" }}>
        <Stack spacing={0}>
          <Box className="w-[80%] max-h-full bg-white p-8 flex flex-col gap-4 relative">
            <Stack direction={"column"} style={{ marginBottom: "10px" }}>
              <span className="text-2xl font-bold">WIN-0323-001</span>
              <span className="text-xl font-medium ">PO0703231</span>
            </Stack>
            <Stack direction={"row"} justifyContent="space-between">
              <Stack spacing={1}>
                <LabelValue label="Supplier Name:" value={"Name"} />
                <LabelValue label="Supplier Location:" value={"USA"} />
                <LabelValue label="Supplier Contact:" value={"1(45) 7777777"} />
              </Stack>
              <div className="absolute -right-52 top-10">
                <Stack>
                  <Paper elevation={1}>
                    <Stack direction="row" spacing={1}>
                      <h1
                        style={{
                          paddingLeft: "0.5rem",
                          paddingTop: "1px",
                          paddingBottom: "1px",
                        }}
                      >
                        Status
                      </h1>
                      <Tag
                        color={searchValue("Received")}
                        style={{
                          width: "5rem",
                          textAlign: "center",
                          paddingTop: "3px",
                        }}
                      >
                        Arrived
                      </Tag>
                    </Stack>
                  </Paper>
                </Stack>
              </div>
            </Stack>
          </Box>
          <div className="flex mb-2 justify-end items-center">
            <LabelValue label="Delivery Date:" value={"12/02/2023"} />
          </div>
          <Table columns={columns} dataSource={datas} />
        </Stack>
      </Paper>
      <ConfirmButtons isLoading={isLoading} sendButtonDefault={false} />
    </Stack>
  );
};

export default PageView;
