import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import DeliveryNoteGrid from "../../../components/grids/WareHouseInGrid";
import { getAllDeliveryNoteAction } from "../../../store/wareHouse/actions";
import { useMediaQuery } from "@mui/material";
import SubLayout from "../../../components/layout/subLayout/SubLayout";
import { Tag } from "antd";
import { searchValue } from "../../../utils/setColor";
import CustomTable from "../../../components/tables/CustomTable";
import { useNavigate } from "react-router-dom";

const FinanceDeliveryNote = (props: any) => {
  const { wareHouse, auth, layout } = useSelector((state: any) => state);
  const values = wareHouse?.all?.data;
  const isOnTablet = useMediaQuery("(max-width: 960px)");
  const dispatch = useDispatch();
  useEffect(() => {
    auth?.token && getAllDeliveryNoteAction(auth?.token, "?")(dispatch);
  }, [auth?.token, dispatch]);
  const data: {
    id: any;
    deliveryID: any;
    data: any;
    pOrderID: any;
    arrivalDate: any;
    status: any;
    qnt: any;
  }[] = [];

  values &&
    values?.forEach((el: any) => {
      data?.push({
        id: el._id,
        deliveryID: el.deliveryID,
        pOrderID: el.pOrderID.pOrderID,
        arrivalDate: el.arrivalDate,
        status: el?.status,
        data: el,
        qnt: el?.listOfItems?.reduce(
          (sum: number, a: any) => (sum = sum + a?.qtySent),
          0
        ),
      });
    });
  const columns = [
    {
      label: "Name",
      key: "name",
    },
    {
      label: "Purchase Order ID",
      key: "pOrderID",
    },
    {
      label: "Updated date",
      key: "date",
    },
    {
      label: "Status",
      key: "status",
      render: (status: any) => {
        return (
          <>
            <Tag color={searchValue(status)}>{status}</Tag>
          </>
        );
      },
    },
  ];
  const customerdata = wareHouse?.all?.data?.map((el: any) => {
    return {
      key: el?._id,
      name: el?.pOrderID?.supplier?.name,
      pOrderID: el?.pOrderID?.pOrderID,
      date: el?.updatedAt?.split("T")[0],
      status: el?.status,
    };
  });
  const navigate = useNavigate();

  console.log(wareHouse?.all?.data, "wareHouse?.all?.data");

  return (
    <SubLayout currentModule="Delivery Note" hidePagination hasGridList>
      {layout?.isGridView === "grid" ? (
        <div
          className={` grid sm:grid-cols-2 md:${
            isOnTablet ? "grid-cols-2" : "grid-cols-3"
          } xl:grid-cols-4 2xl:grid-cols-5 gap-5 ${
            layout?.isSideNavOpen ? "" : " "
          }`}
        >
          <DeliveryNoteGrid data={data} />
        </div>
      ) : (
        <CustomTable
          columns={columns}
          data={customerdata}
          onRowClick={(record) => navigate(`/inventory/ws/in/${record?.key}`)}
          loading={wareHouse?.isFetching}
        />
      )}
    </SubLayout>
  );
};

export default FinanceDeliveryNote;
