import { Stack, IconButton, Tooltip } from "@mui/material";
import QuotationGrid from "../../../components/grids/QuotationGrid";
import { Spin, Skeleton, Tag } from "antd";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllQuotationSupplierAction,
  getAllQuotationsAction,
  createQuotationAction,
  deleteQuotToSupplierAction,
} from "../../../store/quotation/actions";
import { myQuotationActions } from "../../../store/quotation";
import { rfqIncludeItem } from "../../../assets/data/includedItem";
import SubLayout from "../../../components/layout/subLayout/SubLayout";
import PrimaryButton from "../../../components/buttons/PrimaryButton";
import CustomTable from "../../../components/tables/CustomTable";
import { MdDeleteOutline } from "react-icons/md";
import DeleteModal from "../../../components/Modals/DeleteModal";
import { searchValue } from "../../../utils/setColor";

const AddNewQuotationButton = (props: any) => {
  const navigate = useNavigate();
  const [showDrafted, setShowDrafted] = useState(false);
  const { auth, quotation } = useSelector((state: any) => state);
  const dispatch = useDispatch();
  const handleAddNewQuotation = () => {
    auth?.token &&
      createQuotationAction(auth?.token, {
        isNotify: true,
        notification: {
          action: "RFQ Created",
          role: ["admin", "finance", "finance-manager", "inventory"],
          message: `New RFQ has been Created`,
          title: "Request for Quotation",
        },
      })(dispatch);
  };
  useEffect(() => {
    quotation?.new &&
      navigate(
        `/${props?.route === "purchase" ? "purchase" : "inventory"}/rfq/${
          quotation?.new?.data.quotationId
        }`
      );
  }, [navigate, props?.route, quotation?.new]);

  return (
    <div className="flex gap-5">
      <Tooltip title={showDrafted ? "Hidde Drafted RFQs" : "Show Drafted RFQs"}>
        <IconButton
          // sx={{ p: 1 }}
          aria-label="directions"
          onClick={() => {
            setShowDrafted(!showDrafted);
            props.setShowDrafted(!showDrafted);
          }}
        >
          {showDrafted ? <VisibilityIcon /> : <VisibilityOutlinedIcon />}
        </IconButton>
      </Tooltip>
      <PrimaryButton
        btnName="New RFQ"
        icon={<AddCircleIcon />}
        onClick={() => handleAddNewQuotation()}
        isLoading={quotation?.newIsFetching}
        loadingColor="white"
      />
    </div>
  );
};
const PageView = (props: any) => {
  const [showDrafted, setShowDrafted] = useState(true);
  const [visible, setVisible] = useState(false);
  const [itemToDelete, setItemToDelete] = useState("");
  const { auth, quotation, layout } = useSelector((state: any) => state);
  const dispatch = useDispatch();
  const [limit, setLimit] = useState(24);
  const [page, setPage] = useState(1);
  const navigate = useNavigate();
  dispatch(
    myQuotationActions.setQuery(
      `?${
        layout?.selectedFilters?.type &&
        `status=${layout?.selectedFilters?.type}&`
      }${
        layout?.selectedFilters?.supplier &&
        `supplier=${layout?.selectedFilters?.supplier}&`
      }`
    )
  );

  useEffect(() => {
    dispatch(myQuotationActions.setSelected(null));
    dispatch(myQuotationActions.setNewRfq(null));
    auth?.token &&
      getAllQuotationSupplierAction(
        auth?.token,
        `?limit=${limit}&page=${page - 1}&field=${rfqIncludeItem}`
      )(dispatch);
  }, [auth?.token, dispatch, page, limit]);

  useEffect(() => {
    auth?.token &&
      getAllQuotationsAction(
        auth?.token,
        `?isDraft=true&status=draft`
      )(dispatch);
  }, [auth?.token, quotation.allRfqSupplier.data, dispatch]);

  useEffect(() => {
    dispatch(myQuotationActions.setSelected(null));
  }, [dispatch]);
  const filterData = [
    {
      title: "type",
      subItems: quotation?.filters?.status?.map((el: any) => ({
        label: el.status,
        value: el.status,
      })),
    },
    {
      title: "Supplier",
      subItems: quotation?.filters?.suppliers?.map((el: any) => ({
        label: el?.name.toString().toUpperCase(),
        value: el?._id,
      })),
    },
  ];
  const columns = [
    { key: "name", label: "Supplier" },
    { key: "quotationId", label: "Purchase order Id" },
    { key: "numberOfProduct", label: "Number of Item" },
    { key: "doneBy", label: "Done By" },
    { key: "date", label: "Date" },
    {
      key: "status",
      label: "Status",
      render: (_: any, record: any) => (
        <Tag
          color={searchValue(record.status)}
          className="w-[6rem] flex py-1 justify-center"
        >
          {record.status}
        </Tag>
      ),
    },
    { key: "action", label: "Action" },
  ];

  const quotationData = quotation?.allRfqSupplier?.data?.map((d: any) => {
    return {
      name: d?.supplier?.name,
      totalAmount: d?.totalAmount,
      quotationId: d?.quotation?.quotationId,
      status: d?.status,
      date: d?.confirmationDate,
      numberOfProduct: d?.quotation?.listOfProducts?.length,
      doneBy: d?.createdBy?.names,
      id: d?._id,
    };
  });
  const actions = [
    {
      label: (
        <div>
          <MdDeleteOutline size={20} />
        </div>
      ),
      key: "delete",
      className: "text-red-500 hover:underline",
    },
  ];
  const handleSubmit = () => {
    auth?.token &&
      getAllQuotationSupplierAction(
        auth?.token,
        `?${
          layout?.selectedFilters?.type
            ? `status=${layout?.selectedFilters?.type}`
            : ""
        }${
          layout?.selectedFilters?.supplier
            ? `&supplier=${layout?.selectedFilters?.supplier}`
            : ""
        }&limit=${limit}&page=${page - 1}&field=${rfqIncludeItem}`
      )(dispatch);
  };
  const handleActionClick = (action: string, row: any) => {
    setVisible(true);
    setItemToDelete(row?.id);
    console.log(row, "row");
  };
  const handleCancel = () => {
    setVisible(false);
  };

  const deleteRequest = async () => {
    await deleteQuotToSupplierAction(
      auth?.token,
      itemToDelete,
      {
        isDeleted: true,
      },
      "?limit=15&page=0"
    )(dispatch);
    setItemToDelete("");
    setVisible(false);
  };
  const handleRowClick = (row: any) => {
    console.log(row, "row");
    navigate(`${row?.quotationId}/${row?.id}`);
  };
  return (
    <SubLayout
      currentModule="Request For Quotation"
      setPage={setPage}
      setLimit={setLimit}
      limit={limit}
      total={quotation?.allRfqSupplier?.total}
      count={Math.ceil(quotation?.allRfqSupplier?.total / limit)}
      filterData={filterData}
      handleSubmit={handleSubmit}
      buttons={[<AddNewQuotationButton setShowDrafted={setShowDrafted} />]}
      hasGridList
    >
      {layout?.isGridView === "grid" && (
        <div>
          <div>
            <div className="flex flex-wrap gap-8 justify-start items-start">
              {quotation?.isFetching ? (
                <div className="flex flex-wrap gap-8 justify-start items-start">
                  {[1, 2, 3, 4, 1, 2, 3, 4]?.map(() => (
                    <div className="bg-white w-[360px] h-[154px] border-t-[0.5px] border-t-[rgba(217,219,233,0.5)] rounded-[10px] shadow-[1px_3px_16px_0px_rgba(0,0,0,0.07)] p-6">
                      <Skeleton active paragraph={{ rows: 2 }} />
                    </div>
                  ))}
                </div>
              ) : (
                <QuotationGrid data={quotation?.allRfqSupplier?.data} />
              )}
            </div>
          </div>
          {showDrafted && (
            <div>
              <Stack direction="column">
                <span className="space-x-1 text-[#030229] font-normal">
                  RFQs Drafted :
                  {quotation.isFetching ? (
                    <Spin size="small" className="ml-4" />
                  ) : (
                    quotation?.all?.result
                  )}
                </span>
                {!quotation?.isFetching && (
                  <div className="flex flex-wrap gap-8 justify-start items-start">
                    <QuotationGrid data={quotation?.all?.data} draft={true} />
                  </div>
                )}
              </Stack>
            </div>
          )}
        </div>
      )}
      {layout?.isGridView === "list" && (
        <CustomTable
          columns={columns}
          data={quotationData}
          actions={actions}
          onRowClick={handleRowClick}
          onActionClick={handleActionClick}
          loading={quotation.isFetching}
        />
      )}
      <DeleteModal
        visible={visible}
        onOk={deleteRequest}
        isLoading={quotation?.isFetching}
        onCancel={handleCancel}
        itemName="Stock Item"
      />
    </SubLayout>
  );
};
export default PageView;
