import React from "react";
import { Tabs } from "antd";
import type { TabsProps } from "antd";
import "./tabCss.css";
import { useSelector } from "react-redux";

interface CustomTabsProps {
  items: TabsProps["items"];
  onChange?: (key: string) => void;
}

const CustomTabs: React.FC<CustomTabsProps> = ({ items, onChange }) => {
  const layout = useSelector((state: any) => state?.layout);
  return (
    <div style={{ margin: "auto" }}>
      <Tabs
        defaultActiveKey={layout?.selectedTabIndex}
        onChange={onChange}
        indicator={{ size: (origin) => origin - 20, align: "center" }}
        type="card"
        className="custom-tabs "
        items={items?.map(({ key, label }) => ({ key, label }))}
      />
    </div>
  );
};

export default CustomTabs;
