import React, { useEffect } from "react";
import { ReactComponent as OverviewIcon } from "../../../../assets/icons/dashboard/Icon.svg";
import { useDispatch, useSelector } from "react-redux";
import OverviewChart from "./overviewChart";
import {
  getAllOrderHistoryAction,
  getAllOverViewAction,
  getAllPaymentHistoryAction,
} from "../../../../store/subscription/overview/actions";
import OverViewCard from "./OverViewCard";
import { FaArrowUpLong } from "react-icons/fa6";
import CustomButton from "../../../../components/buttons/CustomButton";
import { FaLongArrowAltDown } from "react-icons/fa";
import PaymentHistorySubscriptionTable from "../../../../components/tables/subscription/PaymenHistorySubscriptionTable";
import { getDefaultCurrencyCode } from "../../../../utils/helper";
import SubLayout from "../../../../components/layout/subLayout/SubLayout";
import CustomTypography from "../../../../styles/globalStyles/CustomTypography";
interface secProp {
  name?: any;
  value?: any;
  bgColor?: any;
}
export const DeviceSecurity = ({ name, value, bgColor }: secProp) => {
  return (
    <>
      <div className="flex gap-3">
        <div
          className={`bg-${bgColor} w-[16px] h-[12px] rounded-sm mt-1`}
        ></div>
        <div>
          <p className="text-[#605BFF] text-[14px] font-medium">{name}</p>
          <p className="text-[#030229] text-[12px] pl-1">{value}</p>
        </div>
      </div>
    </>
  );
};
const PageView = () => {
  const { auth, company } = useSelector((state: any) => state);
  const dispatch = useDispatch();

  useEffect(() => {
    if (auth.token) {
      getAllOverViewAction(auth.token, "?")(dispatch);
      getAllOrderHistoryAction(auth.token, "?")(dispatch);
      getAllPaymentHistoryAction(auth.token, "?")(dispatch);
    }
  }, [dispatch, auth.token]);

  return (
    <SubLayout currentModule="Overview" hidePagination>
      <div className={`w-full flex xl:flex-row flex-col gap-5`}>
        <div className={`w-full flex-1`}>
          <OverviewChart />
        </div>
        <div
          className={`w-full xl:w-[33%] grid md:grid-cols-2 xl:grid-cols-1 gap-3`}
        >
          <OverViewCard
            title={"Total Device Financed"}
            amount={`${getDefaultCurrencyCode(company)} 0`}
            comparedAmount={`${getDefaultCurrencyCode(company)} 0`}
            icon={<OverviewIcon />}
            parcent={<p className="text-[#57C38A]">2.47%</p>}
            textColor="#57C38A"
            icon2={<FaArrowUpLong color="#57C38A" size={15} />}
            compareText={"Increased"}
            description={"All devices sold under device financing"}
          />
          <OverViewCard
            title={"Total Amount Earned"}
            amount={`${getDefaultCurrencyCode(company)} 0`}
            comparedAmount={`${getDefaultCurrencyCode(company)} 0`}
            icon={<OverviewIcon />}
            parcent={<p className="text-[#57C38A]">2.47%</p>}
            textColor={"[#57C38A]"}
            icon2={<FaArrowUpLong color="#57C38A" size={15} />}
            compareText={"Increased"}
            description={
              "Total Amount Earned (New Subscribers) on selected day."
            }
          />
        </div>
      </div>
      <div className="mt-5 grid md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-3 gap-3">
        <OverViewCard
          title={"Instalment Amount Paid"}
          amount={`${getDefaultCurrencyCode(company)} 0`}
          comparedAmount={`${getDefaultCurrencyCode(company)} 0`}
          icon={<OverviewIcon />}
          parcent={<p className="text-[#F1595C]">2.47%</p>}
          textColor="[#57C38A]"
          icon2={<FaLongArrowAltDown color="#F1595C" size={15} />}
          compareText={"Decreased"}
          description={
            "Total amount paid by subscriber with loan active account on selected day."
          }
          btn={
            <CustomButton
              btnName={<p className="text-[12px] font-normal"> View Reports</p>}
              textColor={"[#605BFF]"}
              borderColor={"[#605BFF40]"}
            />
          }
        />
        <OverViewCard
          title={"Outstanding Balance"}
          amount={`${getDefaultCurrencyCode(company)} 0`}
          comparedAmount={`${getDefaultCurrencyCode(company)} 0`}
          icon={<OverviewIcon />}
          icon2={<FaArrowUpLong color="#57C38A" size={15} />}
          description={"Across all customers"}
          textColor="[#57C38A]"
          parcent={<p className="text-[#57C38A]">0%</p>}
          compareText={
            "Total amount expected to be paid by subscribers up to the day selected."
          }
          btn={
            <CustomButton
              btnName={<p className="text-[12px] font-normal"> View Reports</p>}
              textColor={"[#605BFF]"}
              borderColor={"[#605BFF40]"}
            />
          }
        />
        <OverViewCard
          title={"Late Payments Amount"}
          amount={`${getDefaultCurrencyCode(company)} 0`}
          comparedAmount={`${getDefaultCurrencyCode(company)} 0`}
          icon={<OverviewIcon />}
          textColor="[#57C38A]"
          parcent={<p className="text-[#57C38A]">2.47%</p>}
          icon2={<FaArrowUpLong color="#57C38A" size={15} />}
          description={"Across all customers"}
          compareText={"Total amount of missed payment."}
          btn={
            <CustomButton
              btnName={<p className="text-[12px] font-normal"> View Reports</p>}
              textColor={"[#605BFF]"}
              borderColor={"[#605BFF40]"}
            />
          }
        />
      </div>

      <div className="w-full mt-5 text-black xbg-white rounded-md p-5 bg-white">
        <CustomTypography variant="h2" className="pb-3">
          Recent Payment
        </CustomTypography>
        <PaymentHistorySubscriptionTable />
      </div>
    </SubLayout>
  );
};

export default PageView;
