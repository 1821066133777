import React, { useState, useEffect } from "react";
import { Button, Card, Skeleton } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { getAllBusinessAction } from "../../../../store/setting/company/actions";
import {
  getOneChanneltAction,
  updateChannelAction,
} from "../../../../store/channel/actions";
import { SearchIcon } from "lucide-react";

import CreatePaymentCategoryModal from "../../../../components/forms/CreatePaymentCategory";
import {
  getallpaymentcategoryAction,
  getallpaymentsAction,
} from "../../../../store/setting/payments/actions";
import PaymentOptionsComponent from "./paymentOption";
import CreatePaymentMethodModal from "../../../../components/forms/CreatePaymentMethodModal ";

const PaymentProduct = () => {
  const { auth, layout, channel, payments } = useSelector(
    (state: any) => state
  );
  const dispatch = useDispatch();
  const [switchState, setSwitchState] = useState<any>({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalCategory, setIsModalCategory] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    auth?.token && getAllBusinessAction(auth?.token, "?")(dispatch);
    getOneChanneltAction(layout?.channelInfo?._id, auth?.token)(dispatch);
  }, [auth.token, dispatch, layout?.channelInfo?._id]);

  useEffect(() => {
    if (layout?.channelInfo?.options?.businessProduct) {
      const initialSwitchState =
        layout.channelInfo.options.businessProduct.reduce(
          (acc: any, { name, id }: any) => ({
            ...acc,
            [name]: { name, id, isEnabled: true },
          }),
          {}
        );
      setSwitchState(initialSwitchState);
    }
  }, [layout.channelInfo]);

  useEffect(() => {
    auth?.token &&
    getallpaymentsAction(
      auth.token,
      `?channel=${channel?.selected?._id}`
    )(dispatch);
    auth?.token && getallpaymentcategoryAction(auth.token,`?channel=${channel?.selected?._id}`)(dispatch);
   
  }, [dispatch, channel?.selected?._id, auth?.token]);

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const openCategory = () => {
    setIsModalCategory(true);
  };

  const closeCategory = () => {
    setIsModalCategory(false);
  };

  return (
    <div className="text-[#030229] bg-white p-4">
      <div className="flex justify-between mb-6">
        <div className="flex flex-row items-center space-x-2 "></div>
        <div className="sm:flex flex-row items-center">
          <div className="items-center flex flex-row gap-3">
            <div className="relative">
              <input
                type="text"
                className="border border-gray-300 rounded-md py-2 pl-10 pr-4 w-full focus:outline-none focus:ring-2 focus:ring-blue-500 bg-white text-black"
                placeholder="Search..."
                value={searchQuery}
                onChange={handleSearch}
              />
              <SearchIcon className="w-5 h-5 text-gray-400 absolute left-3 top-1/2 transform -translate-y-1/2" />
            </div>
          </div>
          <Button
            className="bg-[#605BFF] text-white cursor-pointer h-9 sm:ml-3 mt-3 sm:mt-0 "
            onClick={openModal}
          >
            + New Payment Method
          </Button>
        </div>
      </div>
      <div>
        <PaymentOptionsComponent searchQuery={searchQuery} />
      </div>
      <CreatePaymentMethodModal isOpen={isModalOpen} onClose={closeModal} />
      <CreatePaymentCategoryModal
        isModalCategory={isModalCategory}
        closeCategory={closeCategory}
      />
    </div>
  );
};

export default PaymentProduct;
