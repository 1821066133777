import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IoHomeOutline, IoBriefcaseOutline } from "react-icons/io5";
import { AiOutlineEdit } from "react-icons/ai";
import { IoLocationOutline } from "react-icons/io5";
import UpdateChannelForm from "./updateChannelForm";
import { Modal, Skeleton, Space } from "antd";
import {
  getAllRegionAction,
  getAllShopAction,
} from "../../../../store/channel/actions";
import { myChannelActions } from "../../../../store/channel";

const ChannelProfilePage = (props: any) => {
  const { auth, layout, channel } = useSelector((state: any) => state);
  const [updateData, setUpdateData] = useState<any>("");
  const [isUpdateModalOpen, setIsUpdateModelOpen] = useState(false);
  const dispatch = useDispatch();
  const channelId = layout?.channelInfo?._id;

  const updateModelOpen = (value: any) => {
    setUpdateData(value);
    setIsUpdateModelOpen(true);
  };
  const updateCancel = () => {
    setUpdateData(" ");
    setIsUpdateModelOpen(false);
  };
  React.useEffect(() => {
    auth?.token &&
      channelId &&
      getAllRegionAction(auth?.token, `channel=${channelId}`)(dispatch);
  }, [dispatch, auth?.token, channelId]);
  React.useEffect(() => {
    auth?.token &&
      channelId &&
      getAllShopAction(auth?.token, `channel=${channelId}`)(dispatch);
    dispatch(myChannelActions.setDeviceWithCommission(null));
  }, [auth?.token, channelId, dispatch]);

  const styles = {
    boxShadow: "0px 10px 20px 0px rgba(194, 194, 194, 0.16)",
    custom: {
      boxShadow: "0px 2px 2px 0px rgba(15, 15, 71, 0.04)",
      border: "1px solid rgba(15, 15, 71, 0.10)",
    },
  };

  const statiData = [
    {
      iconImg: (
        <IoHomeOutline size={19} color="#605BFF" style={{ opacity: ".8" }} />
      ),
      numberValue: channel?.allShop?.total || 0,
      label: "Total Shops",
    },
    {
      iconImg: (
        <IoBriefcaseOutline
          size={19}
          color="#605BFF"
          style={{ opacity: ".8" }}
        />
      ),
      numberValue: layout?.channelInfo?.options?.businessProduct?.length || 0,
      label: "Total Business Products",
    },
    {
      iconImg: (
        <IoLocationOutline
          size={19}
          color="#605BFF"
          style={{ opacity: ".8" }}
        />
      ),
      numberValue: channel?.allRegion?.total || 0,
      label: "Total Regions",
    },
  ];

  return (
    <>
      <div className="text-[#030229] p-1 mt-3 w-full">
        <div className="w-full px-6">
          <div className="flex flex-wrap gap-5 mb-8 ">
            {channel?.isFetching ? (
              <>
                {" "}
                {[1, 2, 3]?.map((el: any) => (
                  <div
                    className="w-[386px] bg-white h-[100px] rounded-[8px] p-4 "
                    style={styles.custom}
                  >
                    {" "}
                    <Skeleton active paragraph={{ rows: 1 }}></Skeleton>
                  </div>
                ))}
              </>
            ) : (
              statiData?.map((data: any, _index: any) => (
                <div
                  className="w-[386px] relative bg-white h-[100px] rounded-[8px] p-4 "
                  style={styles.custom}
                >
                  <div className="flex gap-8 px-2">
                    <div className="mt-[auto] mb-[auto] h-10 w-10 rounded-full border relative inline-flex items-center justify-center">
                      {data?.iconImg}
                    </div>
                    <div className="flex flex-col gap-1 mt-[auto] mb-[auto]">
                      <h1 className="font-semibold text-xl">
                        {data?.numberValue}
                      </h1>
                      <p className="font-normal text-[.9rem] opacity-80">
                        {data?.label}
                      </p>
                    </div>
                  </div>
                </div>
              ))
            )}
          </div>
          <div
            style={styles.custom}
            className="p-4 rounded-[10px] mt-4 flex justify-between"
          >
            <div className="flex gap-4 px-6 ">
              <img
                src={layout?.channelInfo?.logo}
                alt="logo"
                className="w-[3rem] h-[3rem] rounded-full"
              />
              <h1 className="text-[#0F0F47] font-medium text-lg my-[auto]">
                {layout?.channelInfo?.name}
              </h1>
            </div>
            <button
              className="bg-[#E5E5E5] h-7 rounded-[5px] px-2 flex gap-2 pt-1  my-[auto]"
              onClick={() => updateModelOpen(layout?.channelInfo)}
            >
              <AiOutlineEdit color="#0F0F4780" size={18} />
              <span className="text-[#0F0F4780] text-[12px] pt-1 font-normal">
                Edit
              </span>
            </button>
          </div>
          <div className="pt-6">
            <h1 className="text-[#030229] text-lg font-medium py-4">
              Contacts Details
            </h1>
            <div className="flex gap-6">
              {layout?.channelInfo?.contact?.length === 0 ? (
                <h1 className="font-light text-sm">No contacts added !!!!!</h1>
              ) : (
                layout?.channelInfo?.contact?.map((el: any) => (
                  <div
                    className="w-[360px] bg-white h-[160px] rounded-[10px] p-4 flex flex-col "
                    style={styles.custom}
                  >
                    <Space>
                      <p className="text-base text-[#030229] opacity-80 font-medium py-2">
                        Name :{" "}
                      </p>
                      <p className="text-sm text-[#03022980]">{el?.name}</p>
                    </Space>
                    <Space>
                      <p className="text-base text-[#030229] opacity-80 font-medium py-2">
                        Position :
                      </p>
                      <p className="text-sm text-[#03022980]">{el?.position}</p>
                    </Space>
                    <Space>
                      <p className="text-base text-[#030229] opacity-80 font-medium py-2">
                        Phone :
                      </p>
                      <p className="text-sm text-[#03022980]">{el?.phone}</p>
                    </Space>
                  </div>
                ))
              )}
            </div>
          </div>
        </div>
      </div>
      <Modal
        title={"Update Channel"}
        open={isUpdateModalOpen}
        onCancel={updateCancel}
        footer={null}
        className="min-w-min"
      >
        <div>
          <UpdateChannelForm data={updateData} onCancel={updateCancel} />
        </div>
      </Modal>
    </>
  );
};

export default ChannelProfilePage;
