import { myDashboardActions } from ".";
import { myLayoutActions } from "../layout";
import {
  getDaySalesStats,
  getSalesStats,
  getRevenueStats,
  getRegionStats,
  getAllProductsByBrandservice,
  getDataActivationStats,
  getRevenueAndQtyservices,
  getPaymentStats,
  getProductStats,
  getPieceSoldStats,
  getShopStats,
  getAgentStats,
  getAllNosaleStats,
  getCurrentStockStats,
  getKPIStatsService,
  getAllNosaleForSpecificBrandStats,
  getBrandedStockStatsService,
  getBrandedStockStatisticsService,
  getRevenueAndQtyByRangeservices,
  getDataActivationByRangeStat,
  getPaymentRangeStats,
  getBrandedIkosoraStats,
} from "./services";
export const getSalesStatsActions = (token: string, query: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myDashboardActions.setIsFetching(true));
      const res = await getSalesStats(token, query);
      const resRevenue = await getRevenueStats(token, query);
      // console.log(">>>>>>>:: shops", res);
      if (res?.status === 200) {
        dispatch(myDashboardActions.setSalesStats(res));
        dispatch(myDashboardActions.setAllRevenueStats(resRevenue));
        dispatch(myDashboardActions.setIsFetching(false));
      }
      dispatch(myDashboardActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const getSalesStatsByDayActions = (token: string, day: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myDashboardActions.setIsFetching(true));
      const res = await getDaySalesStats(token, day);
      // console.log(">>>>>>>:: Sales per day", res);
      if (res?.status === 200) {
        dispatch(myDashboardActions.setDaySalesStats(res));
        dispatch(myDashboardActions.setIsFetching(false));
      }
      dispatch(myDashboardActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const getCurrentStockActions = (token: string, query: any) => {
  return async (dispatch: any) => {
    try {
      dispatch(myDashboardActions.setIsFetching(true));
      const res = await getCurrentStockStats(token, query);
      // console.log(">>>>>>>:: Sales per day", res);
      if (res?.status === 200) {
        dispatch(myDashboardActions.setCurrentStock(res));
        dispatch(myDashboardActions.setIsFetching(false));
      }
      dispatch(myDashboardActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const getStatsByRegionsActions = (token: string, day: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myDashboardActions.setIsFetching(true));
      const res = await getRegionStats(token, day);
      // console.log(">>>>>>>:: Sales per day", res);
      if (res?.status === 200) {
        dispatch(myDashboardActions.setRegionStats(res));
        dispatch(myDashboardActions.setIsFetching(false));
      }
      dispatch(myDashboardActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const getAllProductsByBrandActions = (token: string, brand: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myDashboardActions.setIsFetching(true));
      const res = await getAllProductsByBrandservice(token, brand);
      // console.log(">>>>>>>:: Sales per day", res);
      if (res?.status === 200) {
        dispatch(myDashboardActions.setProductsByBrand(res));
        dispatch(myDashboardActions.setIsFetching(false));
      }
      dispatch(myDashboardActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const getDataActivationStatsActions = (
  token: string,
  query: string,
  selectedView: any
) => {
  return async (dispatch: any) => {
    try {
      dispatch(myDashboardActions.setIsFetching(true));
      const res =
        selectedView === "day"
          ? await getDataActivationStats(token, query)
          : await getDataActivationByRangeStat(token, query);
      // console.log(">>>>>>>:: Sales per day", res);
      if (res?.status === 200) {
        dispatch(myLayoutActions.setDashboardPreview(selectedView));
        dispatch(myDashboardActions.setDataActivetion(res));
        dispatch(myDashboardActions.setIsFetching(false));
      }
      dispatch(myDashboardActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const getRevenueAndQtyStatsActions = (
  token: string,
  query: string,
  selectedView: string
) => {
  return async (dispatch: any) => {
    try {
      dispatch(myDashboardActions.setIsFetching(true));
      const res =
        selectedView === "day"
          ? await getRevenueAndQtyservices(token, query)
          : await getRevenueAndQtyByRangeservices(token, query);
      if (res?.status === 200) {
        dispatch(myLayoutActions.setDashboardPreview(selectedView));
        dispatch(myDashboardActions.setRevenueAndQtyStats(res));
        dispatch(myDashboardActions.setIsFetching(false));
      }
      dispatch(myDashboardActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const getKPIStatsAction = (query: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myDashboardActions.setIsFetching(true));
      const res = await getKPIStatsService(query);
      if (res?.status === 200) {
        dispatch(myDashboardActions.setKpiStats(res));
        dispatch(myDashboardActions.setIsFetching(false));
      }
      dispatch(myDashboardActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const getProductsStatsActions = (token: string, query: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myDashboardActions.setProductStatsIsFetching(true));
      const res = await getProductStats(token, query);
      // console.log(">>>>>>>:: Sales per day", res);
      if (res?.status === 200) {
        dispatch(myDashboardActions.setProductStats(res));
        dispatch(myDashboardActions.setProductStatsIsFetching(false));
      }
      dispatch(myDashboardActions.setProductStatsIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const getPiecesSoldStatsActions = (token: string, query: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myDashboardActions.setIsFetching(true));
      const res = await getPieceSoldStats(token, query);
      // console.log(">>>>>>>:: Sales per day", res);
      if (res?.status === 200) {
        dispatch(myDashboardActions.setPieceSoldStats(res));
        dispatch(myDashboardActions.setIsFetching(false));
      }
      dispatch(myDashboardActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const getQuantitySoldStatsActions = (token: string, query: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myDashboardActions.setQtySoldStatsIsFetching(true));
      const res = await getPieceSoldStats(token, query);
      // console.log(">>>>>>>:: Sales per day", res);
      if (res?.status === 200) {
        dispatch(myDashboardActions.setQuantitySoldStats(res));
        dispatch(myDashboardActions.setQtySoldStatsIsFetching(false));
      }
      dispatch(myDashboardActions.setQtySoldStatsIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const getIkosoraStatsActions = (token: string, query: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myDashboardActions.setIsFetching(true));
      const res = await getBrandedIkosoraStats(token, query);
      // console.log(">>>>>>>:: Sales per day", res);
      if (res?.status === 200) {
        dispatch(myDashboardActions.setIkosoraStats(res));
        dispatch(myDashboardActions.setIsFetching(false));
      }
      dispatch(myDashboardActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const getShopStatsActions = (token: string, query: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myDashboardActions.setShopStatsIsFetching(true));
      const res = await getShopStats(token, query);
      // console.log(">>>>>>>:: Sales per day", res);
      if (res?.status === 200) {
        dispatch(myDashboardActions.setShopStats(res));
        dispatch(myDashboardActions.setShopStatsIsFetching(false));
      }
      dispatch(myDashboardActions.setShopStatsIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const getAgentStatsActions = (token: string, query: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myDashboardActions.setAgentStatsIsFetching(true));
      const res = await getAgentStats(token, query);
      // console.log(">>>>>>>:: Sales per day", res);
      if (res?.status === 200) {
        dispatch(myDashboardActions.setAgentStats(res));
        dispatch(myDashboardActions.setAgentStatsIsFetching(false));
      }
      dispatch(myDashboardActions.setAgentStatsIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const getAllNosaleStatsActions = (token: string, query: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myDashboardActions.setIsFetching(true));
      const res = await getAllNosaleStats(token, query);
      // console.log(">>>>>>>:: Sales per day", res);
      if (res?.status === 200) {
        dispatch(myDashboardActions.setNosaleStats(res));
        dispatch(myDashboardActions.setIsFetching(false));
      }
      dispatch(myDashboardActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const getAllNosaleStatsforSpecificBrandActions = (
  token: string,
  query: string
) => {
  return async (dispatch: any) => {
    try {
      dispatch(myDashboardActions.setIsFetching(true));
      const res = await getAllNosaleForSpecificBrandStats(token, query);
      if (res?.status === 200) {
        dispatch(myDashboardActions.setNosaleSpecificBrandStats(res));
        dispatch(myDashboardActions.setIsFetching(false));
      }
      dispatch(myDashboardActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const getBrandedStockActions = (token: string, query: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myDashboardActions.setBrandedStockIsFetchingStats(true));
      const res = await getBrandedStockStatsService(token, query);
      if (res?.status === 200) {
        dispatch(myDashboardActions.setBrandedStockStats(res));
        dispatch(myDashboardActions.setBrandedStockIsFetchingStats(false));
      }
      dispatch(myDashboardActions.setBrandedStockIsFetchingStats(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const getBrandedStockStatsActions = (token: string, query: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myDashboardActions.setIsFetching(true));
      const res = await getBrandedStockStatisticsService(token, query);
      if (res?.status === 200) {
        dispatch(myDashboardActions.setBrandedStockStatistics(res));
        dispatch(myDashboardActions.setIsFetching(false));
      }
      dispatch(myDashboardActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};
