import React, { useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  DatePicker,
  Dropdown,
  Menu,
  Radio,
  Table,
} from "antd";
import type { DatePickerProps, TableProps } from "antd";
import { getAllDiscountAction } from "../../../../store/discount/actions";
import { useDispatch, useSelector } from "react-redux";
import {
  getlistloansAction,
  getloanapplicationAction,
  getProviderBalanceAction,
} from "../../../../store/myZaka/actions";
import moment from "moment";
import dayjs from "dayjs";
import DetailsHeaderActionBar from "../../../../components/details/supplier/DetailsPage/DetailsHeaderActionBar";
import CSVExportButton from "../../../../components/cards/NoSale/CSVExportButton";
import { myzakarecentransactionHeaders } from "../../../../components/csvHeaders/Headers";
import { getlistloans_csvAction } from "../../../../store/csvDownload/actions";
import { CiViewColumn } from "react-icons/ci";
import ScrollableFrame from "../../../../components/layout/ScrollableFrame";
import { ensureArrayResponse } from "../../../../utils/helper";
import { MdCurrencyBitcoin } from "react-icons/md";
import SubLayout from "../../../../components/layout/subLayout/SubLayout";

interface DataType {
  loanid: string;
  loantransactionid: string;
  transactioncreated: string;
  externalloanid: string;
  financialtransactionid: string;
  loantransactionstatus: string;
  loantranstatuslastupdate: string;
  loanprovideridentity: string;
  loanprovidername: string;
  loanproviderusername: string;
  consumeridentity: string;
  initiatinguseridentity: string;
  amount: string;
  financialtransactionstatus: string;
  financialtranstatuslastupdate: string;
  externaltransactionstatus: string;
  externaltranstatuslastupdate: string;
}

const MyZakaWallet = () => {
  const { auth, discount, myZaka, CSV, layout } = useSelector(
    (state: any) => state
  );
  const dispatch = useDispatch();

  let today = moment();
  const formattedDate = new Date().toISOString().slice(0, 10);
  let previousDate = today.subtract(7, "days");
  let formattedPreviousDate = previousDate.format("YYYY-MM-DD");
  const [startDate, setStartDate] = React.useState<any>(formattedPreviousDate);
  const [endDate, setEndDate] = React.useState<any>(formattedDate);
  const [limit, setLimit] = useState(15);
  const [page, setPage] = useState(1);

  const [visible, setVisible] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState("");
  const [selectedLoanId, setSelectedLoanId] = React.useState<any>("");

  const myZakadata = ensureArrayResponse(
    myZaka?.listloans?.data?.loantransactions?.loantransaction
  );

  useEffect(() => {
    if (selectedLoanId) {
      localStorage.setItem("selectedLoanId", selectedLoanId);
    } else {
      localStorage.removeItem("selectedLoanId");
    }
  }, [selectedLoanId]);

  const handleRadioChange = (value: any) => {
    setSelectedLoanId(value === "all" ? null : value);
  };

  const handleClearFilter = (confirm: () => void) => {
    setSelectedLoanId(null);
    fetchAllData();
    confirm();
  };

  const fetchAllData = () => {
    getlistloansAction(
      auth.token,
      `?limit=${limit}&page=${page - 1}`
    )(dispatch);
  };

  const initialColumns: TableProps<any>["columns"] = [
    {
      title: <span className="text-sm font-poppins">Loan Id</span>,
      dataIndex: "loanid",
      key: "loanid",
      width: 100,
      ellipsis: true,
      className: "text-center whitespace-nowrap",
      filterDropdown: ({ confirm }) => {
        // Map to include both loan ID and customer first name
        const uniqueLoanOptions = myZaka?.loanId?.data
          ?.map((item: any) => ({
            loanid: item?.loanapplicationid,
            firstname: item?.cart?.customer?.firstName || "Unknown", // Handle undefined firstName
          }))
          .filter(
            (value: any, index: any, self: any) =>
              self.findIndex(
                (v: any) =>
                  v.loanid === value.loanid && v.firstname === value.firstname
              ) === index
          ); // Ensure uniqueness based on both fields

        return (
          <div style={{ padding: 8, maxHeight: 300, overflowY: "auto" }}>
            {uniqueLoanOptions?.map(({ loanid, firstname }: any) => (
              <div
                key={loanid}
                style={{ marginBottom: 8 }}
                className="flex flex-row gap-2"
              >
                <Checkbox
                  checked={selectedLoanId === loanid}
                  onChange={() => {
                    const newSelectedLoanId = loanid;
                    handleRadioChange(newSelectedLoanId);
                    confirm();
                  }}
                >
                  <span>
                    {loanid} - {firstname}
                  </span>
                </Checkbox>
              </div>
            ))}
            <div style={{ marginTop: 8, textAlign: "left" }}>
              <Button
                size="small"
                type="link"
                onClick={() => {
                  handleRadioChange("all");
                  confirm();
                }}
                className="items-center"
              >
                Clear Filter
              </Button>
            </div>
          </div>
        );
      },
      onFilter: (value, record) =>
        value && value !== "all" ? record.loanid === value : true,
      filteredValue:
        selectedLoanId && selectedLoanId !== "all" ? [selectedLoanId] : null,
    },
    {
      title: <span className="text-sm font-poppins">Transaction Created</span>,
      key: "transactioncreated",
      dataIndex: "transactioncreated",
      width: 150,
      ellipsis: true,
      className: "text-center whitespace-nowrap",
    },
    {
      title: <span className="text-sm font-poppins">External Loan Id</span>,
      key: "externalloanid",
      dataIndex: "externalloanid",
      width: 150,
      ellipsis: true,
      className: "text-center whitespace-nowrap",
    },
    {
      title: (
        <span className="text-sm font-poppins">Financial Transaction Id</span>
      ),
      key: "financialtransactionid",
      dataIndex: "financialtransactionid",
      width: 200,
      ellipsis: true,
      className: "text-center whitespace-nowrap",
    },
    {
      title: (
        <span className="text-sm font-poppins">Loan Transaction Status</span>
      ),
      key: "loantransactionstatus",
      dataIndex: "loantransactionstatus",
      width: 200,
      ellipsis: true,
      className: "text-center whitespace-nowrap",
    },
    {
      title: (
        <span className="text-sm font-poppins">
          Loan Transtatus Last Update
        </span>
      ),
      key: "loantranstatuslastupdate",
      dataIndex: "loantranstatuslastupdate",
      width: 200,
      ellipsis: true,
      className: "text-center whitespace-nowrap",
    },
    {
      title: (
        <span className="text-sm font-poppins">Loan Provider Identity</span>
      ),
      key: "loanprovideridentity",
      dataIndex: "loanprovideridentity",
      width: 200,
      ellipsis: true,
      className: "text-center whitespace-nowrap",
    },
    {
      title: <span className="text-sm font-poppins">Loan Provider Name</span>,
      key: "loanprovidername",
      dataIndex: "loanprovidername",
      width: 200,
      ellipsis: true,
      className: "text-center whitespace-nowrap",
    },
    {
      title: (
        <span className="text-sm font-poppins">Loan Provider Username</span>
      ),
      key: "loanproviderusername",
      dataIndex: "loanproviderusername",
      width: 200,
      ellipsis: true,
      className: "text-center whitespace-nowrap",
    },
    {
      title: <span className="text-sm font-poppins">Consumer Identity</span>,
      key: "consumeridentity",
      dataIndex: "consumeridentity",
      width: 200,
      ellipsis: true,
      className: "text-center whitespace-nowrap",
    },
    {
      title: "Initiating User Identity",
      key: "initiatinguseridentity",
      dataIndex: "initiatinguseridentity",
      width: 200,
      ellipsis: true,
      className: "text-center whitespace-nowrap text-sm",
    },
    {
      title: <span className="text-sm font-poppins">Amount</span>,
      key: "amount",
      dataIndex: "amount",
      width: 100,
      ellipsis: true,
      className: "text-center whitespace-nowrap",
    },
    {
      title: (
        <span className="text-sm font-poppins">
          Financial Transaction Status
        </span>
      ),
      key: "financialtransactionstatus",
      dataIndex: "financialtransactionstatus",
      width: 200,
      ellipsis: true,
      className: "text-center whitespace-nowrap",
    },
    {
      title: (
        <span className="text-sm font-poppins">
          Financial Transtatus Last Update
        </span>
      ),
      key: "financialtranstatuslastupdate",
      dataIndex: "financialtranstatuslastupdate",
      width: 200,
      ellipsis: true,
      className: "text-center whitespace-nowrap",
    },
    {
      title: (
        <span className="text-sm font-poppins">
          External Transaction Status
        </span>
      ),
      key: "externaltransactionstatus",
      dataIndex: "externaltransactionstatus",
      width: 200,
      ellipsis: true,
      className: "text-center whitespace-nowrap",
    },
    {
      title: (
        <span className="text-sm font-poppins">
          External Transtatus Last Update
        </span>
      ),
      key: "externaltranstatuslastupdate",
      dataIndex: "externaltranstatuslastupdate",
      width: 200,
      ellipsis: true,
      className: "text-center whitespace-nowrap",
    },
  ];

  const [columns, setColumns] = useState(initialColumns);

  const [selectedColumns, setSelectedColumns] = useState(
    initialColumns.map((col) => ({
      key: col.key,
      title: col.title,
      checked: true,
    }))
  );

  useEffect(() => {
    if (auth.token) {
      getProviderBalanceAction(auth.token)(dispatch);
    }
  }, [dispatch, auth.token]);

  useEffect(() => {
    if (auth.token) {
      getloanapplicationAction(auth.token)(dispatch);
    }
  }, [dispatch, auth.token]);

  useEffect(() => {
    if (auth.token) {
      getProviderBalanceAction(auth.token)(dispatch);
    }
  }, [dispatch, auth.token]);

  useEffect(() => {
    if (auth.token) {
      if (selectedLoanId || selectedStatus) {
        getlistloansAction(
          auth.token,
          `?limit=${limit}&page=${
            page - 1
          }&fromdate=${startDate}&todate=${endDate}&status=${selectedStatus}&loanId=${selectedLoanId}`
        )(dispatch);
      } else {
        getlistloansAction(
          auth.token,
          `?limit=${limit}&page=${page - 1}`
        )(dispatch);
      }
    }
  }, [
    dispatch,
    auth.token,
    limit,
    page,
    selectedStatus,
    startDate,
    endDate,
    selectedLoanId,
  ]);
  const totallength =
    myZaka?.listloans?.data?.loantransactions?.loantransaction.length;

  useEffect(() => {
    if (layout?.openCSVButton && auth?.token)
      getlistloans_csvAction(
        auth.token,
        `?page=${0}&limit=${totallength}`
      )(dispatch);
  }, [layout?.openCSVButton, totallength]);

  useEffect(() => {
    if (auth?.token) {
      getAllDiscountAction(auth?.token, `?`)(dispatch);
    }
  }, [auth?.token, dispatch]);

  const onChangeDate: DatePickerProps["onChange"] = async (
    date: any,
    dateString: any
  ) => {
    setStartDate(dateString);
  };

  const onChangeEndDate: DatePickerProps["onChange"] = async (
    date: any,
    dateString: any
  ) => {
    setEndDate(dateString);
  };

  const disabledDate = (current: any) => {
    if (current && current > moment().endOf("day")) {
      return true;
    }
    return false;
  };

  // Handle checkbox change
  const handleCheckboxChange = (key: string) => {
    setSelectedColumns((prev) =>
      prev.map((col) =>
        col.key === key ? { ...col, checked: !col.checked } : col
      )
    );
  };

  // Apply button logic
  const applyChanges = () => {
    const newColumns = selectedColumns
      .filter((col) => col.checked)
      .map((col) => {
        return initialColumns.find((initCol) => initCol.key === col.key)!;
      });
    setColumns(newColumns);
    setVisible(false);
  };

  const menu = (
    <Menu>
      <div style={{ padding: "8px", maxHeight: "200px", overflowY: "auto" }}>
        {selectedColumns.map((col: any) => (
          <div key={col.key} style={{ marginBottom: "8px" }}>
            <Checkbox
              checked={col.checked}
              onChange={() => handleCheckboxChange(col.key!)}
            >
              {col.title}
            </Checkbox>
          </div>
        ))}
        <Button
          type="primary"
          size="small"
          onClick={applyChanges}
          style={{ marginTop: "8px" }}
        >
          Apply
        </Button>
      </div>
    </Menu>
  );

  const data: DataType[] = myZakadata.map((item: any) => ({
    loanid: item?.loanid,
    loantransactionid: item?.loantransactionid,
    transactioncreated: dayjs(item?.transactioncreated).format(
      "YYYY-MM-DD HH:mm:ss"
    ),
    externalloanid: item?.externalloanid,
    loantransactiontype: item?.loantransactiontype,
    financialtransactionid: item?.financialtransactionid,
    loantransactionstatus: item?.loantransactionstatus,
    loantranstatuslastupdate: dayjs(item?.loantranstatuslastupdate).format(
      "YYYY-MM-DD HH:mm:ss"
    ),
    loanprovideridentity: item?.loanprovideridentity,
    loanprovidername: item?.loanprovidername,
    loanproviderusername: item?.loanproviderusername,
    consumeridentity: item?.consumeridentity,
    initiatinguseridentity: item?.initiatinguseridentity,
    amount: item?.amount?.amount,
    financialtransactionstatus: item?.financialtransactionstatus,
    financialtranstatuslastupdate: dayjs(
      item?.financialtranstatuslastupdate
    ).format("YYYY-MM-DD HH:mm:ss"),
    externaltransactionstatus: item?.externaltransactionstatus,
    externaltranstatuslastupdate: dayjs(
      item?.externaltranstatuslastupdate
    ).format("YYYY-MM-DD HH:mm:ss"),
  }));

  const cvsdata =
    CSV?.csvDownloadedData?.data?.loantransactions?.loantransaction?.map(
      (item: any) => ({
        loanid: item?.loanid,
        loantransactionid: item?.loantransactionid,
        transactioncreated: dayjs(item?.transactioncreated).format(
          "YYYY-MM-DD HH:mm:ss"
        ),
        externalloanid: item?.externalloanid,
        loantransactiontype: item?.loantransactiontype,
        financialtransactionid: item?.financialtransactionid,
        loantransactionstatus: item?.loantransactionstatus,
        loantranstatuslastupdate: item?.loantranstatuslastupdate,
        loanprovideridentity: item?.loanprovideridentity,
        loanprovidername: item?.loanprovidername,
        loanproviderusername: item?.loanproviderusername,
        consumeridentity: item?.consumeridentity,
        initiatinguseridentity: item?.initiatinguseridentity,
        amount: item?.amount?.amount,
        financialtransactionstatus: item?.financialtransactionstatus,
        financialtranstatuslastupdate: dayjs(
          item?.financialtranstatuslastupdate
        ).format("YYYY-MM-DD HH:mm:ss"),
        externaltransactionstatus: item?.externaltransactionstatus,
        externaltranstatuslastupdate: dayjs(
          item?.externaltranstatuslastupdate
        ).format("YYYY-MM-DD HH:mm:ss"),
      })
    );

  return (
    <SubLayout
      currentModule="MyZaka Wallet"
      hidePagination
      buttons={[
        <CSVExportButton
          csvHeaders={myzakarecentransactionHeaders}
          csvData={cvsdata}
          filename={`RecentTransaction.csv`}
        />,
      ]}
      datePicker={
        <div className="flex flex-col sm:flex-row gap-2">
          <div className="flex items-center ">
            <span>From</span>
            <DatePicker
              onChange={onChangeDate}
              defaultValue={dayjs().subtract(7, "day")}
              style={{ width: 120 }}
              disabledDate={disabledDate}
            />
          </div>
          <div className="flex items-center space-x-2">
            <span>To</span>
            <DatePicker
              onChange={onChangeEndDate}
              defaultValue={dayjs()}
              style={{ width: 120 }}
              disabledDate={disabledDate}
            />
          </div>
        </div>
      }
    >
      <div className=" w-[100%] h-auto  flex flex-wrap justify-between   p-2 font-poppins">
        <div className=" bg-white rounded-md  shadow-[0px_4px_24px_0px_rgba(3,2,41,0.06)] h-[150px] w-[280px]">
          <div className=" w-[100%] flex flex-row justify-between">
            <div className="flex flex-row gap-2 ">
              <div className="text-[#030229] p-4">Current Balance</div>
            </div>
            <div className="text-[#030229] p-4">
              {" "}
              <div className="space-x-6 flex"></div>
            </div>
          </div>
          <div className="text-[30px] text-[#030229] p-3 font-poppins">
            {
              myZaka?.providerBalance?.data?.["ns10:getbalanceresponse"]
                ?.balance?.currency
            }{" "}
            {
              myZaka?.providerBalance?.data?.["ns10:getbalanceresponse"]
                ?.balance?.amount
            }
          </div>
        </div>
      </div>

      <div className=" bg-white  shadow-[0px_4px_24px_0px_rgba(3,2,41,0.06)] rounded-md h-auto  ">
        <div className="w-[100%]  flex justify-between px-3  text-[#030229] h-22 items-center">
          <div className="text-[16px] mt-2">Recent Transactions</div>
          {/* <span className="text-[16px] mb-3 mt-2 flex items-center">
            <Dropdown overlay={menu} trigger={["click"]}>
              <Button>
                {" "}
                <CiViewColumn size={20} />
                Customize Columns
              </Button>
            </Dropdown>
          </span> */}
        </div>
        <div className="overflow-x-auto">
          <ScrollableFrame
            setPage={setPage}
            setLimit={setLimit}
            limit={limit}
            total={
              myZaka?.listloans?.data?.loantransactions?.loantransaction?.length
            }
            count={Math.ceil(
              myZaka?.listloans?.data?.loantransactions?.loantransaction
                ?.length / limit
            )}
          >
            <Table
              columns={columns}
              loading={myZaka?.isFetching}
              dataSource={data}
              scroll={{ x: "max-content" }}
              className="table-auto"
              pagination={false}
            />
          </ScrollableFrame>
        </div>
      </div>
    </SubLayout>
  );
};

export default MyZakaWallet;
