import React from "react";
import { Tag } from "antd";
import { searchValue } from "../../utils/setColor";

interface tagDataType {
  title: string;
  color: string;
  size: string;
  className?: string;
}

const CustomTag = ({ title, color, className, size }: tagDataType) => {
  return (
    <Tag
      color={searchValue(color || "Pending")}
      className={`${className} capitalize min-w-20 text-center ${
        size === "large" ? "text-[10px] py-0.5 " : "text-[14px] py-1"
      }`}
    >
      {title}
    </Tag>
  );
};

export default CustomTag;
