import React from "react";
import { myLayoutActions } from "../../store/layout";
import { useDispatch, useSelector } from "react-redux";

interface searchProps {
  placeHolder?: string;
  borderColor?: string;
  focusBorder?: string;
}

const InputSearch = ({
  placeHolder = "Search",
  borderColor = "[#E4E4E7]",
  focusBorder = "[#E4E4E7]",
}: searchProps) => {
  const { layout } = useSelector((state: any) => state);
  const dispatch = useDispatch();

  return (
    <form className="w-full mx-auto">
      <div className="relative">
        <input
          type="search"
          id="default-search"
          value={layout?.searchText}
          className={`block w-full p-2.5 ps-3 text-sm text-gray-900 border border-${borderColor} rounded-lg bg-gray-50  focus:border-${focusBorder} focus:outline-none`}
          placeholder={placeHolder}
          onChange={(e) =>
            dispatch(myLayoutActions.setSearchText(e.target.value))
          }
        />
      </div>
    </form>
  );
};

export default InputSearch;
