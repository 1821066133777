import React, { useEffect } from "react";
import { Space, Tag } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { getAllRepairsAction } from "../../../store/repair/actions";
import { ReactComponent as Repairs } from "../../../assets/icons/repairs.svg";
import { Table } from "antd";
import type { TableColumnsType } from "antd";
import { searchValue } from "../../../utils/setColor";
import defaultPic from "../../../assets/nophoto.png";
import SubLayout from "../../../components/layout/subLayout/SubLayout";
import CustomTypography from "../../../styles/globalStyles/CustomTypography";
import CardLayout from "../../../components/cards/Layout/CardLayout";

interface DataType {
  key: React.Key;
  device: string;
  foto: string;
  imei: any;
  shop: string;
  agent: string;
  status: any;
}

const RepairOverview = (props: any) => {
  const { auth, repairs } = useSelector((state: any) => state);
  const dispatch = useDispatch();

  const repair = [
    {
      label: "All  Devices",
      value: repairs?.all?.total || 0,
    },
    {
      label: "Completed",
      value:
        repairs?.all?.data?.filter((c: any) => c?.status === "completed")
          ?.length || 0,
    },
    {
      label: "In Transit",
      value:
        repairs?.all?.data?.filter((c: any) => c?.status === "transit-out")
          ?.length || 0,
    },
    {
      label: "Pending",
      value:
        repairs?.all?.data?.filter((c: any) => c?.status === "pending")
          ?.length || 0,
    },
    {
      label: "Cancelled",
      value:
        repairs?.all?.data?.filter((c: any) => c?.status === "cancelled")
          ?.length || 0,
    },
  ];

  const columns: TableColumnsType<DataType> = [
    {
      title: "Device",
      dataIndex: "device",
      key: "device",
      render: (text, record) => {
        return (
          <Space>
            <img
              className="w-10 h-10 rounded-full"
              src={record?.foto ? record?.foto : defaultPic}
              alt="customer avatar"
            />
            <p className="capitalize"> {text?.toLowerCase()}</p>
          </Space>
        );
      },
    },
    {
      title: "IMEI",
      dataIndex: "imei",
      key: "imei",
    },
    {
      title: "Shop",
      dataIndex: "shop",
      key: "shop",
    },
    {
      title: "Agent",
      dataIndex: "agent",
      key: "agent",
      render: (text, _) => <p className="capitalize">{text}</p>,
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (text, _) => {
        return (
          <Tag
            className="min-w-20 py-1 capitalize text-center"
            color={searchValue(text)}
          >
            {text}
          </Tag>
        );
      },
    },
  ];

  useEffect(() => {
    auth?.token &&
      getAllRepairsAction(auth?.token, `?page=0&limit=500`)(dispatch);
  }, [auth?.token, dispatch]);

  const recentactivities = repairs?.all?.data?.slice(0, 5)?.map((el: any) => {
    return {
      key: el?._id,
      device: el?.cartItemId?.priceProduct?.product?.model?.toLowerCase(),
      imei: el?.cartItemId?.warehouseProduct?.serialNumber,
      shop: el?.shop?.name,
      agent: el?.createdBy?.names?.toLowerCase(),
      foto: el?.cartItemId?.priceProduct?.product?.images?.at(0),
      status: el?.status,
    };
  });

  return (
    <SubLayout currentModule="Overview" hidePagination>
      <CardLayout isLoading={repairs?.isFetching} initialItems={5}>
        {repair?.map((data: any) => (
          <div className="bg-white p-6 flex gap-6 rounded-lg">
            <Repairs />
            <div>
              <CustomTypography variant="h1">{data?.value}</CustomTypography>
              <CustomTypography variant="label">{data?.label}</CustomTypography>
            </div>
          </div>
        ))}
      </CardLayout>
      <div className="w-full bg-white rounded-lg p-4 mt-6">
        <CustomTypography variant="h2" className="mb-2">
          Recent Activities
        </CustomTypography>
        <Table
          columns={columns}
          dataSource={recentactivities}
          pagination={false}
          scroll={{ x: true }}
          loading={repairs?.isFetching}
        />
      </div>
    </SubLayout>
  );
};

export default RepairOverview;
