import React from "react";
import { Tag } from "antd";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Stack } from "@mui/material";
import CustomTypography from "../../styles/globalStyles/CustomTypography";
import CustomTag from "../buttons/Tag";

const ShopCard = (props: any) => {
  const { layout } = useSelector((state: any) => state);
  const navigate = useNavigate();

  return (
    <div
      className={`relative bg-white text-black border border-white hover:border-gray-200 h-[160px] rounded-md`}
    >
      <div
        className="p-2 2xl:pl-5 "
        onClick={() => navigate(`${props?.data?.id}`)}
      >
        <div className="flex w-full">
          <div className="w-full">
            <div className="flex ">
              <div className="flex-1 w-full">
                <CustomTypography variant="h2">
                  {props?.data?.name}
                </CustomTypography>
              </div>
              <div>
                <CustomTag
                  title={props?.data?.status}
                  color={props?.data?.status}
                  size="large"
                />
              </div>
            </div>
            <CustomTypography
              variant="value"
              className="text-blue-500 sm:text-sm"
            >
              {props?.data?.region} - {props?.data?.address}
            </CustomTypography>
            <div className="my-2">
              {props?.data?.merchantName && (
                <div>
                  <CustomTypography variant="label">merchant:</CustomTypography>
                  <CustomTypography variant="value">
                    {props?.data?.merchantName}
                  </CustomTypography>
                </div>
              )}
              {props?.data?.msisdn && (
                <div>
                  <CustomTypography variant="label">msisdn:</CustomTypography>
                  <CustomTypography variant="value">
                    {props?.data?.msisdn}
                  </CustomTypography>
                </div>
              )}
              {props?.data?.momocode && (
                <div>
                  <CustomTypography variant="label">
                    myzaka code:
                  </CustomTypography>
                  <CustomTypography variant="value">
                    {props?.data?.momocode}
                  </CustomTypography>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="flex flex-col">
          <div className="flex flex-col">
            {props?.data?.agents?.map((agent: any, _index: number) => (
              <>
                <span>{agent.name}</span>
                <span>{agent.phone}</span>
              </>
            ))}
          </div>

          <p className="text-xs">{props?.data?.phone}</p>
        </div>
        <div>
          <div
            className={`absolute bottom-2  ${
              layout?.isSideNavOpen
                ? " flex gap-2 md:block lg:flex"
                : "flex gap-2 "
            }`}
          >
            <Stack alignItems={"center"} spacing={2} direction={"row"}>
              <Tag
                className="capitalize "
                color={props?.data?.type === "connect-shop" ? "cyan" : "lime"}
              >
                {props?.data?.type?.split("-")?.join(" ")}
              </Tag>
              {props?.data?.data?.shopLocation?.district && (
                <Tag className="capitalize">
                  {props?.data?.data?.shopLocation?.district}
                </Tag>
              )}
            </Stack>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShopCard;
