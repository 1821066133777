import { Tabs } from "antd";
import type { TabsProps } from "antd";
import { useSelector } from "react-redux";
import ProductDetailsCard from "./productDetailsCard";
import History from "./history";
import DetailsHeaderActionBar from "../../../components/details/supplier/DetailsPage/DetailsHeaderActionBar";
import ProductPurchaseOrder from "./productPO";
import ProductInvoiceDetails from "./productInvoice";
import InventoryItemSearch from "../POS/InventoryItemSearch";
import ViewLayout from "../../../components/layout/subLayout/ViewLayout";

const ProductHistoryDetails = (props: any) => {
  const { inventory, auth } = useSelector((state: any) => state);
  const alloweRole = auth?.user?.role;

  const styles = {
    customElement: {
      boxShadow: "0px 10px 20px 0px rgba(194, 194, 194, 0.16)",
    },
    customShadowCard: {
      boxShadow: "0px 1px 2px 2px rgba(194, 194, 194, 0.06)",
    },
    customBorderCard: {
      border: "0.5px solid rgba(3, 2, 41, 0.15)",
    },
  };

  const onChange = (key: string) => {
    console.log(key);
  };

  const items: TabsProps["items"] = [
    {
      key: "1",
      label: <h1 className="font-medium text-base">TimeLine</h1>,
      children: inventory?.searchBySn?.data?.map((d: any) => {
        return <History data={d} />;
      }),
    },
    {
      key: "2",
      label: (
        <h1 className="font-medium text-base">
          {["finance", "finance-manager"]?.includes(alloweRole) &&
            "Purchase Order"}
        </h1>
      ),
      children: ["finance", "finance-manager"]?.includes(alloweRole)
        ? inventory?.searchBySn?.data?.map((d: any) => {
            return <ProductPurchaseOrder data={d} />;
          })
        : "",
    },
    {
      key: "3",
      label: inventory?.searchBySn?.data?.at(0)?.status?.toLowerCase() ===
        "sold" && <h1 className="font-medium text-base">Receipt</h1>,
      children: inventory?.searchBySn?.data?.map((d: any) => {
        return <ProductInvoiceDetails data={d} />;
      }),
    },
  ];

  return (
    <ViewLayout
      currentModule="Manage Products"
      subModule={"Product History"}
      onClick={() => props.setProductDetails(false)}
    >
      <InventoryItemSearch />
      <div
        className="bg-white pl-5 pt-2 text-black  rounded-md  mt-5"
        style={styles.customElement}
      >
        <div className="mt-5">
          {inventory?.searchBySn?.data?.map((d: any) => {
            return <ProductDetailsCard data={d} />;
          })}
        </div>
        <Tabs items={items} onChange={onChange} />
      </div>
    </ViewLayout>
  );
};

export default ProductHistoryDetails;
