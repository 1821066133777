import RequestGrid from "../../../../components/grids/RequestGrid";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "@mui/system";
import {
  getAllStockRequestSentAction,
  getStatusCountAction,
} from "../../../../store/wareHouseOut/actions";
import { myChannelActions } from "../../../../store/channel";
import { warehouseOutIncludeItem } from "../../../../assets/data/includedItem";
import CurstomeCardSkeleton from "../../../../components/skeleton/CurstomeCardSkeleton";
import SubLayout from "../../../../components/layout/subLayout/SubLayout";
import InputSearch from "../../../../components/inputs/InputSearch";

const StockRequests = (props: any) => {
  const { wareHouseOut, auth, layout } = useSelector((state: any) => state);
  const dispatch = useDispatch();
  const [page, setPage] = React.useState(1);
  const [limit, setLimit] = React.useState(20);
  const smallScreen = useMediaQuery("(min-width:900px) and (max-width:930px)");

  useEffect(() => {
    if (auth?.token) {
      getAllStockRequestSentAction(
        auth?.token,
        `?limit=${limit}&page=${page - 1}&field=${warehouseOutIncludeItem}`
      )(dispatch);
    }
  }, [auth?.token, dispatch, limit, page]);

  const handleSubmit = () => {
    if (auth?.token) {
      getAllStockRequestSentAction(
        auth?.token,
        `?limit=${limit}&page=${page - 1}&field=${warehouseOutIncludeItem}${
          layout?.selectedFilters?.status
            ? `&status=${layout?.selectedFilters?.status}`
            : ""
        }`
      )(dispatch);
    }
  };
  useEffect(() => {
    if (auth?.token) {
      getStatusCountAction(auth?.token)(dispatch);
    }
  }, [auth?.token, dispatch]);

  const values = wareHouseOut?.allStockRequested?.data;

  useEffect(() => {
    dispatch(myChannelActions.setRfsSelected(null));
  }, []);

  useEffect(() => {
    if (layout?.searchText) {
      auth?.token &&
        getAllStockRequestSentAction(
          auth?.token,
          `?limit=${limit}&page=${
            page - 1
          }&field=${warehouseOutIncludeItem}&sk=${layout?.searchText}`
        )(dispatch);
    } else {
      auth?.token &&
        getAllStockRequestSentAction(
          auth?.token,
          `?limit=${limit}&page=${page - 1}&field=${warehouseOutIncludeItem}`
        )(dispatch);
    }
  }, [layout?.searchText, dispatch]);
  const statusData = [
    {
      title: "status",
      subItems: [
        {
          label: "All",
          value: "",
        },
        {
          label: "Requested",
          value: "request",
        },
        {
          label: "Review",
          value: "review",
        },
        {
          label: "Approved",
          value: "approved",
        },
        {
          label: "Done",
          value: "done",
        },
      ],
    },
  ];
  
  return (
    <SubLayout
      currentModule={props.route ?? "Warehouse"}
      search={
        <div className="w-full xl:w-[25rem]">
          <InputSearch placeHolder="Seach by Warehouse out Id" />
        </div>
      }
      setPage={setPage}
      setLimit={setLimit}
      limit={limit}
      total={
        wareHouseOut?.warehouseOut?.total ||
        wareHouseOut?.allStockRequested?.total
      }
      count={Math.ceil(
        (wareHouseOut?.warehouseOut?.total ||
          wareHouseOut?.allStockRequested?.total) / limit
      )}
      filterData={statusData}
      handleSubmit={handleSubmit}
    >
        <div>
          {wareHouseOut?.requestSentIsFetching ? (
            <div className="grid sm:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 w-full gap-4">
              {Array.from({ length: 8 })?.map((el: any) => (
                <CurstomeCardSkeleton />
              ))}
            </div>
          ) : (
            <div
              className={`grid ${
                layout?.isSideNavOpen && smallScreen
                  ? "sm:grid-cols-1 lg:grid-cols-2"
                  : "sm:grid-cols-2 lg:grid-cols-3"
              } xl:grid-cols-3 2xl:grid-cols-4 gap-4`}
            >
              <RequestGrid data={values} />
            </div>
          )}
        </div>
    </SubLayout>
  );
};

export default StockRequests;
