import React, { useEffect, useState } from "react";
import DetailsTabs from "./cards/DetailsPage/DetailsTabs";
import SidePanelDetails from "./cards/SidePanelDetails";
import ViewLayout from "./layout/subLayout/ViewLayout";
import {
  ProductInfoPricesHeaders,
  ProductInfoStockHeaders,
  ProductInfoSuppliersHeaders,
} from "./csvHeaders/Headers";
import { useDispatch, useSelector } from "react-redux";
import { myLayoutActions } from "../store/layout";
import CsvDownloadButton from "./buttons/CsvDownloadButton";

const DetailsPage = (props: any) => {
  const { product, layout, supplier } = useSelector((state: any) => state);
  const [activeTab, setActiveTab] = useState(0);
  const dispatch = useDispatch();

  const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
    dispatch(myLayoutActions.setSelectedTabIndex(newValue));
  };
  useEffect(() => {
    dispatch(myLayoutActions.setSelectedTabIndex(0));
  }, []);

  const csvData_Stock = product?.productStore?.map((el: any) => ({
    date: el?.updatedAt?.slice(1, 10),
    supplier: el?.warehouseItem?.po?.supplier?.name,
    product: product?.selected?.model,
    serialNumber: el?.barCode,
    specification: el?.specification,
    station: el?.shopStock
      ? el?.shopStock?.channel?.name + "-" + el?.shopStock?.name
      : "In Warehouse",
    status:
      el?.status === "out"
        ? "In Shop"
        : el?.status === "in"
        ? " In Warehouse"
        : el?.status,
  }));

  const csvData_prive = product?.selectedProduct?.data?.map((el: any) => ({
    channel: el?.channel,
    type: el?.product?.type,
    brand: el?.product?.brand,
    model: el?.product?.model,
    specification: el?.specification,
    prices: el?.price,
    exWarranty: el?.extendedWarranty + "%",
  }));

  const csvData_supply = supplier?.suppliersByBrand?.data?.map((el: any) => ({
    model: product?.selected?.model,
    supplierName: el?.name,
    type: el?.type,
    telephone: el?.telephone,
    email: el?.email,
    location: el?.location,
    tin: el?.data?.tin ?? "N/A",
  }));

  const selectedHeader =
    layout?.selectedTabIndex === 0
      ? ProductInfoStockHeaders
      : layout?.selectedTabIndex === 1
      ? ProductInfoPricesHeaders
      : ProductInfoSuppliersHeaders;

  const csvData =
    layout?.selectedTabIndex === 0
      ? csvData_Stock
      : layout?.selectedTabIndex === 1
      ? csvData_prive
      : csvData_supply;

  const fileName =
    layout?.selectedTabIndex === 0
      ? `${props?.simpleDetails?.model}_report.csv`
      : layout?.selectedTabIndex === 1
      ? `${product?.selected?.model}_prices.csv`
      : `${product?.selected?.model}_suppliers.csv`;

  return (
    <ViewLayout
      currentModule={props?.pageName}
      subModule={props?.title}
      buttons={[
        <CsvDownloadButton
          csvHeaders={selectedHeader}
          csvData={csvData}
          filename={fileName}
        />,
      ]}
    >
      <div className="flex-row-reverse gap-2">
        <SidePanelDetails
          UpdateForm={props?.UpdateForm}
          handleClickDelete={props?.handleClickDelete}
          simpleDetails={props?.simpleDetails}
          specsData={props?.specsData}
        />
        {/* Tabs */}
        <DetailsTabs
          tabs={props?.tabs}
          activeTab={activeTab}
          handleChange={handleChange}
        />
      </div>
    </ViewLayout>
  );
};

export default DetailsPage;
