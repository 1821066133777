import { useEffect } from "react";
import { getProductHistoryActions } from "../../../store/inventory/actions";
import { useDispatch, useSelector } from "react-redux";
import { Skeleton } from "antd";

const History = (props: any) => {
  const { auth, inventory } = useSelector((state: any) => state);
  const dispatch = useDispatch();
  const productId = inventory?.searchBySn?.data[0]?._id;
  useEffect(() => {
    auth?.token &&
      getProductHistoryActions(
        auth?.token,
        `wareHouseItem=${productId}`
      )(dispatch);
  }, [auth?.token, dispatch]);

  return (
    <>
      {inventory?.isFetching ? (
        <div className="h-[22rem]">
          <Skeleton />
        </div>
      ) : (
        <ul className="md:ml-6  h-[22rem] overflow-auto py-5">
          {inventory?.productHistory?.data?.length > 0 ? (
            <>
              {inventory?.productHistory?.data?.map((el: any, index: any) => {
                return (
                  <li className="flex" key={index}>
                    <div className="mt-3 h-[auto]">
                      <div className="h-3 w-3 bg-[#0f0f4716] border-[0.1px] border-[#0f0f4746] rounded-full"></div>
                      <hr className="border-[0.1px] border-[#0f0f4711] mt-[0.6px] ml-[.3rem] mr-3 h-full cursor-pointer  duration-500" />
                    </div>
                    <div className={`mb-3 px-6 py-2 w-full h-full rounded-md`}>
                      <div className="w-[90%] ">
                        <div className="mb-2 font-normal">
                          <div className="text-[#0f0f47] mt-3 md:flex gap-2 font-medium text-sm">
                            Delivered From
                            <p className="border w-fit border-[#03022940] rounded-full px-2 md:mx-2 font-normal text-sm mt-3 md:mt-0 flex justify-center items-center">
                              {el?.from}
                            </p>
                            <span className="pl-5 py-2">to</span>
                            <p className="border w-fit border-[#03022940] rounded-full px-2 font-normal md:mx-2 text-sm mt-3 md:mt-0 flex justify-center items-center">
                              {el?.to}
                            </p>
                          </div>

                          {el?.doneAt && (
                            <p className="text-[#03022980] text-[12px] pt-2">
                              Paid On {el?.doneAt?.slice(0, 10)}
                            </p>
                          )}
                        </div>
                        <div className="mt-4">
                          <p className="text-[#0f0f47] flex gap-2 font-medium text-sm">
                            Created At: {el?.createdAt?.slice(0, 10)}
                          </p>
                          <p className="text-[#0f0f47] text-[12px] pt-2">
                            Updated By {el?.updatedBy}
                          </p>
                        </div>
                      </div>
                    </div>
                  </li>
                );
              })}
            </>
          ) : (
            <div className="flex items-center justify-center pt-10">
              <span className="text-gray-300">No Data</span>
            </div>
          )}
        </ul>
      )}
    </>
  );
};

export default History;
