import React, { ReactNode, useState } from "react";
import TopLeftSide from "./TopLeftSide";
import TopRightSide from "./TopRightSide";
import FilterBar from "./FilterBar";
import { TabsProps } from "antd";

interface viewLayoutProps {
  children: ReactNode;
  customSize?: number;
  currentModule: string;
  subModule: string | string[];
  datePicker?: ReactNode;
  buttons?: ReactNode[];
  [key: string]: any;
  filterComponent?: ReactNode;
  StatusComponent?: ReactNode;
  gridList?: ReactNode;
  search?: ReactNode;
  tabs?: boolean;
  items?: TabsProps["items"];
  filterOption?: string;
  setFilterOption?: (value: any) => void;
  onChange?: (key: string) => void;
  onClick?: () => void;
}

const ViewLayout = ({
  children,
  currentModule,
  subModule,
  datePicker,
  buttons,
  filterComponent,
  StatusComponent,
  gridList,
  search,
  tabs,
  filterOption,
  setFilterOption,
  onClick,
  items = [],
  ...props
}: viewLayoutProps) => {
  const checkExist = search !== undefined || StatusComponent !== undefined;
  filterComponent !== undefined || gridList !== undefined;

  return (
    <div className="w-full h-full mt-5 text-[#030229]">
      <div className={`w-full max-h-[7.5rem] overflow-y-scroll scrollbar-hide`}>
        <div className="flex justify-between w-full flex-wrap">
          <TopLeftSide
            currentModule={currentModule}
            subModule={subModule}
            onClick={onClick}
          />
          <TopRightSide datePicker={datePicker} buttons={buttons} {...props} />
        </div>
        <div className="flex justify-between w-full my-4 flex-wrap">
          <div className="flex gap-3">
            <FilterBar
              StatusComponent={StatusComponent}
              filterComponent={filterComponent}
            />
          </div>
        </div>
      </div>
      <div
        className={` ${
          checkExist ? "h-[calc(100vh-240px)]" : "h-[calc(100vh-190px)]"
        } overflow-y-auto  w-full}`}
      >
        {children}
      </div>
    </div>
  );
};

export default ViewLayout;
