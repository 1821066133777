import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getOneCustomerAction } from "../../../store/customer/actions";
import RepairsTable from "../../../apps/repairs/components/RepairsTable";

const CustomerDetails = (props: any) => {
  const { customerId } = useParams();
  const { auth } = useSelector((state: any) => state);

  const dispatch = useDispatch();

  useEffect(() => {
    const fetchOnProduct = async () => {
      if (auth?.token && customerId) {
        await getOneCustomerAction(auth?.token, customerId)(dispatch);
      }
    };
    fetchOnProduct();
  }, [customerId, auth, dispatch]);

  return <RepairsTable />;
};

export default CustomerDetails;
