import React, { useState } from "react";
import { Divider, Drawer, Button } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { updateRepairStatusAction } from "../../../store/repair/actions";
import { useNavigate } from "react-router-dom";
import RepairModal from "../../../components/Modals/RepairModel";
import DelivererIn from "../components/DelivererIn";
import DelivererOut from "../components/DelivererOut";
import DeviceIssues from "../components/DeviceIssues";
import CustomTypography from "../../../styles/globalStyles/CustomTypography";
import { getDefaultCurrencyCode } from "../../../utils/helper";

const RepairDrower: React.FC<{
  open: boolean;
  onClose: any;
  data: any;
  dataToUpdate: any;
  setDataToUpdate: any;
}> = ({ open, onClose, data, setDataToUpdate, dataToUpdate }) => {
  const { auth, company } = useSelector((state: any) => state);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [openModelOnSite, setOpenModelOnSite] = useState(false);
  const [openOfficeModel, setOpenOfficeModel] = useState(false);
  const [checked, setChecked] = useState<any>([]);

  const dataId = data?.key || data?._id;

  const showModalOnSite = () => {
    setOpenModelOnSite(true);
  };

  const showModalOffice = () => {
    setOpenOfficeModel(true);
  };

  const handleCancelOnSite = () => {
    setOpenModelOnSite(false);
  };

  const handleCancelOffice = () => {
    setOpenOfficeModel(false);
  };

  const handlerFixedOnSite = async () => {
    await updateRepairStatusAction(auth.token, dataId, {
      status: "repairing",
      repairedAt: "shop",
    })(dispatch);
    setOpenModelOnSite(false);
    onClose();
    navigate("/repairs");
  };

  const handlerFixedOnOffice = async () => {
    await updateRepairStatusAction(auth.token, dataId, {
      status: "send-to-office",
      repairedAt: "office",
    })(dispatch);
    setOpenOfficeModel(false);
    onClose();
    navigate("/repairs");
  };

  return (
    <>
      <Drawer
        width={600}
        placement="right"
        closable={false}
        onClose={() => {
          onClose();
          setChecked([]);
        }}
        open={open}
        style={{ color: "black" }}
      >
        <CustomTypography variant="h2">Customer</CustomTypography>

        <div className="flex justify-between py-1">
          <CustomTypography variant="label">Names</CustomTypography>
          <CustomTypography variant="value">
            {data?.customer ||
              `${data?.cartItemId?.cart?.customer?.firstName} ${
                data?.cartItemId?.cart?.customer?.lastname || ""
              }` ||
              data?.cartItemId?.cart?.customer?.name}
          </CustomTypography>
        </div>
        <div className="flex justify-between py-1">
          <CustomTypography variant="label">National ID</CustomTypography>
          <CustomTypography variant="value">
            {data?.data?.cartItemId?.cart?.customer?.nid ??
              data?.cartItemId?.cart?.customer?.nid}
          </CustomTypography>
        </div>
        <div className="flex justify-between py-1">
          <CustomTypography variant="label">MSISDN</CustomTypography>
          <CustomTypography variant="value">
            {data?.data?.cartItemId?.cart?.customer?.msisdn ||
              data?.data?.cartItemId?.cart?.customer?.phone ||
              data?.cartItemId?.cart?.customer?.msisdn ||
              data?.cartItemId?.cart?.customer?.phone}
          </CustomTypography>
        </div>
        <Divider />
        <CustomTypography variant="h2">Device</CustomTypography>

        <div className="flex justify-between py-1">
          <CustomTypography variant="label">Type</CustomTypography>
          <CustomTypography variant="value">
            {data?.data?.cartItemId?.shopStockItem?.requestedItem?.product
              ?.product?.type ??
              data?.cartItemId?.shopStockItem?.requestedItem?.product?.product
                ?.type}
          </CustomTypography>
        </div>
        <div className="flex justify-between py-1">
          <CustomTypography variant="label">Brand</CustomTypography>
          <CustomTypography variant="value">
            {" "}
            {data?.data?.cartItemId?.shopStockItem?.requestedItem?.product
              ?.product?.brand ??
              data?.cartItemId?.shopStockItem?.requestedItem?.product?.product
                ?.brand}
          </CustomTypography>
        </div>
        <div className="flex justify-between py-1">
          <CustomTypography variant="label">Model</CustomTypography>
          <CustomTypography variant="value">
            {" "}
            {data?.model ??
              data?.cartItemId?.shopStockItem?.requestedItem?.product?.product
                ?.model}
          </CustomTypography>
        </div>
        <div className="flex justify-between py-1">
          <CustomTypography variant="label">Specifications</CustomTypography>
          <CustomTypography variant="value">
            {" "}
            {data?.specifications ??
              data?.cartItemId?.shopStockItem?.requestedItem?.product?.specification
                ?.slice(2)
                ?.map((d: any) => d[1] && `${d[0]}:${d[1]}`)
                ?.join(", ")}
          </CustomTypography>
        </div>
        <div className="flex justify-between py-1">
          <CustomTypography variant="label">IMEI</CustomTypography>
          <CustomTypography variant="value">
            {" "}
            {data?.data?.cartItemId?.warehouseProduct?.serialNumber ??
              data?.cartItemId?.warehouseProduct?.serialNumber}
          </CustomTypography>
        </div>
        <div className="flex justify-between py-1">
          <CustomTypography variant="label">Price</CustomTypography>
          <CustomTypography variant="value">
            {`${
              data?.data?.cartItemId?.payment?.amount?.toLocaleString() ||
              data?.cartItemId?.payment?.amount?.toLocaleString()
            } ${getDefaultCurrencyCode(company)}`}
          </CustomTypography>
        </div>
        <Divider />
        <CustomTypography variant="h2">Shop</CustomTypography>

        <div className="flex justify-between py-1">
          <CustomTypography variant="label">Name</CustomTypography>
          <CustomTypography variant="value">
            {data?.cartItemId?.shopStockItem?.shop?.name ?? data?.shop}
          </CustomTypography>
        </div>
        <div className="flex justify-between py-1">
          <CustomTypography variant="label">Agent</CustomTypography>
          <CustomTypography variant="value">
            {data?.data?.cartItemId?.cart?.createdBy?.names ??
              data?.cartItemId?.createdBy?.names}
          </CustomTypography>
        </div>
        <Divider />
        <CustomTypography variant="h2">Issues</CustomTypography>

        <DeviceIssues
          issues={data?.data}
          setDataToUpdate={setDataToUpdate}
          dataToUpdate={dataToUpdate}
          setChecked={setChecked}
          checked={checked}
        />

        <Divider />
        <div className="flex flex-col">
          <CustomTypography variant="label">Comment</CustomTypography>
          <CustomTypography
            variant="value"
            className="mt-2 border border-[#03022924] rounded-md p-4 w-full"
          >
            {data?.data?.comment ?? data?.comment}
          </CustomTypography>
        </div>

        <Divider />
        {["done", "completed", "transit-in", "transit-out"]?.includes(
          data?.status
        ) && <DelivererIn data={data?.data?.delivererIn} />}

        {["done", "completed", "transit-out"]?.includes(data?.status) && (
          <DelivererOut data={data?.data?.delivererOut} />
        )}

        {data?.status === "pending" && (
          <div className="space-x-4">
            <Button
              style={{ color: "white" }}
              className="bg-gray-400 border-none outline-none"
              onClick={showModalOnSite}
            >
              Fixed on Site
            </Button>
            <Button
              style={{ color: "#212121" }}
              className="bg-orange-300 border-none outline-none"
              onClick={showModalOffice}
            >
              Fixed on Office
            </Button>
          </div>
        )}
      </Drawer>
      <RepairModal
        title="On-Site Repair"
        openModel={openModelOnSite}
        onOk={handlerFixedOnSite}
        onCancel={handleCancelOnSite}
      />
      <RepairModal
        title="Repaired At Office"
        openModel={openOfficeModel}
        onOk={handlerFixedOnOffice}
        onCancel={handleCancelOffice}
      />
    </>
  );
};

export default RepairDrower;
