import { Stack, Button } from "@mui/material";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import { Select } from "antd";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { myProductActions } from "../../store/product";
import {
  getAllProductsAction,
  getAllAttributesAction,
} from "../../store/product/actions";
import { getPricelistItemAction } from "../../store/channel/actions";
import FilterButton from "../buttons/FilterButton";
const { Option } = Select;

const PriceListFilter = (props: any) => {
  const { product, auth, channel ,layout} = useSelector((state: any) => state);
  const dispatch = useDispatch();
  const [selectedFilters, setSelectedFilters] = useState(false);
  const [type, setType] = useState("");
  const [brand, setBrand] = useState("");
  const [productId, setProductId] = useState("");
  const [page, setPage] = React.useState(1);
  const [limit, setLimit] = React.useState(props?.limit || 15);

  useEffect(() => {
    dispatch(
      myProductActions.setQuery(
        `?${type && `type=${type}&${brand && `brand=${brand}`}`}`
      )
    );
  }, [brand, dispatch, type]);

  const resetFilter = () => {
    if (selectedFilters) {
      setType("");
      setBrand("");
      setProductId("");
    }
  };

  useEffect(() => {
    const fetchingData = async () => {
      auth?.token &&
        getAllProductsAction(
          auth?.token,
          product?.query + `&limit=${limit}&page=${page - 1}` || "?"
        )(dispatch);
      auth?.token &&
        (await getAllAttributesAction(
          auth?.token,
          product?.query || "?"
        )(dispatch));

      auth?.token && productId.length !== 1 && productId.length !== 0
        ? await getPricelistItemAction(
            auth?.token,
            `?channel=${
              channel?.selected?._id ?? auth?.user?.shop?.channel
            }&product=${productId}`
          )(dispatch)
        : await getPricelistItemAction(
            auth?.token,
            `?channel=${channel?.selected?._id}`
          )(dispatch);
    };
    fetchingData();
  }, [
    auth,
    product?.query,
    channel?.selected,
    dispatch,
    productId,
    limit,
    page,
  ]);
  
  return (
    <div className="px-4 py-2">
      <div className="flex sm:items-center sjustify-between">
        <div>
          <Button
            startIcon={
              selectedFilters ? <FilterAltIcon /> : <FilterAltOutlinedIcon />
            }
            onClick={() => {
              setSelectedFilters(!selectedFilters);
              resetFilter();
            }}
          >
            Filter
          </Button>
        </div>

        {selectedFilters && (
          <div className="flex sm:flex-row flex-col gap-2 w-full">
            <Select
              className="h-10"
              showSearch
              allowClear
              placeholder="Filter By Type"
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option?.label ?? "").toString().includes(input)
              }
              loading={product.isFetching}
              disabled={product.isFetching}
              filterSort={(optionA, optionB) =>
                (optionA?.label ?? "")

                  .toString()
                  .toLowerCase()
                  .localeCompare(
                    (optionB?.label ?? "").toString().toLowerCase()
                  )
              }
              options={product?.attributes?.types}
              onChange={(value: any) => {
                !value && setType("");
                !value && setBrand("");
                !value && setProductId(" ");
                value && setType(value);
              }}
            />
            {type && (
              <Select
                className="h-10"
                loading={product.isFetching}
                disabled={product.isFetching}
                showSearch
                allowClear
                placeholder="Filter By Brand"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  (option?.label ?? "").toString().includes(input)
                }
                filterSort={(optionA, optionB) =>
                  (optionA?.label ?? "")

                    .toString()
                    .toLowerCase()
                    .localeCompare(
                      (optionB?.label ?? "").toString().toLowerCase()
                    )
                }
                options={product?.attributes?.brands}
                onChange={(value: any) => {
                  !value && setBrand("");
                  !value && setProductId(" ");
                  value && setBrand(value);
                }}
              />
            )}
            {brand && (
              <Select
                className="h-10"
                loading={product.isFetching || channel.isFetching}
                disabled={product.isFetching || channel.isFetching}
                showSearch
                placeholder="Filter By Product"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  (option?.label ?? "").toString().includes(input)
                }
                filterSort={(optionA, optionB) =>
                  (optionA?.label ?? "")

                    .toString()
                    .toLowerCase()
                    .localeCompare(
                      (optionB?.label ?? "").toString().toLowerCase()
                    )
                }
                onChange={(value: any) => {
                  !value && setProductId(" ");
                  value && setProductId(value);
                }}
                // onSearch={onSearch}
              >
                {product?.all?.data?.map((el: any) => (
                  <Option value={el?._id}>{el?.model}</Option>
                ))}
              </Select>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default PriceListFilter;
